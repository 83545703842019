import CategoryTable from "../../components/categories/CategoryTable";
import CategoriesContextProvider from "./CategoriesContext";
import CreateCategory from "../../components/categories/CreateCategory";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

export default function Categories() {
  const [visible, setVisible] = useState(false);
  return (
    <CategoriesContextProvider>
      <CategoriesWrapper>
        <div className="header">
          <div onClick={(e) => setVisible(true)} className="create-new">
            <PlusOutlined />
            <div>Create new</div>
          </div>
          <CreateCategory visible={visible} setVisible={setVisible} />
        </div>
        <CategoryTable />
      </CategoriesWrapper>
    </CategoriesContextProvider>
  );
}

const CategoriesWrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px;

    .create-new {
      margin-left: auto;
      cursor: pointer;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 40px;
      background: rgb(3 147 8 / 14%);
      font-weight: 600;
      color: green;
      font-size: 16px;
      border-radius: 10px;
    }
  }

  .table-wrapper {
    padding: 10px 20px;
  }
`;
