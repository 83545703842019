import { useContext } from "react";
// import { AnimateSharedLayout } from 'framer-motion';
import { Switch } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";
import AdminLayout from "./components/layout";

import { AuthContext } from "./AuthProvider";

import AuthMiddleware from "./routes/middleware/Authmiddleware";

const permissableRoutes = (routes, pages, user) => {
  if (!user) return [];
  const pagesByKey = pages.reduce((acc, page) => {
    const { key, users = [], roles = [] } = page;
    if (users && users.includes(user.objectId)) {
      acc[key] = page;
    } else if (roles && roles.includes(user.type)) {
      acc[key] = page;
    }
    return acc;
  }, {});

  return routes.filter((route) => {
    return pagesByKey[route.key];
  });
};

const App = () => {
  const {
    login: { data, loading },
    pages,
  } = useContext(AuthContext);

  return (
    !loading && (
      <div>
        <Switch>
          {!data && (
            <NonAuthLayout>
              {authRoutes.map((props, idx) => {
                return <AuthMiddleware key={idx} {...props} />;
              })}
            </NonAuthLayout>
          )}

          {!["customer", "rider", "partner"].includes(data?.type) && (
            <AdminLayout>
              {permissableRoutes(userRoutes, pages, data).map((props, idx) => {
                return (
                  <AuthMiddleware
                    key={idx}
                    data={data}
                    isAuthProtected={true}
                    {...props}
                  />
                );
              })}
            </AdminLayout>
          )}
        </Switch>
      </div>
    )
  );
};

export default App;
