import { createContext, useReducer, useEffect, useState } from "react";
import { Card, Col, Divider, Tag, Typography, message } from "antd";
import useRestaurants from "../../hooks/useRestaurants";
import Parse from "parse";
import { useParams } from "react-router-dom";
import Categories from "../../components/restaurant/categories/index";
import moment from "moment";

export const RestaurantCategories = createContext();

const Types = {
  SET_RESTAURANTS: "SET_RESTAURANTS",
  SET_CATEGORIES: "SET_CATEGORIES",
};

const initialState = {
  restaurant: null,
  categories: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.SET_RESTAURANTS:
      return { ...state, restaurant: action.payload };
    case Types.SET_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export default function RestaurantCategoriesProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getById } = useRestaurants();
  const [logs, setLogs] = useState({});
  const { id } = useParams();

  // FETCH RESTAURANT
  const fetchRestaurant = async (id) => {
    getById(
      {
        id,
        select: ["name", "banner_image"],
        exclude: [
          "categories",
          "managedBy",
          "sub_stores",
          "updatedAt",
          "hub",
          "group",
          "meta_tags",
          "operating_hours",
        ],
      },
      (err, data) => {
        if (data) {
          dispatch({
            type: Types.SET_RESTAURANTS,
            payload: data.toJSON(),
          });
        } else if (err) {
          message.error(err);
        }
      }
    );
  };

  // FETCH CATEGORIES
  const fetchCategories = async (id) => {
    try {
      message.loading("Loading categories...", 0);
      const categories = await Parse.Cloud.run("getRestaurantCategories", {
        id,
      });
      dispatch({
        type: Types.SET_CATEGORIES,
        payload: categories,
      });
      message.destroy(0);
    } catch (err) {
      message.error(err.message);
      message.destroy(0);
    }
  };

  const sortingUpdate = async (params) => {
    try {
      await Parse.Cloud.run("restaurantCategoriesSortingUpdate", params);
    } catch (err) {
      message.error(err.message);
    }
  };

  const updateCategory = async (params, done = () => { }) => {
    try {
      await Parse.Cloud.run("updateRestaurantCategory", params);
      done();
    } catch (err) {
      message.error(err.message);
      done();
    }
  };



  useEffect(() => {
    fetchRestaurant(id);
    fetchCategories(id);
  }, []);



  const updateCategories = (categories) => {
    dispatch({
      type: Types.SET_CATEGORIES,
      payload: categories,
    });
  };
  const fetchCategoryLogs = async () => {
    console.log("fetchCategoryLogs");
    try {
      const logs = await new Parse.Query("logs")
        .equalTo("target", id)
        .select("msg", "username", "createdAt", "previous", "changes")
        .equalTo("type", "restaurant_categories")
        .descending("createdAt")
        .find();

      const logsJson = logs.map((log) => log.toJSON());

      const logsJsonByDate = logsJson.reduce((acc, log) => {
        const date = moment(log.createdAt).format("DD MMM YYYY");
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(log);
        return acc;
      }, {});

      setLogs(logsJsonByDate);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {


    fetchCategoryLogs();
  }, []);

  return (
    <RestaurantCategories.Provider
      value={{ ...state, updateCategories, updateCategory, sortingUpdate, id }}
    >
      <Categories />
      <Divider />
      <Col span={24}>
        <Card title="Categories logs">
          {
            logs && Object.keys(logs).map((d, i) => {
              return (
                <div key={i} style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",

                  margin: "1rem 0"

                }}>
                  <Typography.Title level={4}>{d}</Typography.Title>
                  {logs[d].map((log, i) => {
                    return (
                      <Tag style={{
                        padding: "1rem",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }} color="blue" key={i}>
                        <div>
                          <p>{log.msg}</p>
                          <p>Previous: {JSON.stringify(log.previous)}</p>
                          <p>Changes: {JSON.stringify(log.changes)}</p>
                          <Tag color="orange">By {log.username}</Tag>
                        </div>
                        <div>
                          {moment(log.createdAt).format("hh:mm A")}
                        </div>
                      </Tag>
                    );
                  })}
                </div>
              );
            })
          }
        </Card>
      </Col >
    </RestaurantCategories.Provider >
  );
}
