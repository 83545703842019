import { useEffect } from "react";
import { Form, InputNumber, Select, DatePicker } from "antd";
import { isFloat } from "../../../../common/utils";
const { Item } = Form;

export default function PriceAmount({ formData, form }) {
  useEffect(() => {
    form.setFieldsValue({
      price: {
        discount: {
          type: formData?.price?.discount?.type ?? "fixed",
          amount: formData?.price?.discount?.amount ?? 0,
          validity: formData?.price?.discount?.validity ?? null,
        },
      },
    });
  }, [formData]);

  return (
    <>
      <Item
        name={["price", "amount"]}
        label="Price Amount"
        wrapperCol={{ span: 8 }}
        rules={[
          {
            required: true,
            message: "Please input price amount",
            validator: (rule, value) => {
              if (value === undefined) {
                return Promise.reject("Price required!");
              } else if (isFloat(value)) {
                return Promise.reject("Price must be integer");
              } else if (value < 0) {
                return Promise.reject("Price must be positive");
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <InputNumber style={{ width: "200px" }} placeholder="Amount" />
      </Item>
      <Item
        name={["price", "discount", "type"]}
        label="Discount Type"
        wrapperCol={{ span: 8 }}
        rules={[{ required: true, message: "Please input Vat" }]}
      >
        <Select placeholder="Select one" style={{ minWidth: "200px" }}>
          <Select.Option value="fixed">Fixed</Select.Option>
          <Select.Option value="percent">Percent</Select.Option>
        </Select>
      </Item>
      <Item
        name={["price", "discount", "amount"]}
        label="Discount Amount"
        wrapperCol={{ span: 8 }}
        rules={[
          {
            validator: (rule, value) => {
              if (isFloat(value)) {
                return Promise.reject("Must be integer");
              } else if (value < 0) {
                return Promise.reject("Must be positive");
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <InputNumber
          min={0}
          placeholder="Discount Amount"
          style={{ minWidth: "200px" }}
        />
      </Item>
      <Item
        name={["price", "discount", "validity"]}
        label="Discount Validity"
        wrapperCol={{ span: 8 }}
      >
        <DatePicker
          placeholder="Discount Validity"
          style={{ minWidth: "200px" }}
          showTime
          format="YYYY-MM-DD HH:mm:ss"
        />
      </Item>
    </>
  );
}
