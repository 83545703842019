import { Drawer, message } from "antd";
import { useRef } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  LayerGroup,
  GeoJSON,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import styled from "styled-components";
import { layerToArea } from ".";
import { v3Axios } from "../../utils";

export default function EditArea({
  osmProviders,
  layer: originalLayer,
  setVisible,
  updateLayer,
}) {
  const featureGroupRef = useRef(null);
  const center = { lat: 23.770112, lng: 90.410154 };
  const zoom = 15;

  const _onCreate = async (e) => {
    const layer = e.layer.toGeoJSON();
    layer.properties = {
      name: originalLayer.properties.name,
    };
    layer.zone = originalLayer.zone;
    const area = layerToArea(layer);

    try {
      await v3Axios.patch(`/api/v1/areas/${originalLayer.id}`, {
        location: area.location,
      });

      updateLayer(originalLayer.id, area.location.coordinates);
      message.success("Area updated successfully");
      setVisible(null);
    } catch (err) {
      message.error(err.message || "Something went wrong");
    }
  };

  return (
    <Drawer
      title={`Update Area ${originalLayer.properties.name}`}
      placement="right"
      visible={Boolean(originalLayer)}
      onClose={() => setVisible(false)}
      width={1000}
    >
      <Wrapper>
        <MapContainer center={center} zoom={zoom}>
          <FeatureGroup>
            <LayerGroup ref={featureGroupRef}>
              <GeoJSON
                data={originalLayer}
                style={{
                  color: `#a35e60`,
                  CSSStyleSheet: "solid",
                }}
              />
            </LayerGroup>
            <EditControl
              position="topright"
              onCreated={_onCreate}
              draw={{
                circlemarker: false,
                circle: false,
                marker: false,
                polyline: false,
              }}
              edit={{
                edit: false,
              }}
            />
          </FeatureGroup>
          <TileLayer
            url={osmProviders.mapTiler.url}
            attribution={osmProviders.mapTiler.attribution}
          />
        </MapContainer>
      </Wrapper>
    </Drawer>
  );
}

const Wrapper = styled.div`
  .leaflet-container {
    width: 100%;
    height: 600px;
  }

  .zone {
    height: calc(100vh - 140px);
    padding-right: 10px;
    &-item {
      padding: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      background: #fff;

      .icon {
        cursor: pointer;
        color: #ff4d4f;
        width: 20px;
      }
    }
  }
`;
