import { useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  message,
  DatePicker,
  Space,
} from "antd";
import Styled from "styled-components";
import usePurchaseRecord from "../../hooks/usePurchaseRecord";
import useProduct from "../../hooks/useProduct";
import { parser } from "../../utils";
import InventoriesByProduct from "./InventoriesByProduct";
import Parse from "parse";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function AddNewRecord() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({
    loading: false,
    data: [],
  });
  const { addNewPurchaseRecord } = usePurchaseRecord();
  const { getProducts } = useProduct();
  const [purchaseRecord, setPurchaseRecord] = useState({
    loading: false,
    data: null,
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hubs, setHubs] = useState({});

  const onFinish = (values) => {
    const { variants } = values;
    if (Array.isArray(variants)) {
      const vGroup = selectedProduct.price.variants.reduce((acc, v) => {
        v.items.forEach((item) => {
          acc[item.id] = item;
        });
        return acc;
      }, {});

      variants.forEach((variant) => {
        const v = vGroup[variant.id];
        if (v) {
          variant.name = v.name;
        }
      });
    }

    if (values.unit_price > values.selling_price) {
      const ok = window.confirm(
        "Unit price is higher than selling price. Are you sure?"
      );
      if (!ok) return;
    }

    if (values.expired_date) {
      values.expired_date = new Date(values.expired_date).toISOString();
    }

    setLoading(true);
    addNewPurchaseRecord(values, (err, res) => {
      setLoading(false);
      if (res) {
        message.success("New purchase record added successfully!");
        form.resetFields();
      }

      if (err) {
        message.error(err);
      }
    });
  };

  const fetchHubs = async (done) => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      const obj = {};
      hubs.forEach((hub) => {
        obj[hub.id] = hub.get("name");
      });
      setHubs(obj);
      if (typeof done === "function") done(obj);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    if (purchaseRecord.data) {
      setPurchaseRecord({ loading: false, data: null });
      form.resetFields();
    }

    return () => {
      form.resetFields();
    };
  }, []);

  // Fetch products
  useEffect(() => {
    fetchHubs((hubs) => {
      getProducts(
        {
          limit: 20,
          is_inv: true,
          select: [
            "name",
            "images",
            "price",
            "is_inv",
            "stock",
            "restaurant.hub",
            "restaurant.name",
          ],
          exclude: ["timeline", "inventory", "category"],
        },
        (err, res) => {
          if (res) {
            let data = parser(res.results);
            data.forEach((product) => {
              const hubId = product.restaurant?.get("hub")?.id;
              if (hubId) {
                product.hub = hubs[hubId];
              }
            });
            setProducts({ loading: false, data });
          } else if (err) {
            message.error(err);
          }
        }
      );
    });
  }, []);

  const getVariants = (variants, filter = []) => {
    if (!Array.isArray(variants)) return [];
    let arr = [];
    variants.forEach(({ items }) => {
      items.forEach((item) => {
        if (!filter.find((f) => f?.id === item.id)) {
          arr.push(item);
        }
      });
    });
    return arr;
  };

  return (
    <section>
      <Row justify="center" gutter={[24, 24]}>
        <Col md={18} lg={14} xl={12}>
          <FormStyled
            form={form}
            layout="vertical"
            name="addNewRecord"
            onFinish={onFinish}
            size="large"
            className="border"
          >
            <div className="header">
              <div className="left-section">
                <h3 className="title">Add new record</h3>
              </div>
            </div>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="product"
                  label="Select product"
                  rules={[
                    {
                      required: true,
                      message: "Please select product item!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={purchaseRecord.data}
                    placeholder="Select Item"
                    optionFilterProp="children"
                    loading={products.loading}
                    onChange={(value) => {
                      let prod = products.data?.find(
                        (item) => item.id === value
                      );
                      if (prod) {
                        setSelectedProduct(prod);
                      }
                    }}
                    onSearch={(value) => {
                      getProducts(
                        {
                          search: value,
                          limit: 20,
                          is_inv: true,
                          select: [
                            "name",
                            "images",
                            "price",
                            "is_inv",
                            "stock",
                            "restaurant.hub.name",
                            "restaurant.name",
                          ],
                          exclude: ["timeline", "inventory", "category"],
                        },
                        (err, res) => {
                          if (res) {
                            let data = parser(res.results);
                            data.forEach((product) => {
                              const hubId = product.restaurant?.get("hub")?.id;
                              if (hubId) {
                                product.hub = hubs[hubId];
                              }
                            });
                            setProducts({
                              loading: false,
                              data,
                            });
                          } else if (err) {
                            message.error(err);
                            setProducts({
                              loading: false,
                              data: [],
                            });
                          }
                        }
                      );
                    }}
                    filterOption={(input, option) => {
                      return (
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {products.data?.map((prod, i) => {
                      return (
                        <Option key={i} value={prod.id}>{`${prod.name}  | ${
                          prod.hub
                        } | ${prod.restaurant?.get("name")}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {selectedProduct && (
                  <>
                    <Space>
                      <strong>Current stock: {selectedProduct?.stock}</strong>
                      <strong>
                        Current price: {selectedProduct.price?.amount} tk
                      </strong>
                    </Space>
                    <div>
                      Restaurant: {selectedProduct?.restaurant?.get("name")}
                    </div>
                  </>
                )}
              </Col>
              {selectedProduct &&
              Array.isArray(selectedProduct.price.variants) ? (
                <Col span={24}>
                  <h3>Variants</h3>

                  <Form.List name="variants">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          {fields.map(({ key, name, ...rest }, index) => {
                            return (
                              <div className="variant-item" key={index}>
                                <Row gutter={[16, 16]}>
                                  <Col span={9}>
                                    <Form.Item
                                      {...rest}
                                      name={[name, "id"]}
                                      label={`Variant Item`}
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <Select placeholder="Select Item">
                                        {getVariants(
                                          selectedProduct?.price?.variants,
                                          form.getFieldsValue()?.variants
                                        ).map((variant) => {
                                          return (
                                            <Select.Option
                                              key={variant.id}
                                              value={variant.id}
                                            >
                                              {variant.name}
                                            </Select.Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      name={[name, "quantity"]}
                                      label="Quantity"
                                      rules={[
                                        {
                                          type: "number",
                                          required: true,
                                          min: 0,
                                          message: "Please input quantity!",
                                        },
                                      ]}
                                    >
                                      <InputNumber placeholder="Quantity" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      name={[name, "unit_price"]}
                                      label="Unit price"
                                      rules={[
                                        {
                                          type: "number",
                                          required: true,
                                          min: 0,
                                          message: "Please input unit price!",
                                        },
                                      ]}
                                    >
                                      <InputNumber placeholder="Unit price" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={3}>
                                    <Form.Item label=" ">
                                      <Button
                                        icon={<MinusOutlined />}
                                        shape="circle"
                                        onClick={() => remove(name)}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Button
                            icon={<PlusOutlined />}
                            shape="circle"
                            onClick={() => add()}
                          />
                        </div>
                      );
                    }}
                  </Form.List>
                </Col>
              ) : (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="quantity"
                      label="Quantity"
                      rules={[
                        {
                          type: "number",
                          required: true,
                          min: 1,
                          message: "Please input quantity!",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Quantity" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="unit_price"
                      label="Unit Price"
                      rules={[
                        {
                          required: true,
                          message: "Please input purchase price!",
                        },
                      ]}
                    >
                      <InputNumber min={0} placeholder="Unit Price" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="selling_price"
                      label="Selling Price"
                      rules={[
                        {
                          required: true,
                          message: "Please input selling price!",
                        },
                      ]}
                    >
                      <InputNumber min={0} placeholder="Selling Price" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="expired_date" label="Expires On">
                      <DatePicker
                        disabledDate={(current) => {
                          return current.isBefore(Date.now(), current);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    loading={loading}
                    htmlType="submit"
                    type="primary"
                  >
                    {purchaseRecord.data ? "Update" : "Submit"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </FormStyled>
        </Col>
        {selectedProduct && (
          <InventoriesByProduct product={selectedProduct.id} />
        )}
      </Row>
    </section>
  );
}

const FormStyled = Styled(Form)`
    background: #fff;
    padding: 20px;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-section{
            display: flex;
            align-items: center;
            justify-content: center;

            .title{
                font-size: 24px;
                margin-bottom: 0;
                letter-spacing: 1px;
                font-weight: 500;
            }
        }
    }

    .ant-input-number{
        width: 100%;
    }

    .ant-form-item{
        margin-bottom: 0px;
    }

    .product-name{
        font-size: 18px;
        background: #001529;
        min-height: 40px;
        padding: 10px 20px;
        color: #fff;
        letter-spacing: 1px;
        border-radius: 2px;
    }

    .variant-item{
        background: #f0f2f5;
        padding: 10px 20px;
        margin-bottom: 10px;
        border-radius: 2px;

        .title {
          font-size: 16px;
          font-weight: 500;
        }
    }
`;
