let areas = [
  {
    name: "adabor",
    items: [
      "adarsha chaya neer",
      "baitul aman housing society",
      "comfort housing",
      "dhaka housing",
      "ibn sina housing",
      "janata housing limited",
      "mehedibagh housing",
      "mohanpur",
      "monsurabad residential area",
      "north adabor",
      "shyamoli housing limited",
      "sunibir housing society",
    ],
  },
  {
    name: "aftabnagar",
    items: [
      "block a",
      "block b",
      "block c",
      "block d",
      "jahurul islam city",
      "sector 1, jahurul islam city",
      "sector 2, jahurul islam city",
    ],
  },
  {
    name: "agargaon",
    items: ["agargaon administrative area", "taltola", "west agargaon"],
  },
  {
    name: "azimpur",
    items: ["3 no bgb gate", "amtola", "polashi", "rasulbagh"],
  },
  {
    name: "babu bazar",
    items: ["bongshi bazar", "imamganj", "mitford", "naya bazar", "zindabahar"],
  },
  {
    name: "badda",
    items: [
      "adarshanagar",
      "anandanagar",
      "dit project",
      "dit project, merul badda",
      "dit project, middle badda",
      "east badda",
      "merul badda",
      "middle badda",
      "mogairdia",
      "north badda",
      "north east badda",
      "padordia, north badda",
      "padordia, satarkul, north badda",
      "satarkul, north badda",
      "south badda",
      "united city",
    ],
  },
  {
    name: "baganbari",
    items: ["madartek"],
  },
  {
    name: "bakshi bazar",
    items: ["nawab bagicha"],
  },
  {
    name: "banani",
    items: [
      "banani dohs",
      "block a",
      "block b",
      "block c",
      "block d",
      "block e",
      "block f",
      "block g",
      "block h",
      "block i",
      "block j",
      "block k",
      "block l",
      "block m",
      "chairman bari",
      "kakoli",
      "soinik club railgate",
    ],
  },
  {
    name: "banasree",
    items: [
      "block a",
      "block b",
      "block c",
      "block d",
      "block e",
      "block f",
      "block g",
      "block h",
      "block j",
      "block l",
      "block m",
      "south banasree",
    ],
  },
  {
    name: "bangshal",
    items: [
      "ali neki dewri",
      "alu bazar",
      "armanitola",
      "kayet tuli",
      "kosaituly",
      "mahuttuli",
      "malitola",
      "mokim bazar",
      "nawab katra",
      "naya bazar",
      "nazira bazar",
      "old maghaltuli",
      "satraoja",
      "shat raoja",
      "siddique bazar",
      "sikka tuly",
      "suritola",
    ],
  },
  {
    name: "baridhara",
    items: ["baridhara dohs", "block j", "block k"],
  },
  {
    name: "bashabo",
    items: [
      "east bashabo",
      "kadamtola",
      "madartek",
      "middle bashabo",
      "north bashabo",
      "sabujbagh",
      "south bashabo",
    ],
  },
  {
    name: "bashundhara",
    items: [
      "block a",
      "block b",
      "block c",
      "block d",
      "block e",
      "block f",
      "block g",
      "block h",
      "block i",
      "ekuria bazar",
    ],
  },
  {
    name: "baunia",
    items: [
      "badaldi",
      "baunia bazar",
      "bottola",
      "dakkhinpara",
      "kalibari",
      "karimbagh",
      "north baunia",
      "pabnartek",
      "pukurpar",
    ],
  },
  {
    name: "beraid",
    items: ["bhuiya para", "boro beraid", "chinadipara", "purbopara"],
  },
  {
    name: "bhashantek",
    items: [
      "baganbari, north bhashantek",
      "benaroshi palli",
      "bhasantek bazar",
      "bhasantek bazar, benaroshi math",
      "bhashantek bazar",
      "block d, ruposhi bangla housing",
      "block ka, benaroshi polli",
      "bostir bazar",
      "dewanpara",
      "dhamalkot",
      "east bhashantek",
      "north bhashantek",
      "shyamol polli residential area, west bhashantek",
      "west bhashantek",
    ],
  },
  {
    name: "chankharpul",
    items: ["chankharpul moar"],
  },
  {
    name: "chawkbazar",
    items: [
      "becharam dewri",
      "begum bazar",
      "boro katra",
      "choto katra",
      "mitford",
      "mogholtuli",
      "moulvibazar",
      "rahamotganj",
      "showari ghat",
    ],
  },
  {
    name: "dakkhinkhan",
    items: [
      "ainusbagh",
      "amtola",
      "anol",
      "anwarbagh",
      "ashiyan city",
      "ashkona",
      "ashkona, gawair",
      "atipara",
      "azampur",
      "chairman para",
      "chalabon",
      "coatbari",
      "dakkhinkhan bazar",
      "dakkhinpara",
      "faidabad",
      "gawair",
      "gawair, ashkona",
      "gawair, chalabon",
      "hatimbag",
      "holan",
      "islambagh",
      "joynal market",
      "kawla",
      "kawla, baurartek",
      "koshai bari",
      "koshai bazar railgate",
      "madhubagh",
      "miyapara",
      "modhubagh",
      "mollabari",
      "mollapara",
      "mollartek",
      "mondolbari",
      "moushair",
      "naddapara",
      "nagarbari",
      "nowapara",
      "ponditpara",
      "purbopara",
      "sarderpara",
      "sayednagar",
      "taltola",
      "sarderpara",
    ],
  },
  {
    name: "dayaganj",
    items: ["dayaganj bazar", "jelepara", "koratitola"],
  },
  {
    name: "demra",
    items: [
      "adarshabagh",
      "amulia model town",
      "bamoil",
      "bamoil, sarulia",
      "basherpool",
      "basherpool, paradogar",
      "borobhanga",
      "chanpara",
      "dogair",
      "dogair, borobhanga",
      "dogair, sarulia",
      "hazi nagar, sarulia",
      "konapara",
      "mahmud nagar",
      "matuail",
      "mirpara",
      "noraibag",
      "paity",
      "paradogair",
      "paradogair, konapara",
      "paradogar",
      "paradogar, konapara",
      "puraton paradogar",
      "rajakhali",
      "sanarpar, dogair",
      "sanarpar, sarulia",
      "sarulia",
      "sarulia, hazi nagar",
      "signboard",
      "signboard, dogair",
      "staff quarter",
      "tengra",
      "tengra, sarulia",
      "thulthulia",
    ],
  },
  {
    name: "dhaka cantonment",
    items: [
      "albodirtek",
      "baganbari",
      "baigartek",
      "balughat",
      "barontek",
      "cantonment residential area",
      "dholaikhal",
      "garrison",
      "kachukhet",
      "manikdi",
      "manikdi, matikata",
      "matikata",
      "nirjhor residential area",
      "zia colony",
    ],
  },
  {
    name: "dhanmondi",
    items: [
      "green square",
      "jafrabad",
      "jafrabad, sankar",
      "jigatola",
      "madhu bazar",
      "north dhanmondi",
      "sankar",
      "science laboratory",
      "shukrabad",
      "sobhanbagh",
      "west dhanmondi",
    ],
  },
  {
    name: "dholairpar",
    items: ["dholairpar bazar", "east dholairpar"],
  },
  {
    name: "donia",
    items: ["nayapara", "north donia", "rasulpur", "south donia"],
  },
  {
    name: "dumni",
    items: ["pink city"],
  },
  {
    name: "eskaton",
    items: ["bangla motor", "new eskaton", "pwd quater"],
  },
  {
    name: "fakirapul",
    items: ["fakirapul bazar"],
  },
  {
    name: "fulbaria",
    items: ["ananda bazar"],
  },
  {
    name: "gendaria",
    items: [
      "100 katha",
      "52 katha",
      "bagha bari",
      "bagicha",
      "boishakhi housing",
      "dayaganj chowrasta",
      "dhupkhola",
      "dit",
      "faridabad",
      "ghunti ghor",
      "husain bagh",
      "katherpool",
      "loharpool",
      "murgitola",
      "nama para",
      "shahid nagar",
      "vati khana",
    ],
  },
  {
    name: "golapbagh",
    items: [
      "brammon chiron",
      "east golapbagh",
      "kazirbagh",
      "north golapbagh",
      "rishi para",
    ],
  },
  {
    name: "goran",
    items: ["east goran", "north goran", "south goran"],
  },
  {
    name: "gulisthan",
    items: ["alu bazar", "fulbaria", "siddique bazar", "zero point"],
  },
  {
    name: "gulshan",
    items: ["gulshan 1", "gulshan 2", "niketon"],
  },
  {
    name: "hatirpool",
    items: ["pukurpar"],
  },
  {
    name: "hazaribagh",
    items: [
      "badda nagar",
      "bagholpur",
      "bhuiyan estate",
      "borhanpur",
      "charakghata",
      "company ghat",
      "enayetganj",
      "gajmahal",
      "gonokatuli",
      "hazaribagh bazar",
      "hazaribagh model city",
      "jhauchor",
      "jhauchor model town",
      "kalu nagar",
      "moneshwar",
      "nabipur",
      "nawabganj",
      "shikder real state",
      "sonatongor",
      "tannery block",
      "tannery moar",
      "west hazaribagh",
    ],
  },
  {
    name: "islambagh",
    items: ["east islambagh"],
  },
  {
    name: "islampur",
    items: ["kumartoli"],
  },
  {
    name: "jatrabari",
    items: [
      "bibir bagicha",
      "brammon chiron",
      "chontek",
      "dayaganj",
      "dholpur",
      "east dholairpar",
      "gabindapur",
      "hazirbagh",
      "kajlarpar",
      "kazipara",
      "kazirgaon",
      "konapara",
      "konapara, matuail",
      "kutubkhali",
      "matuail",
      "mazpara",
      "mir hazirbagh",
      "mridhabari",
      "north jatrabari",
      "paradogar",
      "rayerbagh",
      "sheikhdi",
      "shonir akhra",
      "south jatrabari",
      "south sayedabad",
      "west jatrabari",
      "west mir hazirbagh",
    ],
  },
  {
    name: "jigatola",
    items: [
      "gabtola colony",
      "nijhum residential area",
      "niloy residential area",
      "rayer bazar",
      "tannery block",
      "tannery moar",
      "tollabagh",
    ],
  },
  {
    name: "joar shahara",
    items: [
      "balur math",
      "himbari",
      "joar shahara bazar",
      "lichu bagan",
      "olipara",
    ],
  },
  {
    name: "jurain",
    items: [
      "east jurain",
      "faridabad",
      "muradpur",
      "new jurain",
      "north jurain",
    ],
  },
  {
    name: "kadamtali",
    items: [
      "dholairpar",
      "east jurain",
      "janatabagh",
      "muradpur",
      "north muradpur",
      "rayerbagh",
      "shonir akhra",
    ],
  },
  {
    name: "kafrul",
    items: [
      "corner view residential area",
      "east kafrul",
      "ibrahimpur",
      "north east kafrul",
      "north kafrul",
      "south kafrul",
      "west kafrul",
    ],
  },
  {
    name: "kalabagan",
    items: ["green view area", "lake circus"],
  },
  {
    name: "kalachandpur",
    items: ["moddhopara", "poshchimpara", "uttarpara"],
  },
  {
    name: "kallyanpur",
    items: [
      "banoful residential area",
      "eastern housing 2",
      "kallyanpur notun bazar",
      "navana garden society",
      "rojonigondha residential area",
      "south kallyanpur",
    ],
  },
  {
    name: "kamalapur",
    items: ["north kamalapur", "south kamalapur"],
  },
  {
    name: "kamrangirchar",
    items: [
      "ahsanbagh",
      "ali nagar",
      "aminbagh",
      "ashrafbad",
      "ashrafbad, nurbagh",
      "borogram",
      "hasan nagar",
      "hazrat nagar",
      "huzurpara",
      "ibrahim nagar",
      "islam nagar",
      "jannatbagh",
      "jawlahati",
      "karimabad",
      "khalifaghat",
      "kurarghat",
      "mahadi nagar",
      "mominbagh",
      "monsurbagh",
      "munshihati",
      "muslimbagh",
      "nabinagar",
      "nizambagh",
      "nurbagh",
      "rasulpur",
      "tekerhati",
    ],
  },
  {
    name: "kathalbagan",
    items: ["box culvert", "pukurpar"],
  },
  {
    name: "khilgaon",
    items: [
      "block a",
      "block c",
      "bou bazar",
      "chowdhurypara",
      "gouranagar",
      "jheelpar",
      "jorapukur para",
      "khilgaon punorbason area",
      "khilgaon shati elma residential area",
      "khilgaon somaj kallyan society",
      "khilgaon south society",
      "moulovirtek",
      "moulovirtek residential area",
      "nandipara",
      "nobinbagh",
      "notunbagh",
      "riajbagh",
      "saherunbagh",
      "shantipur",
      "sipahibagh",
      "south khilgaon",
      "taltola",
      "tilpapara",
      "trimohoni",
    ],
  },
  {
    name: "khilkhet",
    items: [
      "aburtek",
      "amtola",
      "barua",
      "beparipara",
      "boatghat",
      "bottola",
      "dorjipara",
      "kha para",
      "khilkhet bazar",
      "lake city concord",
      "moddhopara",
      "moddhopara bazar",
      "namapara",
      "namapara, talertek",
      "naval area",
      "naya nagar",
      "tetultola",
      "uttarpara",
    ],
  },
  {
    name: "korail",
    items: ["bou bazar", "jamai bazar"],
  },
  {
    name: "kotwali",
    items: [
      "babu bazar",
      "badamtoli",
      "ghi potti",
      "islampur",
      "nawab bari",
      "naya bazar",
      "patuatuli",
      "ray saheb bazar",
      "ray saheb bazar moar",
      "ray shaheb bazar",
      "shakhari bazar",
      "tati bazar",
    ],
  },
  {
    name: "kuril",
    items: [
      "ghatpar",
      "kazi bari",
      "kazibari",
      "kuratoli",
      "kuril chowrasta",
      "miya bari",
      "moddhopara",
      "purbopara",
      "south kuril",
    ],
  },
  {
    name: "lalbagh",
    items: [
      "amligola",
      "chadni ghat",
      "chowdhury bazar",
      "dhakeshwari",
      "islambagh",
      "islampur",
      "kashmiritola",
      "khaje dewan",
      "killar moar",
      "nawabganj",
      "palashi",
      "pilkhana",
      "posta",
      "rasulbagh",
      "shahidnagar",
      "sheikh shaheb bazar",
    ],
  },
  {
    name: "lalmatia",
    items: [
      "block a",
      "block b",
      "block c",
      "block d",
      "block e",
      "block f",
      "block g",
    ],
  },
  {
    name: "madartek",
    items: [
      "chowrasta",
      "east madartek",
      "middle madartek",
      "north madartek",
      "south madartek",
      "west madartek",
      "west nandipara",
    ],
  },
  {
    name: "malibagh",
    items: [
      "baganbari",
      "chowdhurypara",
      "east malibagh",
      "gulbagh",
      "malibagh railgate bazar",
      "mouchak",
      "sonalibagh",
      "west malibagh",
    ],
  },
  {
    name: "manda",
    items: ["north manda", "south manda"],
  },
  {
    name: "maniknagar",
    items: ["east maniknagar", "kazirbagh", "north maniknagar"],
  },
  {
    name: "matuail",
    items: ["matuail bus stand", "saddam market"],
  },
  {
    name: "meradia",
    items: [
      "ansarbagh",
      "bhuiyapara",
      "hindupara",
      "meradia bazar",
      "moddhopara",
      "nayapara",
      "noapara",
      "west meradia",
    ],
  },
  {
    name: "mirpur",
    items: [
      "1st colony",
      "1st colony, lalkuthi",
      "2nd colony",
      "3rd colony",
      "ahmed nagar",
      "ahmed nagar, paikpara",
      "al kamal housing",
      "alubdi",
      "ananda nagar",
      "arambagh",
      "arifabad housing",
      "baghbari",
      "baishteki",
      "baishteki, section 13",
      "bardhan bari",
      "basupara",
      "basupara, lalkuthi",
      "batennagar",
      "bcic colony",
      "bishil",
      "borobagh",
      "borobazar",
      "box nagar",
      "darussalam",
      "diabari",
      "duaripara",
      "eastern housing",
      "extension pallabi",
      "gabtoli",
      "godkhali",
      "golartek",
      "goran chotbari",
      "gudaraghat, section 1",
      "harirampur",
      "jahanabad",
      "jahurabad",
      "janata housing",
      "kabarsthan gate",
      "kalwalapara",
      "kazipara",
      "kotbari",
      "lalkuthi",
      "mirpur cantonment",
      "mirpur dohs",
      "monipur",
      "muktijoddha complex",
      "nababerbagh",
      "paikara",
      "paikpara",
      "pallabi",
      "pirerbagh",
      "priyanka housing",
      "rayhan market",
      "royal city",
      "rupnagar",
      "rupnagar industrial area",
      "sagufta housing society",
      "section 1",
      "section 10",
      "section 11",
      "section 12",
      "section 13",
      "section 13, baishteki",
      "section 14",
      "section 15",
      "section 2",
      "section 3",
      "section 6",
      "section 7",
      "section 8",
      "section 9",
      "senpara",
      "senpara parbata",
      "shagufta",
      "shagufta housing",
      "shah ali bagh",
      "shahid buddijibi area",
      "shewrapara",
      "technical",
      "tolarbagh",
    ],
  },
  {
    name: "moghbazar",
    items: [
      "boro moghbazar",
      "mirbagh",
      "mirertek",
      "modhubagh",
      "noyatola",
      "pearabagh",
      "sonalibagh",
      "tnt colony",
      "wireless",
      "wireless railgate",
    ],
  },
  {
    name: "mohakhali",
    items: [
      "amtoli",
      "dakkhinpara",
      "korail",
      "mohakhali bazar",
      "mohakhali commercial area",
      "mohakhali dohs",
      "mohakhali wireless",
      "muktijoddha chaoni c&b taltola",
      "muktijoddha chaoni dakkhin para",
      "rasulbagh",
      "south mohakhali",
      "tb gate",
      "wireless gate",
    ],
  },
  {
    name: "mohammadpur",
    items: [
      "ajij mahalla",
      "akkas nagar",
      "akota housing",
      "asad gate",
      "bashbari",
      "basila",
      "basila, metro housing",
      "bijli mahalla",
      "bijli mahalla, jannatbagh",
      "block a",
      "block b",
      "block c",
      "block d",
      "block e",
      "block f",
      "chad uddyan",
      "chand miya housing",
      "chandrima model town",
      "college gate",
      "dhaka uddyan",
      "future housing",
      "future model town",
      "geneva camp",
      "green view housing",
      "jafrabad",
      "jahuri mahalla",
      "joint quarter, aziz mahalla",
      "katashur",
      "krishi market",
      "masjid market",
      "metropolitan housing society",
      "mohammadia housing",
      "mohammadia housing society",
      "nabinagar housing",
      "nobodoy housing",
      "nobodoy housing limited",
      "nobodoy housing society",
      "pisci culture housing society",
      "pisciculture housing society",
      "rajdhani housing",
      "rayer bazar",
      "saat masjid housing",
      "shaymoli housing limited",
      "shekhertek",
      "shyamoli housing",
      "swapnodhara housing",
      "tikkapara",
      "tin raster moar",
      "town hall",
      "turag housing",
      "west dhanmondi residential area, basila",
    ],
  },
  {
    name: "motijheel",
    items: [
      "agb colony",
      "arambagh",
      "bank colony",
      "dilkusha commercial area",
      "fakirapul",
      "motijheel commercial area",
      "motijheel government colony",
      "tnt colony",
    ],
  },
  {
    name: "mugda",
    items: [
      "bank colony",
      "green model town",
      "north mugda",
      "sabujkanan",
      "south mugda",
    ],
  },
  {
    name: "nadda",
    items: [
      "morol bazar",
      "nadda bazar",
      "nurerchala",
      "purbopara",
      "sarkerbari",
    ],
  },
  {
    name: "nandipara",
    items: [
      "boro bottola",
      "choto bottola",
      "east nandipara",
      "middle nandipara",
      "nandipara bazar",
      "west nandipara",
    ],
  },
  {
    name: "nawabpur",
    items: ["bash potti", "kaptan bazar", "rothkhola", "thatari bazar"],
  },
  {
    name: "naya bazar",
    items: ["zindabahar"],
  },
  {
    name: "new eskaton",
    items: ["bangla motor", "gaus nagar"],
  },
  {
    name: "new market",
    items: ["bata signal", "katabon", "nilkhet", "science laboratory"],
  },
  {
    name: "nikunja",
    items: ["nikunja 1", "nikunja 2"],
  },
  {
    name: "paltan",
    items: ["bijoynagar", "naya paltan", "purana paltan"],
  },
  {
    name: "panthapath",
    items: ["rasel square"],
  },
  {
    name: "purbachal",
    items: [
      "sector 1",
      "sector 10",
      "sector 11",
      "sector 13",
      "sector 2",
      "sector 3",
      "sector 4",
      "sector 7",
      "sector 8",
      "sector 9",
    ],
  },
  {
    name: "rajarbagh",
    items: ["chamelibagh", "momenbagh"],
  },
  {
    name: "ramna",
    items: ["nawratan colony"],
  },
  {
    name: "rampura",
    items: ["east rampura", "hazipara", "mohanagar project", "west rampura"],
  },
  {
    name: "rayer bazar",
    items: [
      "baroikhali",
      "bottola",
      "chorokghata",
      "east rayer bazar",
      "jafrabad",
      "modhubazar",
      "nimtola",
      "rayer bazar model town",
      "sultanganj",
      "sultanganj, baroikhali",
      "west dhanmondi",
    ],
  },
  {
    name: "rayerbagh",
    items: ["mujahid nagar"],
  },
  {
    name: "sabujbagh",
    items: [
      "ahmedbagh",
      "bashabo",
      "begunbari",
      "dakkhingaon",
      "east rajarbagh",
      "kusumbagh",
      "madartek",
      "manikdia",
      "mayakanon",
      "rajarbagh",
    ],
  },
  {
    name: "sayedabad",
    items: [
      "brammon chiron",
      "jonopoth moar",
      "koratitola",
      "saidabad bus stand",
      "south sayedabad",
    ],
  },
  {
    name: "shahbagh",
    items: ["katabon"],
  },
  {
    name: "shahjadpur",
    items: [
      "bashtola",
      "east shahjadpur",
      "jheelpar",
      "shahjadpur bus stand",
      "south shahjadpur",
    ],
  },
  {
    name: "shahjahanpur",
    items: [
      "benjir baganbari residential area",
      "north shahjahanpur",
      "railway government colony",
      "railway staff colony",
      "shahidbagh",
      "south shahjahanpur",
    ],
  },
  {
    name: "shamibagh",
    items: ["koratitola"],
  },
  {
    name: "shanir akhra",
    items: ["kadamtali"],
  },
  {
    name: "shantibagh",
    items: ["west shantibagh"],
  },
  {
    name: "shantinagar",
    items: ["aminbagh", "chamelibagh", "shantinagar bazar"],
  },
  {
    name: "shekher jayga",
    items: ["shekher jayga bazar"],
  },
  {
    name: "shyamoli",
    items: [
      "block b",
      "block c",
      "khalpar",
      "north shyamoli",
      "pisciculture housing society",
    ],
  },
  {
    name: "shyampur",
    items: [
      "alambagh",
      "boroitola",
      "east jurain",
      "eastern housing",
      "faridabad",
      "jurain",
      "karimullah bagh",
      "mir hazirbagh",
      "muradpur",
      "new jurain",
      "north jurain",
      "postogola",
      "railgate",
      "shafiqnagar",
      "shyampur commercial area",
      "south donia",
      "west dholairpar",
    ],
  },
  {
    name: "siddheshwari",
    items: ["mouchak"],
  },
  {
    name: "siddhirganj",
    items: ["sanarpar"],
  },
  {
    name: "signboard",
    items: ["north signboard"],
  },
  {
    name: "sutrapur",
    items: [
      "bangla bazar",
      "bania nagar",
      "dalpottir moar",
      "dholaikhal",
      "ekrampur",
      "farashganj",
      "kagojitola",
      "kolta bazar",
      "kulutola",
      "lokkhi bazar",
      "malakar tola",
      "narinda",
      "ray saheb bazar",
      "ray saheb bazar moar",
      "ray shaheb bazar moar",
      "rokon pur",
      "shintola",
      "shyambazar",
      "victoria park",
    ],
  },
  {
    name: "tejgaon",
    items: [
      "arjatpara",
      "babli",
      "begunbari",
      "farmgate",
      "jahanara garden",
      "kawran bazar",
      "kunipara",
      "monipuripara",
      "nakhalpara",
      "rajabazar",
      "shahinbag",
      "shahinbagh",
      "shanti niketon residential area",
      "tejgaon industrial area",
      "tejkunipara",
      "tejturi bazar",
    ],
  },
  {
    name: "tikatuli",
    items: ["eastern housing", "hatkhola", "shamibagh"],
  },
  {
    name: "turag",
    items: [
      "ahalia",
      "bamnartek",
      "bhatulia",
      "chondolbhog",
      "dharangartek",
      "dharangertek",
      "dhour",
      "diabari",
      "fulbaria",
      "islamabad",
      "jatrabari",
      "kamarpara",
      "nayanagar",
      "nolbhog",
      "noyanichala",
      "pakuria",
      "rajabari",
      "ranabhola",
    ],
  },
  {
    name: "uttara",
    items: [
      "abdullahpur",
      "badshartek",
      "diabari",
      "dolipara",
      "sector 1",
      "sector 10",
      "sector 11",
      "sector 12",
      "sector 13",
      "sector 14",
      "sector 15",
      "sector 16",
      "sector 17",
      "sector 18",
      "sector 3",
      "sector 4",
      "sector 5",
      "sector 6",
      "sector 7",
      "sector 8",
      "sector 9",
    ],
  },
  {
    name: "uttarkhan",
    items: [
      "adarshapara",
      "atipara",
      "bhuiya para",
      "borobagh",
      "chamurkhan",
      "chanpara",
      "cheragipara",
      "dobadiya",
      "faydabad",
      "fojir batan",
      "gazipara",
      "helal market",
      "jamtola",
      "kachkura",
      "kuripara",
      "madarbari",
      "masterpara",
      "mausaid",
      "mazar chowrasta",
      "mazarpara",
      "moddhopara",
      "moinartek",
      "mollabari",
      "mollapara, mollabari",
      "munda",
      "puranpara",
      "rajabari",
      "taltola",
      "uzampur",
    ],
  },
  {
    name: "vatara",
    items: [
      "barobigha",
      "east vatara",
      "east vatara, sayednagar",
      "jagannathpur",
      "khilbarirtek",
      "nadda",
      "nadda bus stand",
      "nayabari",
      "nayanagar",
      "notun bazar",
      "nurerchala",
      "sayednagar",
      "sayeed nagar",
      "solmaid",
      "vatara moar",
      "west vatara",
    ],
  },
  {
    name: "wari",
    items: [
      "banagram",
      "baniyanagar",
      "dholaikhal",
      "gopibagh",
      "hatkhola",
      "kaptan bazar",
      "murgipotti",
      "murgitola",
      "mushundi",
      "narinda",
      "robidhash para",
      "thatari bazar",
    ],
  },
  {
    name: "niketon",
    items: [],
  },
  {
    name: "bhashantak",
    items: [],
  },
  {
    name: "malibag",
    items: [],
  },
  {
    name: "bashundhara r/a",
    items: [],
  },
  {
    name: "aftab nagar",
    items: [],
  },
  {
    name: "shantibag",
    items: [],
  },
  {
    name: "dholaipar",
    items: [],
  },
  {
    name: "malibag",
    items: [],
  },
  {
    name: "chawk bazar",
    items: [],
  },
  {
    name: "baridhara dohs",
    items: [],
  },
  {
    name: "pilkhana",
    items: [],
  },
  {
    name: "kakrail",
    items: [],
  },
  {
    name: "dhaka university",
    items: [],
  },
  {
    name: "kolabagan",
    items: [],
  },
  {
    name: "dakhin banasree",
    items: [],
  },
  {
    name: "kalachadpur",
    items: [],
  },
  {
    name: "hazaribag",
    items: [],
  },
  {
    name: "kamlapur",
    items: [],
  },
  {
    name: "niketon",
    items: [],
  },
  {
    name: "shahbag",
    items: [],
  },
  {
    name: "sher e bangla nagar",
    items: [],
  },
  {
    name: "buet",
    items: [],
  },
  {
    name: "kallayanpur",
    items: [],
  },
  {
    name: "joar sahara",
    items: [],
  },
  {
    name: "kalachadpur",
    items: [],
  },
  {
    name: "aminul",
    items: [],
  },
  {
    name: "joar sahara",
    items: [],
  },
  {
    name: "bhashantak",
    items: [],
  },
  {
    name: "dakhin banasree",
    items: [],
  },
  {
    name: "shegunbagicha",
    items: [],
  },
  {
    name: "over sea",
    items: [],
  },
  {
    name: "paribagh",
    items: [],
  },
  {
    name: "shukrabad",
    items: [],
  },
  {
    name: "manikdi",
    items: [],
  },
  {
    name: "dhaka airport",
    items: [],
  },
  {
    name: "mirpur dohs",
    items: [],
  },
  {
    name: "testarea",
    items: [],
  },
  {
    name: "lalbag",
    items: [],
  },
];
// areas.sort((a, b) => a.items.length - b.items.length);

export default areas;
