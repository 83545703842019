import { Col, Row, Space } from "antd";
import { localDateTime } from "../../../utils";
import { BiEnvelope } from "react-icons/bi";

function InfoItem({ title, value }) {
  return (
    <div className="info-item">
      <div className="title">{title}</div>
      <div className="value">{value ?? "N/A"}</div>
    </div>
  );
}

export default function HistoryUser({
  name,
  email,
  phone,
  area,
  address,
  count,
  totalAmount = [],
  restaurants = [],
  categories = [],
  products,
  orderGap = [],
  orderHours = [],
  deliveredTimes = [],
  avgDeliveryTime,
  lastOrderedAt,
  id,
  index,
  searches = [],
  pusher = () => {},
}) {
  return (
    <div className="user">
      <Row gutter={[24, 24]}>
        <Col span={24} lg={12}>
          <div className="user-info">
            <div className="name">
              <div className="index">{index}</div>
              <Space>
                <div>
                  {name}{" "}
                  <BiEnvelope
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    onClick={() => {
                      pusher({ name, email });
                    }}
                  />{" "}
                </div>
                <small style={{ color: "gray" }}>#{id}</small>
              </Space>
            </div>
            <InfoItem title="Email" value={email} />
            <InfoItem title="Phone" value={phone} />
            <InfoItem title="Area" value={area} />
            <InfoItem title="Address" value={address} />
            <InfoItem title="Total Order" value={count} />
            <InfoItem
              title="Avg Order Value"
              value={totalAmount.toFixed() + " BDT"}
            />
            <InfoItem
              title="Order Gaps"
              value={
                <div className="items">
                  {orderGap.map((v, i) => (
                    <div className="item" key={i}>
                      {v || 1}
                    </div>
                  ))}
                </div>
              }
            />
            <InfoItem
              title="Order Hours"
              value={
                <div className="items">
                  {orderHours.map((hr, i) => (
                    <div className="item" key={i}>
                      {(hr % 12 === 0 ? 12 : hr % 12) +
                        (hr < 12 ? " AM" : " PM")}
                    </div>
                  ))}
                </div>
              }
            />
            <InfoItem
              title="Delivery Times"
              value={
                <div className="items">
                  {deliveredTimes.map((v, i) => (
                    <div className="item" key={i}>
                      {v}
                    </div>
                  ))}
                </div>
              }
            />
            <InfoItem
              title="Average Delivery Experience"
              value={Math.round(avgDeliveryTime) + " mins"}
            />
            {lastOrderedAt && (
              <InfoItem
                title="Last Ordered At"
                value={localDateTime(lastOrderedAt)}
              />
            )}
          </div>
        </Col>
        <Col span={24} lg={12}>
          <Row gutter={[10, 10]}>
            <Col span={24} md={12} lg={12}>
              <h3>Restaurants</h3>
              <div className="restaurants customScroll">
                {restaurants.map((restaurant) => (
                  <div className="item">{restaurant.name}</div>
                ))}
              </div>
            </Col>
            <Col span={24} md={12} lg={12}>
              <h3>Products</h3>
              <div className="products customScroll">
                {products.map((product) => (
                  <div className="item">
                    <div>{product.name}</div>
                    <div style={{ color: "gray" }}>@{product.restaurant}</div>
                  </div>
                ))}
              </div>
            </Col>
            <Col span={24} md={12} lg={24}>
              <h3>Categories</h3>
              <div className="categories customScroll">
                {categories.map((category) => (
                  <div className="item">{category.name}</div>
                ))}
              </div>
            </Col>
            {searches.length > 0 && (
              <Col span={24} md={12} lg={24}>
                <h3>Searches</h3>
                <div className="categories customScroll">
                  {searches.map((search) => (
                    <div className="item">{search}</div>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
