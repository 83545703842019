import { DatePicker } from "antd";
import moment from "moment";

export default function DateHandlerVoucher({
  onDateChange,
  defaultValue,
  margin,
}) {
  const onChangeHandler = (date, dateString) => {
    const formattedDateString = moment(
      dateString,
      "DD-MM-YYYY HH:mm:ss"
    ).toISOString();
    onDateChange(date, formattedDateString);
  };

  return (
    <div style={{ margin: margin ? margin : 0 }}>
      <DatePicker
        style={{ borderRadius: "10px", minWidth: "260px" }}
        format="DD-MM-YYYY HH:mm:ss"
        onChange={onChangeHandler}
        disabledDate={(current) => moment().add(-1, "days") >= current}
        defaultValue={defaultValue ? moment(defaultValue) : null}
        size="large"
      />
    </div>
  );
}

DateHandlerVoucher.defaultProps = {
  margin: "16px 0",
};
