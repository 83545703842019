import { useReducer, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputNumber,
  Input,
  Select,
  DatePicker,
  Switch,
  message,
  Space,
} from "antd";
import styled from "styled-components";
import useRestaurants from "../../hooks/useRestaurants";
import useCategories from "../../hooks/useCategories";
import useUser from "../../hooks/useUser";
import usePromo from "../../hooks/usePromo";
import { parser } from "../../utils";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function FormItem({ children, span = {}, ...rest }) {
  return (
    <Col xs={24} sm={12} lg={8} xl={6} {...span}>
      <Form.Item {...rest}>{children}</Form.Item>
    </Col>
  );
}

const Types = {
  GET_RESTAURANTS_REQUEST: "GET_RESTAURANTS_REQUEST",
  GET_RESTAURANTS_SUCCESS: "GET_RESTAURANTS_SUCCESS",
  GET_RESTAURANTS_FAILURE: "GET_RESTAURANTS_FAILURE",
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  GET_PROMO_REQUEST: "GET_PROMO_REQUEST",
  GET_PROMO_SUCCESS: "GET_PROMO_SUCCESS",
  GET_PROMO_FAILURE: "GET_PROMO_FAILURE",
};

const initialState = {
  restaurants: { loading: false, data: [] },
  users: { loading: false, data: [] },
  categories: { loading: false, data: [] },
  promo: { loading: false, data: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_RESTAURANTS_REQUEST:
      state.restaurants.loading = true;
      return { ...state };
    case Types.GET_RESTAURANTS_SUCCESS:
      state.restaurants.loading = false;
      state.restaurants.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANTS_FAILURE:
      state.restaurants.loading = false;
      state.restaurants.data = [];
      return { ...state };
    case Types.GET_USERS_REQUEST:
      state.users.loading = true;
      return { ...state };
    case Types.GET_USERS_SUCCESS:
      state.users.loading = false;
      state.users.data = action.payload;
      return { ...state };
    case Types.GET_USERS_FAILURE:
      state.users.loading = false;
      state.users.data = [];
      return { ...state };
    case Types.GET_CATEGORIES_REQUEST:
      state.categories.loading = true;
      return { ...state };
    case Types.GET_CATEGORIES_SUCCESS:
      state.categories.loading = false;
      state.categories.data = action.payload;
      return { ...state };
    case Types.GET_CATEGORIES_FAILURE:
      state.categories.loading = false;
      state.categories.data = [];
      return { ...state };
    case Types.GET_PROMO_REQUEST:
      state.promo.loading = true;
      return { ...state };
    case Types.GET_PROMO_SUCCESS:
      state.promo.loading = false;
      state.promo.data = action.payload;
      return { ...state };
    case Types.GET_PROMO_FAILURE:
      state.promo.loading = false;
      state.promo.data = null;
      return { ...state };
    default:
      return state;
  }
};

export default function CreateNewPromo({ promoId }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { createPromo, getById, updatePromo } = usePromo();
  const { getRestaurants, getById: getRestaurantById } = useRestaurants();
  const { getUsers } = useUser();
  const { getMany: getCategories } = useCategories();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const url = new URL(window.location);
  const id = promoId || url.searchParams.get("id");
  const [userSearchBy, setUserSearchBy] = useState("name");
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const onFinish = (values) => {
    const { end_date, start_date, eligible_users, activeTime, ...rest } =
      values;

    // validate active time formate
    if (activeTime?.length > 0) {
      const now = new Date();
      const date = now.toLocaleDateString();
      const [invalidTime] = activeTime.filter((time) => {
        const start = new Date(date + " " + time.startTime);
        const end = new Date(date + " " + time.endTime);
        if (
          start.toString() === "Invalid Date" ||
          end.toString() === "Invalid Date"
        ) {
          return true;
        }
        return false;
      });

      if (invalidTime) {
        message.error(
          `Invalid time format: ${invalidTime.startTime} - ${invalidTime.endTime}`
        );
        return;
      }
    }

    const params = {
      ...rest,
      activeTime,
      end_date: end_date?.toISOString(),
      start_date: start_date?.toISOString(),
      eligible_users: eligible_users ?? [],
    };
    setLoading(true);

    if (!state.promo.data) {
      createPromo(params, (err, promo) => {
        if (promo) {
          message.success("Promo Created Successfully!");
          form.resetFields();
        }

        if (err) {
          message.error(err);
        }
        setLoading(false);
      });
    } else {
      updatePromo(id, params, (err, promo) => {
        setLoading(false);
        if (err) message.error(err);
        if (promo) {
          message.success("Promo updated successfully");
        }
      });
    }
  };

  const restaurantOnSearch = (value, ids) => {
    dispatch({ type: Types.GET_RESTAURANTS_REQUEST });

    getRestaurants(
      {
        limit: 30,
        search: value,
        select: ["objectId", "name", "hub.name"],
        ids,
      },
      (err, data) => {
        if (data) {
          dispatch({
            type: Types.GET_RESTAURANTS_SUCCESS,
            payload: data.results.map((i) => ({ ...i.toJSON(), id: i.id })),
          });
        }

        if (err) {
          dispatch({ type: Types.GET_RESTAURANTS_FAILURE });
        }
      }
    );
  };

  const categoryOnSearch = (value, ids) => {
    dispatch({ type: Types.GET_CATEGORIES_REQUEST });
    getCategories({ limit: 30, search: value, ids }, (err, data) => {
      if (data) {
        dispatch({
          type: Types.GET_CATEGORIES_SUCCESS,
          payload: parser(data.results),
        });
      }

      if (err) {
        dispatch({ type: Types.GET_CATEGORIES_FAILURE });
      }
    });
  };

  const applyOnChange = (value) => {
    if (state.promo.data) {
      state.promo.data.apply_on = value;
      dispatch({
        type: Types.GET_PROMO_SUCCESS,
        payload: state.promo.data,
      });
    }

    if (value === "categories") {
      setCategorySelected(true);
    } else {
      setCategorySelected(false);
    }
  };

  const userSearchHandler = (value, ids) => {
    dispatch({ type: Types.GET_USERS_REQUEST });
    getUsers(
      {
        limit: 10,
        [userSearchBy]: value,
        type: ["customer"],
        select: ["objectId", "name", "username"],
        ids,
      },
      (err, data) => {
        if (data) {
          if (!state.users.data.length === 0) {
            dispatch({
              type: Types.GET_USERS_SUCCESS,
              payload: parser(data.results),
            });
          } else {
            const groupById = state.users.data.reduce((acc, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {});

            data.results.forEach((user) => {
              if (!groupById[user.id]) {
                state.users.data.push(parser(user));
              }
            });

            dispatch({
              type: Types.GET_USERS_SUCCESS,
              payload: state.users.data,
            });
          }
        }

        if (err) {
          dispatch({ type: Types.GET_USERS_FAILURE });
        }
      }
    );
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    if (id) {
      dispatch({ type: Types.GET_PROMO_REQUEST });

      getById(id, (err, data) => {
        if (err) {
          dispatch({ type: Types.GET_PROMO_FAILURE });
        }

        if (data) {
          dispatch({
            type: Types.GET_PROMO_SUCCESS,
            payload: parser(data),
          });

          restaurantOnSearch(null, data.get("apply_on_restaurants"));
          userSearchHandler(null, data.get("eligible_users"));
          categoryOnSearch(null, data.get("categories"));
        }
      });
    }

    if (state.promo.data && !id) {
      form.resetFields();
      state.promo.data = null;
      dispatch({ type: Types.GET_PROMO_SUCCESS, payload: null });
    }
  }, [id]);

  useEffect(() => {
    if (state.promo.data) {
      let {
        promo_code,
        promo_auth,
        promo_type,
        promo_amount,
        min_order_amount,
        max_discount_amount,
        cashback_amount,
        off_amount,
        apply_on,
        applicable_from,
        max_usage,
        is_active,
        eligible_users,
        restaurant,
        categories,
        start_date,
        end_date,
        max_order,
        min_order,
        include_stores,
        apply_on_restaurants,
        title,
        hide_restaurant,
        subTitle,
        activeTime,
        max_usage_user,
        restaurant_bear_percent,
      } = state.promo.data;

      start_date = start_date
        ? moment(
            start_date.iso ? new Date(start_date.iso) : start_date,
            "YYYY-MM-DD"
          )
        : null;
      end_date = end_date
        ? moment(end_date.iso ? new Date(end_date.iso) : end_date, "YYYY-MM-DD")
        : null;

      if (restaurant) {
        restaurant = restaurant.id ?? restaurant.objectId;

        // if restaurant not loaded yet, load it
        const foundRestaurant = state.restaurants.data?.find(
          (rest) => rest.id === restaurant
        );
        if (!foundRestaurant) {
          getRestaurantById(
            { id: restaurant, select: ["objectId", "name", "hub.name"] },
            (err, res) => {
              if (res) {
                state.restaurants.data?.push({ ...res.toJSON(), id: res.id });
                dispatch({
                  type: Types.GET_RESTAURANTS_SUCCESS,
                  payload: state.restaurants.data,
                });
              }

              if (err) {
                message.error(err);
                form.setFieldsValue({ restaurant: null });
              }
            }
          );
        }
      }

      if (eligible_users && eligible_users.length > 0) {
        getUsers(
          {
            limit: 1000,
            ids: eligible_users,
            select: ["objectId", "name", "phone", "username"],
          },
          (err, users) => {
            if (err) message.error(err);
            if (users) {
              if (!state.users.data.length === 0) {
                dispatch({
                  type: Types.GET_USERS_SUCCESS,
                  payload: parser(users.results),
                });
              } else {
                const groupById = state.users.data.reduce((acc, curr) => {
                  acc[curr.id] = curr;
                  return acc;
                }, {});

                users.results.forEach((user) => {
                  if (!groupById[user.id]) {
                    state.users.data.push(parser(user));
                  }
                });

                dispatch({
                  type: Types.GET_USERS_SUCCESS,
                  payload: state.users.data,
                });
              }
            }
          }
        );
      }

      form.setFieldsValue({
        promo_code,
        promo_auth,
        promo_type,
        promo_amount,
        min_order_amount,
        max_discount_amount,
        cashback_amount,
        off_amount,
        apply_on,
        applicable_from,
        max_usage,
        is_active,
        eligible_users,
        restaurant,
        categories,
        start_date,
        end_date,
        max_order,
        min_order,
        include_stores,
        apply_on_restaurants,
        hide_restaurant,
        title,
        subTitle,
        activeTime,
        max_usage_user,
        restaurant_bear_percent,
      });
    }
  }, [state.promo.data]);

  if (state.promo.loading) {
    return "Loading...";
  }

  return (
    <Container>
      <Row justify="center">
        <Col span={24}>
          {!id && (
            <div className="header">
              <div className="left-section">
                <h3 className="title">Create New Promo</h3>
              </div>
            </div>
          )}
          {!(id && !state.promo.data) && (
            <Form
              form={form}
              layout="vertical"
              name="create-promo"
              onFinish={onFinish}
              className="create-promo-form border"
              initialValues={{
                eligible_users: [],
                categories: [],
                applicable_from: "both", // app || both (app & website)
                restaurant_bear_percent: 100,
              }}
              size="large"
            >
              <Row gutter={[12, 12]}>
                <FormItem
                  name="title"
                  label="Title"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Title" />
                </FormItem>
                <FormItem
                  name="subTitle"
                  label="Sub Title"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Sub Title" />
                </FormItem>
                <FormItem
                  name="promo_code"
                  label="Promo Code"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Promo Code" />
                </FormItem>
                <FormItem
                  name="promo_auth"
                  label="Promo Auth"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Promo Auth" />
                </FormItem>
                <FormItem
                  name="promo_type"
                  label="Promo Type"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select promo type">
                    <Select.Option value="Fixed">Fixed</Select.Option>
                    <Select.Option value="Percent">Percent</Select.Option>
                  </Select>
                </FormItem>
                <FormItem
                  name="promo_amount"
                  label="Promo Amount"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} />
                </FormItem>
                <FormItem
                  name="min_order_amount"
                  label="Min Order Amount"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} />
                </FormItem>
                <FormItem
                  name="max_discount_amount"
                  label="Max Discount Amount"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} />
                </FormItem>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <Form.Item
                    name="apply_on"
                    label="Apply On"
                    rules={[{ required: true }]}
                  >
                    <Select onChange={applyOnChange} placeholder="Select one">
                      <Select.Option value="product">Product</Select.Option>
                      <Select.Option value="categories">Category</Select.Option>
                      <Select.Option value="delivery_charge">
                        Delivery Charge
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {(categorySelected ||
                  state.promo?.data?.apply_on === "categories") && (
                  <Col xs={24} sm={12} lg={8} xl={6}>
                    <Form.Item
                      name="categories"
                      label="Categories"
                      rules={[{ required: true }]}
                    >
                      <Select
                        filterOption={filterOption}
                        loading={state.categories.loading}
                        onSearch={categoryOnSearch}
                        size="large"
                        placeholder="Select one"
                        mode="multiple"
                        showSearch
                        allowClear
                      >
                        {state.categories?.data?.map((res, idx) => (
                          <Select.Option key={idx} value={res.id}>
                            {res.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <FormItem
                  name="applicable_from"
                  label="Applicable From"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select one">
                    <Select.Option value="app">App</Select.Option>
                    <Select.Option value="both">
                      Both (App & Website)
                    </Select.Option>
                  </Select>
                </FormItem>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <Form.Item name="restaurant" label="Promo Bear Restaurant">
                    <Select
                      filterOption={filterOption}
                      allowClear
                      loading={state.restaurants.loading}
                      onSearch={(v) => restaurantOnSearch(v, null)}
                      placeholder="Select one"
                      showSearch
                      onChange={(v) => {
                        setSelectedRestaurant(v);
                      }}
                    >
                      {state.restaurants?.data?.map((res, idx) => (
                        <Select.Option key={idx} value={res.id}>
                          {`${res.name}(${res.hub.name})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {(selectedRestaurant ||
                  state.promo?.data?.restaurant_bear_percent > 0) && (
                  <FormItem
                    name="restaurant_bear_percent"
                    label="Restaurant Will Bear (%)"
                  >
                    <InputNumber min={0} max={100} />
                  </FormItem>
                )}
                <FormItem name="max_usage" label="Total Max Usage">
                  <InputNumber min={0} />
                </FormItem>
                <FormItem name="max_usage_user" label="User Max Usage">
                  <InputNumber min={0} />
                </FormItem>
                <FormItem
                  size="large"
                  name="start_date"
                  label="Start Date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      current.isBefore(new Date(), current)
                    }
                  />
                </FormItem>
                <FormItem
                  name="end_date"
                  label="End Date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      current.isBefore(new Date(), current)
                    }
                  />
                </FormItem>
                <FormItem label="Active Times">
                  <Form.List name="activeTime">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...rest }) => {
                          return (
                            <Space key={key}>
                              <Form.Item
                                {...rest}
                                name={[name, "startTime"]}
                                label="Start Time"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="hh:mm:ss" />
                              </Form.Item>
                              <Form.Item
                                {...rest}
                                name={[name, "endTime"]}
                                label="End Time"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="hh:mm:ss" />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          );
                        })}
                        <Form.Item>
                          <Button icon={<PlusOutlined />} onClick={() => add()}>
                            Add
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </FormItem>
                <FormItem
                  valuePropName="checked"
                  name="is_active"
                  label="Is Active"
                  rules={[{ required: true }]}
                >
                  <Switch />
                </FormItem>
                <FormItem>
                  <Row gutter={[16]}>
                    <Col span={12}>
                      <Form.Item name="max_order" label="User Max order">
                        <InputNumber placeholder="Max order" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="min_order" label="User Min order">
                        <InputNumber placeholder="Min order" />
                      </Form.Item>
                    </Col>
                  </Row>
                </FormItem>
                <FormItem
                  valuePropName="checked"
                  name="include_stores"
                  label="Include Stores"
                >
                  <Switch />
                </FormItem>

                <FormItem
                  valuePropName="checked"
                  name="hide_restaurant"
                  label="Hide From Restaurant"
                >
                  <Switch />
                </FormItem>

                <Col span={24}>
                  <Form.Item
                    name="apply_on_restaurants"
                    label="Apply On Restaurants"
                  >
                    <Select
                      filterOption={filterOption}
                      allowClear
                      loading={state.restaurants.loading}
                      onSearch={(v) => restaurantOnSearch(v, null)}
                      size="large"
                      placeholder="Select one"
                      showSearch
                      mode="multiple"
                    >
                      {state.restaurants?.data?.map((res, idx) => (
                        <Select.Option key={idx} value={res.id}>
                          {`${res.name}(${res.hub.name})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item label="User Search By">
                        <Select
                          size="large"
                          defaultValue={userSearchBy}
                          onChange={(value) => setUserSearchBy(value)}
                        >
                          <Select.Option value="name">Name</Select.Option>
                          <Select.Option value="phone">Phone</Select.Option>
                          <Select.Option value="email">Email</Select.Option>
                          <Select.Option value="username">
                            Username
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      <Form.Item
                        name="eligible_users"
                        label={
                          <span
                            onClick={() => {
                              const { eligible_users } = form.getFieldsValue();
                              message.info(
                                `Total eligible users: ${
                                  eligible_users?.length || 0
                                }`
                              );
                            }}
                          >
                            Eligible Users
                          </span>
                        }
                      >
                        <Select
                          filterOption={filterOption}
                          onSearch={userSearchHandler}
                          showSearch
                          loading={state.users.loading}
                          size="large"
                          placeholder="Select one"
                          mode="multiple"
                        >
                          {state.users?.data?.map(
                            ({ id, name, username }, idx) => {
                              let text = `${name}`;
                              if (text && username) text += ` (${username})`;
                              else if (!text && username) text = username;

                              return (
                                <Select.Option key={idx} value={id}>
                                  {text}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      style={{ width: "120px" }}
                      size="large"
                      htmlType="submit"
                      type="primary"
                    >
                      {state.promo ? "Update" : "Create"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    margin-bottom: 24px;

    .left-section {
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 24px;
        margin-bottom: 0;
        letter-spacing: 1px;
        font-weight: 500;
      }
    }
  }

  .create-promo-form {
    padding: 24px;
    background-color: #fff;

    label {
      font-size: 16px;
      font-weight: 500;
    }

    .ant-input-number,
    .ant-picker {
      width: 100%;
    }
  }
`;
