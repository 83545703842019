import { Form, Select } from "antd";
const { Item } = Form;

export default function Restaurant({ restaurants = [] }) {
  return (
    <div className="section">
      <Item
        name="restaurant"
        label="Restaurant"
        style={{ marginBottom: "5px" }}
      >
        <Select size="large" showSearch placeholder="Select a restaurant">
          {restaurants.map(({ objectId: id, name, hub }) => (
            <Select.Option key={id} value={id}>
              {name} <small style={{ color: "gray" }}>@{hub?.name}</small>
            </Select.Option>
          ))}
        </Select>
      </Item>
    </div>
  );
}
