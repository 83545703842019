import { useReducer, useEffect, createContext } from "react";
import { message } from "antd";
import useHub from "../../hooks/useHub";
import { parser } from "../../utils";
import Parse from "parse";
import useRestaurants from "../../hooks/useRestaurants";

export const HubsContext = createContext();

const Types = {
  FETCH_HUB_REQUEST: "FETCH_HUB_REQUEST",
  FETCH_HUB_SUCCESS: "FETCH_HUB_SUCCESS",
  FETCH_HUB_FAILURE: "FETCH_HUB_FAILURE",
  SET_AREAS: "SET_AREAS",
  SET_RESTAURANTS: "SET_RESTAURANTS",
};

const initialState = {
  hubs: {
    loading: false,
    data: { count: 0, results: [], areaList: [] },
  },
  areas: [],
  restaurants: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.FETCH_HUB_REQUEST:
      state.hubs.loading = true;
      return { ...state };
    case Types.FETCH_HUB_SUCCESS:
      state.hubs.loading = false;
      state.hubs.data = action.payload;
      return { ...state };
    case Types.FETCH_HUB_FAILURE:
      state.hubs.loading = false;
      state.hubs.data = initialState.hubs.data;
      return { ...state };
    case Types.SET_AREAS:
      state.areas = action.payload;
      return { ...state };
    case Types.SET_RESTAURANTS:
      state.restaurants = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default function HubProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getHubs } = useHub();
  const { getRestaurants } = useRestaurants();

  const updateHubs = (data) => {
    dispatch({
      type: Types.FETCH_HUB_SUCCESS,
      payload: data,
    });
  };

  const fetchHubs = (params, fetchRest) => {
    dispatch({ type: Types.FETCH_HUB_REQUEST });
    getHubs(params, (err, data) => {
      if (err) {
        dispatch({ type: Types.FETCH_HUB_FAILURE });
        message.error(err);
      } else {
        const hubs = parser(data.results);

        updateHubs({
          count: data.count,
          results: hubs,
        });

        if (fetchRest) {
          fetchRestaurants({
            count: data.count,
            results: hubs,
          });
        }
      }
    });
  };

  const fetchHubAreas = async () => {
    try {
      let areas = await new Parse.Query("hub_area")
        .select(["hub.name", "name", "delivery_charge"])
        .limit(1000)
        .find();

      if (areas) {
        areas = areas.reduce((acc, area) => {
          const { name, hub, delivery_charge } = area.toJSON();

          if (!acc[name]) acc[name] = {};
          acc[name][hub.objectId] = {
            refs: area,
            name: hub.name,
            delivery_charge,
          };

          return acc;
        }, {});

        const areaList = state.hubs.data.results.reduce((acc, hub) => {
          hub.areas.forEach((area) => {
            acc.push(area);
          });
          return acc;
        }, []);

        areas = areaList.map((area) => {
          const hub = areas[area.name];
          if (hub) {
            return {
              ...area,
              ...hub,
            };
          }

          return area;
        });

        // sorting by name
        areas.sort((a, b) => a.name.localeCompare(b.name));

        dispatch({
          type: Types.SET_AREAS,
          payload: areas,
        });
      }
    } catch (err) {
      message.error(err);
    }
  };

  const fetchRestaurants = (hubs) => {
    getRestaurants(
      { select: ["name", "banner_image", "hub.name"], limit: 600 },
      (err, res) => {
        if (res) {
          const resById = {};

          res.results.forEach((r) => {
            resById[r.id] = r.toJSON();
          });

          hubs.results.forEach((hub, i) => {
            hubs.results[i]["brandsObj"] = hub.brands.reduce((acc, id) => {
              if (resById[id]) {
                acc.push(resById[id]);
              }
              return acc;
            }, []);
          });

          dispatch({
            type: Types.SET_RESTAURANTS,
            payload: res.results.map((r) => r.toJSON()),
          });

          updateHubs(hubs);
        } else if (err) {
          message.error(err);
        }
      }
    );
  };

  const deleteBrandItem = (hubId, index) => {
    const hub = state.hubs.data.results.find((h) => h.id === hubId);
    if (hub) {
      hub.brands.splice(index, 1);
      hub.brandsObj.splice(index, 1);
      hub.ref?.set("brands", hub.brands);
      updateHubs(state.hubs.data);
    }
  };

  const updateHub = async (hub) => {
    try {
      hub.ref.set(
        "brands",
        hub.brandsObj.map((i) => i.objectId)
      );
      if (hub.ref instanceof Parse.Object) {
        await hub.ref.save(null, {
          sessionToken: Parse.User.current().getSessionToken(),
        });
        message.success("Updated successfully!");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <HubsContext.Provider
      value={{
        ...state,
        fetchHubs,
        fetchHubAreas,
        updateHubs,
        deleteBrandItem,
        updateHub,
      }}
    >
      {children}
    </HubsContext.Provider>
  );
}
