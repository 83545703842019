import { DownloadOutlined, SendOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Pagination,
  Button,
  message,
  DatePicker,
} from "antd";
import { useState } from "react";
import EmailTemplate from "./EmailTemplate";
import { downloadCsv } from "../../../utils";
import useProduct from "../../../hooks/useProduct";
import useRestaurants from "../../../hooks/useRestaurants";
import useCategories from "../../../hooks/useCategories";

export default function HistoryFilter({ fetchData, observer, pusher }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getProducts } = useProduct();
  const { getRestaurants } = useRestaurants();
  const { getMany: getCategories } = useCategories();

  const [products, setProducts] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [previousValues, setPreviousValues] = useState({});

  function validateDataFormat(input) {
    const regex =
      /^(?:(\$gt:\d+;\s?)|(\$gte:\d+;\s?)|(\$eq:\d+;\s?)|(\$lt:\d+;\s?)|(\$lte:\d+;\s?))+$/;
    return regex.test(input);
  }

  const parseQuery = (input) => {
    return input
      .split(";")
      .map((i) => i.trim())
      .filter((i) => i)
      .map((i) => ({ [i.split(":")[0]]: Number(i.split(":")[1]) }))
      .reduce((a, b) => ({ ...a, ...b }), {});
  };

  const onFinish = (values) => {
    let { orderCount, orderValue } = values;
    if (orderCount) {
      values.orderCount = parseQuery(orderCount);
    } else {
      delete values.orderCount;
    }

    if (orderValue) {
      values.orderValue = parseQuery(orderValue);
    } else {
      delete values.orderValue;
    }

    if (values.lastOrderedAt) {
      values.lastOrderedAt = new Date(values.lastOrderedAt).toISOString();
    } else {
      delete values.lastOrderedAt;
    }

    setLoading(true);
    fetchData(values, () => setLoading(false));
    setPreviousValues(values);
  };

  const fetchMenuItems = async (name, value) => {
    try {
      if (name === "product") {
        getProducts(
          {
            name: value,
            limit: 20,
            select: ["name", "restaurant.name"],
          },
          (err, value) => {
            if (err) {
              message.error(err.message);
            } else {
              setProducts(value.results.map((v) => v.toJSON()));
            }
          }
        );
      } else if (name === "restaurant") {
        getRestaurants(
          { name: value, limit: 20, select: ["name", "hub.name"] },
          (err, value) => {
            if (err) {
              message.error(err.message);
            } else {
              setRestaurants(value.results.map((v) => v.toJSON()));
            }
          }
        );
      } else if (name === "category") {
        getCategories(
          { name: value, limit: 20, select: ["name"] },
          (err, value) => {
            if (err) {
              message.error(err.message);
            } else {
              setCategories(value.results.map((v) => v.toJSON()));
            }
          }
        );
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const capitalize = (text) => {
    const words = text
      .toLowerCase()
      .split(" ")
      .filter((i) => i)
      .slice(0, 2);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const downloadHandler = () => {
    const values = form.getFieldsValue();
    let { orderCount, orderValue } = values;
    if (orderCount) {
      values.orderCount = parseQuery(orderCount);
    } else {
      delete values.orderCount;
    }

    if (orderValue) {
      values.orderValue = parseQuery(orderValue);
    } else {
      delete values.orderValue;
    }

    if (values.lastOrderedAt) {
      values.lastOrderedAt = new Date(values.lastOrderedAt).toISOString();
    } else {
      delete values.lastOrderedAt;
    }

    setLoading(true);
    fetchData(
      {
        ...values,
        skip: 0,
        limit: 100000,
        download: true,
      },
      (data) => {
        setLoading(false);
        if (data) {
          data.forEach((user) => {
            pusher({
              name: capitalize(user.name),
              email: user.email,
            });
          });

          downloadCsv(
            [
              {
                header: [
                  {
                    title: "Name",
                    key: "name",
                  },
                  {
                    title: "Email",
                    key: "email",
                  },
                  {
                    title: "Phone",
                    key: "phone",
                  },
                  {
                    title: "Area",
                    key: "area",
                  },
                  {
                    title: "Address",
                    key: "address",
                  },
                  {
                    title: "Total Order",
                    key: "count",
                  },
                  {
                    title: "Avg Order Value",
                    key: "totalAmount",
                  },
                  {
                    title: "Average Delivery Experience",
                    key: "avgDeliveryTime",
                  },
                ],
                data: data.map((user) => ({
                  ...user,
                  area: user.area?.replaceAll(",", "-"),
                  address: user.address?.replaceAll(",", "-"),
                  totalAmount: user.totalAmount.toFixed() + " BDT",
                  avgDeliveryTime: Math.round(user.avgDeliveryTime) + " mins",
                })),
              },
            ],
            "user-history"
          );
        }
      }
    );
  };

  return (
    <div className="filter customScroll">
      <EmailTemplate observer={observer} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          limit: 20,
          status: ["delivered"],
          sortBy: "lastOrderedAt",
          sortOrder: -1,
          emailExist: null,
        }}
      >
        <Form.Item
          name="orderCount"
          label="OrderCount"
          rules={[
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                if (validateDataFormat(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Invalid data format!"));
              },
            },
          ]}
        >
          <Input placeholder="$gt:300; $lt:500;" />
        </Form.Item>

        <Form.Item
          name="orderValue"
          label="OrderValue"
          rules={[
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                if (validateDataFormat(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Invalid data format!"));
              },
            },
          ]}
        >
          <Input placeholder="$gt:300; $lt:500;" />
        </Form.Item>
        <Form.Item name="emailExist" label="Email Exist">
          <Select>
            <Select.Option value={null}>Doesn't Matter</Select.Option>
            <Select.Option value={true}>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select mode="multiple">
            <Select.Option value="delivered">Delivered</Select.Option>
            <Select.Option value="cancelled">Cancelled</Select.Option>
            <Select.Option value="rejected">Rejected</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="lastOrderedAt" label="Last Ordered">
          <DatePicker
            format="DD-MM-YYYY"
            picker="date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="userId" label="User">
          <Input placeholder="User ID" />
        </Form.Item>
        <Form.Item name="productIds" label="Products">
          <Select
            mode="multiple"
            showSearch
            onSearch={(value) => {
              fetchMenuItems("product", value);
            }}
            allowClear
            filterOption={false}
            placeholder="Search Products"
          >
            {products.map((product) => (
              <Select.Option key={product.objectId} value={product.objectId}>
                {product.name} @ {product.restaurant.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="restaurantIds" label="Restaurants">
          <Select
            mode="multiple"
            showSearch
            onSearch={(value) => {
              fetchMenuItems("restaurant", value);
            }}
            allowClear
            filterOption={false}
            placeholder="Search Restaurants"
          >
            {restaurants.map((restaurant) => (
              <Select.Option
                key={restaurant.objectId}
                value={restaurant.objectId}
              >
                {restaurant.name} @ {restaurant.hub?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="categoryIds" label="Categories">
          <Select
            mode="multiple"
            showSearch
            onSearch={(value) => {
              fetchMenuItems("category", value);
            }}
            allowClear
            filterOption={false}
            placeholder="Search Categories"
          >
            {categories.map((category) => (
              <Select.Option key={category.objectId} value={category.objectId}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="limit" label="Limit">
          <InputNumber style={{ width: "100%" }} min={1} />
        </Form.Item>
        <Form.Item name="sortBy" label="Sort By">
          <Select>
            <Select.Option value="totalAmount">Avg Order Value</Select.Option>
            <Select.Option value="count">Total Order</Select.Option>
            <Select.Option value="avgDeliveryTime">
              Avg Delivery Time
            </Select.Option>
            <Select.Option value="lastOrderedAt">Last Ordered At</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="sortOrder">
          <Select>
            <Select.Option value={1}>Ascending</Select.Option>
            <Select.Option value={-1}>Descending</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            style={{ borderRadius: "10px" }}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downloadHandler}
            block
            loading={loading}
          >
            Download
          </Button>
        </Form.Item>
        <div className="filter-footer">
          <Button
            icon={<SendOutlined />}
            type="primary"
            htmlType="submit"
            loading={loading}
            block
          >
            Submit
          </Button>

          <Pagination
            simple
            defaultCurrent={1}
            size="small"
            pageSize={20}
            total={100000}
            onChange={(page, pageSize) => {
              fetchData({
                ...previousValues,
                skip: (page - 1) * pageSize,
              });
            }}
            style={{ padding: "5px 0" }}
          />
        </div>
      </Form>
    </div>
  );
}
