import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Form, Input, Select, Space, Button, message } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import useProduct from "../../../hooks/useProduct";
import useRestaurants from "../../../hooks/useRestaurants";

function SectionHeader({ show, setShow, name, title }) {
  return (
    <div className="flex-between">
      <h3>{title}</h3>
      {!show[name] && (
        <Button
          icon={<PlusOutlined />}
          shape="circle"
          onClick={() => {
            setShow({ ...show, [name]: true });
          }}
        ></Button>
      )}
      {show[name] && (
        <Button
          icon={<MinusOutlined />}
          shape="circle"
          onClick={() => {
            setShow({ ...show, [name]: false });
          }}
        ></Button>
      )}
    </div>
  );
}

export default function EmailForm({ setData, observer = () => {} }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getProducts } = useProduct();
  const { getRestaurants } = useRestaurants();

  const [products, setProducts] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [show, setShow] = useState({});
  var [timer, setTimer] = useState(null);

  const debouncer = (fn, delay = 1000) => {
    return (...args) => {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        fn(...args);
      }, delay);
      setTimer(newTimer);
    };
  };

  const fetchMenuItems = debouncer(async (name, value) => {
    try {
      if (name === "product") {
        const values = form.getFieldsValue();
        let ids = [];
        if (values.restaurants?.list) {
          values.restaurants.list = values.restaurants.list
            .filter((i) => i)
            .map(({ name, ...rest }) => {
              return { ...JSON.parse(name), ...rest };
            });

          ids = values.restaurants.list.map((i) => i.objectId);
        }
        getProducts(
          {
            name: value,
            limit: 10,
            select: [
              "name",
              "restaurant.name",
              "restaurant.slug",
              "images",
              "price",
            ],
            restaurants: ids,
          },
          (err, value) => {
            if (err) {
              message.error(err.message);
            } else {
              setProducts(value.results.map((v) => v.toJSON()));
            }
          }
        );
      } else if (name === "restaurant") {
        getRestaurants(
          {
            name: value,
            limit: 20,
            select: ["name", "banner_image", "hub.name", "cuisines", "slug"],
          },
          (err, value) => {
            if (err) {
              message.error(err.message);
            } else {
              setRestaurants(value.results.map((v) => v.toJSON()));
            }
          }
        );
      }
    } catch (err) {
      message.error(err.message);
    }
  }, 1000);

  const onChange = () => {
    const values = form.getFieldsValue();
    if (values.restaurants?.list) {
      values.restaurants.list = values.restaurants.list
        .filter((i) => i)
        .map(({ name, ...rest }) => {
          return { ...JSON.parse(name), ...rest };
        });
    }

    if (values.products?.list) {
      values.products.list = values.products.list
        .filter((i) => i)
        .map(({ name, ...rest }) => {
          return { ...JSON.parse(name), ...rest };
        });
    }

    setData(values);
  };

  const onFinish = async (values) => {
    if (values.restaurants?.list) {
      values.restaurants.list = values.restaurants.list
        .filter((i) => i)
        .map(({ name, ...rest }) => {
          return { ...JSON.parse(name), ...rest };
        });
    }

    if (values.products?.list) {
      values.products.list = values.products.list
        .filter((i) => i && i.name)
        .map(({ name, ...rest }) => {
          for (let key in name) {
            if (name[key] === undefined) {
              delete name[key];
            }
          }
          return { ...JSON.parse(name), ...rest };
        });
    }

    try {
      setLoading(true);
      await Parse.Cloud.run("sendMailToCustomer", values);
      message.success("Email sent successfully");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
    }
  };

  const getDiscount = (price = {}) => {
    const { discount, amount } = price;
    if (!amount || !discount || !discount.amount) return null;
    if (discount.type === "percentage") {
      return Math.round((amount * discount.amount) / 100);
    } else {
      return discount.amount;
    }
  };

  useEffect(() => {
    observer((data) => {
      if (data && data.name && data.email) {
        const values = form.getFieldsValue();
        if (!values.info) {
          values.info = {};
        }
        if (values.info && !values.info.to) {
          values.info.to = [];
        }

        let shortName = data.name
          .split(" ")
          .filter((i) => i)
          .map((i) => i.trim())
          .slice(0, 2)
          .join(" ")
          .replaceAll("null", "There")
          .trim();

        const id = `${shortName}:${data.email}`;
        if (values.info.to.includes(id)) {
          return;
        }

        values.info.to.push(id);
        form.setFieldsValue(values);
      }
    });
  }, []);

  return (
    <Col span={24} lg={16}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onChange={onChange}
        className="email-form"
      >
        <div className="section">
          <SectionHeader
            show={show}
            setShow={setShow}
            name="info"
            title="Info"
          />
          <div>
            <Form.Item
              name={["info", "subject"]}
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "Please enter subject",
                },
              ]}
            >
              <Input placeholder="Subject" />
            </Form.Item>
            <Form.Item name={["info", "to"]} label="Name:Email">
              <Select
                mode="tags"
                showSearch
                clearIcon
                allowClear
                style={{ width: "100%" }}
              ></Select>
            </Form.Item>
          </div>
        </div>
        <div className="section">
          <SectionHeader
            show={show}
            setShow={setShow}
            name="home"
            title="Approachable Text"
          />
          {
            <div style={{ display: show["home"] ? "block" : "none" }}>
              <Form.Item name={["home", "title"]} label="Title">
                <Input placeholder="Title" />
              </Form.Item>
              <Form.Item name={["home", "description"]} label="Description">
                <Input.TextArea placeholder="Description" />
              </Form.Item>
              <Form.Item name={["home", "image"]} label="Image">
                <Input placeholder="Image" />
              </Form.Item>
            </div>
          }
        </div>
        <div className="section">
          <SectionHeader
            show={show}
            setShow={setShow}
            name="banner"
            title="Banner"
          />
          {
            <div style={{ display: show["banner"] ? "block" : "none" }}>
              <Form.Item name={["banner", "image"]} label="Image">
                <Input placeholder="Image" />
              </Form.Item>
              <Form.Item name={["banner", "link"]} label="Link">
                <Input placeholder="Link" />
              </Form.Item>
            </div>
          }
        </div>
        <div className="section">
          <SectionHeader
            show={show}
            setShow={setShow}
            name="highlight"
            title="Highlight"
          />
          {
            <div style={{ display: show["highlight"] ? "block" : "none" }}>
              <Form.Item name={["highlight", "title"]} label="Title">
                <Input placeholder="Title" />
              </Form.Item>
              <Form.Item
                name={["highlight", "description"]}
                label="Description"
              >
                <Input.TextArea placeholder="Description" />
              </Form.Item>
              <Form.Item name={["highlight", "link"]} label="link">
                <Input placeholder="Link" />
              </Form.Item>
              <Form.Item name={["highlight", "code"]} label="Code">
                <Input placeholder="Code" />
              </Form.Item>
            </div>
          }
        </div>
        <div className="section">
          <SectionHeader
            show={show}
            setShow={setShow}
            name="restaurants"
            title="Restaurants"
          />
          {
            <div style={{ display: show["restaurants"] ? "block" : "none" }}>
              <Form.Item name={["restaurants", "title"]} label="Title">
                <Input placeholder="Restaurants" />
              </Form.Item>
              <Form.Item name={["restaurants", "subTitle"]} label="Sub Title">
                <Input placeholder="Open 7PM to 2AM" />
              </Form.Item>
              <Form.List name={["restaurants", "list"]} label="Title">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div className="flex-between" key={field.key}>
                        <Form.Item {...field} name={[field.name, "name"]}>
                          <Select
                            showSearch
                            onSearch={(value) => {
                              fetchMenuItems("restaurant", value);
                            }}
                            allowClear
                            filterOption={false}
                            placeholder="Search Restaurant"
                            style={{ width: "200px" }}
                            onChange={onChange}
                          >
                            {restaurants.map((restaurant) => (
                              <Select.Option
                                key={restaurant.objectId}
                                value={JSON.stringify({
                                  name: restaurant.name,
                                  id: restaurant.slug,
                                  image: restaurant.banner_image,
                                  cuisines: restaurant.cuisines,
                                  objectId: restaurant.objectId,
                                })}
                              >
                                {restaurant.name} @ {restaurant.hub?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Space>
                          <Form.Item {...field} name={[field.name, "tag"]}>
                            <Input placeholder="Tag" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, "bg"]}>
                            <Input placeholder="Background" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, "color"]}>
                            <Input placeholder="Text Color" />
                          </Form.Item>
                        </Space>
                        <Button onClick={() => remove(field.name)}>
                          Remove
                        </Button>
                      </div>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Restaurant
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          }
        </div>
        <div className="section">
          <SectionHeader
            show={show}
            setShow={setShow}
            name="products"
            title="Products"
          />
          {
            <div style={{ display: show["products"] ? "block" : "none" }}>
              <Form.Item name={["products", "title"]} label="Title">
                <Input placeholder="Products Title" />
              </Form.Item>
              <Form.Item name={["products", "subTitle"]} label="Sub Title">
                <Input placeholder="Open 7PM to 2AM" />
              </Form.Item>
              <Form.List name={["products", "list"]} label="Title">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <div className="flex-between">
                        <Form.Item {...field} name={[field.name, "name"]}>
                          <Select
                            showSearch
                            onSearch={(value) => {
                              fetchMenuItems("product", value);
                            }}
                            allowClear
                            filterOption={false}
                            placeholder="Search Product"
                            style={{ width: "200px" }}
                            onChange={onChange}
                          >
                            {products.map((product) => (
                              <Select.Option
                                key={product.objectId}
                                value={JSON.stringify({
                                  name: product.name,
                                  id: product.objectId,
                                  image: product.images?.[0] ?? "",
                                  price: product.price.amount,
                                  discount: getDiscount(product.price),
                                  restaurant: product.restaurant?.slug,
                                })}
                              >
                                {product.name} @ {product.restaurant?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Space>
                          <Form.Item {...field} name={[field.name, "tag"]}>
                            <Input placeholder="Tag" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, "bg"]}>
                            <Input placeholder="Background" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, "color"]}>
                            <Input placeholder="Text Color" />
                          </Form.Item>
                        </Space>
                        <Button onClick={() => remove(field.name)}>
                          Remove
                        </Button>
                      </div>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Product
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          }
        </div>
        <Button type="primary" htmlType="submit" loading={loading}>
          Send
        </Button>
      </Form>
    </Col>
  );
}
