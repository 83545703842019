import Parse from "parse";

export default function useCategories() {
  const repoName = "category";
  const defaultFunc = () => {};

  const getById = async (id, callback = defaultFunc) => {
    if (!id) {
      callback("Id can't be empty!", null);
      return;
    }

    try {
      const category = await new Parse.Query(repoName)
        .equalTo("objectId", id)
        .first();
      if (category) {
        callback(null, category);
      } else {
        callback("Category not found!", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getByName = async (name, callback = defaultFunc) => {
    if (!name) {
      callback("Name can't be empty!", null);
      return;
    }

    try {
      const category = await new Parse.Query(repoName)
        .equalTo("name", name)
        .first();
      if (category) {
        callback(null, category);
      } else {
        callback("Category not found!", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getMany = async (
    { limit, skip, select, search, name, ids, is_restricted, isTobacco } = {},
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      if (limit) query.limit(limit);
      if (skip) query.skip(skip);
      if (select && Array.isArray(select)) {
        query.select(...select);
      }
      if (search || name) query.matches("name", search || name, "i");
      query.withCount();
      query.ascending("name");
      if (Array.isArray(is_restricted)) {
        query.containedIn("is_restricted", is_restricted);
      }
      if (Array.isArray(isTobacco)) {
        query.containedIn("isTobacco", isTobacco);
      }
      if (Array.isArray(ids)) {
        query.containedIn("objectId", ids);
      }
      const data = await query.find();
      callback(null, data);
    } catch (err) {
      callback(err.message, null);
    }
  };

  const addNew = async ({ name, image, prep_time }, callback = defaultFunc) => {
    try {
      const category = new Parse.Object(repoName);
      category.set("name", name);
      category.set("image", image);
      category.set("prep_time", prep_time);

      const result = await category.save();
      if (result) {
        callback(null, category);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const updateItem = async ({ id, data }, callback = defaultFunc) => {
    try {
      getById(id, async (err, category) => {
        if (err) {
          callback(err, null);
          return;
        }

        const { name, image, prep_time } = data;
        category.set("name", name);
        if (prep_time !== undefined) {
          category.set("prep_time", prep_time);
        }
        if (image) category.set("image", image);
        const result = await category.save();
        if (result) {
          callback(null, category);
        }
      });
    } catch (err) {
      callback(err.message, null);
    }
  };

  const deleteItem = async (id, callback = defaultFunc) => {
    getById(id, async (err, category) => {
      if (err) {
        callback(err.message, null);
        return;
      }

      try {
        const res = await category.destroy();
        if (res) {
          callback(null, true);
        }
      } catch (err) {
        callback(err.message, null);
      }
    });
  };

  return {
    getById,
    getByName,
    getMany,
    addNew,
    updateItem,
    deleteItem,
  };
}
