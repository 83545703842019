import { parse } from "date-fns";
import Parse from "parse";

const fetchUserDetails = async (id) => {
    try {
      const select = ['active','order_count'];
    //   const select = [''];
    const type = "customer";
      
  
      const response = await Parse.Cloud.run("getUserDetails", { id, select, type });
      console.log(response);
    } catch (err) {
      console.error("Error fetching user details:", err.message);
    }
  };
  
  export default function userDashboard() {
    // fetchUserDetails("i1iOI2vSiK");
  }
  
  export { fetchUserDetails };