import {
  Affix,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { CloseOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";

export default function HubSettings() {
  const [form] = Form.useForm();
  const [hubs, setHubs] = useState([]);
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      setHubs(hubs.map((h) => h.toJSON()));
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchSettings = async () => {
    try {
      message.loading("Loading...");
      const settings = await new Parse.Query("config")
        .equalTo("key", "hub_settings")
        .first();
      if (settings) {
        setSettings(settings);
        const value = settings.get("value") || { opening: [] };
        value.opening?.forEach((o) => {
          if (o.date) {
            o.date = o.date.map((t) => moment(new Date(t), "YYYY-MM-DD"));
          }
          if (o.time) {
            o.time = o.time.map((t) =>
              moment(
                new Date(new Date().toLocaleDateString() + " " + t),
                "HH:mm a"
              )
            );
          }
        });
        form.setFieldsValue({ opening: value.opening });
      }
      message.destroy();
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchHubs();
    fetchSettings();
  }, []);

  const onFinish = async ({ opening }) => {
    try {
      setLoading(false);

      opening.forEach((o) => {
        o.date = o.date.map((t) => new Date(t).toLocaleDateString());
      });

      opening.forEach((o) => {
        o.time = o.time.map((t) => new Date(t).toLocaleTimeString());
      });

      const value = settings.get("value") || { opening: [] };
      value.opening = opening;
      settings.set("value", value);
      await settings.save();
      message.success("Saved");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <h1>Hub Opening Settings</h1>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.List name="opening">
          {(fields, { add, remove }) => {
            return (
              <div>
                <Affix offsetTop={64}>
                  <div className="top flex-between">
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                    <Button
                      loading={loading}
                      htmlType="submit"
                      type="primary"
                      icon={<SendOutlined />}
                    >
                      Update
                    </Button>
                  </div>
                </Affix>
                {fields.map((field) => {
                  return (
                    <Row gutter={[10]} key={field.key} className="list-item">
                      <Col span={24} md={20} lg={21} xl={22}>
                        <Row gutter={[10]} key={field.key}>
                          <Col span={24} md={12} lg={8} xl={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "id"]}
                              label="Hub"
                              rules={[
                                { required: true, message: "Missing name" },
                              ]}
                            >
                              <Select placeholder="Select a hub">
                                {hubs.map((hub) => (
                                  <Select.Option
                                    key={hub.objectId}
                                    value={hub.objectId}
                                  >
                                    {hub.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12} lg={8} xl={10}>
                            <Space>
                              <Form.Item
                                {...field}
                                name={[field.name, "date"]}
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                                label="Date Range"
                              >
                                <DatePicker.RangePicker
                                  format="YYYY-MM-DD"
                                  placeholder={["Start Date", "End Date"]}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "time"]}
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                                label="Time Range"
                              >
                                <TimePicker.RangePicker
                                  format="HH:mm a"
                                  placeholder={["Start Time", "End Time"]}
                                />
                              </Form.Item>
                            </Space>
                          </Col>
                          <Col span={24} md={12} lg={8} xl={4}>
                            <Space>
                              <Form.Item
                                {...field}
                                name={[field.name, "isOpen"]}
                                label="Operation"
                                valuePropName="checked"
                                rules={[
                                  { required: true, message: "Please confirm" },
                                ]}
                              >
                                <Switch
                                  checkedChildren="Open"
                                  unCheckedChildren="Closed"
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "mergeData"]}
                                label="Merge Brands"
                                valuePropName="checked"
                                rules={[{ required: true }]}
                              >
                                <Switch
                                  checkedChildren="Merge"
                                  unCheckedChildren="Not Merge"
                                />
                              </Form.Item>
                            </Space>
                          </Col>
                          <Col span={24} md={12} lg={8} xl={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "belongsTo"]}
                              label="Will Belongs To"
                            >
                              <Select placeholder="Select a hub" allowClear>
                                {hubs.map((hub) => (
                                  <Select.Option
                                    key={hub.objectId}
                                    value={hub.objectId}
                                  >
                                    {hub.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} md={12} lg={8}>
                            <Form.Item
                              name={[field.name, "message"]}
                              label="Message (Optional)"
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={4} lg={3} xl={2}>
                        <Form.Item label={" "} style={{ textAlign: "center" }}>
                          <Button
                            type="danger"
                            onClick={() => remove(field.name)}
                            icon={<CloseOutlined />}
                            shape="circle"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .list-item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e8e8e8;
    background: #fafafa;
  }

  .top {
    padding: 10px;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #e8e8e8;
    margin-left: -5px;
    margin-right: -5px;
  }
`;
