import { Table, Space, Select, Button, DatePicker, Row, Col } from "antd";
import RestaurantLinks from "../Common/RestaurantLinks";
import { useContext } from "react";
import { PaymentsContext } from "../../pages/Payments";
import { CalendarOutlined } from "@ant-design/icons";
import { isAfter } from "date-fns";

const StatisticsItem = ({ title, value }) => {
  return (
    <Col span={12} md={8} lg={6} xl={4}>
      <div
        style={{
          background: "#fff",
          padding: "10px 20px",
        }}
        className="border"
      >
        <div>{title}</div>
        <div
          style={{
            fontWeight: "500",
            letterSpacing: "1px",
            fontSize: "18px",
          }}
        >
          {Math.round(value)} tk
        </div>
      </div>
    </Col>
  );
};

function Statistics() {
  const {
    payments: { data },
    sumStatisticsData,
  } = useContext(PaymentsContext);

  const {
    total,
    vat,
    commission,
    penalty,
    payable,
    failed_order_amount,
    netPayable,
  } = sumStatisticsData(data);

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
      <StatisticsItem title="Total" value={total} />
      <StatisticsItem title="VAT" value={vat} />
      <StatisticsItem title="Commission" value={commission} />
      <StatisticsItem title="Payable" value={payable} />
      <StatisticsItem title="Penalty" value={penalty} />
      <StatisticsItem title="Failed Order Amount" value={failed_order_amount} />
      <StatisticsItem title="Net Payable" value={netPayable} />
    </Row>
  );
}

export default function PaymentsTable() {
  const { dispatch, Types, payments } = useContext(PaymentsContext);

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          style={{ width: "200px" }}
          format="MM/DD/YYYY"
          size="large"
          allowClear
          disabledDate={(current) => current.isAfter(Date.now(), current)}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? dateString : null);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
  });

  const columns = [
    {
      title: "Image",
      dataIndex: "banner_image",
      key: "image",
      render: (image) => (
        <img src={image} className="table-image" alt="restaurant" />
      ),
    },
    {
      title: "Restaurant",
      dataIndex: "name",
      key: "name",
      width: "160px",
      render: (name, { objectId, hub }) => {
        return (
          <div>
            <RestaurantLinks id={objectId}>
              <div style={{ fontSize: "16px" }}>{name}</div>
            </RestaurantLinks>
            <div style={{ color: "gray" }}>@{hub?.name}</div>
          </div>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "dates",
      key: "dates",
      ...dateSearchProps("dates"),
      render: (dates, { objectId, paymentLastDate }) => {
        return (
          <Space>
            {Array.isArray(dates) && dates.length > 0 && (
              <span>{dates[0].date}</span>
            )}
            {dates.length > 1 && <span> to </span>}
            {dates.length > 1 && (
              <Select
                onChange={(date) => {
                  dispatch({
                    type: Types.UPDATE_DATE_RANGE,
                    payload: { date, restaurantId: objectId },
                  });
                }}
                value={paymentLastDate}
                style={{ width: "160px" }}
              >
                {dates.map((item, i) => (
                  <Select.Option key={i} value={item.date}>
                    {item.date}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Space>
        );
      },
    },
    {
      title: "Net Sales",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Vat Amount",
      dataIndex: "vat",
      key: "vat",
      sorter: (a, b) => a.vat - b.vat,
      render: (vat) => Math.round(vat),
    },
    {
      title: "Commission (Restaurants)",
      dataIndex: "commission",
      key: "commission",
      width: "100px",
      sorter: (a, b) => a.commission - b.commission,
      render: (commission) => Math.round(commission),
    },
    {
      title: "Revenue (Store)",
      dataIndex: "revenue",
      key: "revenue",
      width: "100px",
      sorter: (a, b) => a.revenue - b.revenue,
      render: (revenue) => Math.round(revenue),
    },
    {
      title: "Payable",
      dataIndex: "payable",
      key: "payable",
      width: "100px",
      sorter: (a, b) => a.payable - b.payable,
      render: (payable) => Math.round(payable),
    },
    {
      title: "Penalty Amount",
      dataIndex: "penalty",
      key: "penalty",
      width: "100px",
      sorter: (a, b) => a.penalty - b.penalty,
      render: (penalty) => Math.round(penalty),
    },
    {
      title: "Total Failed Amount",
      dataIndex: "failedOrderAmount",
      key: "failedOrderAmount",
      width: "100px",
      sorter: (a, b) => a.failedOrderAmount - b.failedOrderAmount,
      render: (failedOrderAmount) => Math.round(failedOrderAmount),
    },
    {
      title: "Net payable",
      dataIndex: "netPayable",
      key: "netPayable",
      width: "100px",
      sorter: (a, b) => a.netPayable - b.netPayable,
      render: (netPayable) => Math.round(netPayable),
    },
    {
      title: "",
      key: "action",
      render: (text, { objectId, payments, paymentLastDate }) => (
        <Space>
          <Button
            onClick={() => {
              let ids = [];

              payments.forEach(({ objectId: id, payment_date }) => {
                if (
                  !isAfter(new Date(payment_date), new Date(paymentLastDate))
                ) {
                  ids.push(id);
                }
              });

              const win = window.open(
                `/payments/${objectId}/history`,
                "_blank"
              );
              win.focus();
            }}
            type="primary"
          >
            Payment Details
          </Button>
          <Button
            onClick={() => {
              let ids = [];

              payments.forEach(({ objectId: id, payment_date }) => {
                if (
                  !isAfter(new Date(payment_date), new Date(paymentLastDate))
                ) {
                  ids.push(id);
                }
              });

              const win = window.open(
                `/invoice/new?restaurant=${objectId}&ids=${ids.join(",")}`,
                "_blank"
              );
              win.focus();
            }}
            type="primary"
          >
            Payment
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Statistics />
      <Table
        columns={columns}
        loading={payments.loading}
        dataSource={payments.data.filter((i) => i.active)}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "200"],
          total: payments.data.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        scroll={{ x: "max-content" }}
        onChange={(pagination, filters, sorter) => {
          if (filters.dates) {
            dispatch({
              type: Types.SET_UNIVERSAL_DATE,
              payload: filters.dates,
            });
          }
        }}
      />
    </div>
  );
}
