import { Button, DatePicker, message, Popover, Space, Table } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";
import { localDateTime, parser } from "../../utils";
import { CalendarOutlined } from "@ant-design/icons";
import { dayStartHour } from "../../common/utils";
import useSelect from "../../components/inventory/utils/useSelect";
import useRestaurants from "../../hooks/useRestaurants";

export default function OrderIssues() {
  const [issues, setIssues] = useState({});
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const { getRestaurants } = useRestaurants();
  const [, getSelectRestaurantProps] = useSelect({
    items: restaurants,
    onSearch: (v) =>
      getRestaurants(
        {
          limit: 8,
          search: v,
          select: ["name", "hub.name"],
        },
        (err, res) => {
          if (err) {
            message.error(err.message);
          } else {
            setRestaurants(parser(res.results));
          }
        }
      ),
    getName: (v) => `${v.name} - ${v.hub?.get("name")}`,
    getValue: (v) => v.id,
  });

  const fetchIssues = async ({
    limit,
    skip,
    status,
    approved,
    type,
    refund_status,
    accountable,
    sort,
    createdAt,
    restaurant_penalty,
  }) => {
    try {
      setLoading(true);
      const query = new Parse.Query("order_issue")
        .limit(limit)
        .skip(skip)
        .withCount()
        .exists("order")
        .select([
          "order.status",
          "order.payment_method",
          "order.delete",
          "refund_status",
          "type",
          "refund_amount",
          "restaurant_penalty",
          "accountable",
          "rider_penalty",
          "status",
          "approved",
          "added_by.name",
          "details",
        ]);

      if (Array.isArray(status)) {
        query.containedIn("status", status);
      }

      if (Array.isArray(createdAt)) {
        const [start, end] = createdAt.map((date) => dayStartHour(date));
        end.setDate(end.getDate() + 1);
        query.greaterThanOrEqualTo("createdAt", start);
        query.lessThanOrEqualTo("createdAt", end);
      }

      if (Array.isArray(approved)) {
        query.containedIn("approved", approved);
      }

      if (restaurant_penalty) {
        query.containedIn("restaurant_penalty.id", restaurant_penalty);
      }

      if (Array.isArray(type)) {
        query.containedIn("type", type);
      }

      if (Array.isArray(refund_status)) {
        query.containedIn("refund_status", refund_status);
      }

      if (Array.isArray(accountable)) {
        query.containedIn("accountable", accountable);
      }

      if (sort && sort.field) {
        if (sort.order === "ascend") {
          query.ascending(sort.field);
        } else {
          query.descending(sort.field);
        }
      } else {
        query.descending("createdAt");
      }

      const issues = await query.find();

      issues.results = issues.results.reduce((acc, issue) => {
        const { order, ...rest } = issue.toJSON();

        const data = {
          ...rest,
          key: issue.id,
          orderId: order?.objectId,
          orderStatus: order?.status,
          paymentMethod: order?.payment_method,
        };

        if (!order.delete) {
          acc.push(data);
        }

        return acc;
      }, []);
      setIssues(issues);
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error(err.message);
      setLoading(false);
    }
  };

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? dateString : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  });

  useEffect(() => {
    fetchIssues({ limit: 100, skip: 0 });
  }, []);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "createdAt",
      ...dateSearchProps("createdAt"),
      render: (id, { createdAt }) => (
        <Link
          style={{ color: "gray", fontWeight: 600, letterSpacing: "1px" }}
          to={`/order/list/${id}`}
        >
          <div>#{id}</div>
          <small>{localDateTime(createdAt)}</small>
        </Link>
      ),
    },

    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      render: (is) => (is ? "Yes" : "No"),
    },
    {
      title: "Issue Type",
      dataIndex: "type",
      key: "type",
      filters: [
        { text: "Missing Item", value: "missing item" },
        { text: "Late Delivery", value: "late delivery" },
        { text: "Damaged Item", value: "damaged item" },
        { text: "Wrong Item", value: "wrong item" },
        { text: "Prepare time exceed", value: "prep time exceeded" },
        {
          text: "Order Rejected By Restaurant",
          value: "rejected by restaurant",
        },
        { text: "Other", value: "other" },
      ],
    },
    {
      title: "Accountable",
      dataIndex: "accountable",
      key: "accountable",
      filters: [
        { text: "Restaurant", value: "restaurant" },
        { text: "Rider", value: "rider" },
      ],
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (details) => (
        <Popover content={details}>
          <Button>Details</Button>
        </Popover>
      ),
    },
    {
      title: "Restaurant Penalty",
      dataIndex: "restaurant_penalty",
      key: "restaurant_penalty",
      width: 160,
      ...getSelectRestaurantProps("restaurant_penalty"),
      sorter: (a, b) => a.restaurant_penalty - b.restaurant_penalty,
      render: (rests) =>
        Array.isArray(rests) ? (
          <div>
            {rests.map((rest, i) => (
              <div key={i}>
                {rest.name || rest.id}: {rest.amount} tk
              </div>
            ))}
          </div>
        ) : null,
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Open", value: "open" },
        { text: "Close", value: "close" },
      ],
    },
    {
      title: "Refund Status",
      dataIndex: "refund_status",
      key: "refund_status",
      filters: [
        { text: "Requested", value: "requested" },
        { text: "Initiated", value: "initiated" },
        { text: "Completed", value: "completed" },
      ],
    },
    {
      title: "Refund Amount",
      dataIndex: "refund_amount",
      key: "refund_amount",
      sorter: (a, b) => a.refund_amount - b.refund_amount,
    },

    {
      title: "Rider Penalty",
      dataIndex: "rider_penalty",
      key: "rider_penalty",
    },
    {
      title: "Raised By",
      dataIndex: "added_by",
      key: "addedBy",
      render: (addedBy) => addedBy?.name,
    },
  ];

  return (
    <div>
      <h1>Order Issues</h1>
      <Table
        loading={loading}
        columns={columns}
        dataSource={issues.results ?? []}
        pagination={{
          total: issues.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ["50", "100", "200", "500", "1000"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter;
          fetchIssues({
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            sort: { field, order },
            status: filters.status,
            approved: filters.approved,
            type: filters.type,
            refund_status: filters.refund_status,
            accountable: filters.accountable,
            createdAt: filters.createdAt,
            restaurant_penalty: filters.restaurant_penalty,
          });
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
