import menu from "./menu";
import { Drawer, Layout, Menu, Typography } from "antd";
import Styled from "styled-components";
import { Link } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

function MenuTop({ collapsed }) {
  return (
    <MenuTopWrapper>
      <Typography.Text
        className="name"
        style={{
          color: "white",
          fontSize: "20px",
        }}
      >
        <img
          src="/munchies-loader2.gif"
          alt="logo"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "10px",
          }}
        />
      </Typography.Text>
      <p className="v">v{process.env.REACT_APP_APP_VERSION}</p>
    </MenuTopWrapper>
  );
}

const MenuTopWrapper = Styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;

    p{
        margin-bottom: 0;
    }
    .v{
        font-size: 12px;
        margin-right: 10px;
        color: gray;
    }

`;

const accessControl = (menu, pages = [], user) => {
  if (!Array.isArray(menu)) return [];

  const pagesByKey = pages.reduce((acc, page) => {
    const { key, users = [], roles = [] } = page;
    if (users && users.includes(user.objectId)) {
      acc[key] = page;
    } else if (roles && roles.includes(user.type)) {
      acc[key] = page;
    }
    return acc;
  }, {});

  const newItems = [];

  menu.forEach((item) => {
    const { key, items: subMenu } = item;

    if (key && pagesByKey[key]) {
      newItems.push(item);
    } else if (!key && Array.isArray(subMenu)) {
      const newSubMenu = [];

      subMenu.forEach((subItem) => {
        const { key: subKey } = subItem;

        if (subKey && pagesByKey[subKey]) {
          newSubMenu.push(subItem);
        }
      });

      if (newSubMenu.length > 0) {
        item.items = newSubMenu;
        newItems.push(item);
      }
    }
  });

  return newItems;
};

export default function Sidebar({
  width,
  collapsed,
  onCollapse,
  authData,
  pages,
}) {
  const getActiveMenu = () => {
    const url = new URL(window.location);
    const paths = url.pathname
      .substring(1)
      ?.split("/")
      .map((i) => "/" + i);
    if (Array.isArray(paths) && paths.length) {
      return paths[0];
    } else {
      return "/";
    }
  };

  const getActiveKey = () => {
    const url = new URL(window.location);
    const paths = url.pathname
      .substring(1)
      ?.split("/")
      .map((i) => "/" + i);
    if (Array.isArray(paths) && paths.length) {
      return paths.join("");
    } else {
      return "/";
    }
  };

  const content = (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        width: width < 768 ? "100%" : "",
      }}
      collapsible={width > 768}
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="customScroll"
      theme="dark"
    >
      <MenuTop collapsed={collapsed} />
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[getActiveKey()]}
        defaultOpenKeys={[getActiveMenu()]}
        style={{
          fontSize: "16px",
          letterSpacing: ".5px",
          margin: 0,
          padding: 0,
          paddingBottom: "60px",
        }}
        onClick={() => {
          if (width < 768) onCollapse(true);
        }}
      >
        {accessControl(menu, pages, authData).map((menuItem) =>
          menuItem.items ? (
            <SubMenu
              key={menuItem.base}
              title={menuItem.title}
              icon={menuItem.icon}
              style={{ fontWeight: 500 }}
            >
              {menuItem.items.map((subMenuItem) => (
                <Item
                  style={{ margin: 0, fontWeight: 500 }}
                  key={menuItem.base + subMenuItem.link}
                >
                  <Link
                    style={{ fontWeight: 500 }}
                    to={menuItem.base + subMenuItem.link}
                  >
                    {subMenuItem.title}
                  </Link>
                </Item>
              ))}
            </SubMenu>
          ) : (
            <Item key={menuItem.link} icon={menuItem.icon}>
              <Link style={{ fontWeight: 500 }} to={menuItem.link}>
                {menuItem.title}
              </Link>
            </Item>
          )
        )}
      </Menu>
    </Sider>
  );

  if (width < 768) {
    return (
      <Drawer
        visible={!collapsed}
        placement="left"
        onClose={() => onCollapse(true)}
        closable={false}
        width={200}
      >
        {content}
      </Drawer>
    );
  }

  return content;
}
