import { Button, DatePicker, Drawer, message, Space, Table } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { useParams } from "react-router-dom";
import { localDateTime } from "../../../utils";
import OrderDetails from "../../../pages/Order/Details";

export default function RatingReviews() {
  const [data, setData] = useState([]);
  const [orderId, setOrderId] = useState("");

  const { id } = useParams();

  const fetchData = async (params) => {
    try {
      const res = await Parse.Cloud.run("riderRatingAndReviews", params);
      setData(res);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchData({
      riderId: id,
    });
  }, []);

  return (
    <div>
      <Table
        dataSource={data}
        title={() => <h3>Reviews</h3>}
        columns={[
          {
            title: "Order",
            dataIndex: "orderId",
            key: "orderId",
            width: "160px",
            render: (id, { createdAt }) => {
              return (
                <div
                  onClick={() => setOrderId(id)}
                  style={{ cursor: "pointer" }}
                >
                  <div>{id}</div>
                  <small style={{ color: "gray" }}>
                    {localDateTime(createdAt)}
                  </small>
                </div>
              );
            },
          },
          {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
            width: "100px",
          },
          {
            title: "Review",
            dataIndex: "review",
            key: "review",
          },
        ]}
        pagination={{
          total: data.length,
          pageSize: 1000,
          onChange: (page, pageSize) => {
            fetchData({
              riderId: id,
              limit: pageSize,
              skip: (page - 1) * pageSize,
            });
          },
        }}
      />
      <Drawer
        title="Order Details"
        placement="right"
        closable={true}
        onClose={() => setOrderId("")}
        visible={orderId}
        width={1000}
      >
        {orderId && <OrderDetails orderId={orderId} />}
      </Drawer>
    </div>
  );
}
