import { memo, useCallback, useContext, useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Input,
  Space,
  InputNumber,
  Button,
  message,
  Progress,
  Checkbox,
} from "antd";
import styled from "styled-components";
import Addons from "./Addons";
import Variants from "./Variants";
import MetaInfo from "./MetaInfo";
import useMedia from "../../../hooks/useMedia";
import { BulkProductUploadContext } from "../../../pages/Restaurant/BulkProductUpload";
import { DeleteOutlined } from "@ant-design/icons";
import { isFloat } from "../../../common/utils";

const { Item } = Form;

function ProductItem({ id, data, deleteItem, setData }) {
  const [form] = Form.useForm();
  const { uploadFile } = useMedia();
  const [progress, setProgress] = useState(0);
  const { restaurant, addProductFunc, saveData } = useContext(
    BulkProductUploadContext
  );

  const onFinish = useCallback(
    (values) => {
      if (!data.category) {
        return message.error(
          `Please select category for product (${values.name})`
        );
      }

      if (!data.image) {
        return message.error(
          `Image upload isn't complete yet of (${values.name}) !`
        );
      }

      if (!values["price"]) {
        return message.error(`Please enter price for product (${values.name})`);
      }

      // add restaurant
      values["images"] = [data.image];
      values["restaurant"] = restaurant.data.id;
      values["category"] = data.category.id;

      if (values.price?.variants?.length > 0) {
        values.price["type"] = "variant";
        values.price.variants.forEach((item) => {
          if (item.total && !item.price) {
            item.price = item.total - values.price.amount;
          }
        });
      } else {
        values.price["type"] = "flat";
        delete values.price.variants;
      }

      values["price"]["discount"] = {
        type: "fixed",
        amount: 0,
      };

      addProductFunc(values, (err, done) => {
        if (err) {
          message.error(err);
        } else {
          setData({ ...data, submit: true });
        }
      });
    },
    [data, id]
  );

  const uploadHandler = useCallback(
    async (file) => {
      uploadFile({ file, url: file.uploadUrl }, (err, percent) => {
        if (err) {
          message.error(err);
          return;
        }
        setProgress(percent);
        if (percent === 100) {
          const newData = { ...data, images: [file.uploadUrl.split("?")[0]] };
          setData(newData);
        }
      });
    },
    [data]
  );

  useEffect(() => {
    if (!data.form && data.formData) {
      form.setFieldsValue(data.formData);
    } else {
      form.setFieldsValue({
        name: data.name,
        price: { amount: data.price },
      });
    }
    data["form"] = form;
    setData(data);
  }, []);

  useEffect(() => {
    if (data.file) uploadHandler(data.file);
  }, []);

  useEffect(() => {
    if (data?.checked) {
      form.setFieldsValue({
        checked: true,
      });
    } else {
      form.setFieldsValue({
        checked: false,
      });
    }
  }, [data?.checked]);

  return (
    <Wrapper span={24} md={12} xl={8}>
      <Form
        form={form}
        className="product-form"
        name={`form-${id}`}
        onFinish={onFinish}
        onChange={() => {
          saveData(data);
        }}
        initialValues={{
          price: {},
        }}
        size="middle"
      >
        {data.file && (
          <Progress
            type="circle"
            width={30}
            className="upload-progress"
            percent={progress}
          />
        )}
        <div className="properties">
          <Space direction="vertical">
            <div style={{ display: "flex" }}>
              <div className="check-box">
                <Item
                  name="checked"
                  valuePropName="checked"
                  style={{ margin: 0 }}
                >
                  <Checkbox
                    onChange={(v) => {
                      setData({ ...data, checked: v.target.checked });
                    }}
                  ></Checkbox>
                </Item>
                <Item name="category" style={{ display: "none" }}>
                  <Input />
                </Item>
              </div>
              <Button
                onClick={deleteItem}
                style={{ marginLeft: "10px" }}
                shape="circle"
                icon={<DeleteOutlined />}
                danger
              />
            </div>
            {data?.category && (
              <div title="Category" className="category">
                {data.category?.name}
              </div>
            )}
          </Space>
        </div>
        <Card
          cover={
            <img
              alt="example"
              style={{ maxHeight: "360px" }}
              src={data.images ? data.images[0] : data?.file?.thumbUrl}
            />
          }
        >
          <div className="first-imp">
            <Item
              name="name"
              style={{ width: "65%" }}
              rules={[{ required: true, message: "Name required!" }]}
            >
              <Input placeholder="Product Name" />
            </Item>
            <Item
              name={["price", "amount"]}
              rules={[
                {
                  required: true,
                  type: "number",
                  validator: (rule, value) => {
                    if (value === undefined) {
                      return Promise.reject("Price required!");
                    } else if (isFloat(value)) {
                      return Promise.reject("Price must be integer");
                    } else if (value < 0) {
                      return Promise.reject("Price must be positive");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              style={{ width: "30%" }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Price"
                suffix="tk"
              />
            </Item>
            <Item name={["price", "vat"]} style={{ display: "none" }}>
              <InputNumber />
            </Item>
          </div>
          <Item name="details" style={{ width: "100%" }}>
            <Input.TextArea placeholder="Description" />
          </Item>
          <Variants />
          <Addons />
          <MetaInfo />
        </Card>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled(Col)`
  @keyframes slowup {
    0% {
      opacity: 0;
      transform: translateY(20px);
      height: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      height: auto;
    }
  }

  .product-form {
    position: relative;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;

    .upload-progress {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 100%;
      z-index: 1;
      background: #fff;
      width: 46px;
      padding: 8px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    }

    .properties {
      position: absolute;
      top: 0;
      right: 0;
      height: 160px;
      padding: 16px;
      z-index: 1;
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 500;
    }

    .category {
      min-width: 120px;
      height: 32px;
      padding: 5px 16px;
      background: #fff;
      color: #c2185b;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    }

    .availability {
      width: 160px;
      border: none;
    }

    .check-box {
      width: 40px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      margin-left: auto;
    }
  }
  .slowup {
    animation: slowup 0.5s ease-in-out;
  }

  .ant-card-body {
    padding: 20px 16px;
  }

  .first-imp {
    display: flex;
    justify-content: space-between;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-btn {
    background: #001529b5;
    color: #fff;
    border: none;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    letter-spacing: 1px;
    .bx {
      font-size: 16px;
    }
    &:hover {
      opacity: 0.9;
    }
  }

  .variant-item {
    margin-bottom: 10px;
    padding: 10px;
  }
`;

export default memo(ProductItem);
