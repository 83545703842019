import { Form, Input, Space, Button, InputNumber, Col, Switch } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Boxer, { Box } from "../../../Common/Boxer";
import { useEffect, useState } from "react";
import { isFloat } from "../../../../common/utils";

const { Item, List } = Form;

function Variant({
  name: variantName,
  fieldKey,
  remove: removeVariant,
  form,
  previouslyAvailable,
  setPreviouslyAvailable,
  ...rest
}) {
  const getAllItems = (showMessage) => {
    try {
      const priceField = form?.getFieldsValue("price");
    if (priceField) {
      const variants = priceField.price.variants;
      console.log(variants);
      let totalAvailable = 0;
      if (Array.isArray(variants)) {
        variants.forEach((variant) => {
          variant.items?.forEach((item) => {
            if (item.availability || item.availability === "checked") {
              totalAvailable = totalAvailable + 1;
            }
          });
        });
        if (showMessage && totalAvailable === 0) {
          alert(
            "You have no available Items, Please make your restaurant unavailable "
          );
        }
        if (showMessage && totalAvailable === 1 && previouslyAvailable === 0) {
          alert(
            "You just made an item available! Please make your restaurant available "
          );
        }
        setPreviouslyAvailable(totalAvailable);
      }
    }
    } catch (error) {
      console.log(error.message);
    }
    
  };

  useEffect(() => {
    getAllItems(false);
  }, []);

  return (
    <Box
      title={`Variant ${variantName + 1}`}
      rightMenu={
        <Button
          size="small"
          onClick={() => removeVariant(variantName)}
          danger
          style={{ border: "none" }}
          icon={<DeleteOutlined />}
        ></Button>
      }
    >
      <Item
        {...rest}
        name={[variantName, "title"]}
        style={{ marginBottom: "0px" }}
        label="Variation Title"
        rules={[
          {
            required: true,
            message: `Title can't be empty!`,
          },
        ]}
      >
        <Input size="large" placeholder="Title" />
      </Item>
      <Space style={{ marginBottom: "10px" }}>
        <Item
          {...rest}
          name={[variantName, "min"]}
          label="Minimum"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            placeholder="Min"
            style={{ width: "150px" }}
            size="large"
            min={0}
          />
        </Item>
        <Item
          {...rest}
          name={[variantName, "max"]}
          label="Maximum"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            placeholder="Max"
            style={{ width: "150px" }}
            size="large"
            min={0}
          />
        </Item>
      </Space>
      <h3>Items</h3>
      <List name={[variantName, "items"]}>
        {(fields, { add, remove }, i) => (
          <div key={i} style={{ position: "relative" }}>
            {fields.map(({ key, name, fieldKey, ...rest }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <Item
                  {...rest}
                  name={[name, "name"]}
                  style={{ marginBottom: "0px" }}
                  rules={[
                    {
                      required: true,
                      message: `Item name can't be empty!`,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Item Name" />
                </Item>
                <Item
                  {...rest}
                  name={[name, "price"]}
                  style={{ marginBottom: "0px", display: "none" }}
                >
                  <InputNumber size="large" placeholder="+Price" />
                </Item>
                <Item
                  {...rest}
                  name={[name, "total"]}
                  style={{ marginBottom: "0px" }}
                  rules={[
                    {
                      required: true,
                      message: `Price can't be empty!`,
                      validator: (rule, value) => {
                        if (value === undefined) {
                          return Promise.reject("Price required!");
                        } else if (isFloat(value)) {
                          return Promise.reject("Price must be integer");
                        } else if (value < 0) {
                          return Promise.reject("Price must be positive");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Total Price"
                    style={{ width: "100px" }}
                    min={0}
                  />
                </Item>
                <Space>
                  <strong>Stock</strong>
                  <Item
                    {...rest}
                    name={[name, "stock"]}
                    style={{ marginBottom: "0px" }}
                  >
                    <InputNumber
                      disabled
                      style={{
                        background: "#ff7875",
                        border: "none",
                        color: "black",
                      }}
                    />
                  </Item>
                </Space>
                <Item
                  {...rest}
                  name={[name, "availability"]}
                  style={{ marginBottom: "0px" }}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={() => getAllItems(true)}
                    checkedChildren="available"
                    unCheckedChildren="unavailable"
                  />
                </Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}

            <Item style={{ marginBottom: "0px" }}>
              <Button
                size="large"
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                style={{ display: "flex", alignItems: "center" }}
              >
                Add Item
              </Button>
            </Item>
          </div>
        )}
      </List>
    </Box>
  );
}

export default function Variants(props) {
  const [previouslyAvailable, setPreviouslyAvailable] = useState(0);

  return (
    <Col span={24}>
      <Boxer>
        <List name={["price", "variants"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, ...rest }) => {
                return (
                  <Variant
                    key={key}
                    id={key}
                    form={props.form}
                    previouslyAvailable={previouslyAvailable}
                    setPreviouslyAvailable={setPreviouslyAvailable}
                    remove={remove}
                    {...rest}
                  />
                );
              })}

              <div style={{ paddingLeft: "10px" }}>
                <Button
                  type="dashed"
                  size="large"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Add Variations
                </Button>
              </div>
            </>
          )}
        </List>
      </Boxer>
    </Col>
  );
}

