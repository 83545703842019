import { Form, Select } from "antd";
const { Item } = Form;

export default function Categories({ categories, getCategories }) {
  const { loading, data } = categories;

  return (
    <Item
      name="category"
      label="Category"
      rules={[
        {
          required: true,
          message: "Please select category",
        },
      ]}
      style={{ marginBottom: "5px" }}
    >
      <Select
        size="large"
        placeholder="Select category"
        showSearch
        onSearch={(v) => getCategories({ search: v, limit: 100 })}
        allowClear
        loading={loading}
        filterOption={(input, option) => {
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {data.results.map((category, index) => (
          <Select.Option key={index} value={category.id}>
            {category.name}
          </Select.Option>
        ))}
      </Select>
    </Item>
  );
}
