import { useState } from "react";
import { Col, Table, message } from "antd";
import Parse from "parse";
import { useEffect } from "react";
import { localDateTime, parser } from "../../utils";
import usePurchaseRecord from "../../hooks/usePurchaseRecord";

export default function InventoriesByProduct({ product }) {
  const [inventories, setInventories] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const { getPurchaseRecords } = usePurchaseRecord();
  const select = [
    "quantity",
    "unit_price",
    "selling_price",
    "expired_date",
    "stock",
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "160px",
      render: (date) => localDateTime(date),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "100px",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      width: "100px",
      sorter: (a, b) => a.unit_price - b.unit_price,
    },
    {
      title: "Selling Price",
      dataIndex: "selling_price",
      key: "selling_price",
      width: "100px",
      sorter: (a, b) => a.selling_price - b.selling_price,
    },
    {
      title: "Remain",
      dataIndex: "stock",
      key: "stock",
      width: "100px",
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Expired date",
      dataIndex: "expired_date",
      key: "expired_date",
      width: "100px",
    },
  ];

  const fetchInventories = async (params) => {
    setInventories({ ...inventories, loading: true });
    try {
      getPurchaseRecords(params, (err, res) => {
        if (res) {
          setInventories({
            loading: false,
            data: parser(res.results),
            count: res.count,
          });
        }
      });
    } catch (err) {
      message.error(err.message);
      setInventories({ ...inventories, loading: false });
    }
  };

  useEffect(() => {
    fetchInventories({ limit: 10, skip: 0, product, select });

    return () => {
      setInventories({ ...inventories, data: [] });
    };
  }, [product]);

  const tableOnChange = (pagination) => {
    fetchInventories({
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      products: [product],
      select,
    });
  };

  return (
    <Col span={12}>
      <Table
        loading={inventories.loading}
        dataSource={inventories.data}
        columns={columns}
        onChange={tableOnChange}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: inventories.count,
        }}
        scroll={{ x: "max-content" }}
      />
    </Col>
  );
}
