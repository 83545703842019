import { useEffect, useState } from "react";
import usePromo from "../../hooks/usePromo";
import { message, Table, Space, Button, Popconfirm, Drawer } from "antd";
import { parser } from "../../utils";
import { Link } from "react-router-dom";
import useSearch from "../../components/inventory/utils/useSearch";
import RestaurantLinks from "../../components/Common/RestaurantLinks";
import {
  DeleteOutlined,
  EditOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import CreateNewPromo from "./CreatePromo";

export default function Promo() {
  const { getPromos } = usePromo();
  const [promos, setPromos] = useState({
    loading: true,
    data: {
      count: 0,
      results: [],
    },
  });
  const [_, getColumnSearchProps] = useSearch();
  const [promoId, setPromoId] = useState(null);

  const fetchPromo = async (params) => {
    setPromos({ ...promos, loading: true });
    getPromos(params, (err, data) => {
      if (err) {
        return message.error(err);
      }

      promos.loading = false;
      promos.data.count = data.count;
      promos.data.results = parser(data.results);
      setPromos({ ...promos });
    });
  };

  const deleteHandler = async (ref) => {
    try {
      if (ref?.id) {
        const del = await ref.destroy();
        if (del) {
          message.success("Successfully deleted");
          const index = promos.data.results.findIndex(
            (item) => item.id === del.id
          );
          if (index > -1) {
            promos.data.results.splice(index, 1);
            setPromos({ ...promos });
          }
        }
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchPromo({ limit: 20 });
  }, []);

  const columns = [
    {
      title: "Promo Code",
      dataIndex: "promo_code",
      key: "promo_code",
      width: "150px",
      ...getColumnSearchProps("promo_code"),
      render: (text, { promo_auth }) => (
        <strong title={promo_auth}>{text}</strong>
      ),
    },
    {
      title: "Total Usage",
      dataIndex: "totalUsage",
      key: "totalUsage",
      width: "100px",
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
      width: "150px",
      render: (res) => {
        return (
          <div>
            {res && (
              <RestaurantLinks id={res.id}>{res.get("name")}</RestaurantLinks>
            )}
          </div>
        );
      },
    },
    {
      title: "Promo Type",
      dataIndex: "promo_type",
      key: "promo_type",
      width: "150px",
    },
    {
      title: "Promo amount",
      dataIndex: "promo_amount",
      key: "promo_amount",
      width: "100px",
    },
    {
      title: "Min Order Amount",
      dataIndex: "min_order_amount",
      key: "min_order_amount",
      width: "100px",
    },
    {
      title: "Max Discount Amount",
      dataIndex: "max_discount_amount",
      key: "max_discount_amount",
      width: "100px",
    },
    {
      title: "Apply On",
      dataIndex: "apply_on",
      key: "apply_on",
      width: "150px",
      filters: [
        { text: "Product", value: "products" },
        { text: "Categories", value: "categories" },
        { text: "Delivery Change", value: "delivery_charge" },
      ],
      onFilter: (value, record) => record.apply_on.indexOf(value) === 0,
    },
    {
      title: "Max Usage",
      dataIndex: "max_usage",
      key: "max_usage",
      width: "100px",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      width: "100px",
      render: (is_active) => {
        return is_active ? "Yes" : "No";
      },
    },
    {
      title: "Hide Restaurant",
      key: "hide_restaurant",
      dataIndex: "hide_restaurant",
      width: "100px",
      render: (hide_restaurant) => {
        return hide_restaurant ?? "No";
      },
    },
    {
      title: "Action",
      key: "action",
      width: "200px",
      render: (text, { id, ref }) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                to={`/promo/add?id=${id}`}
              >
                <EditOutlined />
              </Link>
            }
          ></Button>
          <Popconfirm
            title="Are you sure you want to delete this promo?"
            onConfirm={() => deleteHandler(ref)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger shape="circle" icon={<DeleteOutlined />}></Button>
          </Popconfirm>
          <Button
            icon={<ExpandOutlined />}
            onClick={() => setPromoId(id)}
            style={{ border: "none" }}
          ></Button>
        </Space>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter) => {
    fetchPromo({
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      promo_code: filters.promo_code ? filters.promo_code[0] : null,
      apply_on: filters.apply_on ? filters.apply_on : null,
    });
  };

  const promoMap = (promos) => {
    if (!Array.isArray(promos)) {
      message.error("Promos must be an array!");
      return [];
    }
    return promos.map((promo) => ({
      key: promo.id,
      id: promo.id,
      ...promo,
    }));
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={promoMap(promos.data.results)}
        loading={promos.loading}
        pagination={{
          total: promos.data.count,
          defaultPageSize: 20,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        onChange={onChange}
        scroll={{ x: true }}
      />
      <Drawer
        title="Promo Details"
        width={1200}
        visible={promoId}
        onClose={() => setPromoId(null)}
        destroyOnClose={true}
      >
        {promoId && <CreateNewPromo promoId={promoId} />}
      </Drawer>
    </div>
  );
}
