import { Col, Row, Switch } from "antd";
import { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RestaurantCategories } from "../../../pages/Restaurant/Categories";
import Styled from "styled-components";
import Products from "./Products";

function CategoryAvailability({ id, availability }) {
  const { updateCategory } = useContext(RestaurantCategories);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(availability);

  return (
    <Switch
      size="middle"
      checked={value}
      loading={loading}
      onClick={(value) => {
        setLoading(true);
        setValue(value);
        updateCategory({ id, availability: value }, () => {
          setLoading(false);
        });
      }}
    />
  );
}

function CategoryList() {
  const { categories, updateCategories, sortingUpdate, id } =
    useContext(RestaurantCategories);

  const onDragend = ({ source, destination }) => {
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const newCategories = Array.from(categories);
    const [removed] = newCategories.splice(source.index, 1);
    newCategories.splice(destination.index, 0, removed);
    updateCategories(newCategories);

    sortingUpdate({
      restaurantId: id,
      ids: newCategories.map((cat) => cat.objectId),
    });
  };

  return (
    <Col span={24} md={8}>
      <DragDropContext onDragEnd={onDragend}>
        <Droppable droppableId="droppable">
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="category-list customScroll list"
              >
                <div className="group-items">
                  {categories?.map((item, i) => (
                    <Draggable draggableId={`draggable-${i}`} index={i}>
                      {(provided) => {
                        return (
                          <div
                            key={i}
                            className="item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => {
                              const element = document.getElementById(
                                item.objectId
                              );
                              if (element) {
                                // 👇 Will scroll smoothly to the top of the next section
                                element.scrollIntoView({ behavior: "smooth" });
                              }
                            }}
                          >
                            <img src={item.category?.get("image")} alt="" />
                            <div>
                              <h3 className="title">
                                {item.category?.get("name")}
                              </h3>
                              <CategoryAvailability
                                id={item.objectId}
                                availability={item.availability}
                              />
                            </div>
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Col>
  );
}

export default function Categories() {
  const { id } = useContext(RestaurantCategories);
  const showList = localStorage.getItem(`res-cat-list-${id}`);

  return (
    <Wrapper>
      <Row gutter={[12, 12]}>
        <CategoryList />
        <Products showList={showList} />
      </Row>
    </Wrapper>
  );
}

const Wrapper = Styled.div`

  .group-items {
      margin-top: 10px;
      padding-right: 5px;

      .item {
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        background: #546e7a;
        border-radius: 5px;
        position: relative;
        animation: fadeIn 0.5s ease-in-out;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        &:hover {
            opacity: .9;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .avail {
          position: absolute;
          top: 10px;
          right: 10px;
          background: #fff;
          width: 20px;
          height: 10px;
          border-radius: 10px;
        }

        .available{
          background: #afffaf;
        }
        .unavailable{
          background: #ff7373;
        }
        .stockout{
          background: #f7c766;
        }

        .title,
        .subtitle {
          color: #fff;
          margin-bottom: 0px;
          letter-spacing: 1px;
        }

        button {
          position: absolute;
          top: 0;
          right: 0;
          margin: 16px;
          border: none;
          transition: all 0.3s ease;

          &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .list {
      .group-items {
        background: inherit;

        .item {
          background: #f7f7f7;
          border: 1px solid #e8e8e8;
          

          .title {
            color: black;
          }
        }
      }
    }

    .products {
      height: calc(100vh - 110px);
    }

    .category-list {
      height: calc(100vh - 110px);
      background: #fff;
      border-radius: 5px;
      padding: 10px;
    }


    .category-droppable {
      background: #fff;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #e8e8e8;

      .title {
        margin-bottom: 0px;
        font-size: 16px;
      }

    }

`;
