import styled from "styled-components";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Image } from "antd";

const Item = ({ title, value }) => {
  return (
    <div className="profile-info-item">
      <div className="profile-info-item-title">{title}</div>
      <div className="profile-info-item-value">
        {value ?? <span style={{ color: "gray" }}>N/A</span>}
      </div>
    </div>
  );
};

const ImageViewer = ({ images = [] }) => {
  if (images.length === 0)
    return (
      <div className="image-viewer">
        <div className="image">No Image</div>
      </div>
    );
  return (
    <div className="image-viewer">
      {images.map((image, index) => (
        <div key={index} className="image">
          <Image src={image} preview={image} alt="" />
        </div>
      ))}
    </div>
  );
};

export default function ProfileInfo({ data }) {
  if (!data) return <div>Not Found</div>;

  const {
    objectId,
    username,
    email,
    phone,
    name,
    image,
    license,
    bikeDocuments,
    nid,
    nidNumber,
    jobType,
    vehicleType,
    vehicleNumber,
    employeeType,
    dutyHour,
    basicSalary,
    grantorNid,
    grantorContact,
    grantorRelation,
    presentAddress,
    permanentAddress,
    joiningDate,
    endingDate,
    hub,
    referredBy,
    date_of_birth,
  } = data;

  return (
    <Wrapper className="profile-info">
      <div className="profile-header">
        <div className="left">
          <div className="image">{image && <img src={image} alt="" />}</div>
          <div className="info">
            <h1 className="name">{name}</h1>
            <h2 className="username">{username}</h2>
            <p className="phone">{phone}</p>
            <div className="email">{email}</div>
          </div>
        </div>
        <div className="right">
          <Link to={`/rider/update/${objectId}`}>
            <EditOutlined /> Edit
          </Link>
        </div>
      </div>
      <div className="profile-body">
        <Item title="Hub" value={hub?.name} />
        <Item title="Job Type" value={jobType} />
        <Item title="Vehicle Type" value={vehicleType} />
        <Item title="Employee Type" value={employeeType} />
        <Item title="NID Number" value={nidNumber} />
        <Item title="Rider Basic" value={basicSalary} />
        {date_of_birth && (
          <Item
            title="Date of Birth"
            value={new Date(date_of_birth.iso).toLocaleDateString()}
          />
        )}
        <Item title="Rider duty hour" value={dutyHour} />
        <Item title="Vehicle Number" value={vehicleNumber} />
        <Item title="Grantor NID" value={<ImageViewer images={grantorNid} />} />
        <Item title="Grantor Contact" value={grantorContact} />
        <Item title="Grantor Relation" value={grantorRelation} />
        <Item title="Present Address" value={presentAddress} />
        <Item title="Permanent Address" value={permanentAddress} />
        <Item title="Joining Date" value={joiningDate} />
        <Item title="Ending Date" value={endingDate} />
        <Item title="Referred By" value={referredBy?.name} />
        <Item title="License" value={<ImageViewer images={license} />} />
        <Item title="NID" value={<ImageViewer images={nid} />} />
        <Item
          title="Bike Documents"
          value={<ImageViewer images={bikeDocuments} />}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .profile-header {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;

      .image {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        background: #eee;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .info {
        .name,
        .username,
        .phone {
          margin-bottom: 0;
        }
        .name {
          font-size: 20px;
          font-weight: bold;
        }
        .username {
          font-size: 14px;
        }
        .phone {
          font-size: 14px;
        }
      }
    }
  }

  .profile-body {
    margin-top: 20px;
    .profile-info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      font-weight: 500;
      font-size: 16px;
    }

    .profile-info-item-title {
      color: #000000b0;
      font-size: 15px;
    }

    .profile-info-item-value {
      text-align: right;
      background: #f7f7f7;
      padding: 2px 10px;
      border-radius: 27px;
      width: content-fit;
      overflow: hidden;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      color: #000000b0;
    }
  }

  .image-viewer {
    min-width: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    justify-content: flex-end;

    flex-wrap: wrap;
    .image {
      width: 50px;
      height: 50px;
      margin-right: 5px;
      text-align: center;
      border-radius: 10px;
      color: gray;
      font-size: 14px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
`;
