import { createContext, useReducer, useState, useEffect } from "react";
import Parse from "parse";
import DateHandler from "../../components/dashboard/DateHandler";
import moment from "moment";
import { addDays } from "date-fns";
import { useParams } from "react-router-dom";
import RestaurantSalesReportOrders from "../../components/restaurant/sales-report/Orders";
import OrdersPerHour from "../../components/restaurant/sales-report/OrdersPerHour";
import OrdersPerDate from "../../components/restaurant/sales-report/OrdersPerDate";
import { Affix, Row, Col, Space, Drawer } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SoldItems from "../../components/restaurant/sales-report/SoldItems";
import OrdersInArea from "../../components/restaurant/sales-report/OrdersInArea";
import Statistics from "../../components/restaurant/sales-report/Statistics";
import SalesPerDate from "../../components/restaurant/sales-report/SalesPerDate";
import { RestaurantTopMenu } from "../../components/Common/RestaurantLinks";
import OrderDetails from "../Order/Details";

export const SalesReportContext = createContext();

const initialState = {
  loading: false,
  data: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

function Menu() {
  const Item = ({ id, name }) => {
    return (
      <div className="menu-item">
        <a className="link" href={`#${id}`}>
          {name}
        </a>
      </div>
    );
  };
  return (
    <div className="menu">
      <Item id="statistics" name="Statistics" />
      <Item id="sales-per-day" name="Sales Per Date" />
      <Item id="orders-per-day" name="Orders Per Date" />
      <Item id="orders-in-areas" name="Orders In Areas" />
      <Item id="orders-per-hour" name="Orders Per Hour" />
      <Item id="sold-items" name="Sold Items" />
      <Item id="orders" name="Orders" />
    </div>
  );
}

const getSplitDate = (date) => {
  const dateRange = date.split(",");
  return dateRange.map((d) => moment(new Date(d)).format("YYYY-MM-DD"));
};

export default function RestaurantSalesReport() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const search = new URLSearchParams(window.location.search);
  const [date, setDate] = useState(
    search.get("date")
      ? getSplitDate(search.get("date"))
      : [
          addDays(new Date(), -9).toLocaleDateString(),
          addDays(new Date(), 1).toLocaleDateString(),
        ]
  );
  const [orderId, setOrderId] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const fetchReport = async (params) => {
    dispatch({ type: "LOADING" });
    try {
      const data = await Parse.Cloud.run("restaurantSalesReport", params);
      dispatch({ type: "SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "ERROR" });
    }
  };

  useEffect(() => {
    const [startDate, endDate] = date.map((i) => new Date(i).toISOString());
    fetchReport({ ids: [id], date: [startDate, endDate] });

    history.push(
      `/restaurant/${id}/sales-report?date=${date.map((d) =>
        moment(d).format("YYYY-MM-DD")
      )}`
    );
  }, [date]);

  return (
    <SalesReportContext.Provider
      value={{
        ...state,
        date,
        setDate,
      }}
    >
      <Wrapper>
        <div className="rest-info">
          {state.data && (
            <div className="left">
              <img className="image" src={state.data.banner_image} alt="" />
              <h1 className="name">{state.data.name}</h1>
            </div>
          )}
          <RestaurantTopMenu id={id} />
        </div>
        <Row gutter={[16, 24]} className="body">
          <Col span={24} md={6}>
            <Affix offsetTop={100}>
              <DateHandler
                setDate={setDate}
                defaultValue={[
                  moment(new Date(date[0]), "yyyy-MM-DD"),
                  moment(new Date(date[1]), "yyyy-MM-DD"),
                ]}
              />
              <Menu />
            </Affix>
          </Col>
          <Col span={24} md={18}>
            {state.loading && <div className="loading">Loading...</div>}
            {state.data && (
              <div className="contents customScroll">
                <Statistics />
                <SalesPerDate />
                <OrdersPerDate />
                <OrdersInArea />
                <OrdersPerHour />
                <SoldItems />
                <RestaurantSalesReportOrders setOrderId={setOrderId} />
              </div>
            )}
          </Col>
        </Row>
      </Wrapper>
      <Drawer
        title="Order Details"
        placement="right"
        closable={false}
        onClose={() => setOrderId(null)}
        visible={orderId}
        width={1000}
      >
        {orderId && <OrderDetails orderId={orderId} />}
      </Drawer>
    </SalesReportContext.Provider>
  );
}

const Wrapper = styled(Row)`
  .rest-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;

    .left {
      display: flex;
      align-items: center;
      .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .name {
        margin-left: 10px;
        margin-bottom: 0px;
      }
    }
  }

  .body {
    background: #fff;
    padding: 20px;
    padding-right: 0px;
    width: 100%;

    .loading {
      text-align: center;
      padding: 20px;
    }

    .menu {
      background: #f2f2f2;
      border: 1px solid #e8e8e8;
      min-height: 400px;
      border-radius: 10px;
      padding: 20px;

      .menu-item {
        background: #fff;
        margin-bottom: 5px;
        border-radius: 10px;
        border: 1px solid #e8e8e8;

        .link {
          letter-spacing: 0.5px;
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          padding: 10px 10px;
          color: #363636;
          display: block;
        }

        &:hover {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        }
      }
    }
    .contents {
      padding: 20px;
      max-height: calc(100vh - 200px);

      #orders-per-day,
      #sales-per-day,
      #orders-per-hour,
      #sold-items,
      #orders-in-areas,
      #orders {
        margin-top: 50px;
      }

      #statistics {
        .item {
          font-size: 16px;
          border: 1px solid #e8e8e8;
          border-radius: 5px;
          padding: 10px;
          background: #f7f7f7;

          .title {
            font-weight: 500;
          }

          .value {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      #sold-items {
        .sold-items {
          .item {
            display: flex;
            margin-bottom: 5px;
            padding: 10px 20px;
            font-weight: 500;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            &:nth-child(odd) {
              background: #f7f7f7;
            }

            .idx {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
            .name {
              width: 20%;
            }
            .order {
              width: 15%;
            }
            .quantity {
              width: 15%;
            }
            .total {
              width: 15%;
            }
            .discount {
              width: 15%;
            }

            .progress {
              display: inline-block;
              margin-left: 10px;
              font-weight: 500;

              &.up {
                color: #ff4d4f;
              }
              &.down {
                color: #52c41a;
              }
            }
          }

          .variants {
            width: 100%;
            .variant {
              margin-bottom: 5px;
              padding: 5px 20px;
              font-weight: 500;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &:nth-child(even) {
                background: #f7f7f7;
              }
            }
          }
        }
      }

      .orders {
        .order {
          padding: 5px 20px;
          margin-bottom: 10px;
          border-bottom: 1px solid #ddd;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .id {
              font-weight: 500;
              margin-bottom: 0;
              letter-spacing: 1px;
            }

            .status {
              text-align: right;
              margin-bottom: 10px;

              .x {
                font-size: 14px;
                padding: 5px 20px;
                background: #faad14;
                border-radius: 10px;
                text-align: center;
                text-transform: capitalize;
                font-weight: 500;
                letter-spacing: 1px;

                &.delivered {
                  background: #0080003d;
                  color: green;
                }
                &.cancelled {
                  background: #faad1452;
                  color: #a76f00;
                }
                &.rejected {
                  background: #ff787566;
                  color: #990300;
                }
              }

              .time {
                font-size: 12px;
                letter-spacing: 1px;
                font-weight: 500;
                color: #999;
                margin-top: 5px;
              }
            }
          }

          .order-item {
            margin-bottom: 10px;

            .title-1 {
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 5px;
            }
            .title-2 {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 5px;
            }

            .variants {
              margin-top: 10px;
              .v-item {
                display: flex;
                justify-content: space-between;
                max-width: 300px;
                background: #fff;
                padding: 3px 10px;
                margin-bottom: 5px;
              }
            }
          }

          .info {
            font-weight: 500;
            .item {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 5px;
              .title {
                width: 120px;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
`;
