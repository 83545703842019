import { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  LayerGroup,
  GeoJSON,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import { Button, Col, Input, Modal, Row, Select, Space, message } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { v3Axios } from "../../utils";
import EditArea from "./EditArea";

function AddNewLayerName({ layer, set, onSubmit, zones = [] }) {
  const [name, setName] = useState("");
  const [zone, setZone] = useState("");

  return (
    <Modal
      title="Area Name"
      visible={Boolean(layer)}
      onOk={() => set(null)}
      onCancel={() => set(null)}
      footer={null}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <label>Area Name</label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label>Select Zone</label>
          <Select
            placeholder="Select zone"
            value={zone}
            onChange={(e) => setZone(e)}
            style={{ width: "100%" }}
          >
            {zones.map((zone) => (
              <Select.Option value={zone._id}>{zone.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Button
            onClick={() => {
              onSubmit({ name, layer, zone }, () => {
                setName("");
                setZone("");
              });
            }}
          >
            Submit
          </Button>
        </div>
      </Space>
    </Modal>
  );
}

const osmProviders = {
  mapTiler: {
    // default leaflet provider
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: `&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors`,
  },
};

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("./geo_location.png"),
  iconUrl: require("./geo_location.png"),
  shadowUrl: require("./marker-shadow.png"),
});

export const areaToLayer = (area) => {
  return {
    id: area._id,
    zone: area.zone,
    type: "Feature",
    properties: {
      name: area.name,
    },
    geometry: area.location,
  };
};

export const layerToArea = (layer) => {
  return {
    name: layer.properties.name,
    location: layer.geometry,
    zone: layer.zone,
  };
};

export default function Zone() {
  const center = { lat: 23.770112, lng: 90.410154 };
  const zoom = 15;
  const mapRef = useRef(null);
  const [newLayer, setNewLayer] = useState(null); // add new layer modal
  const [zones, setZones] = useState([]); // all zones
  const [layers, setLayers] = useState([]);
  const featureGroupRef = useRef(null);
  const [editLayer, setEditLayer] = useState(false);

  const _onCreate = (e) => {
    const layer = e.layer.toGeoJSON();
    layer.properties = {
      name: `Polygon ${layers.length + 1}`,
    };

    setNewLayer(layer);
  };

  const onEachFeature = (feature, layer) => {
    const name = feature.properties.name;
    layer.bindPopup(name);
  };

  const fetchZones = async () => {
    try {
      const { data } = await v3Axios.get("/api/zones");
      setZones(data);
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const fetchLayers = async () => {
    try {
      const { data } = await v3Axios.get("/api/v1/areas");
      setLayers(data.map(areaToLayer));
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const deleteLayer = async (id) => {
    try {
      await v3Axios.delete(`/api/v1/areas/${id}`);
      message.success("Deleted");
      setLayers((layers) => {
        return layers.filter((layer) => layer.id !== id);
      });
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const addArea = async ({ name, layer, zone }) => {
    try {
      layer.properties.name = name;
      layer.zone = zone;

      const { data } = await v3Axios.post("/api/v1/areas", layerToArea(layer));
      layer.id = data._id;
      setLayers([layer, ...layers]);
      setNewLayer(null);
      message.success("Added");
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
      setNewLayer(null);
    }
  };

  const updateLayer = (id, coordinates) => {
    setLayers((layers) => {
      const layer = layers.find((layer) => layer.id === id);
      if (layer) {
        layer.geometry.coordinates = coordinates;
      }

      return [...layers];
    });
  };

  useEffect(() => {
    fetchZones();
    fetchLayers();
  }, []);

  return (
    <Wrapper>
      <Row gutter={[10]}>
        <Col span={20}>
          <MapContainer center={center} zoom={zoom} ref={mapRef}>
            <FeatureGroup>
              <LayerGroup ref={featureGroupRef}>
                {layers.map((layer, index) => {
                  return (
                    <GeoJSON
                      key={index}
                      data={layer}
                      onEachFeature={onEachFeature}
                      style={{
                        // random color
                        color: `#${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`,
                        CSSStyleSheet: "solid",
                      }}
                    />
                  );
                })}
              </LayerGroup>
              <EditControl
                position="topright"
                onCreated={_onCreate}
                draw={{
                  circlemarker: false,
                  circle: false,
                  marker: false,
                  polyline: false,
                }}
                edit={{
                  edit: false,
                }}
              />
            </FeatureGroup>
            <TileLayer
              url={osmProviders.mapTiler.url}
              attribution={osmProviders.mapTiler.attribution}
            />
          </MapContainer>
        </Col>
        <Col span={4}>
          <h1 style={{ textAlign: "right" }}>Zone Areas</h1>
          <div className="zone customScroll">
            {layers.map((layer) => {
              return (
                <div className="zone-item flex-between" key={layer.id}>
                  <div className="name">{layer.properties.name}</div>
                  <Space>
                    <Button
                      onClick={() => setEditLayer(layer)}
                      size="small"
                      shape="circle"
                      icon={<EditOutlined />}
                    ></Button>

                    <Button
                      size="small"
                      icon={<CloseOutlined />}
                      shape="circle"
                      danger
                      onClick={() => deleteLayer(layer.id)}
                    ></Button>
                  </Space>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      <AddNewLayerName
        layer={newLayer}
        set={setNewLayer}
        onSubmit={addArea}
        zones={zones}
      />
      {editLayer && (
        <EditArea
          setVisible={setEditLayer}
          osmProviders={osmProviders}
          layer={editLayer}
          updateLayer={updateLayer}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .leaflet-container {
    width: 100%;
    height: 600px;
  }

  // .sr-only {
  //   display: none;
  // }

  .zone {
    height: calc(100vh - 140px);
    padding-right: 10px;
    &-item {
      padding: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      background: #fff;

      .icon {
        cursor: pointer;
        color: #ff4d4f;
        width: 20px;
      }
    }
  }
`;
