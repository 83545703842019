import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import Parse from "parse";
import { deleteFile, getSignedUrl, uploadFile } from "../api/media";
import { getRestaurants } from "../api/restaurant";
import { getImageKey } from "../common/utils";

const initialState = {
  list: {
    loading: false,
    data: [],
    count: 0,
  },
  createNew: {
    loading: false,
  },
  activeBanner: null,
  searchingResult: [],
};

export const fetchList = createAsyncThunk(
  "promotionalBanner/fetchList",
  async () => {
    const res = await new Parse.Query("promotional_banner")
      .ascending("sort_order")
      .find();

    return res;
  }
);

export const createNew = createAsyncThunk(
  "promotionalBanner/createNew",
  async (data) => {
    const { title, file, platform, content_type, hubs, done, areas } = data;
    try {
      let image;
      if (!file) {
        throw new Error("Please upload an image");
      }

      const { url } = await getSignedUrl(file.type);
      if (url) {
        await uploadFile({ url, file });
        image = url.split("?")[0];
      }

      const banner = new Parse.Object("promotional_banner");
      banner.set("title", title);
      banner.set("image", image);
      banner.set("platform", platform);
      banner.set("content_type", content_type);
      banner.set("hubs", hubs);
      banner.set("areas", areas);
      const res = await banner.save();
      if (typeof done === "function") {
        done();
      }
      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updateListAsync = async (data) => {
  try {
    await Parse.Object.saveAll(data);
  } catch (err) {
    message.error(err.message);
  }
};

export const searchBannerItems = createAsyncThunk(
  "promotionalBanner/searchBannerItems",
  async ({ content_type, search }) => {
    if (content_type === "Restaurant") {
      const restaurants = await getRestaurants({
        limit: 100,
        search,
        select: ["name", "banner_image", "hub.name"],
      });

      return {
        type: "restaurant",
        results: restaurants.results,
      };
    } else if (content_type === "Category") {
      return {
        type: "category",
        results: [],
      };
    }

    return [];
  }
);

export const updateBanner = createAsyncThunk(
  "promotionalBanner/updateBanner",
  async (object) => {
    if (object instanceof Parse.Object) {
      await object.save();
      return object;
    }
  }
);

export const addHubBrands = createAsyncThunk(
  "promotionalBanner/addHubBrands",
  async ({ hub, existingItems }) => {
    let brands = hub.brands;
    brands = brands.filter((id) => !existingItems.find((e) => e.id === id));
    const res = await getRestaurants({
      select: ["name", "banner_image", "hub.name"],
      ids: brands,
      limit: brands.length,
    });
    return res.results.map((i) => i.toJSON());
  }
);

export const deleteBanner = createAsyncThunk(
  "promotionalBanner/deleteBanner",
  async (object) => {
    if (object instanceof Parse.Object) {
      await object.destroy();
      const imageKey = getImageKey(object.get("image"));
      if (imageKey) {
        await deleteFile(imageKey);
      }
      return object;
    }
  }
);

const promotionalBanner = createSlice({
  name: "promotionalBanner",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list.data = action.payload;
    },
    setActiveBanner: (state, action) => {
      state.activeBanner = action.payload;
    },
    addBannerItem: (state, action) => {
      const item = state.searchingResult.find(
        (item) => item.objectId === action.payload
      );

      if (item) {
        if (!state.activeBanner.items) {
          state.activeBanner.items = [];
        }

        if (item.type === "category") {
          state.activeBanner.items.unshift({
            id: item.objectId,
            type: "category",
            name: item.name,
            image: item.image,
            restaurant: item.restaurant,
          });
        } else {
          state.activeBanner.items.unshift({
            id: item.objectId,
            type: "restaurant",
            name: item.name,
            image: item.banner_image,
            hub: item.hub,
          });
        }
      }
    },
    updateActiveItems: (state, action) => {
      state.activeBanner.items = action.payload;
    },
    deleteActiveItems: (state, action) => {
      state.activeBanner.items = state.activeBanner.items.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: {
    [fetchList.pending]: (state, action) => {
      state.list.loading = true;
    },
    [fetchList.fulfilled]: (state, action) => {
      state.list.loading = false;
      state.list.data = action.payload.map((object) => ({
        ...object.toJSON(),
        object,
      }));
    },
    [fetchList.rejected]: (state, action) => {
      state.list.loading = false;
      message.error(action.error.message);
    },
    [createNew.pending]: (state, action) => {
      state.createNew.loading = true;
    },
    [createNew.fulfilled]: (state, action) => {
      state.createNew.loading = false;
      state.list.data.unshift({
        ...action.payload.toJSON(),
        object: action.payload,
      });
    },
    [createNew.rejected]: (state, action) => {
      state.createNew.loading = false;
      message.error(action.error.message);
    },
    [searchBannerItems.fulfilled]: (state, action) => {
      state.searchingResult = [];
      if (action.payload.type === "restaurant") {
        action.payload.results.forEach((item) => {
          const exist = state.activeBanner.items?.find(
            ({ id }) => id === item.id
          );
          if (!exist) {
            state.searchingResult.push(item.toJSON());
          }
        });
      } else if (action.payload.type === "category") {
        state.searchingResult = action.payload.results;
      }
    },
    [updateBanner.fulfilled]: (state, action) => {
      if (action.payload) {
        const index = state.list.data.findIndex(
          (item) => item.objectId === action.payload.id
        );
        if (index > -1) {
          state.list.data.splice(index, 1, {
            ...action.payload.toJSON(),
            object: action.payload,
          });
          message.success("Updated");
        }
      }
    },
    [updateBanner.rejected]: (state, action) => {
      message.error(action.error.message);
    },
    [deleteBanner.fulfilled]: (state, { payload }) => {
      if (payload) {
        const index = state.list.data.findIndex(
          (item) => item.objectId === payload.id
        );
        if (index > -1) {
          state.list.data.splice(index, 1);
        }
      }
    },
    [addHubBrands.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.activeBanner.items = [
          ...state.activeBanner.items,
          ...payload.map((item) => ({
            id: item.objectId,
            name: item.name,
            image: item.banner_image,
            hub: item.hub,
          })),
        ];
      }
    },
    [addHubBrands.rejected]: (state, action) => {
      message.error(action.error.message);
    },
  },
});

export default promotionalBanner.reducer;
export const {
  updateList,
  setActiveBanner,
  addBannerItem,
  updateActiveItems,
  deleteActiveItems,
} = promotionalBanner.actions;
