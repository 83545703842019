import Parse from "parse";

export default function usePromo() {
  const repoName = "promo";
  const defaultFunc = (err, data) => {};

  const getPromos = async (
    {
      select,
      promo_code,
      is_active,
      promo_amount,
      restaurant,
      category,
      promo_auth,
      apply_on,
      promo_type,
      applicable_from,
    } = {},
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      if (select) {
        if (Array.isArray(select)) query.select(...select);
        else if (typeof select === "string") query.select(select);
      }
      if (promo_code) query.matches("promo_code", promo_code);
      if (is_active) query.equalTo("is_active", is_active);
      if (promo_amount) query.equalTo("promo_amount", promo_amount);
      if (restaurant) {
        query.equalTo("restaurant", {
          __type: "Pointer",
          className: "restaurant",
          objectId: restaurant,
        });
      }
      if (category) query.contains("categories", category);
      if (promo_auth) query.equalTo("promo_auth", promo_auth);
      if (apply_on) {
        if (Array.isArray(apply_on)) query.containedIn("apply_on", apply_on);
        else if (typeof apply_on === "string")
          query.equalTo("apply_on", apply_on);
      }
      if (promo_type) query.equalTo("promo_type", promo_type);
      if (applicable_from) query.equalTo("applicable_from", applicable_from);
      query.withCount();
      query.include("restaurant");
      query.descending("createdAt");
      const result = await query.find({
        sessionToken: Parse.User.current()?.getSessionToken(),
      });
      if (result) {
        callback(null, result);
      }
    } catch (err) {
      callback(err.message);
    }
  };

  const createPromo = async (params, callback = defaultFunc) => {
    try {
      const promo = await Parse.Cloud.run("addPromo", params);
      if (promo) {
        callback(null, promo);
      }
    } catch (err) {
      callback(err.message);
    }
  };

  const getById = async (id, callback = defaultFunc) => {
    if (!id) {
      callback("Id can't be empty!", null);
      return;
    }

    try {
      const promo = await new Parse.Query(repoName)
        .equalTo("objectId", id)
        .first();
      if (promo) {
        callback(null, promo);
      } else {
        callback("Promo not found!");
      }
    } catch (err) {
      callback(err.message);
    }
  };

  const updatePromo = async (id, params, callback = defaultFunc) => {
    if (!id) {
      return callback("Id can't be empty!");
    } else if (!params) {
      return callback("Params can't be empty!");
    } else if (params && typeof params !== "object") {
      return callback("Params must be an object!");
    }

    getById(id, async (err, promo) => {
      if (err) {
        return callback(err);
      }
      try {
        const {
          title,
          subTitle,
          activeTime,
          promo_code,
          promo_auth,
          promo_type,
          promo_amount,
          min_order_amount,
          max_discount_amount,
          cashback_amount,
          off_amount,
          apply_on,
          applicable_from,
          max_usage,
          max_usage_user,
          is_active,
          eligible_users,
          restaurant,
          categories,
          start_date,
          end_date,
          hide_restaurant,
          max_order,
          min_order,
          apply_on_restaurants,
          include_stores,
          restaurant_bear_percent,
        } = params;

        if (title) promo.set("title", title);
        if (subTitle) promo.set("subTitle", subTitle);
        if (promo_code) promo.set("promo_code", promo_code);
        if (promo_auth) promo.set("promo_auth", promo_auth);
        if (promo_type) promo.set("promo_type", promo_type);
        if (promo_amount) promo.set("promo_amount", promo_amount);
        if (min_order_amount) promo.set("min_order_amount", min_order_amount);
        if (max_discount_amount)
          promo.set("max_discount_amount", max_discount_amount);
        if (cashback_amount) promo.set("cashback_amount", cashback_amount);
        if (off_amount) promo.set("off_amount", off_amount);
        if (apply_on) {
          if (apply_on !== promo.get("apply_on")) {
            if (promo.get("apply_on") === "categories") {
              promo.unset("categories");
            }
          }
          promo.set("apply_on", apply_on);
        }
        if (applicable_from) promo.set("applicable_from", applicable_from);
        if (max_usage !== null || max_usage !== undefined)
          promo.set("max_usage", max_usage);
        if (max_usage_user !== null || max_usage_user !== undefined)
          promo.set("max_usage_user", max_usage_user);
        if (is_active !== undefined || is_active !== null) {
          promo.set("is_active", is_active);
        }
        if (eligible_users) promo.set("eligible_users", eligible_users);
        if (restaurant) {
          promo.set("restaurant", {
            __type: "Pointer",
            className: "restaurant",
            objectId: restaurant,
          });
        } else if (promo.get("restaurant") && !restaurant) {
          promo.unset("restaurant");
        }

        if (categories) promo.set("categories", categories);
        if (start_date) {
          promo.set("start_date", {
            __type: "Date",
            iso: start_date,
          });
        }
        if (end_date) {
          promo.set("end_date", {
            __type: "Date",
            iso: end_date,
          });
        }
        if (activeTime) promo.set("activeTime", activeTime);
        if (max_order !== undefined && max_order !== null) {
          promo.set("max_order", max_order);
        }
        if (min_order !== undefined && min_order !== null) {
          promo.set("min_order", min_order);
        }
        if (
          apply_on_restaurants !== undefined &&
          apply_on_restaurants !== null
        ) {
          promo.set("apply_on_restaurants", apply_on_restaurants);
        }
        if (include_stores !== undefined && include_stores !== null) {
          promo.set("include_stores", include_stores);
        }
        if (
          restaurant_bear_percent !== undefined &&
          restaurant_bear_percent !== null
        ) {
          promo.set("restaurant_bear_percent", restaurant_bear_percent);
        }

        promo.set("hide_restaurant", hide_restaurant ?? false);

        const res = await promo.save();
        if (res) {
          callback(null, promo);
        }
      } catch (err) {
        callback(err.message);
      }
    });
  };

  return { createPromo, getById, updatePromo, getPromos };
}
