import { Button, Col, Form, Input, Row, Select, message } from "antd";
import useRestaurants from "../../hooks/useRestaurants";
import { useEffect, useState } from "react";
import Parse from "parse";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import NoticeBoard from "./Noticeboard";

export default function RestaurantNotification() {
  const [form] = Form.useForm();
  const { getRestaurants } = useRestaurants();
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRestaurants = async (name) => {
    try {
      getRestaurants(
        { name, limit: 15, select: ["name", "hub.name"] },
        (err, res) => {
          if (err) {
            message.error(err);
          } else {
            setRestaurants(
              res.results.map((i) => ({
                id: i.id,
                name: `${i.get("name")}-${i.get("hub").get("name")}`,
                key: i.id,
              }))
            );
          }
        }
      );
    } catch (err) {
      message.error(err.message);
    }
  };

  const createNotification = async ({ title, text, restaurant }) => {
    const notification = new Parse.Object("partner_notification");
    notification.set("title", title);
    notification.set("text", text);
    if (restaurant) {
      notification.set("restaurant", {
        __type: "Pointer",
        className: "restaurant",
        objectId: restaurant,
      });
    } else {
      notification.set("seenBy", []);
    }
    await notification.save();
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (Array.isArray(values.restaurant) && values.restaurant.length > 0) {
        await Promise.all(
          values.restaurant.map(async (id) => {
            return await createNotification({
              title: values.title,
              text: values.text,
              restaurant: id,
            });
          })
        );
      } else {
        await createNotification({ title: values.title, text: values.text });
      }

      message.success("Notification created successfully");
      setLoading(false);
      form.resetFields();
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        name="restaurantNotification"
        size="large"
        onFinish={onFinish}
      >
        <Row gutter={[10, 10]} justify="center">
          <Col span={24} md={14}>
            <div className="form-content">
              <h1>Restaurant Notification</h1>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="text"
                label="Details"
                rules={[
                  {
                    required: true,
                    message: "Please input details!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="restaurant" label="Restaurants">
                <Select
                  showSearch
                  placeholder="Select restaurants"
                  optionFilterProp="children"
                  mode="multiple"
                  onSearch={fetchRestaurants}
                  filterOption={false}
                >
                  {restaurants.map((i) => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label=" " style={{ textAlign: "right" }}>
                <Button loading={loading} htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .form-content {
    padding: 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
`;
