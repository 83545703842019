import { Space, Table, Button } from "antd";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { PaymentHistoryContext } from "../../pages/Payments/PaymentHistoryPage";
import Parse from "parse";
import { localDate } from "../../utils";

export default function FullPaymentHistoryTable() {
  const [data, setData] = useState([]);
  const params = useParams();
  const { date, setDate } = useContext(PaymentHistoryContext);

  const fullPaymentHistoryFromServer = async () => {
    const startDateStr = moment(date[0]).format("YYYY-MM-DD");
    const endDateStr = moment(date[1]).format("YYYY-MM-DD");
    const restaurantId = params.resturentId;
    const fullPaymentHistoryFromServer = await Parse.Cloud.run(
      "getFullPaymentHistory",
      { startDateStr, endDateStr, restaurantId }
    );
    return fullPaymentHistoryFromServer;
  };

  useEffect(
    () => fullPaymentHistoryFromServer().then((res) => setData(res)),
    []
  );
  useEffect(
    () => fullPaymentHistoryFromServer().then((res) => setData(res)),
    [date]
  );

  const columns = [
    {
      title: "Payment Date",
      dataIndex: "createdAt",
      render: (date) => localDate(date),
    },
    {
      title: "Net Payable Amount",
      dataIndex: "payable",
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      title: "",
      key: "action",
      render: (text, { objectId }) => (
        <>
          <Space>
            <Button
              onClick={() => {
                const win = window.open(`/invoice/${objectId}`, "_blank");
                win.focus();
              }}
            >
              Invoice
            </Button>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content" }}
      />
    </>
  );
}
