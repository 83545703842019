import { Col, Tag, Row, Space, Typography } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

const variantTotal = (variant) => {
  if (Array.isArray(variant) && variant.length > 0) {
    return variant.reduce((acc, current) => {
      current.items.forEach((item) => {
        acc += item.price;
      });
      return acc;
    }, 0);
  } else {
    return 0;
  }
};

const getTotal = (items = [], key = "") => {
  const res = items.reduce((acc, cur) => acc + (cur[key] ?? 0), 0);
  return Math.round(res);
};

function PricingItem({ title, value, tag = "", tk = true, color, ...rest }) {
  return (tk && value !== 0) || (!tk && value) ? (
    <div className={`pricing-item ${tag}`} {...rest}>
      <div className="title">{title}</div>
      <div className="value" style={{ color }}>
        {value} {tk ? "tk" : ""}
      </div>
    </div>
  ) : null;
}

export default function InvoiceOrders({ orders }) {
  return (
    <Wrapper span={24}>
      {orders.map((order, i) => {
        const {
          orderId,
          time,
          total,
          orderNumber,
          promo,
          items = [],
          refund,
          penalty = 0,
          issue,
          status,
          rejection_reason,
          promo_discount = 0,
          product_discount = 0,
        } = order;

        return (
          <div key={i} className="list-item">
            <Row gutter={[24, 24]}>
              <Col span={24} lg={10}>
                <div className="infos">
                  <div className="status">
                    <div className={`text ${status}`}>{status}</div>
                    <Link
                      style={{ color: "black" }}
                      target="_blank"
                      to={`/order/list/${orderId}`}
                      className="id"
                    >
                      {orderNumber}
                    </Link>
                  </div>
                </div>
                <div className="pricing">
                  <PricingItem
                    title="Order ID"
                    value={
                      <Typography.Text copyable>{orderId}</Typography.Text>
                    }
                    tk={false}
                  />
                  <PricingItem
                    title="Created AT"
                    value={new Date(time).toLocaleString()}
                    tk={false}
                  />
                  <PricingItem title="Items Total" value={total} />
                  {promo_discount + product_discount > 0 && (
                    <PricingItem
                      title="Discount"
                      value={promo_discount + product_discount}
                    />
                  )}
                  <PricingItem
                    color="gold"
                    title="Promo Code"
                    value={promo}
                    tk={false}
                  />
                  {refund && (
                    <PricingItem
                      title="Refund Total"
                      value={refund}
                      color="red"
                    />
                  )}
                  {issue && (
                    <>
                      <PricingItem
                        title="Issue Type"
                        value={
                          <span style={{ textTransform: "capitalize" }}>
                            {issue.type}
                          </span>
                        }
                        color="red"
                        tk={false}
                      />
                      <PricingItem
                        title="Issue Details"
                        value={issue.details}
                        color="red"
                        tk={false}
                      />
                    </>
                  )}
                  <PricingItem title="Penalty" value={penalty} color="red" />
                  <PricingItem
                    title="Commission"
                    value={getTotal(items, "commission")}
                  />
                  <PricingItem title="Vat" value={getTotal(items, "vat")} />
                  <PricingItem
                    title="Payable"
                    value={getTotal(items, "payable")}
                    color="green"
                  />
                  <PricingItem
                    title=" "
                    value={rejection_reason}
                    tk={false}
                    color="red"
                  />
                </div>
              </Col>
              <Col span={24} lg={14}>
                <div>
                  {items.map((item, i) => {
                    return (
                      <div key={i}>
                        <div className="item">
                          <div className="counter">{i + 1}</div>
                          <Row style={{ width: "100%", alignItems: "center" }}>
                            <Col span={24} md={16} className="item-name">
                              <div>
                                <span>
                                  {item.name}
                                  {item.variant && item.variant.length > 0 && (
                                    <span>
                                      {item.variant
                                        .map((i) =>
                                          i.items.map((a) => a.name).join(" ")
                                        )
                                        .join("-")}
                                    </span>
                                  )}
                                </span>
                              </div>

                              {item.refund?.addons?.length > 0 && (
                                <div>
                                  <strong>Refund addons: </strong>
                                  <div>
                                    {item.refund.addons.map((addon) => (
                                      <Space>
                                        <strong>{addon.name} - </strong>
                                        <div>
                                          {addon.quantity} X {addon.price} ={" "}
                                        </div>
                                        <Tag color="green">
                                          {addon.total} tk
                                        </Tag>
                                      </Space>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </Col>
                            <Col span={24} md={8} className="item-prices">
                              <PricingItem
                                title="Qty"
                                value={item.quantity}
                                tk={false}
                              />
                              <PricingItem
                                title="Price"
                                value={
                                  item.sale_unit + variantTotal(item.variant)
                                }
                              />
                              <PricingItem
                                title="Discount"
                                value={item.totalDiscount}
                              />

                              <PricingItem
                                title="Revenue"
                                value={item.revenue}
                              />
                              {item.refund && (
                                <>
                                  <PricingItem
                                    title="Refund Qty"
                                    value={item.refund.quantity}
                                    color="red"
                                    tk={false}
                                  />
                                  <PricingItem
                                    title="Refund Total"
                                    value={item.refund.total}
                                    color="red"
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled(Col)`
  .list-item {
    padding: 10px 20px;
    margin-bottom: 4px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    position: relative;
    min-height: 100px;

    .infos {
      .status {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        background: #cfd8dc;
        padding: 3px 20px 3px 3px;
        border-radius: 20px;

        .text {
          background: rgb(0 0 0 / 85%);
          border-radius: 20px;
          padding: 3px 10px;
          color: #fff;
          text-transform: capitalize;
          letter-spacing: 1px;
          font-weight: 500;

          &.rejected {
            background: #f44336;
          }
        }

        .id {
          font-weight: 500;
        }

        .no {
          font-weight: 500;
          font-size: 16px;
          background: rgb(0 0 0 / 54%);
          padding: 2px 10px;
          border-radius: 30px;
          color: #fff;
        }
      }

      .time {
        margin-top: 10px
        font-size: 12px;
        text-align: right;
      }

      .order-no {
        font-size: 16px;
        margin-top: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        div {
          border: 1px solid #e0e0e0;
          padding: 2px 10px;
          border-radius: 30px;
          font-weight: 500;
        }
      }
    }

    .time {
      font-size: 14px;
      font-weight: 500;
    }

    .item {
      margin-bottom: 5px;
      display: flex;
      background: #cfd8dc8f;
      border: 1px solid #e0e0e0;
      padding: 10px 20px;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
        border-bottom: 1px solid #e0e0e0;
      }

      .counter {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
      }

      .item-name {
        font-weight: 500;
      }
    }

    .pricing,
    .item-prices {
      .pricing-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        &:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px;
        }

        .value {
          font-weight: 500;
        }
      }
    }

    .pricing {
      margin-top: 10px;
    }

    
  }
`;
