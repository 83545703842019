import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { SectionsContext } from "./SectionsContext";
import moment from "moment";

const ExpireDate = ({ id, expire_date = [] }) => {
  const restaurantExpireDate =
    expire_date.length > 0
      ? expire_date.filter((i) => i.objectId === id)
      : null;
  if (expire_date.length === 0) return null;
  return (
    <>
      {restaurantExpireDate.length > 0 && (
        <Tag color="green">
          Expired at:{" "}
          {moment(restaurantExpireDate[0].expire_date).format(
            "DD-MMM-YYYY hh:mm A"
          )}
        </Tag>
      )}
    </>
  );
};

const SectionItemChild = ({
  id,
  name,
  availability,
  hub,
  banner_image,
  selected,
  onSelect,
  deleteSectionItem,
  section,
  expire_date,
}) => {
  return (
    <div
      className={`droppable-item ${selected ? "selected" : ""}`}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <input type="checkbox" checked={selected} onChange={() => onSelect(id)} />
      <div className="body" style={{ width: "95%" }}>
        <Space>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              overflow: "hidden",
              background: "lightgray",
            }}
          >
            {banner_image && (
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={banner_image}
                alt=" "
              />
            )}
          </div>
          <div className="contents">
            <div className="title">
              {name}{" "}
              <Tag color={availability ? "green" : "red"}>
                {availability ? "Available" : "Unavailable"}
              </Tag>
              {expire_date && <ExpireDate id={id} expire_date={expire_date} />}
            </div>
            {hub && <p className="hub">{hub.get("name")}</p>}
          </div>
        </Space>
        <Button
          icon={<DeleteOutlined />}
          shape="circle"
          onClick={() => deleteSectionItem(section, id)}
          danger
        />
      </div>
    </div>
  );
};

export default function SectionItem({
  id,
  index,
  name,
  banner_image,
  section,
  availability,
  hub,
  selected,
  onSelect,
  expire_date,
}) {
  const { deleteSectionItem } = useContext(SectionsContext);
  return (
    <Draggable key={id} draggableId={`${section}-${id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="droppable-item"
        >
          <SectionItemChild
            availability={availability}
            banner_image={banner_image}
            hub={hub}
            id={id}
            name={name}
            key={id}
            deleteSectionItem={deleteSectionItem}
            selected={selected}
            onSelect={onSelect}
            section={section}
            expire_date={expire_date}
          />
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}
