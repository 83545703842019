import { Col } from "antd";
import { Link } from "react-router-dom";

export default function EmailView({ data }) {
  return (
    <Col
      space={24}
      lg={8}
      className="email"
      style={{ background: "#fff", padding: "20px" }}
    >
      {data.home && Object.keys(data.home).length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxHeight: "200px",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "70%", paddingRight: "10px" }}>
            <h2 style={{ fontSize: "24px" }}>{data.home.title}</h2>
            {data.home.description && (
              <p style={{ fontSize: "16px" }}>{data.home.description}</p>
            )}
          </div>
          {data.home.image && (
            <div style={{ style: "30%", padding: "0px 10px" }}>
              <img
                src={data.home.image}
                alt=""
                width="100%"
                style={{ maxHeight: "180px", borderRadius: "10px" }}
              />
            </div>
          )}
        </div>
      )}
      {data.banner && Object.keys(data.banner).length > 0 && (
        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
            padding: "10px",
          }}
        >
          <Link to={data.banner.link}>
            <img
              src={data.banner.image}
              alt=""
              width="100%"
              style={{ maxHeight: "150px", borderRadius: "10px" }}
            />
          </Link>
        </div>
      )}
      {data.highlight && Object.keys(data.highlight).length > 0 && (
        <div
          style={{
            padding: "10px",
            borderRadius: "10px",
            marginBottom: "10px",
            textAlign: "center",
            background: "#000000e0",
            color: "#fff",
          }}
        >
          <h2 style={{ color: "cyan" }}>{data.highlight.title}</h2>
          <p style={{ padding: "0px 20px" }}>{data.highlight.description}</p>
          {data.highlight.link && !data.highlight.code && (
            <Link to={data.highlight.link}>Click here</Link>
          )}
          {data.highlight.code && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to={data.highlight.link || "#"}
                style={{
                  background: "#fff",
                  color: "rgb(255, 77, 73)",
                  padding: "8px 16px",
                  borderRadius: "10px",
                  fontWeight: "500",
                  fontSize: "16px",
                  margin: "10px 0",
                }}
              >
                {data.highlight.code}
              </Link>
            </div>
          )}
        </div>
      )}
      {data.restaurants && Object.keys(data.restaurants).length > 0 && (
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            flexWrap: "wrap",
            rowGap: "10px",
            columnGap: "10px",
          }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            <h2 style={{ marginBottom: "0px" }}>{data.restaurants.title}</h2>
            {data.restaurants.subTitle && (
              <p
                style={{
                  color: "gray",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                {data.restaurants.subTitle}
              </p>
            )}
          </div>

          {data.restaurants.list?.map(
            ({
              id,
              image,
              name,
              tag,
              cuisines,
              bg = "#ff7875",
              color = "#000",
            }) => (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  minHeight: "200px",
                  background: "#f7f7f7",
                  borderRadius: "10px",
                  paddingBottom: "10px",
                  boxShadow: "0px 1px 2px rgba(0,0,0,.2)",
                }}
              >
                <div
                  style={{
                    padding: "10px 0px",
                    margin: "0 8px",
                  }}
                >
                  <img
                    width="100%"
                    src={image}
                    alt=""
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
                    }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      maxHeight: "70px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: "0px",
                      }}
                    >
                      {name}
                    </h3>
                    {cuisines && (
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        {cuisines}
                      </p>
                    )}
                  </div>
                  <Link
                    style={{
                      background: "black",
                      color: "cyan",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      display: "inline-block",
                    }}
                    to={`/restaurants/${id}`}
                  >
                    View Details
                  </Link>
                </div>
                {tag && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "8px",
                      padding: "2px 5px",
                      background: bg,
                      color,
                      borderRadius: "10px",
                      fontWeight: "500",
                      fontSize: "12px",
                      margin: "5px",
                    }}
                  >
                    {tag}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
      {data.products && Object.keys(data.products).length > 0 && (
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            flexWrap: "wrap",
            rowGap: "10px",
            columnGap: "10px",
          }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            <h2 style={{ marginBottom: "0px" }}>{data.products.title}</h2>
            {data.products.subTitle && (
              <p
                style={{
                  color: "gray",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "1px",
                }}
              >
                {data.products.subTitle}
              </p>
            )}
          </div>

          {data.products.list?.map(
            ({
              id,
              restaurant,
              image,
              name,
              tag,
              price,
              discount = 30,
              bg = "#ff7875",
              color = "#000",
            }) => (
              <div
                style={{
                  width: "calc(50% - 8px)",
                  position: "relative",
                  minHeight: "200px",
                  background: "#f7f7f7",
                  borderRadius: "10px",
                  paddingBottom: "10px",
                  boxShadow: "0px 1px 2px rgba(0,0,0,.2)",
                }}
              >
                <div
                  style={{
                    padding: "10px 0px 5px 0px",
                    margin: "0 8px",
                  }}
                >
                  <img
                    width="100%"
                    src={image}
                    alt=""
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
                      height: "80px",
                    }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {discount ? (
                      <div>
                        <del>{price}</del> {price - discount} {"BDT"}
                      </div>
                    ) : (
                      (price || "$$$") + " BDT"
                    )}
                  </div>
                  <h3
                    style={{
                      marginBottom: "5px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxHeight: "50px",
                      whiteSpace: "wrap",
                      padding: "0px 5px",
                    }}
                  >
                    {name}
                  </h3>

                  <Link
                    style={{
                      background: "black",
                      color: "cyan",
                      borderRadius: "30px",
                      padding: "5px 10px",
                      display: "inline-block",
                      fontSize: "12px",
                    }}
                    to={`/restaurants/${restaurant}?id=${id}&addToCart=true`}
                  >
                    Add to Cart
                  </Link>
                </div>
                {tag && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "8px",
                      padding: "2px 5px",
                      background: bg,
                      color,
                      borderRadius: "10px",
                      fontWeight: "500",
                      fontSize: "12px",
                      margin: "5px",
                    }}
                  >
                    {tag}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      )}
    </Col>
  );
}
