import { Form, Input } from "antd";
const { Item } = Form;

export default function ProductName() {
  return (
    <div className="section">
      <Item name="name" label="Product Name" style={{ marginBottom: "5px" }}>
        <Input size="large" placeholder="Product name" />
      </Item>
    </div>
  );
}
