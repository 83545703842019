import styled from "styled-components";
import { Col } from "antd";
import { useHistory } from "react-router-dom";
import { FC } from "react";

const StatisticsItem: FC<any> = ({
  title,
  value,
  icon,
  isAmount,
  link,
  htmlTitle = "",
  ...rest
}) => {
  const history = useHistory();

  return (
    <Col xs={24} sm={12} md={8} lg={6} xl={4} {...rest}>
      <Wrapper
        className="statistics-item"
        onClick={() => {
          if (link) {
            history.push(link);
          }
        }}
        style={{ cursor: link ? "pointer" : "default" }}
        title={htmlTitle}
      >
        <h3 className="title">{title}</h3>
        <img className="icon" src={`/icons/${icon}`} alt="" />
        <div className="value">
          {(value ?? 0).toLocaleString()} {isAmount ? "BDT" : ""}
        </div>
      </Wrapper>
    </Col>
  );
};

export default StatisticsItem;

StatisticsItem.defaultProps = {
  isAmount: true,
};

const Wrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 9px 10px #9da6a7;
  background-color: #ffffff;
  height: 140px;
  position: relative;

  .icon {
    position: absolute;
    left: 16px;
    bottom: 30px;
    width: 40px;
    margin-top: 10px;
  }

  .title {
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 0px;
    color: green;
  }

  .value {
    font-size: 20px;
    letter-spacing: 1px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0px;
    position: absolute;
    right: 20px;
    bottom: 30px;
  }
`;
