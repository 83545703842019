import { useEffect, useState } from "react";
import { Form, Button, message, Row, Col } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import RiderUpdateForm from "../../components/rider/registration/UpdateForm";
import { getHubs } from "../../api/hub";
import Parse from "parse";
import { mergeTypesData, uploadFiles, isDate } from "./Registration";
import moment from "moment";

const riderTypes = [
  ["image", [], false],
  ["name", "", true],
  ["username", "", true],
  ["password", "", false],
  ["phone", "", true],
  ["email", "", false],
  ["date_of_birth", "", false, isDate],
  ["jobType", "salary", true],
  ["employeeType", "fulltime", true],
  ["dutyHour", "", true],
  ["hub", "", true],
  ["nid", [], false],
  ["nidNumber", "", true],
  ["bikeDocuments", [], false],
  ["grantorNid", [], false],
  ["grantorContact", "", false],
  ["grantorRelation", "", false],
  ["vehicleType", "bike", true],
  ["vehicleNumber", "", false],
  ["license", [], false],
  ["referredBy", "", false],
  ["presentAddress", "", false],
  ["permanentAddress", "", false],
  ["joiningDate", "", false, isDate],
  ["endingDate", "", false, isDate],
];

export default function RiderUpdate() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [hubs, setHubs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [riderData, setRiderData] = useState(null);

  const onFinish = async (props) => {
    try {
      setLoading(true);

      if (props.date_of_birth)
        props.date_of_birth = props.date_of_birth.toISOString();
      if (props.joiningDate)
        props.joiningDate = props.joiningDate.toISOString();
      if (props.endingDate) props.endingDate = props.endingDate.toISOString();

      const values = mergeTypesData(riderTypes, props);

      if (!values.email) delete values.email;
      if (values.image.length > 0) {
        const image = await uploadFiles(values.image);
        values.image = image[0];
      } else {
        values.image = riderData.image[0];
      }

      const nid = await uploadFiles(values.nid);
      if (nid.length > 0) {
        values.nid = riderData.nid.concat(nid);
      } else {
        values.nid = riderData.nid;
      }

      const bikeDocuments = await uploadFiles(values.bikeDocuments);
      if (bikeDocuments.length > 0) {
        values.bikeDocuments = riderData.bikeDocuments.concat(bikeDocuments);
      } else {
        values.bikeDocuments = riderData.bikeDocuments;
      }

      const license = await uploadFiles(values.license);
      if (license.length > 0) {
        values.license = riderData.license.concat(license);
      } else {
        values.license = riderData.license;
      }

      const grantorNid = await uploadFiles(values.grantorNid);
      if (grantorNid.length > 0) {
        values.grantorNid = riderData.grantorNid.concat(grantorNid);
      } else {
        values.grantorNid = riderData.grantorNid;
      }

      await Parse.Cloud.run("updateRider", { ...values, id });
      setRiderData((riderData) => {
        riderData.image = [values.image];
        riderData.nid = values.nid;
        riderData.bikeDocuments = values.bikeDocuments;
        riderData.license = values.license;
        riderData.grantorNid = values.grantorNid;
        return riderData;
      });

      message.success("Updated");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const hubs = await getHubs({ select: ["name"] });
        setHubs(hubs.results.map((r) => r.toJSON()));
      } catch (err) {
        message.error(err.message);
      }
    })();
  }, []);

  const imageRemover = (index, key) => {
    setRiderData((riderData) => {
      const field = riderData[key];
      if (Array.isArray(field)) {
        field.splice(index, 1);
        setRiderData({ ...riderData, [key]: field });
      } else if (field && typeof field === "string") {
        setRiderData({ ...riderData, [key]: "" });
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const rider = await new Parse.Query(Parse.User)
          .equalTo("objectId", id)
          .exclude("location")
          .first();

        const riderInfo = await new Parse.Query("rider_info")
          .equalTo("rider", rider)
          .first();

        const riderData = {
          image: [rider.get("image")],
          name: rider.get("name"),
          username: rider.get("username"),
          phone: rider.get("phone"),
          email: rider.get("email"),
        };

        if (rider.get("date_of_birth")) {
          riderData.date_of_birth = moment(rider.get("date_of_birth"));
        }

        if (riderInfo) {
          riderData.jobType = riderInfo.get("jobType");
          riderData.employeeType = riderInfo.get("employeeType");
          riderData.dutyHour = riderInfo.get("dutyHour");
          riderData.hub = riderInfo.get("hub")?.id || "";
          riderData.nid = riderInfo.get("nid") || [];
          riderData.nidNumber = riderInfo.get("nidNumber");
          riderData.bikeDocuments = riderInfo.get("bikeDocuments") || [];
          riderData.grantorNid = riderInfo.get("grantorNid") || [];
          riderData.grantorContact = riderInfo.get("grantorContact");
          riderData.grantorRelation = riderInfo.get("grantorRelation");
          riderData.vehicleType = riderInfo.get("vehicleType");
          riderData.vehicleNumber = riderInfo.get("vehicleNumber");
          riderData.license = riderInfo.get("license") || [];
          riderData.referredBy = riderInfo.get("referredBy")?.id || "";
          riderData.presentAddress = riderInfo.get("presentAddress");
          riderData.permanentAddress = riderInfo.get("permanentAddress");
          if (riderInfo.get("joiningDate"))
            riderData.joiningDate = moment(riderInfo.get("joiningDate"));
          if (riderInfo.get("endingDate"))
            riderData.endingDate = moment(riderInfo.get("endingDate"));
        }

        setRiderData(riderData);

        form.setFieldsValue({
          ...riderData,
          image: [],
          nid: [],
          bikeDocuments: [],
          license: [],
          grantorNid: [],
        });
        setLoading(false);
      } catch (err) {
        message.error(err.message);
        setLoading(false);
      }
    })();
  }, [id]);

  if (!riderData) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper md={18} lg={16} xl={12}>
      <Row justify="center">
        <Col span={24} lg={20} xl={16}>
          <div className="header">
            <div className="left-section">
              <h3 className="title">Update Rider</h3>
            </div>
          </div>
          <Form
            form={form}
            size="large"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            scrollToFirstError={true}
          >
            <RiderUpdateForm
              hubs={hubs}
              riderData={riderData}
              imageRemover={imageRemover}
            />
            <div className="footer">
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                size="large"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .section {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    min-height: 450px;
    border-radius: 10px;
  }

  .header {
    padding: 20px 0;
    .title {
      font-size: 22px;
      margin-bottom: 0;
      text-align: center;
      text-transform: uppercase;
      color: gray;
    }
  }

  .ant-form-item {
    // margin-bottom: 0;
  }
  .ant-form-item-label {
    line-height: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .profile-picture {
    position: relative;

    img {
      height: 150px;
      width: 150px;
      border-radius: 10px;
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      color: red;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: 100px;
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;

      .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        color: red;
        margin: 5px;
      }
    }
  }

  .footer {
    padding: 20px 0;
    margin-top: 10px;
    text-align: right;
  }
`;
