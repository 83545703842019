import { Form, Input } from "antd";

const { Item } = Form;

export default function Description() {
  return (
    <Item className="section" label="Description" name="details">
      <Input.TextArea />
    </Item>
  );
}
