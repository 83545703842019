import { Button, Divider } from "antd";
import { useState } from "react";
import { BiDollar } from "react-icons/bi";

export default function Charge(props) {
  const { charge, payment_method } = props;
  const { delivery_charge, discount, items_total, vat, total } = charge;
  const [open, setOpen] = useState(false);

  return (
    <div className="charge">
      <div onClick={(e) => setOpen(true)} className="charge-icon">
        <BiDollar />
        <span
          className="tx"
          style={{
            background: payment_method !== "cod" ? "#40A9FF" : "",
          }}
        >
          {total}
        </span>
      </div>
      {open && (
        <div className="charge-content">
          <div className="item">
            <span>Delivery charge:</span>{" "}
            <span className="value">+{delivery_charge} tk</span>
          </div>
          <div className="item">
            <span>Discount:</span> <span className="value">-{discount} tk</span>
          </div>
          <div className="item">
            <span>Items total:</span>{" "}
            <span className="value">{items_total} tk</span>
          </div>
          <div className="item">
            <span>Service Charge:</span>{" "}
            <span className="value">+{vat} tk</span>
          </div>
          <Divider style={{ margin: "10px 0" }} />
          <div className="item">
            <span>Total:</span> <span className="value">{total} tk</span>
          </div>
          <div class="charge-footer">
            <Button
              size="middle"
              onClick={(e) => setOpen(false)}
              type="dashed"
              danger
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
