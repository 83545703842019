import {
  Form,
  Select,
  Input,
  InputNumber,
  message,
  Button,
  DatePicker,
} from "antd";
import AddImage from "./AddImage";
import { useState } from "react";
import Parse from "parse";
import { CloseOutlined } from "@ant-design/icons";

const { Item } = Form;

function ImageViewer({ images, onRemove, keyName }) {
  if (!images) return null;
  return (
    <div className="images">
      {images.map((image, index) =>
        image ? (
          <div className="images-item">
            <img src={image} alt="" width="100%" />
            <Button
              className="close-btn"
              shape="circle"
              icon={<CloseOutlined />}
              size="small"
              onClick={() => onRemove(index, keyName)}
            ></Button>
          </div>
        ) : null
      )}
    </div>
  );
}

export default function RiderUpdateForm({ hubs, riderData, imageRemover }) {
  const [riders, setRiders] = useState([]);

  const riderOnSearch = async (value) => {
    try {
      const res = await new Parse.Query(Parse.User)
        .equalTo("type", "rider")
        .matches("name", value, "i")
        .select(["name"])
        .limit(10)
        .find();

      setRiders(res.map((r) => r.toJSON()));
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div>
      <AddImage name="image" label="Image">
        <ImageViewer
          keyName="image"
          images={riderData.image}
          onRemove={imageRemover}
        />
      </AddImage>
      <Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input your name!" }]}
      >
        <Input size="large" placeholder="Full name" />
      </Item>
      <Item
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            message: "Please input riders username!",
          },
        ]}
      >
        <Input placeholder="Username" />
      </Item>
      <Item name="password" label="Update Password">
        <Input placeholder="Password" />
      </Item>
      <Item name="email" label="Email">
        <Input placeholder="Email" />
      </Item>
      <Item name="date_of_birth" label="Date of Birth">
        <DatePicker style={{ width: "100%" }} placeholder="Date of Birth" />
      </Item>
      <Item
        name="hub"
        label="Rider Hub"
        rules={[
          {
            required: true,
            message: "Please select riders hub!",
          },
        ]}
      >
        <Select placeholder="Select Riders Hub">
          {hubs.map((hub) => (
            <Select.Option key={hub.objectId} value={hub.objectId}>
              {hub.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: "Please input riders phone!",
          },
        ]}
      >
        <Input placeholder="Phone" />
      </Item>
      <Item
        name="employeeType"
        label="Employee Type"
        rules={[
          {
            required: true,
            message: "Please select employee type!",
          },
        ]}
      >
        <Select placeholder="Select Employee Type">
          <Select.Option value="fulltime">Full Time</Select.Option>
          <Select.Option value="part-time">Part Time</Select.Option>
        </Select>
      </Item>
      <Item name="basicSalary" label="Rider Basic">
        <InputNumber style={{ width: "100%" }} placeholder="Rider Basic" />
      </Item>
      <Item name="dutyHour" label="Rider Duty Hour">
        <Input style={{ width: "100%" }} placeholder="hh:mm-hh:mm" />
      </Item>
      <AddImage name="nid" label="Nid Documents">
        <ImageViewer
          keyName="nid"
          images={riderData.nid}
          onRemove={imageRemover}
        />
      </AddImage>
      <Item
        name="nidNumber"
        label="Nid Number"
        rules={[
          {
            required: true,
            message: "Please input riders NID number!",
          },
        ]}
      >
        <Input placeholder="Nid number" />
      </Item>
      <Item
        name="jobType"
        label="Job Type"
        rules={[
          {
            required: true,
            message: "Please select job type!",
          },
        ]}
      >
        <Select placeholder="Select Job Type">
          <Select.Option value="commission">Commission</Select.Option>
          <Select.Option value="salary">Salary</Select.Option>
          <Select.Option value="new_commission">New Commission</Select.Option>
        </Select>
      </Item>
      <Item
        name="vehicleType"
        label="Vehicle Type"
        rules={[
          {
            required: true,
            message: "Please select vehicle type!",
          },
        ]}
      >
        <Select placeholder="Select Vehicle Type">
          <Select.Option value="bike">Bike</Select.Option>
          <Select.Option value="cycle">Cycle</Select.Option>
        </Select>
      </Item>
      <Item name="vehicleNumber" label="Vehicle Number">
        <Input placeholder="Vehicle Number" />
      </Item>
      <AddImage name="license" label="License Documents">
        <ImageViewer
          keyName="license"
          images={riderData.license}
          onRemove={imageRemover}
        />
      </AddImage>

      <AddImage name="bikeDocuments" label="Bike Documents">
        <ImageViewer
          keyName="bikeDocuments"
          images={riderData.bikeDocuments}
          onRemove={imageRemover}
        />
      </AddImage>

      <AddImage name="grantorNid" label="Grantor Documents">
        <ImageViewer
          keyName="grantorNid"
          images={riderData.grantorNid}
          onRemove={imageRemover}
        />
      </AddImage>
      <Item name="grantorContact" label="Grantor Contact">
        <Input placeholder="Grantor Contact" />
      </Item>
      <Item name="grantorRelation" label="Grantor Relation">
        <Select>
          <Select.Option value="father">Father</Select.Option>
          <Select.Option value="mother">Mother</Select.Option>
          <Select.Option value="brother">Brother</Select.Option>
          <Select.Option value="sister">Sister</Select.Option>
          <Select.Option value="uncle">Wife</Select.Option>
          <Select.Option value="cousin">Cousin</Select.Option>
          <Select.Option value="friend">Friend</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>
      </Item>
      <Item name="referredBy" label="Referred By">
        <Select
          onSearch={riderOnSearch}
          placeholder="Referred By"
          allowClear
          showSearch
          filterOption={false}
        >
          {riders.map((rider) => (
            <Select.Option key={rider.objectId} value={rider.objectId}>
              {rider.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name="presentAddress" label="Present Address">
        <Input placeholder="area, street, house" />
      </Item>
      <Item name="permanentAddress" label="Permanent Address">
        <Input placeholder="area, street, house" />
      </Item>
      <Item name="joiningDate" label="Joining Date">
        <DatePicker style={{ width: "100%" }} placeholder="joining date" />
      </Item>
      <Item name="endingDate" label="Ending Date">
        <DatePicker style={{ width: "100%" }} placeholder="ending date" />
      </Item>
    </div>
  );
}
