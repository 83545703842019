import Styled from "styled-components";
import OrderIdentifyingInfo from "./header/OrderIdentifyingInfo";
import RightSide from "./header/RightSide";
import UserInfo from "./header/UserInfo";
import { FaMotorcycle } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import { formatRelative } from "date-fns";

function Note({ title, text, icon, ...rest }) {
  return (
    <div className="note" {...rest}>
      <div className="title">
        {icon} {title}
      </div>
      <div className="text">{text}</div>
    </div>
  );
}

export default function CardHeader(props) {
  const {
    note,
    rider_note,
    setOrderId,
    rejection_reason,
    dispatch_hour,
    createdAt,
  } = props;

  return (
    <HeaderWrapper>
      <div className="header">
        <OrderIdentifyingInfo {...props} />
        <RightSide {...props} setOrderId={setOrderId} />
      </div>
      <div className="timing">
        {dispatch_hour && (
          <div
            className={`dispatch ${
              dispatch_hour.text?.toLowerCase() !== "asap" ? "blink" : ""
            }`}
          >
            {dispatch_hour.text}
          </div>
        )}
        <div className="createdAt">
          {formatRelative(new Date(createdAt), Date.now())}
        </div>
      </div>
      <UserInfo {...props} />
      {note && <Note icon={<BiUser />} title="Customer Note" text={note} />}
      {rider_note && (
        <Note icon={<FaMotorcycle />} title="Rider Note" text={rider_note} />
      )}
      {rejection_reason && (
        <Note
          icon={<MdOutlineCancel style={{ color: "red" }} />}
          title="Reject Note"
          text={props.rejection_reason}
        />
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = Styled.div`
    padding-bottom: 5px;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 5px;
      flex-wrap: wrap;
    }

    @keyframes blink {
      0% {
        opacity: .2;

      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: .2;
      }
    }

    .blink {
      animation: blink 1s infinite;
    }

    .timing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      color: #fff;

      .dispatch {
        color: #ffd073;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }


    .user{
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        width: 100%;
        color: #fff!important;
        letter-spacing: 0.5px;
        width: 100%;
        margin-bottom: 5px;
        padding: 5px 10px;
        background: rgb(0 0 0 / 74%);
        border-radius: 10px;
        border: 1px solid #3c3c3c;
        display: flex;
        white-space: wrap;
        justify-content: space-between;

        .title {
          font-size: 12px;
        }

        .name {
          margin-bottom: 3px;

          a {
            color: #f3bc6b;
          }
        }

        .sec {
          .icon {
            margin-right: 5px;
          }
          .text {
            font-size: 12px;
            font-weight: 400;
          }
        }

        &-nid {
          width: 120px;
          padding: 0px 0px 5px 10px;

          .nid-image{
            width: 100%;
            height: 75px;
            border: 2px solid lightgray;
            border-radius: 10px;
            z-index: 10;
          }

          .btn {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 10px;
          }
        }

        &-note {
          color: #f07072;
          margin: 5px 0;
          text-transform: capitalize;
        }
    }

    .note{
      margin-top: 5px;
      color: #fff;
      background: rgb(0 0 0 / 74%);
      padding: 8px 16px;
      width: 100%;
      border-radius: 10px;
      letter-spacing: .5px;
      font-size: 14px;
      line-height: 1.4;
      border: 1px solid #3c3c3c;

      .title{
        font-size: 16px;
        color: #f3bc6b;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }

    .left-side{
      min-width: 50%;
      max-width: 70%;
      background: #fff;
      border-radius: 30px;
      padding: 7px 10px 7px 5px;
      border: 1px solid #434343;
      box-shadow: 0px 1px 2px #434343;
      height: 40px;

      .id{
        font-size: 12px;
        margin-bottom: 0px;
        letter-spacing: 1px;
        font-weight: 600;
        color: cyan;
        background: #000000e8;
        padding: 8px 8px;
        border-radius: 30px;
      }

      .date{
          font-size: 12px;
          font-weight: 600;
          color: #000;
          margin-bottom: 0px;
      }

      @keyframes colorBlink {
        0% {
          background: #fff;
        }
        50% {
          background: #f3bc6b;
        }
        100% {
          background: #fff;
        }
      }

      &.priority{
        background: #f7ccd4;
        animation: colorBlink 1s infinite;
      }
    }

    .right-side{
        .charge{
            margin-right: 5px;

            .charge-icon{
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 50%;
                font-size: 18px;
                color: #918f8e;
                cursor: pointer;
                transition: .3s;
                position: relative;

                &:hover{
                    box-shadow: 0px 3px 11px rgba(0,0,0,.2);
                }

                .tx{
                    font-size: 10px;
                    position: absolute;
                    top: -5px;
                    left: 47%;
                    font-weight: bold;
                    background: #ff9800;
                    padding: 1px 6px;
                    border-radius: 5px;
                    letter-spacing: 1px;
                    color: #000000;
                }
            }

            .charge-content{
                position: absolute;
                top: 0px;
                left: 0;
                width: 100%;
                min-height: 120px;
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0px 7px 28px rgb(0 0 0 / 20%);
                font-weight: 600;
                animation: fadeIn .2s ease-in;

                .item{
                    display: flex;
                    justify-content: space-between;

                    .value{
                        font-weight: bold;
                    }
                }

                .charge-footer{
                    margin-top: 20px;
                    text-align: right;

                    button{
                        border-radius: 10px;
                    }
                }
            }
        }

        .createdAt {
          color: #fff;
          font-size: 12px;
          text-align: right;
        }
    }

    .verified {
      color: green!important;
    }

    .dispatch-hour{
      background: yellow;
      padding: 3px 10px;
      border-radius: 10px;
      color: black;
      letter-spacing: 1px;
    }
`;
