import { useContext, useEffect, useState, Fragment, useCallback } from "react";
import { DetailsContext } from "../../../../pages/Order/Details";
import {
  Button,
  InputNumber,
  Modal,
  Form,
  Radio,
  Space,
  Checkbox,
  message,
  Input,
  Tag,
} from "antd";
import useProduct from "../../../../hooks/useProduct";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { compressedImage } from "../../../../common/utils";
import { BiSave } from "react-icons/bi";
import Parse from "parse";

export default function AddOrderItem({ setLoading, loading }) {
  const {
    order,
    editMode,
    getOrderCharges,
    updateOrderItems,
    necessaryOrderItems,
  } = useContext(DetailsContext);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const { getProducts } = useProduct();
  const [form] = Form.useForm();
  const [hubs, setHubs] = useState({});

  const fetchHubs = async (done) => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      const obj = {};
      hubs.forEach((hub) => {
        obj[hub.id] = hub.get("name");
      });
      setHubs(obj);
      if (typeof done === "function") done(obj);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    if (open && Object.keys(hubs).length === 0) {
      fetchHubs();
    }
  }, [open]);

  const searchHandler = useCallback(
    (e) => {
      getProducts(
        {
          limit: 100,
          search: e.target.value,
          select: [
            "name",
            "images",
            "stock",
            "is_inv",
            "restaurant.name",
            "restaurant.banner_image",
            "restaurant.hub",
            "category.name",
          ],
        },
        (err, items) => {
          if (items) {
            let products = items.results.map((item) => {
              const data = item.toJSON();
              data.hub = hubs[data.restaurant?.hub?.objectId];
              return data;
            });
            // const orderItemsMap = order.data.order_items.reduce((acc, item) => {
            //   acc[item.id] = true;
            //   return acc;
            // }, {});
            // products = products.filter((i) => !orderItemsMap[i.objectId]);
            setProducts(products);
          } else {
            setProducts([]);
          }
        }
      );
    },
    [hubs]
  );

  const onFinish = ({ quantity, addons, variants }) => {
    const orderItem = {
      id: product.objectId,
      quantity,
    };
    if (variants) {
      orderItem["variant"] = Object.keys(variants).reduce((acc, item) => {
        const obj = {};
        obj["variantId"] = item;
        if (Array.isArray(variants[item].items)) {
          obj["items"] = variants[item].items.map((i) => ({ id: i }));
        } else if (typeof variants[item].items === "string") {
          obj["items"] = [{ id: variants[item].items }];
        }
        if (obj.items) {
          acc.push(obj);
        }
        return acc;
      }, []);
    }

    if (addons && Array.isArray(addons) && addons.length > 0) {
      orderItem["addons"] = addons.map((item) => ({ id: item }));
    }

    order.data.order_items.push(orderItem);
    setLoading(true);
    getOrderCharges(order, () => {
      setLoading(false);
    });
    setOpen(false);
    setProducts([]);
    setProduct(null);
  };

  const selectProduct = (product) => {
    setProduct(product);
    form.setFieldsValue({ quantity: 1 });
  };

  const updateItemHandler = useCallback(() => {
    setLoading(true);
    if (order?.data?.id && order.data.order_items) {
      updateOrderItems(
        {
          orderId: order.data.id,
          orderItems: necessaryOrderItems(order.data.order_items),
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [order]);

  return editMode ? (
    <>
      <AddOrderItemWrapper>
        <div onClick={() => setOpen(true)} className="plus-icon">
          <PlusOutlined />
        </div>
        <Button
          loading={loading}
          disabled={loading}
          onClick={updateItemHandler}
          type="primary"
          className="save-icon"
        >
          <BiSave /> Save
        </Button>
      </AddOrderItemWrapper>
      <Modal
        visible={open}
        width={600}
        footer={null}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        title="Add new item to order"
      >
        <AddItemModalWrapper>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            {!product && (
              <div className="search-bar">
                <Input
                  onChange={searchHandler}
                  size="large"
                  placeholder="Search product"
                />
                {products?.length > 0 && (
                  <div className="suggestion-box">
                    <div className="box-items">
                      {products.map((product, i) => (
                        <div
                          onClick={() => selectProduct(product)}
                          className="box-item"
                          key={i}
                        >
                          {product.images?.length > 0 ? (
                            <img
                              className="img"
                              src={compressedImage(product.images[0])}
                              alt=""
                            />
                          ) : (
                            <div className="img"></div>
                          )}
                          <Space direction="vertical" size={0}>
                            <Space>
                              {product.name}{" "}
                              {product.is_inv && (
                                <Tag color={product.stock ? "green" : "red"}>
                                  stock: {product.stock}
                                </Tag>
                              )}
                            </Space>
                            <Space className="res-name">
                              {product.restaurant?.name}
                              <strong>#{product.hub}</strong>
                            </Space>
                          </Space>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {product && (
              <div className="product-display">
                <div className="info">
                  <img src={compressedImage(product.images[0])} alt="product" />
                  <div className="right-section">
                    <Space className="names" direction="vertical">
                      <h3 className="name">{product.name}</h3>
                      <div className="res">
                        from{" "}
                        <Link
                          to={`/restaurant/list/${product.restaurant?.objectId}`}
                        >
                          {product.restaurant?.name}
                        </Link>
                      </div>
                    </Space>
                    <div className="price">
                      Price: {product?.price?.amount} tk
                    </div>
                    <Form.Item
                      label="Quantity"
                      name="quantity"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="quantity"
                        style={{ width: "100px" }}
                      />
                    </Form.Item>
                  </div>
                </div>
                {product.price?.variants?.map((variant, index) => {
                  return (
                    <Fragment key={index}>
                      <Form.Item
                        label={variant.title}
                        name={["variants", variant.id, "items"]}
                        rules={[
                          {
                            required: variant.min > 0 ? true : false,
                            message: `Please select at least ${variant.min} item`,
                          },
                        ]}
                      >
                        {variant.max <= 1 ? (
                          <Radio.Group>
                            <Space direction="vertical">
                              {variant.items.map((item, i) => (
                                <Radio key={i} value={item.id}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "250px",
                                    }}
                                  >
                                    <div>{item.name}</div>
                                    <div>{item.price} tk</div>
                                  </div>
                                </Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        ) : (
                          <Checkbox.Group>
                            <Space direction="vertical">
                              {variant.items.map((item, i) => (
                                <Checkbox key={i} value={item.id}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "250px",
                                    }}
                                  >
                                    <div>{item.name}</div>
                                    <div>{item.price} tk</div>
                                  </div>
                                </Checkbox>
                              ))}
                            </Space>
                          </Checkbox.Group>
                        )}
                      </Form.Item>
                    </Fragment>
                  );
                })}
                {product?.addons?.items?.length > 0 && (
                  <Form.Item name="addons" label={product.addons?.title}>
                    <Checkbox.Group>
                      <Space direction="vertical">
                        {product.addons?.items?.map((item, i) => (
                          <Checkbox key={i} value={item.id}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "250px",
                              }}
                            >
                              <div>{item.name}</div>
                              <div>{item.price} tk</div>
                            </div>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                )}
                <Form.Item className="footer-section">
                  <Button
                    onClick={(e) => {
                      setProduct(null);
                      setProducts([]);
                    }}
                    style={{ marginRight: "10px" }}
                    danger
                  >
                    Cancel
                  </Button>
                  <Button htmlType="submit" type="primary">
                    Add
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form>
        </AddItemModalWrapper>
      </Modal>
    </>
  ) : null;
}

const AddOrderItemWrapper = styled.div`
  padding: 10px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;

  .plus-icon {
    font-size: 22px;
    width: 50px;
    height: 50px;
    background: #0080003d;
    color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background: #00800061;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    }
  }

  .save-icon {
  }
`;

const AddItemModalWrapper = styled.div`
  height: 500px;
  overflow-y: auto;
  position: relative;

  .search-bar {
    width: 100%;
    position: relative;

    input {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      border-radius: 30px;
      border: none;
      background: #f5f5f5;
      padding: 10px 20px;
      box-shadow: none;
      font-weight: 500;
      color: #525252;
      letter-spacing: 0.5px;
      z-index: 2;
    }

    .suggestion-box {
      position: absolute;
      left: 0;
      width: 100%;
      background: #2692ebc2;
      min-height: 300px;
      max-height: 450px;
      top: 0;
      border-radius: 30px;
      z-index: 10;
      padding: 20px;
      padding-top: 60px;
      z-index: 1;
      overflow: hidden;

      .box-items {
        width: 100%;
        height: 300px;
        overflow-y: auto;

        .box-item {
          padding: 10px 20px;
          border-bottom: 1px solid #f2f2f2;
          display: flex;
          align-items: center;
          background: #fff;
          cursor: pointer;

          .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            background: gray;
          }

          .name {
            font-size: 16px;
            font-weight: 500;
          }

          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
  }

  .product-display {
    .restaurant {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;

      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .name {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .info {
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      img {
        width: 150px;
      }

      .right-section {
        padding: 0 20px;

        .names {
          .name {
            font-size: 22px;
            margin-bottom: 0px;
          }
          .res {
            font-size: 16px;
          }
        }

        .price {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

  .footer-section {
    padding: 10px 20px;
    text-align: right;
  }
`;
