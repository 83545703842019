import { Button, Image, Popconfirm, Tag, message } from "antd";
import { useContext, useState } from "react";
import { BiMobile, BiTargetLock, BiUser } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { OrdersContext } from "../../../../pages/Order/OrderProcessor";
import Parse from "parse";
import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@ant-design/icons";

export const UserIcon = (props) => (
  <div {...props} className={`user-icon ${props.verified ? "verified" : ""}`}>
    {props.verified ? <FaUserCheck /> : <BiUser />}
  </div>
);

export default function UserInfo({
  customer_name,
  customer_phone,
  customer_address,
  user,
  customer_area,
}) {
  const [loading, setLoading] = useState("");
  const { nidUpdated } = useContext(OrdersContext);

  const nidVerificationUpdate = async (id, verified) => {
    try {
      if (verified) {
        setLoading("verified");
      } else {
        setLoading("fake");
      }
      await Parse.Cloud.run("nidVerificationUpdate", {
        userId: id,
        verified,
      });
      message.success("NID verification updated successfully");
      setLoading("");
      nidUpdated(id);
    } catch (err) {
      message.error(err.message);
      setLoading("");
    }
  };

  if (!user) return null;

  return (
    <div className="user">
      <div
        className="info"
        style={{ width: user.get("nid")?.[0] ? "calc(100% - 120px)" : "100%" }}
      >
        <div className="name">
          <Link target="_blank" to={`/user/add?id=${user.id}`}>
            <BiUser /> {customer_name} (
            <span style={{ color: "cyan" }}> {customer_area} </span>)
          </Link>
          {user.get("verified") && <Tag color="green">Verified</Tag>}
        </div>

        <div className="sec">
          <span className="icon">
            <BiMobile />
          </span>
          <span className="text">{customer_phone}</span>
        </div>
        <div className="sec">
          <span className="icon">
            <BiTargetLock />
          </span>
          <span className="text">{customer_address}</span>
        </div>
        {user.get("note") && (
          <div className="sec user-note">
            <span className="icon">
              <WarningOutlined />
            </span>
            <span>{user.get("note")}</span>
          </div>
        )}
      </div>

      {user.get("nid")?.[0] && (
        <div className="user-nid">
          <div className="title">
            NID {user.get("nid_verified") ? "(Verified)" : "(Not Verified)"}
          </div>
          <Image
            className="nid-image"
            preview
            src={user.get("nid")[0]}
            alt=""
          />
          {!user.get("nid_verified") && (
            <div className="btn">
              <Popconfirm
                title="Are you sure to mark this user as fake?"
                onConfirm={() => {
                  nidVerificationUpdate(user.id, false);
                }}
              >
                <Button
                  loading={loading === "fake"}
                  type="danger"
                  shape="circle"
                  icon={<CloseOutlined />}
                ></Button>
              </Popconfirm>
              <Button
                onClick={() => {
                  nidVerificationUpdate(user.id, true);
                }}
                type="primary"
                loading={loading === "verified"}
                shape="circle"
                icon={<CheckOutlined />}
              ></Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
