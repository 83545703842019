import { useContext, useState } from "react";
import BoxLayout from "./BoxLayout";
import styled from "styled-components";
import { Button, Dropdown, Menu, message } from "antd";
import { EditOutlined, FileOutlined, InfoOutlined } from "@ant-design/icons";
import { DetailsContext } from "../../../pages/Order/Details";
import Parse from "parse";
import OnlinePaymentDetails from "./OnlinePaymentDetails";
import BkashPaymentDetails from "./BkashPaymentDetails";

function Item({ title, value, borderTop }) {
  return (
    (value !== undefined || value !== null) && (
      <div
        className="item"
        style={{ borderTop: borderTop ? "1px solid #c9c9c9" : "none" }}
      >
        <div>{title}: </div>
        <div>{value}</div>
      </div>
    )
  );
}

function PaymentMethod() {
  const {
    order: { data },
  } = useContext(DetailsContext);
  const { payment_method } = data;

  return (
    <div className="method">
      <div className="title">Payment method </div>
      <div className="value">{payment_method}</div>
    </div>
  );
}

function PaymentStatus() {
  const {
    order: { data },
  } = useContext(DetailsContext);
  const { payment_status, status, ref } = data;
  const [loading, setLoading] = useState(false);

  async function updateToPaid() {
    if (ref instanceof Parse.Object) {
      try {
        setLoading(true);
        ref.set("payment_status", "paid");
        await ref.save(null, {
          sessionToken: Parse.User.current().getSessionToken(),
        });
        setLoading(false);
      } catch (err) {
        message.error(err.message);
        setLoading(false);
      }
    }
  }

  if (status === "pending" && payment_status === "unpaid") {
    return (
      <Dropdown
        trigger={["click"]}
        placement="bottomRight"
        overlay={() => (
          <Menu style={{ minWidth: "100px" }}>
            <Menu.Item onClick={updateToPaid}>Paid</Menu.Item>
          </Menu>
        )}
      >
        <Button
          loading={loading}
          className="status"
          style={{ cursor: "pointer" }}
        >
          {payment_status}
        </Button>
      </Dropdown>
    );
  }

  return <div className="status">{payment_status}</div>;
}

export default function OrderSummery() {
  const {
    order: { data },
  } = useContext(DetailsContext);
  const { charge, promo } = data;

  return (
    <BoxLayout title="Order summery">
      <Wrapper>
        <div className="payment">
          <PaymentMethod />
          <PaymentStatus />
        </div>
        <Item title="Items total" value={`${charge.items_total} tk`} />
        <Item
          title="Items discount"
          value={`${charge.items_discount} tk`}
          borderTop={true}
        />
        <Item
          title={`Promo discount ${promo ? `(${promo.promo_code})` : ""}`}
          value={`${charge.promo_discount} tk`}
        />
        <Item
          title="Total discount"
          value={`-${charge.discount} tk`}
          borderTop={true}
        />
        <Item title="Service charge" value={`+${charge.vat} tk`} />
        <Item title="Delivery charge" value={`+${charge.delivery_charge} tk`} />
        <Item title="Total" value={`${charge.total} tk`} borderTop={true} />

        {data.payment_method !== "cod" && (
          <div className="payment-details">
            <h3>Payment Details</h3>
            {data.payment_method === "online" && (
              <OnlinePaymentDetails orderId={data.id} />
            )}
            {data.payment_method === "bkash" && (
              <BkashPaymentDetails
                orderId={data.id}
                issue={data.issue?.toJSON()}
              />
            )}
          </div>
        )}
      </Wrapper>
    </BoxLayout>
  );
}

const Wrapper = styled.div`
  .payment {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 7px 0;
    margin-bottom: 10px;

    .method {
      width: 58%;
      background: #f5f5f5;
      padding: 10px;
      font-weight: 500;
      text-align: center;
      border-radius: 5px;
      position: relative;

      .info {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #999;
      }

      .value {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    .status {
      width: 38%;
      padding: 3px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #535353;
  }
`;
