import { Button, Col, DatePicker, message, Row, Space } from "antd";
import axios from "axios";
import moment from "moment";
import Parse from "parse";
import { useState } from "react";
import { dayStartHour } from "../../common/utils";
import DateHandler from "../../components/dashboard/DateHandler";
import { downloadCsvFromText } from "../../utils";

function GeneratePayment() {
  const [date, setDate] = useState([]);

  const onSubmit = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_PARSE_URL}/jobs/dailyReport`,
        headers: {
          "x-parse-application-id": process.env.REACT_APP_PARSE_ID,
          "x-parse-master-key":
            process.env.REACT_APP_ENVIRONMENT === "dev" ? "master" : "hellno",
        },
        data: {
          date,
        },
      });
      if (res) {
        message.success("Payment generated successfully");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  console.log(date);

  return (
    <Col span={24} lg={12}>
      <div>
        <h3>Generate Payment</h3>
        <Space style={{ padding: "10px" }}>
          <DatePicker
            style={{ borderRadius: "20px", width: "200px" }}
            size="large"
            onChange={(d, str) => {
              setDate(dayStartHour(str).toISOString());
            }}
          />
          <Button onClick={onSubmit} size="large" shape="round">
            Generate
          </Button>
        </Space>
      </div>
    </Col>
  );
}

export default function DownloadPayments() {
  const [date, setDate] = useState(moment(new Date()));
  const [loading, setLoading] = useState(false);

  const downloadHandler = async () => {
    try {
      setLoading(true);
      const results = await Parse.Cloud.run("invoiceDetailsByDate", {
        date,
      });
      setLoading(false);

      const download = (index) => {
        const res = results[index];
        if (!res) return;
        downloadCsvFromText(res.text, res.name);
        setTimeout(() => {
          download(index + 1);
        }, 200);
      };

      download(0);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} lg={12}>
        <h3>Download Restaurant Invoice</h3>
        <Space>
          <DateHandler setDate={setDate} defaultDate={date} />
          <Button
            loading={loading}
            shape="round"
            size="large"
            onClick={downloadHandler}
          >
            Download
          </Button>
        </Space>
      </Col>
      <GeneratePayment />
    </Row>
  );
}
