import { useContext, useState } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import { Row, Col, Switch, message, Input, Button, Typography } from "antd";
import styled from "styled-components";
import OrderItems from "./orderItems";
import RiderInfo from "./RiderInfo";
import OrderSummery from "./OrderSummery";
import CustomerInfo from "./CustomerInfo";
import OrderTimeline from "./Timeline";
import OrderHeader from "./OrderHeader";
import IssueDetails from "./IssueDetails";
import Parse from "parse";
import BoxLayout from "./BoxLayout";

function ReturnInfo() {
  const { order, saveOrder } = useContext(DetailsContext);
  const { returnInfo, timeline = [], ref: object, rider } = order.data;
  const { receiverName, receiverId, receiverAcknowledged, returnable } =
    returnInfo || {};
  const currentUser = Parse.User.current();

  return returnable && rider ? (
    <BoxLayout title="Return Acknowledgement">
      {receiverName && (
        <div style={{ marginBottom: "10px" }}>
          <strong>{rider.get("name")}</strong> food returned to{" "}
          <strong>{receiverName}</strong>
        </div>
      )}
      {receiverName && currentUser.id !== receiverId && (
        <div className="subtitle">
          {receiverAcknowledged
            ? "Acknowledged"
            : "Waiting for acknowledgement"}
        </div>
      )}
      {currentUser.id === receiverId && (
        <div className="subtitle">
          <Switch
            checked={receiverAcknowledged}
            checkedChildren="Acknowledged"
            unCheckedChildren="Acknowledge"
            onChange={(checked) => {
              try {
                returnInfo.receiverAcknowledged = checked;
                object.set("returnInfo", returnInfo);

                const log = {
                  type: "return",
                  status: checked ? "acknowledged" : "un acknowledged",
                  time: new Date(),
                  user: currentUser.id,
                  msg: `Return ${
                    checked ? "acknowledged" : "not acknowledged"
                  } by ${currentUser.get("name")} from ${rider.get("name")}`,
                };

                timeline.push(log);
                object.set("timeline", timeline);
                object.save();
                saveOrder(order.data);
              } catch (err) {
                message.error(err.message);
              }
            }}
          />
        </div>
      )}
    </BoxLayout>
  ) : null;
}

export default function Details() {
  const { order, updateOrderNote } = useContext(DetailsContext);
  const { data } = order;

  const [order_note, set_order_note] = useState(data.order_note);

  const onSubmit = (e) => {
    e.preventDefault();
    updateOrderNote(order_note);
  };

  return (
    <DetailsWrapper gutter={[16, 16]}>
      <Col xs={24} md={24} xl={16}>
        <section className="left-section">
          <OrderHeader />
          <OrderItems />
        </section>
      </Col>
      <Col xs={24} md={24} xl={8}>
        <section className="right-section">
          {order.data && order.data.issue && <IssueDetails />}
          <BoxLayout title="Order Note">
            <form onSubmit={onSubmit}>
              <Input.TextArea
                rows={4}
                defaultValue={order_note}
                onChange={(e) => set_order_note(e.target.value)}
                style={{ margin: "1rem 0" }}
              />
              {data.order_note_added_by && (
                <Typography.Paragraph style={{ margin: "1rem 0" }} copyable>
                  {data.order_note_added_by}
                </Typography.Paragraph>
              )}
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </form>
          </BoxLayout>
          <RiderInfo rider={data.rider} />

          <ReturnInfo />
          <OrderSummery />
          <CustomerInfo />
        </section>
      </Col>
      <OrderTimeline {...data} />
    </DetailsWrapper>
  );
}

const DetailsWrapper = styled(Row)`
  min-height: 100%;
  position: relative;
  min-width: 500px;
  overflow-x: scroll;

  .left-section {
    height: 100%;

    .order-header {
      padding: 20px 0;
      position: relative;

      .id {
      }

      .status {
        font-size: 20px;
        font-weight: 500;
      }

      .edit-mode {
        position: absolute;
        right: 0;
        top: 0;
        .title {
          font-size: 14px;
          font-weight: 500;
        }

        .issue {
          margin-top: 10px;
        }
      }
    }
  }

  .return-info {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .right-section {
    height: 100%;
  }
`;
