import {
  createContext,
  useReducer,
  useEffect,
  useCallback,
  useRef,
  useState,
} from "react";
import useOrders from "../../hooks/useOrders";
import { Drawer, message, notification } from "antd";
import Parse from "parse";
import { parser } from "../../utils";
import Content from "../../components/order/processor";
import { client, riderMsSocket } from "../../AuthProvider";
import OrderDetails from "./Details";
import autoAssign from "./autoAssign";

export const OrdersContext = createContext();

const Types = {
  TODAYS_ORDERS_REQUEST: "TODAYS_ORDERS_REQUEST",
  TODAYS_ORDERS_SUCCESS: "TODAYS_ORDERS_SUCCESS",
  TODAYS_ORDERS_ERROR: "TODAYS_ORDERS_ERROR",
  GET_RIDERS_REQUEST: "GET_RIDERS_REQUEST",
  GET_RIDERS_SUCCESS: "GET_RIDERS_SUCCESS",
  GET_RIDERS_ERROR: "GET_RIDERS_ERROR",
  UPDATE_RIDER: "UPDATE_RIDER",
  SET_RIDERS_DATA: "SET_RIDERS_DATA",
  TAB_ORDERS_REQUEST: "TAB_ORDERS_REQUEST",
  TAB_ORDERS_SUCCESS: "TAB_ORDERS_SUCCESS",
  TAB_ORDERS_ERROR: "TAB_ORDERS_ERROR",
  UPDATE_STATE: "UPDATE_STATE",
  NID_UPDATE: "NID_UPDATE",
};

const select = [
  "charge",
  "customer_address",
  "customer_name",
  "customer_phone",
  "customer_area",
  "delivery_time",
  "dispatch_hour",
  "hub.name",
  "note",
  "payment_method",
  "payment_status",
  "payments",
  "pickups",
  "order_items",
  "platform",
  "rider_note",
  "status",
  "isPriority",
  "user.username",
  "user.name",
  "user.nid",
  "user.note",
  "user.nid_verified",
  "rider.name",
  "rider.username",
  "dispatch_hour",
  "completedAt",
  "rejection_reason",
];

const initialState = {
  todaysOrders: {
    loading: false,
    data: null,
  },
  riders: { loading: false, data: null, error: null },
  ridersData: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.UPDATE_STATE:
      return { ...action.payload };
    case Types.TODAYS_ORDERS_REQUEST:
      state.todaysOrders.loading = true;
      return { ...state };
    case Types.TODAYS_ORDERS_SUCCESS:
      state.todaysOrders.loading = false;
      state.todaysOrders.data = action.payload;
      return { ...state };
    case Types.TODAYS_ORDERS_ERROR:
      state.todaysOrders.loading = false;
      return { ...state };
    case Types.GET_RIDERS_REQUEST:
      state.riders.loading = true;
      return { ...state };
    case Types.GET_RIDERS_SUCCESS:
      state.riders.loading = false;
      state.riders.data = action.payload;
      state.riders.data.sort((a, b) => {
        const x = a.rider_availability ? 1 : 0;
        const y = b.rider_availability ? 1 : 0;
        return y - x;
      });
      const avail = state.riders.data
        .filter((a) => a.rider_availability)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      const unAvail = state.riders.data
        .filter((a) => !a.rider_availability)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      state.riders.data = avail.concat(unAvail);
      return { ...state };
    case Types.GET_RIDERS_FAILURE:
      state.riders.loading = false;
      return { ...state };
    case Types.SET_RIDERS_DATA:
      state.ridersData = action.payload;
      return { ...state };
    case "new-order":
      if (typeof action.play === "function") {
        action.play();
      }
      return state;
    case Types.UPDATE_RIDER:
      const index = state.riders.data.findIndex(
        (r) => r.id === action.payload.id
      );
      if (index >= 0) {
        const rider = state.riders.data[index];
        if (!action.payload.active) {
          state.riders.data.splice(index, 1);
        } else {
          rider.status = action.payload.status;
          rider.collection = action.payload.collection;
          rider.rider_availability = action.payload.rider_availability;
          rider.isRequested = action.payload.isRequested;
        }
      } else if (action.payload.active) {
        state.riders.data.unshift({
          name: action.payload.name,
          phone: action.payload.phone,
          id: action.payload.id,
          rider_availability: action.payload.rider_availability,
          collection: action.payload.collection,
          isRequested: action.payload.isRequested,
        });
      }

      state.riders.data.sort((a, b) => {
        const x = a.rider_availability ? 1 : 0;
        const y = b.rider_availability ? 1 : 0;
        return y - x;
      });

      // name wise sorting
      const available = state.riders.data
        .filter((a) => a.rider_availability)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      const unavailable = state.riders.data
        .filter((a) => !a.rider_availability)
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

      state.riders.data = available.concat(unavailable);

      return { ...state };
    case Types.NID_UPDATE:
      const id = action.payload;
      const tabs = state.todaysOrders.data || {};
      for (let key in tabs) {
        const orders = tabs[key].orders || [];
        const order = orders.find((i) => i.id === id);
        if (order) {
          order.user.set("nid", []);
          break;
        }
      }

      return { ...state };
    default:
      return state;
  }
};

export default function OrdersContextProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getOrders, assignRider, updateStatus, addDay, getById } = useOrders();
  const [orderId, setOrderId] = useState(null);
  const tone = useRef(null);

  const orderUpdateHandler = useCallback(
    (object) => {
      autoAssign.updateOrder(object);
      const { status, rider, customer_area, completedAt, pickups } =
        object.toJSON();
      for (let id in state.todaysOrders.data) {
        const obj = state.todaysOrders.data[id];
        if (!obj) {
          continue;
        }

        const { orders } = obj;
        if (orders.length > 0) {
          const orderIndex = orders.findIndex((i) => i.id === object.id);
          if (orderIndex !== -1) {
            orders.splice(orderIndex, 1);
            obj.count--;
            break;
          }
        }
      }

      // handle if any reject request
      const restaurantReject = pickups.find(
        ({ status }) => status === "rejected"
      );

      if (restaurantReject) {
        const restRejectOrders = state.todaysOrders.data["restaurant_reject"];
        if (restRejectOrders) {
          const index = restRejectOrders.orders.findIndex(
            (i) => i.id === object.id
          );
          if (index !== -1) {
            restRejectOrders.orders.splice(index, 1, parser(object));
          } else {
            restRejectOrders.orders.push(parser(object));
            restRejectOrders.count++;

            notification.warning({
              title: "Restaurant Reject",
              message: `Order #${object.id} (${restaurantReject.order_number}) has been rejected by restaurant ${restaurantReject.name}`,
              duration: 0,
            });
          }
        }
      }

      const item = state.todaysOrders.data[status];
      if (!item) return;
      item.orders.push(parser(object));
      item.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      item.count++;

      // update rider orders
      if (rider && state.riders.data) {
        const riderObj = state.riders.data.find(
          (item) => item.id === rider.objectId
        );

        if (riderObj) {
          let orderIdx = -1;
          const order = riderObj.orders.find((item, i) => {
            orderIdx = i;
            return item.objectId === object.id;
          });
          if (
            order &&
            ["delivered", "cancelled", "rejected", "created"].includes(
              object.get("status")
            )
          ) {
            riderObj.orders.splice(orderIdx, 1);
            if (object.get("status") === "delivered") {
              riderObj.orders.delivered = (riderObj.orders.delivered || 0) + 1;
            }
          } else if (order) {
            order.status = status;
            order.customer_area = customer_area;
          } else {
            riderObj.orders.push({
              status,
              customer_area,
              objectId: object.id,
            });
          }

          if (
            completedAt &&
            riderObj.completedAt &&
            new Date(riderObj.completedAt) < new Date(completedAt.iso)
          ) {
            riderObj.completedAt = completedAt.iso;
            riderObj.lastArea = customer_area;
          } else if (completedAt && !riderObj.completedAt) {
            riderObj.completedAt = completedAt.iso;
            riderObj.lastArea = customer_area;
          }
        }

        // delete if this order is in another rider orders
        let delIdx = null;
        const prevOrderInRider = state.riders.data.find((rider) => {
          if (rider.objectId === riderObj?.objectId) {
            return false;
          }
          return rider.orders?.find((item, i) => {
            if (item.objectId === object.id) {
              delIdx = i;
              return true;
            }
            return false;
          });
        });

        if (prevOrderInRider) {
          prevOrderInRider.orders.splice(delIdx, 1);
        }
      }

      dispatch({
        type: Types.UPDATE_STATE,
        payload: state,
      });
    },
    [state]
  );

  const ordersQuery = new Parse.Query("order");
  ordersQuery
    .include("user")
    .greaterThanOrEqualTo("createdAt", addDay(new Date(), -2));

  const ridersQuery = new Parse.Query("_User")
    .equalTo("type", "rider")
    .select(
      "name",
      "phone",
      "collection",
      "active",
      "deactive_permission",
      "rider_availability",
      "riderHub",
      "isRequested"
    );

  let ordersLiveSubscription = null;
  let ridersLiveSubscription = null;

  const initLiveQueries = async () => {
    const user = Parse.User.current();
    if (!user) return;
    if (riderMsSocket?.id) {
      riderMsSocket.on("rider_update_client", (data) => {
        getById(data._id, (err, object) => {
          if (object) orderUpdateHandler(object);
        });
      });
    }

    ordersLiveSubscription = await client.subscribe(
      ordersQuery,
      user.getSessionToken()
    );

    ridersLiveSubscription = await client.subscribe(
      ridersQuery,
      user.getSessionToken()
    );

    if (ordersLiveSubscription) {
      // Listen when order update
      ordersLiveSubscription.on("update", orderUpdateHandler);

      // Listen when new order is created
      ordersLiveSubscription.on("create", async (object) => {
        autoAssign.pushNewOrder(object);
        const user = await new Parse.Query("_User")
          .equalTo("objectId", object.get("user").id)
          .select(["name", "username", "nid", "nid_verified"])
          .first();
        let obj = state.todaysOrders.data[object.attributes.status];
        if (!obj || !obj.orders) return;
        const exists = obj.orders.find((i) => i.id === object.id);
        if (!exists) {
          const data = parser(object);
          data.user = user;
          obj.orders.unshift(data);
          obj.count++;

          dispatch({
            type: Types.TODAYS_ORDERS_SUCCESS,
            payload: state.todaysOrders.data,
          });

          if (object.get("status") === "created") {
            dispatch({
              type: "new-order",
              play: () => {
                tone.current?.play();
              },
            });
          }
        }
      });

      ordersLiveSubscription.on("delete", (object) => {
        const { status } = object.attributes;
        if (!status) return;

        const data = state.todaysOrders.data[status];
        if (data) {
          const index = data.orders.findIndex((i) => i.id === object.id);
          if (index !== -1) {
            data.orders.splice(index, 1);

            dispatch({
              type: Types.TODAYS_ORDERS_SUCCESS,
              payload: state.todaysOrders.data,
            });

            return;
          }
        }
      });
    }

    if (ridersLiveSubscription) {
      ridersLiveSubscription.on("update", (object) => {
        autoAssign.updateRider(object);
        dispatch({
          type: Types.UPDATE_RIDER,
          payload: parser(object),
        });
      });
    }
  };

  const clearSubs = useCallback(() => {
    if (ordersLiveSubscription) ordersLiveSubscription.unsubscribe();
    if (ridersLiveSubscription) ridersLiveSubscription.unsubscribe();
  }, [ordersLiveSubscription, ridersLiveSubscription]);

  const getActiveOrdersCount = async (params) => {
    dispatch({ type: Types.TODAYS_ORDERS_REQUEST });
    try {
      const results = await Parse.Cloud.run("activeOrdersCount", params);
      dispatch({
        type: Types.TODAYS_ORDERS_SUCCESS,
        payload: results,
      });
    } catch (err) {
      if (err) {
        dispatch({ type: Types.TODAYS_ORDERS_ERROR });
      }
    }
  };

  const getRiders = async () => {
    dispatch({ type: Types.GET_RIDERS_REQUEST });
    try {
      const riders = await Parse.Cloud.run("getActiveRiders");
      dispatch({ type: Types.GET_RIDERS_SUCCESS, payload: riders });
    } catch (err) {
      dispatch({ type: Types.GET_RIDERS_FAILURE });
      message.error(err.message);
    }

    setTimeout(() => {
      getRiders();
    }, 1000 * 60 * 3);
  };

  const assignRiderFunc = ({ order_id, rider_id }, callback) => {
    assignRider({ order_id, rider_id }, (err, res) => {
      if (res) {
        message.success("Rider assigned!");
        if (typeof callback === "function") callback(true);
      } else {
        message.error(err);
        if (typeof callback === "function") callback(false);
      }
    });
  };

  const fetchOrders = ({ status, ...params }) => {
    const obj = state.todaysOrders.data[status];
    if (!obj) {
      message.error("No orders found");
      return;
    }

    obj.loading = true;
    if (!obj.isDate) {
      delete params.createdAt;
      delete params.completedAt;
    }

    // loading
    dispatch({
      type: Types.TODAYS_ORDERS_SUCCESS,
      payload: state.todaysOrders.data,
    });

    getOrders(
      {
        status,
        select,
        exclude: ["timeline", "inventory", "items"],
        ...params,
      },
      (err, { results, count }) => {
        if (err) {
          dispatch({ type: Types.TODAYS_ORDERS_ERROR });
          message.error(err);
          return;
        }

        if (!params.skip) {
          obj.orders = parser(results);
        } else {
          obj.orders = obj.orders.concat(parser(results));
        }
        obj.loading = false;

        if (params.limit !== undefined) {
          obj.limit = params.limit;
        }
        if (params.skip !== undefined) {
          obj.skip = params.skip;
        }

        if (!obj.isDate) {
          obj.count = count;
        }

        if (obj.isDate) {
          obj.lastFetched = new Date().toISOString();
        }

        dispatch({
          type: Types.TODAYS_ORDERS_SUCCESS,
          payload: state.todaysOrders.data,
        });
      }
    );
  };

  useEffect(() => {
    initLiveQueries();
    getRiders();

    // initialize auto assign
    // autoAssign.init();

    return () => {
      clearSubs();
    };
  }, []);

  return (
    <OrdersContext.Provider
      value={{
        ...state,
        getActiveOrdersCount,
        getRiders,
        updateStatus,
        assignRider: assignRiderFunc,
        orderId,
        setOrderId,
        fetchOrders,
        autoAssign,
        nidUpdated: (id) => {
          dispatch({ type: Types.NID_UPDATE, payload: id });
        },
      }}
    >
      <Content />
      <Drawer
        title={`Order Details - ${orderId}`}
        placement="right"
        visible={orderId}
        onClose={() => setOrderId(null)}
        width={1200}
      >
        {orderId && <OrderDetails orderId={orderId} />}
      </Drawer>
      <audio ref={tone} src="/notify.wav"></audio>
    </OrdersContext.Provider>
  );
}
