import { message, Table, Button, Tag, Input, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import Parse from "parse";
import useSearch from "../../components/inventory/utils/useSearch";
import { parser } from "../../utils";

export const RiderPaymentStatusEnum = {
  PENDING: "Pending",
  PAID: "Paid",
  REJECTED: "Rejected",
};

const RiderPaymentRequest = () => {
  const [data, setData] = useState({
    results: [],
    loading: true,
    count: 0,
  });

  const [, getSearchProps] = useSearch();
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(0);

  const updatePayment = async (id, status, riderId) => {
    try {
      // const RiderPayment = Parse.Object.extend("rider_payment");
      // const query = new Parse.Query(RiderPayment);
      // const riderPayment = await query.get(id);

      // const currentStatus = riderPayment.get("status");
      // if (currentStatus !== RiderPaymentStatusEnum.PENDING) {
      //   message.error(`Payment id ${id} is already ${currentStatus}!`);
      //   return;
      // }

      // riderPayment.set("status", status);
      // riderPayment.set("remarks", remarks);
      // if (status === RiderPaymentStatusEnum.REJECTED) {
      //   // update rider balance
      //   const rider = riderPayment.get("rider");
      //   const balance = rider.get("balance");
      //   rider.set("balance", balance + riderPayment.get("amount"));
      //   await rider.save();
      // }
      // await riderPayment.save();
      console.log(
        "updateRiderWithdrawStatus",
        id,
        status,
        remarks,
        amount,
        riderId
      );

      await Parse.Cloud.run("updateRiderWithdrawStatus", {
        id,
        status,
        remarks,
        paidAmount: amount,
        riderId,
      });

      fetchData({
        limit: 20,
        skip: 0,
      });
      message.info(`Payment id ${id} has been ${status}!`);
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };

  const exapndedRowRender = (record) => {
    const columns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Order Count",
        dataIndex: "orderCount",
        key: "orderCount",
      },
      {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => (
          <Tag color="blue" key={amount}>{`Tk ${amount.toLocaleString()}`}</Tag>
        ),
      },
      {
        title: "Attendance",
        dataIndex: "attendance",
        key: "attendance",
        render: (attendance) => (
          <Tag
            color="blue"
            key={attendance}
          >{`Tk ${attendance.toLocaleString()}`}</Tag>
        ),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (total) => (
          <Tag color="green" key={total}>{`Tk ${total.toLocaleString()}`}</Tag>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record?.calculation}
        pagination={false}
      />
    );
  };

  const fetchData = async ({ limit, skip, date }) => {
    try {
      const query = new Parse.Query("rider_payment")
        .limit(limit)
        .skip(skip)
        .descending("createdAt")
        .include("rider")
        .withCount();

      // console.log("query", query);

      if (Array.isArray(date) && date.length === 2) {
        const [start, end] = date.map((d) => new Date(d));
        end.setHours(23, 59, 59);
        query.greaterThanOrEqualTo("createdAt", start);
        query.lessThanOrEqualTo("createdAt", end);
      }

      const data = await query.find();

      setData({
        results: parser(data.results),
        loading: false,
        count: data.count,
      });
    } catch (error) {
      console.log(error);
      message.error(error.message);
      setData((data) => ({ ...data, loading: false }));
    }
  };

  useEffect(() => {
    fetchData({
      limit: 20,
      skip: 0,
    });
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "objectId",
    },
    {
      title: "Rider",
      dataIndex: "rider",
      key: "rider",
      render: (riderObj) => {
        return riderObj.get("username");
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return (
          <Tag color="blue" key={amount}>{`Tk ${amount.toLocaleString()}`}</Tag>
        );
      },
    },
    {
      title: "Previous Balance",
      dataIndex: "rider",
      key: "rider",
      render: (riderObj) => {
        return (
          <Tag color="green" key={riderObj.get("balance")}>
            {`Tk ${riderObj.get("balance") ?? 0}`}
          </Tag>
        );
      },
    },
    {
      title: "due",
      dataIndex: "rider",
      key: "due",
      render: (rider) => (
        <Tag color="red" key={rider.get("collection")}>
          {`Tk ${rider.get("collection")}`}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        let color = "geekblue";
        if (status === RiderPaymentStatusEnum.PAID) {
          color = "green";
        } else if (status === RiderPaymentStatusEnum.REJECTED) {
          color = "volcano";
        }
        if (record.paidAmount && record.paidAmount < record.amount) {
          color = "orange";
          status = "PARTIAL PAID";
        }

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (paidAmount) => {
        if (paidAmount) {
          return (
            <Tag color="cyan" key={paidAmount}>
              {`Tk ${paidAmount.toLocaleString()}`}
            </Tag>
          );
        } else {
          return <Tag color="red">UNPAID</Tag>;
        }
      },
    },
    {
      title: "Requested At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: "remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        if (record.status !== RiderPaymentStatusEnum.PENDING) {
          return <></>;
        }
        return (
          <div>
            <Popconfirm
              placement="topRight"
              title={
                <div>
                  <Input
                    placeholder="Please enter remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                  <Input
                    placeholder={`Approved amount ${record.amount}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setAmount(record.amount);
                    }}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              }
              onConfirm={() =>
                updatePayment(
                  record.id,
                  RiderPaymentStatusEnum.PAID,
                  record.rider.id
                )
              }
              onCancel={() => {
                setRemarks("");
                setAmount(0);
              }}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setRemarks("");
                }
              }}
              okText="Ok"
            >
              <Button type="primary">Approve</Button>
            </Popconfirm>

            <Popconfirm
              placement="topRight"
              title={
                <div>
                  <Input
                    placeholder="Please enter remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
              }
              onCancel={() => {
                setRemarks("");
              }}
              onConfirm={() =>
                updatePayment(
                  record.id,
                  RiderPaymentStatusEnum.REJECTED,
                  record.rider.id
                )
              }
              okText="Ok"
            >
              <Button type="danger">Reject</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h2>Rider Payment Requests</h2>
      <p>Showing new Commision riders payment requests!</p>

      <Table
        columns={columns}
        dataSource={data.results}
        loading={data.loading}
        pagination={{
          total: data.count,
          defaultPageSize: 20,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        onChange={(pagination, filter) => {
          const { current, pageSize } = pagination;
          const { createdAt } = filter;
          fetchData({
            limit: pageSize,
            skip: (current - 1) * pageSize,
            date: createdAt,
          });
        }}
        expandable={{
          expandedRowRender: exapndedRowRender,
          rowExpandable: (record) => record.calculation.length > 0,
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default RiderPaymentRequest;
