import { useEffect, useContext } from "react";
import { Table, DatePicker, Space, Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InventoryContext } from "./InventoryContext";
import { Link } from "react-router-dom";
import { formatRelative, isSameDay } from "date-fns";
import { CalendarOutlined, EditOutlined } from "@ant-design/icons";
import useSearch from "./utils/useSearch";
import Highlighter from "react-highlight-words";
import ProductLinks from "../Common/ProductLinks";
import { differenceInDays } from "date-fns";
import { localDateTime } from "../../utils";

export default function PurchaseRecordsTable() {
  const { getPurchaseRecords, records, deletePurchaseRecord } =
    useContext(InventoryContext);
  const { loading, data } = records || {};
  const [state, getColumnSearchProps] = useSearch();
  const params = new URL(window.location.href).searchParams;
  const productId = params.get("product");

  useEffect(() => {
    getPurchaseRecords({ limit: 100, skip: 0, product: productId });
  }, [productId]);

  const dateOnChange = ({ dateString, setSelectedKeys, confirm }) => {
    setSelectedKeys(dateString ? [dateString] : []);
    confirm();
  };

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          value={setSelectedKeys[0]}
          onChange={(date, dateString) =>
            dateOnChange({ dateString, setSelectedKeys, confirm })
          }
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    onFilter: (value, record) => {
      return record[dataIndex]
        ? isSameDay(new Date(record[dataIndex]), new Date(value))
        : "";
    },
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "160px",
      ...dateSearchProps("createdAt"),
      render: (date) => localDateTime(date),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "100px",
      render: (image) => (
        <img src={image} alt="product" style={{ width: "80px" }} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "160px",
      ...getColumnSearchProps("name"),
      render: (text, record) => {
        return state.searchedColumn === "name" ? (
          <ProductLinks
            id={record.productId}
            restaurantId={record.restaurantId}
          >
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text : ""}
            />
          </ProductLinks>
        ) : (
          <ProductLinks
            id={record.productId}
            restaurantId={record.restaurantId}
          >
            {text}
          </ProductLinks>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "100px",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      width: "100px",
      sorter: (a, b) => a.unit_price - b.unit_price,
    },
    {
      title: "Selling Price",
      dataIndex: "selling_price",
      key: "selling_price",
      width: "100px",
      sorter: (a, b) => a.selling_price - b.selling_price,
    },
    {
      title: "Remain",
      dataIndex: "stock",
      key: "stock",
      width: "100px",
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Expired Date",
      dataIndex: "expired_date",
      key: "expired_date",
      width: "160px",
      ...dateSearchProps("expire_date"),
      render: (expire_date, { stock }) => {
        let bgColor = "#fff";
        if (expire_date && stock > 0) {
          const days = differenceInDays(new Date(expire_date), new Date());
          if (days >= 3 && days <= 7) {
            bgColor = "#ffc107";
          } else if (days < 3) {
            bgColor = "#ff1493";
          }
        }

        return expire_date ? (
          <div
            style={{
              background: bgColor,
              height: "100%",
              padding: "10px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            {formatRelative(new Date(expire_date), Date.now())}
          </div>
        ) : null;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "100px",
      render: (_, { id }) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                to={`/inventory/${id}/update`}
              >
                <EditOutlined />
              </Link>
            }
          ></Button>
          <Popconfirm
            title="Are you sure delete this purchase record?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              deletePurchaseRecord(id);
            }}
          >
            <Button icon={<DeleteOutlined />} shape="circle" danger></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const parseData = (data) => {
    return data.map((item, i) => {
      const { ref, product, ...rest } = item;
      const {
        name,
        objectId: productId,
        images,
        restaurant,
      } = product?.toJSON() || {};

      return {
        key: i,
        id: item.id,
        name: name ?? "Not found",
        productId,
        image: images && images[0] ? images[0] : "",
        restaurantId: restaurant?.objectId,
        refs: ref,
        productRef: product,
        ...rest,
      };
    });
  };

  const tableOnChange = (pagination, filters) => {
    getPurchaseRecords({
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      product: productId,
      name: filters.name ? filters.name[0] : "",
    });
  };

  return (
    <div>
      <Table
        size="large"
        columns={columns}
        loading={loading}
        dataSource={parseData(data.results)}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "1000", "1500", "2000"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: data.count,
          position: ["topLeft", "topRight"],
        }}
        onChange={tableOnChange}
        scroll={{ x: "900px" }}
      />
    </div>
  );
}
