import {
  useState,
  useEffect,
  createContext,
  useReducer,
  useCallback,
} from "react";
import Component from "../../../components/restaurant/product-upload";
import useCategories from "../../../hooks/useCategories";
import useRestaurants from "../../../hooks/useRestaurants";
import useProduct from "../../../hooks/useProduct";
import { useParams } from "react-router-dom";
import { parser, randomId } from "../../../utils";
import { message } from "antd";

export const BulkProductUploadContext = createContext();

const Types = {
  GET_RESTAURANT_REQUEST: "GET_RESTAURANT_REQUEST",
  GET_RESTAURANT_SUCCESS: "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_FAILURE: "GET_RESTAURANT_FAILURE",
  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
};

const initialState = {
  restaurant: {
    loading: false,
    data: null,
  },
  categories: {
    loading: false,
    data: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_RESTAURANT_REQUEST:
      state.restaurant.loading = true;
      return { ...state };
    case Types.GET_RESTAURANT_SUCCESS:
      state.restaurant.loading = false;
      state.restaurant.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANT_FAILURE:
      state.restaurant.loading = false;
      state.restaurant.data = null;
      return { ...state };
    case Types.GET_CATEGORIES_REQUEST:
      state.categories.loading = true;
      return { ...state };
    case Types.GET_CATEGORIES_SUCCESS:
      state.categories.loading = false;
      state.categories.data = action.payload;
      return { ...state };
    case Types.GET_CATEGORIES_FAILURE:
      state.categories.loading = false;
      state.categories.data = [];
      return { ...state };
    default:
      return state;
  }
};

export default function BulkProductUpload({ id }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getById } = useRestaurants();
  const { getMany: getCategories } = useCategories();
  const { addProduct } = useProduct();
  const { restaurantId = id } = useParams();

  const saveData = ({ form, id, images, category, file, key }) => {
    let store = localStorage.getItem(`${restaurantId}:product-upload`) || "{}";
    if (store) {
      store = JSON.parse(store);
    }

    const formData = form?.getFieldsValue() ?? {};
    store[id] = {
      formData,
      id,
      key: file?.key ?? key,
      images: images ?? [],
      category,
    };

    localStorage.setItem(
      `${restaurantId}:product-upload`,
      JSON.stringify(store)
    );
  };

  const deleteSavedItem = (id) => {
    let store = localStorage.getItem(`${restaurantId}:product-upload`) || "{}";
    if (store) {
      store = JSON.parse(store);
    }

    delete store[id];

    localStorage.setItem(
      `${restaurantId}:product-upload`,
      JSON.stringify(store)
    );
  };

  const fetchRestaurant = async (id) => {
    dispatch({ type: Types.GET_RESTAURANT_REQUEST });
    getById({ id, select: ["name", "banner_image", "vat"] }, (err, res) => {
      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_RESTAURANT_FAILURE });
      } else {
        dispatch({ type: Types.GET_RESTAURANT_SUCCESS, payload: parser(res) });
      }
    });
  };

  const fetchCategories = async (params) => {
    dispatch({ type: Types.GET_CATEGORIES_REQUEST });
    getCategories(params, (err, res) => {
      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_CATEGORIES_FAILURE });
      } else {
        dispatch({
          type: Types.GET_CATEGORIES_SUCCESS,
          payload: parser(res.results),
        });
      }
    });
  };

  const addProductFunc = async (data, callback) => {
    if (data.price.type === "variant") {
      data.price.variants?.forEach((variant) => {
        variant.id = randomId();

        variant.items?.forEach((item) => {
          item.id = randomId();
        });
      });
    }

    if (data.addons?.items) {
      data.addons.items.forEach((addon) => {
        addon.id = randomId();
      });
    }

    addProduct(data, (err, product) => {
      if (err) {
        message.error(err);
        callback(err, null);
        return;
      }
      if (product) {
        callback(null, product);
      }
    });
  };

  useEffect(() => {
    fetchRestaurant(restaurantId);
    fetchCategories({ limit: 20, select: ["name"] });
  }, []);

  return (
    <BulkProductUploadContext.Provider
      value={{
        ...state,
        fetchCategories,
        addProductFunc,
        saveData,
        deleteSavedItem,
        restaurantId,
      }}
    >
      <Component />
    </BulkProductUploadContext.Provider>
  );
}
