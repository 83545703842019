import { useState, createRef } from "react";
import { Space, Input, Button, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const useSearch = ({} = {}) => {
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const searchInput = createRef();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters, callback) => {
    clearFilters();
    setState({ searchText: "" });
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const onFilter = (value, record, dataIndex) => {
    return record[dataIndex]
      ? record[dataIndex]
          .toString()
          ?.toLowerCase()
          ?.includes(value.toLowerCase())
      : "";
  };

  const getColumnSearchProps = (
    dataIndex,
    { onSearchTrigger, onResetTrigger, defaultFilter = true, useSelect } = {}
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {!useSelect && (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              if (onSearchTrigger) {
                onSearchTrigger(e.target.value);
              }
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        {useSelect && (
          <Select
            mode="tags"
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys}
            onChange={(e) => {
              if (onSearchTrigger) {
                onSearchTrigger(e);
              }
              setSelectedKeys(e ?? []);
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Select>
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, onResetTrigger)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!defaultFilter) return true;
      return onFilter(value, record, dataIndex);
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.current.select();
        }, 100);
      }
    },
  });

  return [state, getColumnSearchProps];
};

export default useSearch;
