import { Form, Select } from "antd";
const { Item } = Form;

export default function Restaurant({
  isNew,
  restaurants,
  getRestaurants,
  setFormData,
  formData,
}) {
  const { loading, data } = restaurants;

  const filterHandler = (input, option) => {
    const match = (input, value) => {
      return value.toLowerCase().indexOf(input.toLowerCase()) > -1;
    };

    if (typeof option === "string") {
      return match(input, option);
    }

    if (Array.isArray(option?.children)) {
      return option.children.some((child) =>
        typeof child === "string" ? match(input, child) : false
      );
    }
  };

  const onSearch = (e) => {
    getRestaurants({
      search: e,
      select: ["objectId", "name", "banner_image", "vat", "hub.name"],
    });
  };

  const onChange = (value) => {
    formData["restaurant"] = value;
    setFormData({ ...formData });
  };

  return (
    <Item
      name="restaurant"
      label="Restaurant"
      rules={[
        {
          required: true,
          message: "Please select a restaurant",
        },
      ]}
      style={{ marginBottom: "5px" }}
    >
      <Select
        size="large"
        showSearch
        placeholder="Select a restaurant"
        loading={loading}
        onSearch={onSearch}
        filterOption={filterHandler}
        onChange={onChange}
      >
        {data.results.map(({ objectId: id, name, hub }) => (
          <Select.Option key={id} value={id}>
            {name} <small style={{ color: "gray" }}>@{hub?.name}</small>
          </Select.Option>
        ))}
      </Select>
    </Item>
  );
}
