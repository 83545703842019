import { GlobalOutlined, MobileOutlined } from "@ant-design/icons";
import { Space, Tag, message } from "antd";
import { intervalToDuration } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";

export default function OrderIdentifyingInfo({
  id,
  platform,
  user,
  status,
  createdAt,
  isPriority,
}) {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [orderCount, setOrderCount] = useState(null);

  const fetchUserOrderCount = async (id, createdAt) => {
    try {
      const count = await new Parse.Query("order")
        .equalTo("user", {
          __type: "Pointer",
          className: "_User",
          objectId: id,
        })
        .lessThanOrEqualTo("createdAt", new Date(createdAt))
        .count();
      setOrderCount(count);
    } catch (err) {
      message.error(err);
    }
  };

  const setTimer = useCallback(() => {
    return setInterval(() => {
      const { hours, minutes, seconds } = intervalToDuration({
        start: new Date(createdAt),
        end: Date.now(),
      });
      setTime({ hours, minutes, seconds });
    }, 2000);
  }, [createdAt]);

  useEffect(() => {
    const interval = setTimer();
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchUserOrderCount(user?.id, createdAt);
  }, []);

  return (
    <div
      className={`left-side ${
        isPriority && ["created", "confirmed", "preparing"].includes(status)
          ? "priority"
          : ""
      }`}
    >
      <Space>
        <Link to={`/order/list/${id}`} className="id">
          {id}
        </Link>
        {platform === "web" ? <GlobalOutlined /> : <MobileOutlined />}
        {orderCount ? (
          <Link target="_blank" to={`/order/list?user=${user?.id}`}>
            <Tag
              title="User order number"
              color="red"
              style={{ borderRadius: "30px" }}
            >
              {orderCount}
            </Tag>
          </Link>
        ) : null}
        <div className="date">
          {(!["delivered", "cancelled", "rejected"].includes(status) ||
            (time && time.hours <= 10)) &&
            `${time.hours ?? "00"}:${time.minutes ?? "00"}:${time.seconds}`}
        </div>
      </Space>
    </div>
  );
}
