import { Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

export default function AddImage({ name, label, children, rules = [] }) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        getValueFromEvent={normFile}
        rules={rules}
      >
        <Upload.Dragger
          beforeUpload={() => false}
          style={{
            marginBottom: "10px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            background: "#fff",
          }}
          name="files"
          listType="picture-card"
          multiple={true}
          type="file"
          showUploadList={true}
          getValueFromEvent={normFile}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item label=" ">{children}</Form.Item>
    </>
  );
}
