import {
  DatePicker,
  Form,
  Input,
  Button,
  Upload,
  message,
  DatePickerProps,
  Space,
  Tag,
} from "antd";
import { getSignedUrl } from "../../../api/media";
import { InboxOutlined } from "@ant-design/icons";
import useMedia from "../../../hooks/useMedia";
import moment from "moment";

type ExpiredDate = {
  iso: Date;
  _type: string;
};

export const ExpireDate = ({ date }: { date: ExpiredDate }) => {
  return (
    <Space direction="vertical" size={"middle"}>
      {
        <Tag
          color={moment(date.iso ?? date).isAfter(moment()) ? "green" : "red"}
        >
          {moment(date.iso ?? date).isAfter(moment()) ? "Active" : "Expired"}
        </Tag>
      }

      <div>
        Expired at: {moment(date.iso ?? date).format("DD-MMM-YYYY hh:mm A")}
      </div>
    </Space>
  );
};

export default function RestaurantMou({
  expireDate,
  setExpiredDate,
  mou,
  mouFile,
  setMouFile,
  setMou,
}: {
  expireDate: ExpiredDate;
  mou: string;
  setExpiredDate: (mou: string) => void;
  setMou: (mou: string) => void;
  mouFile?: File | null;
  setMouFile?: (file: File) => void;
}) {
  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && setMouFile) setMouFile(e.target.files[0]);
  //   console.log(e);
  // };
  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setExpiredDate(dateString);
  };
  const { getSignedUrl, uploadFile } = useMedia();

  const handleFileChange = async ({ fileList }: any) => {
    if (fileList[0]?.originFileObj) {
      const file = fileList[fileList.length - 1];

      const { url } = await getSignedUrl(file.type);
      uploadFile({ url, file }, (err, progress) => {
        if (err) {
          message.error(err);
          return;
        }

        if (progress === 100) {
          let splitUrl = url.split("?")[0];
          setMou(splitUrl);
        }
      });
    }
  };
  // let date = JSON.parse(JSON.stringify(expireDate)).iso;

  return (
    <>
      {" "}
      <h3 className="section-title">Restaurant Mou</h3>
      {mou && expireDate && (
        <>
          <div style={{ padding: "4px" }}>
            <a href={mou} download>
              Click here to download MOU!
            </a>
          </div>
          <Button
            type="primary"
            style={{ background: "red" }}
            onClick={() => {
              setMou("");
              setExpiredDate("");
            }}
          >
            Remove MOU
          </Button>
        </>
      )}
      {mou ? (
        <div>
          <iframe
            src={`https://docs.google.com/gview?url=${mou}&embedded=true`}
            style={{ width: "300px", height: "200px" }}
          ></iframe>
        </div>
      ) : (
        <>
          <Upload.Dragger
            beforeUpload={() => false}
            type="drag"
            id="upload-mou"
            multiple={false}
            showUploadList={false}
            accept=".pdf"
            onChange={handleFileChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
        </>
      )}
      {!expireDate && (
        <Form.Item
          name="expired_at"
          label="Expiry date"
          rules={[
            {
              required: mouFile ? true : false,
              message: "Please select the expiry date",
            },
          ]}
        >
          <DatePicker
            placeholder="Expiry date"
            format="DD-MMM-YYYY hh:mm A"
            showTime
            onChange={onDateChange}
            disabledDate={(current) => moment().add(-1, "days") >= current}
          />
        </Form.Item>
      )}
      {/* <>
        <Upload.Dragger
          beforeUpload={() => false}
          type="drag"
          id="upload-mou"
          multiple={false}
          showUploadList={false}
          accept=".pdf"
          onChange={handleFileChange}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
        <Form.Item
          name="expired_at"
          label="Expiry date"
          rules={[
            {
              required: mouFile ? true : false,
              message: "Please select the expiry date",
            },
          ]}
        >
          <DatePicker
            placeholder="Expiry date"
            format="DD-MMM-YYYY hh:mm A"
            showTime
            onChange={onDateChange}
          />
        </Form.Item>
      </> */}
      {/* {expireDate && <div>MOU Expiry date:{date}</div>} */}
      {expireDate && <ExpireDate date={expireDate} />}
    </>
  );
}
