import { DatePicker, message, Select, Space, Table } from "antd";
import Parse from "parse";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSearch from "../../components/inventory/utils/useSearch";

export default function TopSellingProducts() {
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const { id } = useParams();
  const [, getSearchParams] = useSearch();
  const [loading, setLoading] = useState(false);
  const [by, setBy] = useState("product");

  const fetchTopSellingProducts = async (params) => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("topSellingProducts", params);
      setData(res);
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopSellingProducts({
      ids: [id],
      date,
      by,
    });
  }, [id, date, by]);

  return (
    <div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1>Top Selling Products</h1>
        <Space>
          <DatePicker.RangePicker
            size="large"
            onChange={(date, dateStr) => {
              setDate(dateStr);
            }}
          />
          <Select size="large" value={by} onChange={(value) => setBy(value)}>
            <Select.Option value="product">Product</Select.Option>
            <Select.Option value="category">Category</Select.Option>
          </Select>
        </Space>
      </div>
      <Table
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "300px",
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getSearchParams("name"),
            render: (name, { restaurant, image }) => {
              return (
                <Space>
                  <img
                    src={image}
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                  <div>
                    <div>{name}</div>
                    <small style={{ color: "gray" }}>@{restaurant}</small>
                  </div>
                </Space>
              );
            },
          },
          {
            title: "Order",
            dataIndex: "order",
            key: "order",
            sorter: (a, b) => a.order - b.order,
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            sorter: (a, b) => a.quantity - b.quantity,
          },
          {
            title: "Sales",
            dataIndex: "total",
            key: "total",
            sorter: (a, b) => a.total - b.total,
          },
          {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
            sorter: (a, b) => a.discount - b.discount,
          },
          {
            title: "Revenue",
            dataIndex: "revenue",
            key: "revenue",
            sorter: (a, b) => a.revenue - b.revenue,
          },
        ]}
        dataSource={data}
        pagination={{
          pageSize: data.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: data.length,
          position: ["topRight"],
        }}
        loading={loading}
      />
    </div>
  );
}
