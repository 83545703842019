import { memo, useState } from "react";
import {
  Form,
  Col,
  Input,
  InputNumber,
  Space,
  Button,
  Divider,
  Row,
} from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { BiPlus } from "react-icons/bi";
import { isFloat } from "../../../common/utils";

const PlusIcon = (props) => <BiPlus {...props} style={{ fontSize: "18px" }} />;

const { Item, List } = Form;

function Addons() {
  const [len, setLen] = useState(0);

  return (
    <>
      {len > 0 && (
        <>
          <Divider style={{ margin: "7px 0" }} orientation="left">
            Addons
          </Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Item
                name={["addons", "title"]}
                rules={[{ required: true, message: "Title required!" }]}
              >
                <Input placeholder="Addons Title" />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                name={["addons", "min"]}
                rules={[
                  {
                    required: true,
                    message: "Addons min required!",
                    validator: (_, value) => {
                      if (isFloat(value)) {
                        return Promise.reject("Can't be a decimal!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber min={0} placeholder="Min" />
              </Item>
            </Col>
            <Col span={6}>
              <Item
                name={["addons", "max"]}
                rules={[
                  {
                    required: true,
                    message: "Addons max required!",
                    validator: (_, value) => {
                      if (isFloat(value)) {
                        return Promise.reject("Can't be a decimal!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber min={0} placeholder="Max" />
              </Item>
            </Col>
          </Row>
        </>
      )}

      <List name={["addons", "items"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <Space key={key} align="baseline">
                <Item
                  {...restField}
                  name={[name, "name"]}
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input placeholder="Name" />
                </Item>
                <Item
                  {...restField}
                  name={[name, "price"]}
                  rules={[
                    {
                      required: true,
                      type: "number",
                      validator: (rule, value) => {
                        if (value === undefined) {
                          return Promise.reject("Price required!");
                        } else if (isFloat(value)) {
                          return Promise.reject("Price must be integer");
                        } else if (value < 0) {
                          return Promise.reject("Price must be positive");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber placeholder="Price" />
                </Item>

                <div className="center">
                  {i + 2 > len && (
                    <PlusCircleOutlined
                      style={{
                        color: "green",
                        fontSize: "16px",
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        add();
                        setLen(len + 1);
                      }}
                    />
                  )}
                  <CloseCircleOutlined
                    onClick={() => {
                      remove(name);
                      setLen(len - 1);
                    }}
                    style={{ color: "red", fontSize: "16px" }}
                  />
                </div>
              </Space>
            ))}

            {len === 0 && (
              <Item>
                <Button
                  onClick={() => {
                    setLen(len + 1);
                    add();
                  }}
                  icon={<PlusIcon />}
                  className="center add-btn"
                >
                  Addons
                </Button>
              </Item>
            )}
          </>
        )}
      </List>
    </>
  );
}

export default memo(Addons);
