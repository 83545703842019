import { CheckOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useSearch from "../../components/inventory/utils/useSearch";
import useProduct from "../../hooks/useProduct";
import useRestaurants from "../../hooks/useRestaurants";
import { localDateTime, parser } from "../../utils";
import Parse from "parse";
import axios from "axios";

function Stores({ stores }) {
  return (
    <Row gutter={[16, 16]}>
      {stores.map((store) => {
        return (
          <Col span={24} sm={12} md={8} xl={6} key={store.id}>
            <div
              style={{
                background: "#f7f7f7",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #e8e8e8",
              }}
            >
              <Link
                to={`/inventory/barcodes?storeId=${store.id}&storeName=${
                  store.name
                }&storeHub=${store.hub?.get("name")}`}
                style={{ color: "black" }}
              >
                <div>{store.name} </div>
                <div style={{ color: "gray" }}>@{store.hub?.get("name")}</div>
              </Link>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

function GenerateBarcode({ id, name, newBarCodes }) {
  const [create, setCreate] = useState(false);
  const [qty, setQty] = useState("");

  const onSubmit = async () => {
    if (!qty) {
      message.error("Quantity must be greater than 0");
      return;
    }

    const url = localStorage.getItem("barcodeScannerUrl");
    if (!url) {
      throw new Error("Barcode scanner url not found");
    }

    try {
      const InventoryBarcode = Parse.Object.extend("inventory_barcodes");
      const items = [];
      for (let i = 0; i < qty; i++) {
        const inventoryBarcode = new InventoryBarcode();
        inventoryBarcode.set("product", {
          __type: "Pointer",
          className: "product",
          objectId: id,
        });
        inventoryBarcode.set("status", "created");
        items.push(inventoryBarcode);
      }
      const res = await Parse.Object.saveAll(items, {
        sessionToken: Parse.User.current().getSessionToken(),
      });
      setCreate(false);
      res.forEach(async (item) => {
        try {
          await fetch(url, {
            headers: {
              accept: "application/json, text/plain, */*",
              "content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              MUN: item.id,
              TITLE: name,
              DATE: localDateTime(item.createdAt),
            }),
          });
          newBarCodes([item]);
        } catch (err) {
          // delete item
          await item.destroy();
          message.error(
            err.message || "Barcode deleted! Please inform tech team."
          );
        }
      });
      setQty("");
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <div>
      {!create && (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => setCreate(true)}
          shape="circle"
        ></Button>
      )}
      {create && (
        <Space>
          <InputNumber
            value={qty}
            onChange={(v) => setQty(v)}
            placeholder="Quantity"
          />
          <Button
            shape="circle"
            icon={<CheckOutlined />}
            type="primary"
            onClick={onSubmit}
          />
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            danger
            onClick={() => setCreate(false)}
          />
        </Space>
      )}
    </div>
  );
}

function ProductBarCodes({ barCodes, setBarCodes }) {
  const search = new URLSearchParams(window.location.search);
  const productId = search.get("productId");
  const productName = search.get("productName");
  const [loading, setLoading] = useState(false);

  const fetchBarCodes = async ({ limit = 100, skip = 0, status } = {}) => {
    try {
      setLoading(true);
      const query = new Parse.Query("inventory_barcodes")
        .equalTo(
          "product",
          Parse.Object.extend("product").createWithoutData(productId)
        )
        .limit(limit)
        .skip(skip)
        .withCount()
        .select(["status", "orderId"])
        .descending("createdAt");

      if (Array.isArray(status)) {
        query.containedIn("status", status);
      } else if (status && typeof status === "string") {
        query.equalTo("status", status);
      }

      const barCodes = await query.find();
      barCodes.results = parser(barCodes.results);
      setBarCodes(barCodes);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Created",
          value: "created",
        },
        {
          text: "In Store",
          value: "in_store",
        },
        {
          text: "Sold",
          value: "sold",
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
  ];

  useEffect(() => {
    if (productId) {
      fetchBarCodes({});
    }
  }, [productId]);

  if (!productId) {
    return null;
  }

  return (
    <Table
      title={() => <h3>{productName}</h3>}
      loading={loading}
      dataSource={barCodes.results}
      columns={columns}
      scroll={{
        y: 500,
      }}
      pagination={{
        total: barCodes.count,
        defaultPageSize: 100,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        position: ["topRight"],
      }}
    />
  );
}

function StoreProducts({ stores }) {
  const search = new URLSearchParams(window.location.search);
  const storeId = search.get("storeId");
  const storeName = search.get("storeName");
  const storeHub = search.get("storeHub");
  const productId = search.get("productId");
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [, searchParams] = useSearch();
  const [loading, setLoading] = useState(false);
  const [barCodes, setBarCodes] = useState({
    count: 0,
    results: [],
  });

  const fetchProducts = async (params) => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("barcodeItemsByRestaurant", params);
      setProducts(res.results);
      setTotal(res.count);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts({
      restaurantId: storeId,
    });
  }, [storeId]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...searchParams("name"),
      width: "200px",
      render: (name, { category, price }) => {
        return (
          <div>
            <div>{name}</div>
            <div style={{ color: "gray", fontSize: "12px" }}>@{category}</div>
            <div style={{ color: "gray", fontSize: "12px" }}>${price}</div>
          </div>
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      width: "100px",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      width: "100px",
    },
    {
      title: "In Store",
      dataIndex: "in_store",
      key: "in_store",
      width: "100px",
    },
    {
      title: "Sold",
      dataIndex: "sold",
      key: "sold",
      width: "100px",
    },
    {
      title: "Returnable",
      dataIndex: "returnable",
      key: "returnable",
      width: "100px",
    },
    {
      title: "Generate Barcode",
      key: "Create",
      width: "160px",
      render: (record) => (
        <GenerateBarcode
          {...record}
          newBarCodes={(items) => {
            const product = products.find((p) => p.id === record.id);
            if (product) {
              product.created += items.length;
              product.key = new Date().getTime();
              setProducts([...products]);
            }
          }}
        />
      ),
    },
    // {
    //   title: "Items",
    //   key: "Items",
    //   width: "100px",
    //   render: (record) => {
    //     const search = new URLSearchParams(window.location.search);
    //     search.set("productId", record.id);
    //     search.set("productName", record.name);
    //     return (
    //       <Link to={`${window.location.pathname}?${search.toString()}`}>
    //         <Button shape="round" type="primary">
    //           Items
    //         </Button>
    //       </Link>
    //     );
    //   },
    // },
  ];

  return (
    <div>
      <Table
        title={() => (
          <div className="flex-between">
            <h3>
              {storeName} @{storeHub}
            </h3>
            <Space>
              <Input
                placeholder="Scanner URL"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.target.value) {
                    localStorage.setItem("barcodeScannerUrl", e.target.value);
                    e.currentTarget.value = "";
                  }
                }}
              />
              <Dropdown
                overlay={() => (
                  <div
                    style={{
                      background: "#fff",
                      padding: "20px",
                      maxWidth: "700px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      maxHeight: "400px",
                    }}
                    className="customScroll"
                  >
                    <Stores stores={stores} />
                  </div>
                )}
                placement="bottom"
              >
                <Button type="primary" shape="round">
                  Stores
                </Button>
              </Dropdown>
            </Space>
          </div>
        )}
        columns={columns}
        loading={loading}
        dataSource={[...products]}
        rowKey="id"
        pagination={{
          defaultPageSize: 100,
          total,
          showSizeChanger: true,
          pageSizeOptions: ["100", "200", "500", "1000"],
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topRight"],
        }}
        onChange={(pagination, filters) => {
          fetchProducts({
            restaurantId: storeId,
            limit: pagination.pageSize,
            skip: pagination.pageSize * (pagination.current - 1),
            search: filters.name?.[0],
          });
        }}
        scroll={{ x: 700, y: 500 }}
      />
      {/* <Drawer
        title={`${search.get("productName")} Items`}
        placement="right"
        onClose={() => {
          search.delete("productId");
          search.delete("productName");
          history.push(`${window.location.pathname}?${search.toString()}`);
        }}
        visible={!!productId}
      >
        <ProductBarCodes barCodes={barCodes} setBarCodes={setBarCodes} />
      </Drawer> */}
    </div>
  );
}

export default function BarCodes() {
  const [stores, setStores] = useState([]);
  const { getRestaurants } = useRestaurants();
  const storeId = new URLSearchParams(window.location.search).get("storeId");

  const fetchStores = async () => {
    getRestaurants(
      { type: ["store", "sub_store"], select: ["name", "hub.name"] },
      (err, res) => {
        if (err) {
          message.error(err);
        } else {
          setStores(parser(res.results));
        }
      }
    );
  };

  useEffect(() => {
    fetchStores();
  }, []);

  return (
    <div>
      {!storeId && <Stores stores={stores} />}
      {storeId && <StoreProducts stores={stores} />}
    </div>
  );
}
