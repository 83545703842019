import React from "react";
import {
  Button,
  Card,
  List,
  Drawer,
  Space,
  Tag,
  Divider,
  message,
  Badge,
  Popconfirm,
} from "antd";
import Parse from "parse";
import CRUDStory from "./CRUDStory";
import moment from "moment";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

const StoryPage = () => {
  const [data, setData] = React.useState([]);
  const [story, setStory] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    const fetchStory = async () => {
      const query = await new Parse.Query("story")
        .select("title", "media", "expired_at", "restaurant", "areas")
        .descending("createdAt")
        .doesNotExist("deleted_at")
        .descending("expired_at")
        .include("restaurant")
        .limit(10)
        .find();
      setData(query.map((item) => item.toJSON()));

      const count = await new Parse.Query("story").count();
      setCount(count);
    };

    fetchStory();
  }, []);

  const onLoadMore = async () => {
    const results = await new Parse.Query("story")
      .select("title", "media", "expired_at", "restaurant", "areas")
      .descending("createdAt")
      .doesNotExist("deleted_at")
      .descending("expired_at")
      .include("restaurant")
      .skip(data.length)
      .limit(10)
      .find();
    setData((prev) => [...prev, ...results?.map((item) => item.toJSON())]);
  };

  return (
    <Card
      title="Story Management"
      style={{
        borderRadius: 10,
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Button
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
            setStory(null);
          }}
          type="dashed"
        >
          Add Story
        </Button>
        <Divider />
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 5,
          }}
          loadMore={
            count > data.length ? (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 12,
                  height: 32,
                  lineHeight: "32px",
                }}
              >
                <Button type="primary" onClick={() => onLoadMore()}>
                  Load more
                </Button>
              </div>
            ) : (
              <>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <div>🚀 No more stories 🚀</div>
                </div>
              </>
            )
          }
          dataSource={data}
          renderItem={(item) => (
            <Badge.Ribbon text={item.restaurant.name}>
              <List.Item
                key={item.id}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "auto",
                  overflow: "hidden",
                }}
              >
                <Card
                  title={item.title}
                  cover={<img src={item.media} alt={item.name} />}
                  contextMenu="Edit"
                  style={{
                    width: "100%",
                    borderRadius: 10,
                    padding: 10,
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  }}
                >
                  <Space direction="vertical" size={"middle"}>
                    {
                      <Tag
                        color={
                          moment(item.expired_at?.iso).isAfter(moment())
                            ? "green"
                            : "red"
                        }
                      >
                        {moment(item.expired_at?.iso).isAfter(moment())
                          ? "Active"
                          : "Expired"}
                      </Tag>
                    }

                    <div>
                      Expired at:{" "}
                      {moment(item.expired_at?.iso).format(
                        "DD-MMM-YYYY hh:mm A"
                      )}
                    </div>
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => {
                          setVisible(true);
                          setStory(item);
                        }}
                      >
                        <EditFilled />
                      </Button>
                      <Popconfirm
                        title="Delete the story? (Your action will be recorded)"
                        onConfirm={async () => {
                          const story = Parse.Object.extend("story");
                          const query = new Parse.Query(story);
                          const object = await query.get(item.objectId);
                          object.set("deleted_at", new Date());
                          object.set("deletedBy", Parse.User.current());
                          await object.save();
                          message.success("Story deleted successfully");
                          setData((prev) =>
                            prev.filter((item) => item.objectId !== object.id)
                          );
                        }}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="primary" danger>
                          <DeleteFilled />
                        </Button>
                      </Popconfirm>
                    </Space>
                  </Space>
                </Card>
              </List.Item>
            </Badge.Ribbon>
          )}
        />
        <Drawer
          title="Story Upload"
          placement="right"
          width={600}
          onClose={() => {
            setVisible(false);
            setStory(null);
          }}
          visible={visible}
        >
          <CRUDStory story={story} setData={setData} />
        </Drawer>
      </Space>
    </Card>
  );
};

export default StoryPage;
