import ProfileMenu from "./ProfileMenu";
import { Layout, Space } from "antd";
import GoBack from "../Common/GoBack";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

export default function ContentHeader({ width, onCollapse }) {
  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: "100",
        display: "flex",
        alignItems: "center",
        height: "64px",
        background: "#fff",
        borderBottom: "1px solid lightgrey",
        padding: "0 20px",
      }}
    >
      <Space>
        {width < 768 && (
          <div
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              borderRadius: "10px",
              background: "#f0f0f0",
            }}
            onClick={() => onCollapse(false)}
          >
            <MenuOutlined />
          </div>
        )}
        <GoBack />
      </Space>
      <ProfileMenu />
    </Header>
  );
}
