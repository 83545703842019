import { Form, Select } from "antd";
const { Item } = Form;

export default function Categories({ categories = [] }) {
  console.log(categories);
  return (
    <Item className="section" name="category" label="Category">
      <Select size="large" placeholder="Select category">
        {categories.map((category, index) => (
          <Select.Option key={index} value={category.objectId}>
            {category.name}
          </Select.Option>
        ))}
      </Select>
    </Item>
  );
}
