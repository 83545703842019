import { useState, useCallback, useContext } from "react";
import { getAvilColor } from "./helpers";
import { Tag, Space, Select, message } from "antd";
import { compressedImage } from "../../../common/utils";
import ProductLinks from "../../Common/ProductLinks";
import RestaurantLinks from "../../Common/RestaurantLinks";
import { CloseCircleOutlined, ExpandOutlined } from "@ant-design/icons";
import { ProductListContext } from "../../../pages/Product/ProductList";

const Availability = ({ availability, refs }) => {
  const [edit, setEdit] = useState(false);
  const { updateProducts } = useContext(ProductListContext);

  const updateHandler = useCallback(
    async (value) => {
      if (refs) {
        try {
          refs.set("availability", value);
          const saved = await refs.save();
          if (saved) {
            updateProducts((products) => {
              const product = products.results.find((product) => {
                return product.id === refs.id;
              });

              if (product) {
                product.ref = refs;
                product.availability = value;
              }

              return products;
            });
            message.success("Availability updated!");
            setEdit(false);
          }
        } catch (err) {
          message.error(err.message);
        }
      }
    },
    [refs]
  );

  const { Option } = Select;

  return (
    <div>
      {!edit && (
        <Tag
          onClick={() => setEdit(true)}
          style={{ cursor: "pointer" }}
          color={getAvilColor(availability)}
        >
          {availability}
        </Tag>
      )}
      {edit && (
        <Space align="start" size={10}>
          <Select
            defaultValue={availability}
            onChange={updateHandler}
            style={{ width: "160px" }}
          >
            <Option value="available">Available</Option>
            <Option value="unavailable">Unavailable</Option>
            <Option value="stockout">Stockout</Option>
          </Select>
          <CloseCircleOutlined
            style={{ color: "red", fontSize: "20px" }}
            onClick={() => setEdit(false)}
          />
        </Space>
      )}
    </div>
  );
};

const getColumns = ({
  openInventory,
  getColumnSearchProps,
  restaurants,
  categories,
  reqPayloads,
  restaurant,
  setProductId,
}) => {
  const mapRestaurants = (restaurants) => {
    return Array.isArray(restaurants)
      ? restaurants.map(({ name, id, hub }) => {
          return {
            text: `${name} @${hub?.get("name")}`,
            value: id,
          };
        })
      : [];
  };

  const filterFormate = (value) => {
    const res = Array.isArray(value) ? value : value ? [value] : null;
    return res;
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "100px",
      render: (image, { id }) => (
        <div>
          {image && (
            <div className="table-image">
              <img src={compressedImage(image)} alt="" />
              <div className="icon" onClick={() => setProductId(id)}>
                <ExpandOutlined className="ex" />
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "170px",
      defaultFilteredValue: filterFormate(reqPayloads.name),
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps("name"),
      render: (name, { id, restaurantId }) => (
        <Space>
          <ProductLinks id={id} restaurantId={restaurantId}>
            <div style={{ fontSize: "18px" }}>{name}</div>
          </ProductLinks>
        </Space>
      ),
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      width: "100px",
      render: (stock, { id, is_inv }) => {
        return is_inv ? (
          <Space>
            <Tag
              onClick={() => openInventory(id)}
              style={{ cursor: "pointer" }}
              color="gold"
            >
              {stock} inventory
            </Tag>
          </Space>
        ) : null;
      },
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
      width: "170px",
      defaultFilteredValue: filterFormate(reqPayloads.restaurants),
      sorter: (a, b) => a.restaurant - b.restaurant,
      filters: mapRestaurants(restaurants.data),
      onFilter: (value, record) => {
        return record.restaurantId === value;
      },
      render: (res, { restaurantId, hub }) => (
        <RestaurantLinks id={restaurantId}>
          <div>
            <div>{res || restaurant?.name}</div>
            <small>@{hub || restaurant?.hub?.name}</small>
          </div>
        </RestaurantLinks>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      defaultFilteredValue: filterFormate(reqPayloads.category),
      filters: categories.data.map((category) => ({
        text: category.name,
        value: category.id,
      })),
      width: "150px",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "100px",
      sorter: (a, b) => a.price - b.price,
      ...getColumnSearchProps("price"),
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      width: "180px",
      defaultFilteredValue: filterFormate(reqPayloads.availability),
      filters: [
        { text: "Available", value: "available" },
        { text: "Unavailable", value: "unavailable" },
        { text: "Out of Stock", value: "stockout" },
      ],
      onFilter: (value, record) => {
        return record.availability === value;
      },
      render: (_, record) => <Availability {...record} />,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: "100px",
      sorter: (a, b) => a.discount - b.discount,
      render: (discount, { discountType }) => (
        <span style={{ fontWeight: "600" }}>
          {discount}{" "}
          <span style={{ fontSize: "12px", fontWeight: "800" }}>
            {discountType === "percent" ? "%" : "৳"}
          </span>{" "}
        </span>
      ),
    },
  ];
  return columns;
};

export default getColumns;
