import { useState, useEffect, useContext } from "react";
import { CreateContext } from "../../../pages/Product/Create";
import {
  Form,
  message,
  Col,
  Select,
  Space,
  Input,
  notification,
  Card,
  Tag,
} from "antd";
import ProductName from "./form/ProductName";
import Restaurant from "./form/Restaurant";
import Category from "./form/Category";
import Pricing from "./form/Pricing";
import AddImage from "./form/AddImage";
import Description from "./form/Description";
import Footer from "./form/Footer";
import { useHistory } from "react-router-dom";
import Skeleton from "./form/Skeleton";
import MetaInfo from "./form/MetaInfo";
import Addons from "./form/Addons";
import Variants from "./form/Variants";
import "./styles.scss";
import moment from "moment";
import Boxer, { Box } from "../../Common/Boxer";
import { randomId } from "../../../utils";
import Parse from "parse";
import List from "../../../pages/Restaurant/List";

const { Item } = Form;
const { Option } = Select;

export default function Create({ productId }) {
  const {
    dispatch,
    categories,
    restaurants,
    product,
    updateProduct,
    getProductById,
    getCategories,
    getRestaurants,
    addProductFunc,
  } = useContext(CreateContext);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [media, setMedia] = useState({
    visible: false,
    images: [],
    loading: false,
    fileList: [],
    multiple: true,
  });
  const {
    location: { search },
  } = useHistory();
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(search);
  const id = productId || query.get("id");

  const onChange = (e) => {
    setFormData({ ...form.getFieldsValue() });
  };

  const resetForm = () => {
    form.resetFields();
    setMedia({ ...media, images: [] });
    setFormData({});
  };

  const onFinish = (values) => {
    // console.log(values);
    // return;
    setLoading(true);

    if (values.price?.discount?.validity) {
      values.price.discount.validity = new Date(
        values.price.discount.validity
      ).toISOString();
    }

    if (values.addons) {
      if (
        values.addons.items?.length > 0 &&
        (values.addons.min === undefined || values.addons.max === undefined)
      ) {
        message.error("Please fill the addons min and max");
        setLoading(false);
        return;
      }
    }

    if (
      Array.isArray(values.price.variants) &&
      values.price.variants.length > 0
    ) {
      values.price.variants.forEach((variant) => {
        variant.items?.forEach((item) => {
          delete item.stock;
          if (item.total) {
            item.price = item.total - values.price.amount;
          }
          if (!item.id) {
            item.id = randomId();
          }

          if (typeof item.availability === "string") {
            item.availability = true;
          }
        });
      });

      const notMinMax = values.price.variants.find((v) => {
        return v.min === undefined || v.max === undefined;
      });

      if (notMinMax) {
        message.error("Please fill the variants min and max field!");
        setLoading(false);
        return;
      }

      if (values.price.type === "flat") {
        message.error("Price type should be variant!");
        setLoading(false);
        return;
      }
    }

    if (!product.data) {
      if (values.addons && Object.keys(values.addons).length === 0) {
        delete values.addons;
      }
      addProductFunc({ ...values, images: media.images }, (err, res) => {
        setLoading(false);
        if (err) {
          message.error(err);
        } else {
          resetForm();
        }
      });
    } else {
      // update product here
      updateProduct(id, { ...values, images: media.images }, (err) => {
        setLoading(false);
        if (err) {
          notification.error({
            title: "Error",
            message: err,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      getProductById(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && product.data) {
      const { data } = product;
      const {
        name,
        details,
        approval_status,
        price,
        addons,
        restaurant,
        category,
        images,
        meta_title,
        meta_description,
        meta_tags,
        availability,
      } = data;

      if (price?.discount?.validity) {
        price.discount.validity = moment(
          new Date(price.discount.validity),
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      price?.variants?.forEach((variant) => {
        variant.items?.forEach((item) => {
          item.total = (item.price ?? 0) + price.amount;
        });
      });

      const updatedFormData = {
        name,
        restaurant: restaurant?.id,
        category: category?.id,
        details,
        price,
        addons,
        meta_title,
        meta_description,
        meta_tags,
        approval_status,
        availability,
      };
      form.setFieldsValue(updatedFormData);
      setFormData(updatedFormData);

      if (images && Array.isArray(images) && images.length > 0) {
        media.images = images;
        setMedia({ ...media });
      }
    } else if (!id && product.data) {
      dispatch({ type: "GET_PRODUCT_RESET" });
      resetForm();
    }
  }, [product.data, id]);

  useEffect(() => {
    getCategories();
    if (!id) {
      getRestaurants({
        select: ["objectId", "name", "banner_image", "vat", "hub.name"],
      });
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...formData });
  }, [formData]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  const [productLogs, setProductLogs] = useState([]);

  useEffect(() => {
    const getProductLogs = async () => {
      try {
        const logs = await new Parse.Query("logs")
          .equalTo("target", productId)
          .select([
            "msg",
            "createdAt",
            "target",
            "username",
            "changes",
            "previous",
          ])
          .descending("createdAt")
          .find();

        setProductLogs(logs.map((log) => log.toJSON()));
      } catch (err) {
        message.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (productId) {
      getProductLogs();
    }
  }, [productId]);

  return (
    <div className="add-product-form">
      {!product.loading && (
        <Form
          form={form}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          onChange={onChange}
          onFinish={onFinish}
          size="large"
          initialValues={{
            price: {
              type: "flat",
              amount: 0,
            },
          }}
        >
          <div className="header">
            <h2 className="form-title">
              {!product.data ? "Add Product" : "Update product"}
            </h2>
          </div>
          <Boxer className="parent-row">
            {id && (
              <Col xs={24}>
                <Space size={30}>
                  <Item name="approval_status" label="Approval Status">
                    <Select
                      placeholder="Select approval status"
                      style={{ width: "200px" }}
                    >
                      <Option value="pending">Pending</Option>
                      <Option value="approved">Approved</Option>
                    </Select>
                  </Item>
                  <Item name="availability" label="Availability">
                    <Select
                      placeholder="Select Availability"
                      style={{ width: "200px" }}
                    >
                      <Option value="available">Available</Option>
                      <Option value="unavailable">Unavailable</Option>
                      <Option value="stockout">Stock out</Option>
                    </Select>
                  </Item>
                  {product.data && (
                    <Item label="Product Sold">
                      <Input
                        value={product.data.sold}
                        disabled
                        style={{ background: "#fff", width: "120px" }}
                      />
                    </Item>
                  )}
                </Space>
              </Col>
            )}
            <AddImage state={media} setState={setMedia} />
            <Box title="Primary Section">
              <ProductName />
              <Restaurant
                isNew={product.data && formData.restaurant ? false : true}
                restaurants={restaurants}
                getRestaurants={getRestaurants}
                formData={formData}
                setFormData={setFormData}
              />
              <Category categories={categories} getCategories={getCategories} />
              <Description />
            </Box>
            <Pricing formData={formData} form={form} />
            <MetaInfo />
            <Variants
              formData={formData}
              setFormData={setFormData}
              data={formData.price}
              form={form}
            />
            <Addons addons={formData?.addons} />
            <Footer
              resetForm={resetForm}
              isNew={!product.data ? true : false}
              loading={loading}
            />
            {id && (
              <Card
                title="Product logs"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxWidth: "100%",
                  }}
                >
                  {productLogs.map((log) => (
                    <Tag
                      color="blue"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        padding: "10px",
                        maxWidth: "100%",
                      }}
                      key={log.id}
                    >
                      <div style={{ maxWidth: "100%", wordWrap: "break-word" }}>
                        {/* Set maximum width for the content */}
                        <Tag color="orange">{log.msg}</Tag>
                        <div
                          style={{ maxWidth: "100%", wordWrap: "break-word" }}
                        >
                          <strong>Updated:</strong>{" "}
                          <span style={{ wordWrap: "break-word" }}>
                            {JSON.stringify(log.changes)}
                          </span>
                        </div>
                        <div style={{ wordWrap: "break-word" }}>
                          <strong>Previous:</strong>{" "}
                          <span>{JSON.stringify(log.previous)}</span>
                        </div>
                        <Tag color="green">By {log.username}</Tag>
                      </div>

                      <div>
                        <p>
                          {moment(log.createdAt).format("DD MMM YYYY hh:mm A")}
                        </p>
                      </div>
                    </Tag>
                  ))}
                </div>
              </Card>
            )}
          </Boxer>
        </Form>
      )}
      {product.loading && <Skeleton />}
    </div>
  );
}
