import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Area } from "@ant-design/plots";

export default function OrdersPerHour() {
  const { data } = useContext(SalesReportContext);

  const config = {
    data: data.ordersPerHour.map((item) => ({
      label:
        (item.hour % 12 === 0 ? "12" : item.hour % 12) +
        (item.hour >= 12 ? " PM" : " AM"),
      value: Number(item.percentage.replace("%", "")),
    })),
    xField: "label",
    yField: "value",
    smooth: true,
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      };
    },
  };

  return (
    <div id="orders-per-hour">
      <h2>Orders Per Hour</h2>
      <Area {...config} />
    </div>
  );
}
