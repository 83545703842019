import { createContext, useEffect, useReducer } from "react";
import Parse from "parse";
import { message } from "antd";
import useHub from "../../hooks/useHub";
import { parser } from "../../utils";
import moment from "moment";

export const Types = {
  SECTIONS_REQUEST: "SECTIONS_REQUEST",
  SECTIONS_SUCCESS: "SECTIONS_SUCCESS",
  SECTIONS_FAILURE: "SECTIONS_FAILURE",
  CREATE_SECTION_REQUEST: "CREATE_SECTION_REQUEST",
  CREATE_SECTION_SUCCESS: "CREATE_SECTION_SUCCESS",
  CREATE_SECTION_FAILURE: "CREATE_SECTION_FAILURE",
  RESTAURANTS_REQUEST: "RESTAURANTS_REQUEST",
  RESTAURANTS_SUCCESS: "RESTAURANTS_SUCCESS",
  RESTAURANTS_FAILURE: "RESTAURANTS_FAILURE",
  UPDATE_SECTION_NAME_REQUEST: "UPDATE_SECTION_NAME_REQUEST",
  UPDATE_SECTION_NAME_SUCCESS: "UPDATE_SECTION_NAME_SUCCESS",
  UPDATE_SECTION_NAME_FAILURE: "UPDATE_SECTION_NAME_FAILURE",
  DELETE_SECTION_REQUEST: "DELETE_SECTION_REQUEST",
  DELETE_SECTION_SUCCESS: "DELETE_SECTION_SUCCESS",
  DELETE_SECTION_FAILURE: "DELETE_SECTION_FAILURE",
  DRAG_DROP: "DRAG_DROP",
  DRAG_DROP_RESET: "DRAG_DROP_RESET",
  SET_HUBS: "SET_HUBS",
};

const initialState = {
  sections: {
    loading: false,
    data: [],
    error: null,
  },
  newSection: {
    loading: false,
    data: null,
    error: null,
  },
  restaurants: {
    loading: false,
    data: [],
    error: null,
  },
  updateSectionName: {
    loading: false,
    data: null,
    error: null,
  },
  dragDrop: {
    dragging: false,
    draggableId: null,
    source: null,
    destination: null,
  },
  hubs: {
    loading: false,
    data: [],
    error: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.SECTIONS_REQUEST:
      state.sections.loading = true;
      return { ...state };
    case Types.SECTIONS_SUCCESS:
      state.sections.loading = false;
      state.sections.data = action.payload;
      return { ...state };
    case Types.SECTIONS_FAILURE:
      state.sections.loading = false;
      state.sections.error = action.payload;
      return { ...state };
    case Types.CREATE_SECTION_REQUEST:
      state.newSection.loading = true;
      return { ...state };
    case Types.CREATE_SECTION_SUCCESS:
      return { ...action.payload };
    case Types.CREATE_SECTION_FAILURE:
      state.newSection.loading = false;
      state.newSection.error = action.payload;
      return { ...state };
    case Types.RESTAURANTS_REQUEST:
      state.restaurants.loading = true;
      return { ...state };
    case Types.RESTAURANTS_SUCCESS:
      state.restaurants.loading = false;
      state.restaurants.data = action.payload;
      return { ...state };
    case Types.RESTAURANTS_FAILURE:
      state.restaurants.loading = false;
      state.restaurants.error = action.payload;
      return { ...state };
    case Types.UPDATE_SECTION_NAME_REQUEST:
      state.updateSectionName.loading = true;
      return { ...state };
    case Types.UPDATE_SECTION_NAME_SUCCESS:
      state.updateSectionName.loading = false;
      return { ...state };
    case Types.UPDATE_SECTION_NAME_FAILURE:
      state.updateSectionName.loading = false;
      state.updateSectionName.error = action.payload;
      return { ...state };
    case Types.DRAG_DROP:
      return { ...state, dragDrop: action.payload };
    case Types.DRAG_DROP_RESET:
      return { ...state, dragDrop: initialState.dragDrop };
    case Types.SET_HUBS:
      state.hubs.data = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export const SectionsContext = createContext();

export default function SectionsContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getHubs } = useHub();

  const parseRes = (res) => {
    return res.map((section) => {
      section = parser(section);
      const items = section.items;
      if (items) {
        section.items = parser(items);
      }

      return section;
    });
  };

  const parseSectionForDB = ({ items, id, sort_order, expire_date }) => {
    return expire_date
      ? {
          sectionId: id,
          sort_order,
          items: items.map((i) => i.id),
          expire_date,
        }
      : {
          sectionId: id,
          sort_order,
          items: items.map((i) => i.id),
        };
  };

  const getSections = async () => {
    dispatch({ type: Types.SECTIONS_REQUEST });
    try {
      const res = await new Parse.Query("section")
        .select([
          "items.name",
          "items.availability",
          "items.banner_image",
          "items.hub.name",
          "name",
          "activeHours",
          "published",
          "sort_order",
          "hubs.name",
          "areas",
          "expire_date",
        ])
        .find();
      if (res && Array.isArray(res)) {
        res.sort((a, b) => a.get("sort_order") - b.get("sort_order"));
        res.forEach((section) => {
          const expire_date = section.get("expire_date");
          if (expire_date && Array.isArray(expire_date)) {
            section.get("items").forEach((item, index) => {
              const expiredItemIndex = expire_date.findIndex(
                (expireItem) => expireItem.objectId === item.id
              );
              if (expiredItemIndex !== -1) {
                const expireDate = moment(
                  expire_date[expiredItemIndex].expire_date
                );
                if (expireDate.isBefore(moment())) {
                  // Remove the expired item from items array
                  section.get("items").splice(index, 1);
                  // Remove the expired item from expire_date array
                  expire_date.splice(expiredItemIndex, 1);
                }
              }
            });
          }
        });

        dispatch({ type: Types.SECTIONS_SUCCESS, payload: parseRes(res) });
        await updateSection(parseSectionForDB(res));
      }
    } catch (error) {
      dispatch({ type: Types.SECTIONS_FAILURE, payload: error });
    }
  };

  const updateSection = async (data, callback) => {
    try {
      const update = await Parse.Cloud.run("updateSection", data);
      if (update && callback) {
        callback(update);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const createSection = async ({ name, hubs, activeHours, resetForm }) => {
    dispatch({ type: Types.CREATE_SECTION_REQUEST });
    try {
      let Section = Parse.Object.extend("section");
      let section = new Section();
      section.set("name", name);
      section.set("activeHours", activeHours);

      if (hubs) {
        section.set(
          "hubs",
          hubs.map((id) => Parse.Object.extend("hub").createWithoutData(id))
        );
      }

      section.set("sort_order", 0);
      section.set("items", []);
      section = await section.save();

      const newSection = {
        ...parser(section),
        hubs: state.hubs.data.filter((h) => hubs.includes(h.id)),
      };

      state.newSection.loading = false;
      state.sections.data.unshift(newSection);

      dispatch({ type: Types.CREATE_SECTION_SUCCESS, payload: state });
      resetForm();
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.CREATE_SECTION_FAILURE, payload: err });
    }
  };

  const getRestaurants = async ({ limit, skip, search } = {}) => {
    if (!limit) limit = 50;
    if (!skip) skip = 0;
    if (!search) search = "";

    dispatch({ type: Types.RESTAURANTS_REQUEST });
    try {
      const query = new Parse.Query("restaurant");
      if (search) {
        query.matches("name", search, "i");
      }
      query.limit(limit);
      query.skip(skip);
      query.select(
        "objectId",
        "name",
        "hub.name",
        "banner_image",
        "availability"
      );

      const res = await query.find();

      if (res && res.length > 0 && state.restaurants.data.length !== 0) {
        const resObj = {};
        state.restaurants.data.forEach((r) => {
          if (!resObj[r.id]) {
            resObj[r.id] = r;
          }
        });

        res.forEach((r) => {
          if (!resObj[r.id]) {
            state.restaurants.data.push(r);
          }
        });

        dispatch({
          type: Types.RESTAURANTS_SUCCESS,
          payload: state.restaurants.data,
        });
      } else if (res && Array.isArray(res) && res.length > 0) {
        dispatch({ type: Types.RESTAURANTS_SUCCESS, payload: res });
      }
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.RESTAURANTS_FAILURE, payload: err });
    }
  };

  const addNewItemToSection = async (sectionId, items, done) => {
    if (!sectionId || !items) return;

    try {
      const idx = state.sections.data.findIndex(
        (item) => item.id === sectionId
      );

      if (idx < 0) {
        return message.error("Section not found");
      }

      const section = state.sections.data[idx];
      const newItems = await new Parse.Query("restaurant")
        .select("objectId", "name", "hub.name", "banner_image", "availability")
        .containedIn("objectId", items)
        .find();

      section.items = section.items.concat(parser(newItems));

      dispatch({ type: Types.SECTIONS_SUCCESS, payload: state.sections.data });
      updateSection(parseSectionForDB(section));

      if (done) {
        done();
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const updateSectionNameFunc = async (
    sectionId,
    { name, hubs, areas, activeHours },
    callback
  ) => {
    dispatch({ type: Types.UPDATE_SECTION_NAME_REQUEST });
    try {
      const section = state.sections.data.find((s) => s.id === sectionId);
      if (!section) {
        message.error("Section not found");
        return;
      }

      section.ref.set("name", name);
      section.name = name;

      section.ref.set("activeHours", activeHours);
      section.activeHours = activeHours;

      section.ref.set(
        "hubs",
        hubs.map((h) => Parse.Object.extend("hub").createWithoutData(h))
      );
      section.hubs = state.hubs.data.filter((h) => hubs.includes(h.id));
      section.areas = areas;
      section.ref.set("areas", areas);

      await section.ref.save();

      message.success(`Section updated`);

      dispatch({ type: Types.UPDATE_SECTION_NAME_SUCCESS });

      if (callback) {
        callback();
      }

      dispatch({ type: Types.SECTIONS_SUCCESS, payload: state.sections.data });
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.UPDATE_SECTION_NAME_FAILURE, payload: err });
    }
  };

  const deleteSectionFunc = async (sectionId) => {
    try {
      const idx = state.sections.data.findIndex((s) => s.id === sectionId);
      if (idx < 0) {
        message.error("Section not found");
        return;
      }

      const query = new Parse.Query("section");
      query.equalTo("objectId", sectionId);
      let res = await query.first();
      if (res) {
        res = await res.destroy();
        message.success(`Section deleted!`);
      }

      state.sections.data.splice(idx, 1);
      dispatch({ type: Types.SECTIONS_SUCCESS, payload: state.sections.data });
    } catch (err) {
      message.error(err.message);
    }
  };

  const publishSectionFunc = async (sectionId, published) => {
    try {
      const idx = state.sections.data.findIndex(
        (item) => item.id === sectionId
      );
      if (idx < 0) {
        message.error("Section not found");
        return;
      }

      const res = state.sections.data[idx];
      if (res) {
        res.ref.set("published", published);
        res.published = published;
        await res.ref.save();
        message.success(`Section ${published ? "published" : "unpublished"}!`);
      }

      dispatch({ type: Types.SECTIONS_SUCCESS, payload: state.sections.data });
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchHubs = async () => {
    getHubs({ select: ["name"] }, (err, data) => {
      if (data) {
        dispatch({
          type: Types.SET_HUBS,
          payload: parser(data.results),
        });
      }
    });
  };

  const deleteSectionItem = async (sectionId, itemId) => {
    try {
      const idx = state.sections.data.findIndex((s) => s.id === sectionId);
      if (idx < 0) {
        message.error("Section not found");
        return;
      }
      const section = state.sections.data[idx];
      const itemIdx = section.items.findIndex((i) => i.id === itemId);
      if (itemIdx < 0) {
        message.error("Item not found");
        return;
      }

      section.items.splice(itemIdx, 1);
      dispatch({ type: Types.SECTIONS_SUCCESS, payload: state.sections.data });

      updateSection(parseSectionForDB(section));
    } catch (err) {
      message.error(err.message);
    }
  };

  const updateSectionItemExpireDate = async (
    sectionId,
    itemsId = [],
    expireDate
  ) => {
    try {
      const idx = state.sections.data.findIndex((s) => s.id === sectionId);
      if (idx < 0) {
        message.error("Section not found");
        return;
      }
      const section = state.sections.data[idx];
      if (!section.expire_date) {
        section.expire_date = [];
      }
      itemsId.forEach((itemId) => {
        // Check if itemId exists in the expire_date array
        const itemIndex = section.expire_date?.findIndex(
          (item) => item.objectId === itemId
        );
        if (itemIndex !== -1) {
          section.expire_date[itemIndex].expire_date = expireDate;
        } else {
          section.expire_date.push({
            objectId: itemId,
            expire_date: expireDate,
          });
        }
      });

      dispatch({ type: Types.SECTIONS_SUCCESS, payload: state.sections.data });
      updateSection(parseSectionForDB(section));
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchHubs();
  }, []);

  return (
    <SectionsContext.Provider
      value={{
        ...state,
        getSections,
        dispatch,
        parseSectionForDB,
        updateSection,
        createSection,
        addNewItemToSection,
        getRestaurants,
        updateSectionNameFunc,
        deleteSectionFunc,
        publishSectionFunc,
        deleteSectionItem,
        updateSectionItemExpireDate,
      }}
    >
      {children}
    </SectionsContext.Provider>
  );
}
