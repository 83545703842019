import React, { useState } from "react";
import { Col, Row, Tree, Typography } from "antd";
import ZoneMaps from "../../components/maps";
import "./customTree.css";
const DeliveryAreaRestaurant = ({
  zones,
  setAreas,
  height,
  areas = [],
  handleOnCheck,
}) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState(areas);

  const generateTreeData = (data) => {
    return data.map((zone) => ({
      title: zone.name,
      key: zone._id,
      children: zone.areas.map((area) => ({
        title: handleOnCheck ? (
          <div>
            <Typography.Title level={5}>{area.name}</Typography.Title>
            <Typography.Text type="secondary">{area.updatedBy}</Typography.Text>
          </div>
        ) : (
          area.name
        ),
        value: area._id,
        key: area._id,
      })),
    }));
  };

  // Render changes on areas change
  React.useEffect(() => {
    setCheckedKeys(areas);
  }, [areas]);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const onCheck = (checkedKeysValue) => {
    const zonesIds = zones.map((zone) => zone._id);

    const checkedZones = checkedKeysValue.filter(
      (key) => !zonesIds.includes(key)
    );

    // find the area that is unchecked
    const uncheckedArea = checkedKeys.filter(
      (key) => !checkedKeysValue.includes(key)
    );
    // find the new areas that are checked
    const newCheckedAreas = checkedZones.filter(
      (key) => !checkedKeys.includes(key)
    );

    if (handleOnCheck) {
      handleOnCheck(uncheckedArea, newCheckedAreas);
    }

    setAreas(checkedZones);
    setCheckedKeys(checkedZones);
  };

  const treeData = generateTreeData(zones);

  return (
    <Row gutter={16}>
      <Col span={10}>
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          onSelect={onSelect}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          selectedKeys={selectedKeys}
          treeData={treeData}
          className="custom-tree"
        />
      </Col>
      <Col span={14}>
        <ZoneMaps zones={zones} selectedAreas={checkedKeys} />
      </Col>
    </Row>
  );
};

export default DeliveryAreaRestaurant;
