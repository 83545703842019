import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Drawer,
  List,
  Skeleton,
  Space,
  Switch,
  Tag,
  message,
} from "antd";
import Parse from "parse";
import NoticeCRUD from "./AddUpdateNotice";
import { AppNoticeDto } from "./AppNoitce.dto";
import moment from "moment";

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AppNoticeDto[]>([]);
  const [limit, _] = useState(10);
  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(-1);
  const [open, setOpen] = useState(false);
  const [notice, setNotice] = useState<AppNoticeDto | undefined>(undefined);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onDelete = async (id: string) => {
    const Notice = await new Parse.Query("app_notice")
      .equalTo("objectId", id)
      .first();
    Notice?.set("user", Parse.User.current());
    Notice?.set("deletedAt", new Date());
    Notice?.save()
      .then((res) => {
        message.success("Notice deleted successfully");
        onClose();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const parse = new Parse.Query("app_notice")
      .descending("createdAt")
      .greaterThanOrEqualTo("expiresAt", new Date())
      .include("user")
      .select(
        "description",
        "expiresAt",
        "user.username",
        "deletedAt",
        "active"
      )
      .skip(skip)
      .limit(limit);

    if (count === -1) {
      parse
        .count()
        .then((count) => {
          setCount(0);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    parse
      .find()
      .then((results) => {
        setData(results.map((e) => e.toJSON()) as unknown as AppNoticeDto[]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  const handleActiveSwitch = (checked: boolean, item: AppNoticeDto) => {
    new Parse.Query("app_notice")
      .equalTo("objectId", item.objectId)
      .first()
      .then((res) => {
        res?.set("active", checked);
        res?.set("user", Parse.User.current());
        res
          ?.save()
          .then((res) => {
            message.success(
              `Notice ${checked ? "activated" : "deactivated"} successfully`
            );
            setData(
              data.map((e) => {
                if (e.objectId === item.objectId) {
                  e.active = checked;
                  e.user = Parse.User.current()?.toJSON() as any;
                }
                return e;
              })
            );
          })
          .catch((err) => {
            message.error(err.message);
          });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <Card
      title="Notice board"
      extra={
        <Button
          type="primary"
          onClick={() => {
            showDrawer();
          }}
        >
          Add
        </Button>
      }
    >
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
        }}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={() => {
            setSkip(skip + limit);
            loadMoreData();
          }}
          hasMore={data.length < count}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item.objectId}>
                <List.Item.Meta
                  title={item.description}
                  description={
                    <Space direction="horizontal">
                      <Tag color="orange">
                        Expires at:
                        {moment(item.expiresAt?.iso).format(
                          "DD-MMM-YYYY hh:mm:ss A"
                        )}
                      </Tag>
                      <Tag color="blue">
                        Updated by: {item?.user?.username ?? ""}
                      </Tag>
                      {item.deletedAt && (
                        <Tag color="red">Deleted at: {item.deletedAt.iso}</Tag>
                      )}
                    </Space>
                  }
                />
                <Space>
                  {/* <Button
                    type="primary"
                    onClick={() => {
                      setNotice(item);
                      setOpen(true);
                    }}
                  >
                    {" "}
                    Edit
                  </Button> */}
                  <Switch
                    checked={item.active}
                    onChange={(checked) => handleActiveSwitch(checked, item)}
                  />
                  <Button
                    type="default"
                    onClick={() => {
                      onDelete(item.objectId);
                    }}
                  >
                    Delete
                  </Button>
                </Space>
              </List.Item>
            )}
          />
        </InfiniteScroll>
        <Drawer
          title="Notice board Add/Update"
          onClose={onClose}
          visible={open}
          width={600}
        >
          <NoticeCRUD
            notice={notice}
            onClose={() => {
              setNotice(undefined);
              onClose();
            }}
          />
        </Drawer>
      </div>
    </Card>
  );
};

export default App;
