import { useContext, useState } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import EditMode from "./EditMode";
import { Select, Space, Button, message, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import useOrder from "../../../hooks/useOrders";
import styled from "styled-components";
import OrderReject from "../OrderReject";
import { Link } from "react-router-dom";
import OrderCancel from "../OrderCancel";
import { localDateTime } from "../../../utils";

const statuses = [
  "created",
  "confirmed",
  "preparing",
  "ready",
  "picked",
  "delivered",
  "cancelled",
  "rejected",
];

const Status = () => {
  const [edit, setEdit] = useState(false);
  const { order, dispatch, Types } = useContext(DetailsContext);
  const {
    status: defaultStatus,
    payment_method,
    rejected_for,
    rejection_reason,
  } = order.data;
  const [status, setStatus] = useState(defaultStatus);
  const { updateStatus } = useOrder();
  const [loading, setLoading] = useState(false);
  const [rejectBtnHandler, setRejectBtnHandler] = useState(false);
  const [cancelBtnHandler, setCancelBtnHandler] = useState(false);

  const updateHandler = () => {
    if (status === "rejected") {
      setRejectBtnHandler(true);
      setEdit(false);
      return;
    } else if (status === "cancelled") {
      setCancelBtnHandler(true);
      setEdit(false);
      return;
    } else {
      setLoading(true);

      updateStatus(
        {
          orderId: order.data.id,
          status,
        },
        (err, res) => {
          setLoading(false);
          if (err) {
            message.error(err);
          }

          if (res) {
            order.data.status = status;
            dispatch({ type: Types.ORDER_SUCCESS, payload: order.data });
            setEdit(false);
            message.success("Status updated");
          }
        }
      );
    }
  };

  return (
    <div className="status">
      <div>Status: </div>
      {edit && (
        <div style={{ marginTop: "10px" }}>
          <Select
            size="large"
            onChange={(e) => setStatus(e)}
            defaultValue={defaultStatus}
            style={{ width: "200px" }}
            placeholder="Select Status"
          >
            {statuses.map((s, i) => {
              return (
                <Select.Option
                  key={i}
                  style={{ textTransform: "capitalize" }}
                  value={s}
                  disabled={payment_method !== "cod" && s === "pending"}
                >
                  {s}
                </Select.Option>
              );
            })}
          </Select>
          <div style={{ marginTop: "10px" }}>
            <Space>
              <Button onClick={() => setEdit(false)} danger>
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                onClick={updateHandler}
                type="primary"
              >
                Update
              </Button>
            </Space>
          </div>
        </div>
      )}
      {!edit && (
        <Space>
          <div
            style={{
              background: "lightslategray",
              height: "34px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "120px",
              color: "white",
              borderRadius: "3px",
              fontSize: "16px",
              textTransform: "capitalize",
            }}
          >
            {defaultStatus}
          </div>
          <Button
            size="middle"
            onClick={() => setEdit(true)}
            type="primary"
            style={{ display: "flex", alignItems: "center" }}
          >
            <EditOutlined className="icon" />
            <span>Edit</span>
          </Button>
        </Space>
      )}

      <OrderReject
        visible={rejectBtnHandler}
        setVisible={setRejectBtnHandler}
        orderId={order.data.id}
      />
      <OrderCancel
        visible={cancelBtnHandler}
        setVisible={setCancelBtnHandler}
        orderId={order.data.id}
      />

      {(rejected_for || rejection_reason) && (
        <div style={{ marginTop: "10px" }}>
          {rejected_for && (
            <Space>
              Rejected For: <div style={{ color: "red" }}>{rejected_for}</div>
            </Space>
          )}
          {rejection_reason && (
            <Space>
              Reject Reason:{" "}
              <div style={{ color: "red" }}>{rejection_reason}</div>
            </Space>
          )}
        </div>
      )}
    </div>
  );
};

export default function OrderHeader() {
  const { order, userOrderCount } = useContext(DetailsContext);
  const { id, hub } = order.data;
  const { name: hubName, objectId: hubId } = hub?.toJSON() ?? {};

  return (
    <Wrapper className="order-header">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 style={{ marginBottom: 0 }} className="id">
          Order ID #<Typography.Text copyable>{id}</Typography.Text>
        </h3>
      </div>
      {userOrderCount && (
        <Space className="order-count">
          <div className="title">Order no: </div>
          <div className="no">{userOrderCount}</div>
        </Space>
      )}
      <div className="hub">
        <span>Hub: </span>
        <Link to={`/hub/create-new?id=${hubId}`}>{hubName}</Link>
      </div>
      <div className="utility">
        <Status />
        <div>
          <div>{localDateTime(order.data.createdAt)} : Create</div>
          {order.data.completedAt && (
            <div>{localDateTime(order.data.completedAt)} : Complete</div>
          )}
        </div>
      </div>
      <EditMode />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .hub {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    a {
      color: #e91e63;
    }
  }
  .utility {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
  }

  .order-count {
    font-size: 18px;
    .title {
      font-weight: 500;
    }
  }
`;
