import { Form, Radio, Space } from "antd";
const { Item } = Form;

export default function PricingType() {
  return (
    <Item name={["price", "type"]} label="Pricing Type">
      <Radio.Group>
        <Space>
          <Radio value="flat">Flat</Radio>
          <Radio value="variant">Variant</Radio>
        </Space>
      </Radio.Group>
    </Item>
  );
}
