import { Button, Card, DatePicker, Form, Input, message } from "antd";
import React, { useState } from "react";
import Parse from "parse";
import { AppNoticeDto } from "./AppNoitce.dto";

interface Props {
  notice?: AppNoticeDto;
  onClose: () => void;
}
const NoticeCRUD: React.FC<Props> = ({ onClose, notice }) => {
  const [form, setForm] = useState({
    description: notice?.description ?? "",
    expiresAt: notice?.expiresAt?.iso ? new Date(notice.expiresAt.iso) : "",
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    let Notice = null;
    if (notice) {
      Notice = await new Parse.Query("app_notice")
        .equalTo("objectId", notice.objectId)
        .first();
    } else {
      Notice = new Parse.Object("app_notice");
    }
    Notice?.set("description", form.description);
    Notice?.set("expiresAt", new Date(form.expiresAt));
    Notice?.set("user", Parse.User.current());
    Notice?.set("active", true);
    Notice?.set("deletedAt", null);

    Notice?.save()
      .then((res) => {
        setLoading(false);
        message.success("Notice saved successfully");
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Card
      title="Please add/update your notice and save"
      extra={
        <Button
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      }
    >
      <Form>
        <Form.Item
          hasFeedback
          label="Description"
          name="description"
          validateFirst
          rules={[
            {
              required: true,
              message: "Please enter notice",
            },
            {
              max: 100,
              message: "Notice must be less than 100 characters",
            },
            {
              min: 10,
              message: "Notice must be more than 10 characters",
            },
          ]}
        >
          <Input
            placeholder="Please enter notice"
            value={notice?.description ?? form.description}
            onChange={(e) => {
              setForm({
                ...form,
                description: e.target.value,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Expires at"
          name="expiresAt"
          validateFirst
          rules={[
            {
              required: true,
              message: "Please enter expires at",
            },
          ]}
        >
          <DatePicker
            showTime
            onChange={(date, dateString) => {
              setForm({
                ...form,
                expiresAt: dateString,
              });
            }}
            format="DD-MMM-YYYY hh:mm:ss A"
            placeholder="Please select time"
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default NoticeCRUD;
