import { useContext, useState } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Col, Input, Row, Space, Switch } from "antd";

const OrderItem = ({
  name,
  sale_unit,
  discount,
  quantity,
  total,
  variant,
  addons,
}) => {
  return (
    <div className="order-item">
      <h2 className="title-1">{name}</h2>
      <div className="price">
        Price: {quantity} x {sale_unit} = {total} tk
      </div>
      <div className="discount">Discount: {discount} tk</div>
      {variant && variant.length > 0 && (
        <div className="variants">
          <h3 className="title-2">Variant</h3>
          {variant.map(({ variantId, items }) => {
            return (
              <div key={variantId}>
                {items.map((item) => {
                  return (
                    <div key={item.id} className="v-item">
                      <div className="v-name">{item.name}</div>
                      <div className="v-price">{item.price} tk</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {addons && addons.length > 0 && (
        <div className="variants">
          <h3 className="title-2">Addons</h3>
          {addons.map((item) => {
            return (
              <div key={item.id} className="v-item">
                <h3 className="v-name">{item.name}</h3>
                <div className="v-price">{item.price} tk</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default function RestaurantSalesReportOrders({ setOrderId }) {
  const { data } = useContext(SalesReportContext);
  const [search, setSearch] = useState("");
  const [seeDetails, setSeeDetails] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const onSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value === "") {
      setSearchResult([]);
      return;
    }

    const result = data.orders?.filter((order) => {
      const { id, orderNumber, items } = order;

      if (id.toLowerCase().includes(value.toLowerCase())) {
        return true;
      } else if (orderNumber.toLowerCase().includes(value.toLowerCase())) {
        return true;
      } else {
        const found = items.some((item) => {
          if (item.name.toLowerCase().includes(value.toLowerCase())) {
            return true;
          } else if (item.variant?.length) {
            const found = item.variant.some((v) => {
              return v.items.some((i) =>
                i.name?.toLowerCase().includes(value.toLowerCase())
              );
            });

            if (found) return true;
          } else if (item.addons?.length) {
            const found = item.addons.some((a) =>
              a.name?.toLowerCase().includes(value.toLowerCase())
            );
            if (found) return true;
          }

          return false;
        });

        if (found) return true;
      }

      return false;
    });

    setSearchResult(result);
  };

  const InfoItem = ({ title, value }) => (
    <div className="item">
      <div className="value">{value}</div>
      <div className="title">{title}</div>
    </div>
  );

  return (
    <div className="orders" id="orders">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ marginBottom: 0 }}>Orders</h2>
        <Space>
          <Switch checked={seeDetails} onChange={(e) => setSeeDetails(e)} />
          <Input onChange={onSearch} value={search} placeholder="Search" />
        </Space>
      </div>

      {(searchResult?.length ? searchResult : false || data.orders)?.map(
        ({
          id,
          orderNumber,
          items,
          createdAt,
          completedAt,
          status,
          confirmTime,
          prepTime,
          pickedAt,
        }) => {
          return (
            <div key={id} className="order">
              <div className="header">
                <div className="id">
                  <div
                    onClick={() => {
                      setOrderId(id);
                    }}
                  >
                    {orderNumber} -{" "}
                    <span style={{ color: "gray", cursor: "pointer" }}>
                      {id}
                    </span>
                  </div>
                </div>
                <div className={`status`}>
                  <div className={`x ${status}`}>{status}</div>
                  {!seeDetails && createdAt && (
                    <div className="time">
                      {new Date(createdAt).toLocaleString()}
                    </div>
                  )}
                </div>
              </div>
              {seeDetails && (
                <Row>
                  <Col span={24} md={16} xl={14}>
                    <div className="items">
                      {items?.map((item) => (
                        <OrderItem key={item.id} {...item} />
                      ))}
                    </div>
                  </Col>
                  <Col span={24} md={8} xl={10}>
                    <div className="info">
                      {createdAt && (
                        <InfoItem
                          title="created"
                          value={new Date(createdAt).toLocaleString()}
                        />
                      )}
                      <InfoItem title="confirm time" value={confirmTime} />
                      <InfoItem title="prepare time" value={prepTime} />
                      {pickedAt && (
                        <InfoItem title="picked up" value={pickedAt} />
                      )}
                      {completedAt && (
                        <InfoItem
                          title={status + " at"}
                          value={new Date(completedAt).toLocaleString()}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          );
        }
      )}
    </div>
  );
}
