import { Col, Empty, message, Switch } from "antd";
import { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RestaurantCategories } from "../../../pages/Restaurant/Categories";
import Parse from "parse";

function ProductAvailability({ id, availability }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(availability === "available");

  return (
    <Switch
      size="large"
      checked={value}
      loading={loading}
      onChange={async (value) => {
        try {
          setLoading(true);
          const Product = Parse.Object.extend("product");
          const product = new Product();
          product.id = id;
          product.set("availability", value ? "available" : "unavailable");
          await product.save();
          setValue(value);
          setLoading(false);
        } catch (err) {
          message.error(err.message);
          setLoading(false);
        }
      }}
    />
  );
}

function ProductItem({
  objectId,
  name,
  availability,
  images = [],
  idx,
  category,
}) {
  return (
    <Draggable draggableId={`${category}-${idx}`} index={idx}>
      {(provided) => {
        return (
          <div
            key={idx}
            className="item"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <img src={images[0]} alt="restaurant" />
            <div>
              <h3 className="title">{name}</h3>
            </div>
            <ProductAvailability id={objectId} availability={availability} />
            {provided.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
}

function CategoryItem({ objectId, category, products }) {
  return (
    <Droppable droppableId={objectId}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="category-droppable"
            id={objectId}
          >
            <h2 className="title">{category?.get("name") ?? "Unknown Category"}</h2>
            <div className="group-items customScroll">
              {products?.map((product, i) => (
                <ProductItem
                  key={i}
                  category={objectId}
                  idx={i}
                  {...product}
                  object={product.refs || product.ref}
                />
              ))}

              {products?.length === 0 && (
                <div className="empty">
                  <Empty description="No products" />
                </div>
              )}
            </div>
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}

export default function Products() {
  const { categories, updateCategories, updateCategory } =
    useContext(RestaurantCategories);

  const onDragEnd = ({ source, destination }) => {
    const droppableId = source.droppableId;
    if (droppableId !== destination?.droppableId) return;
    else if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const category = categories.find((cat) => cat.objectId === droppableId);
    if (category) {
      const products = Array.from(category.products);
      const item = products[source.index];
      products.splice(source.index, 1);
      products.splice(destination.index, 0, item);
      category.products = products;
      updateCategories(categories);
      updateCategory({
        id: category.objectId,
        ids: products.map((p) => p.objectId),
      });
    }
  };

  return (
    <Col span={24} md={16}>
      <div className="products customScroll">
        <DragDropContext onDragEnd={onDragEnd}>
          {categories.map((item, i) => (
            <CategoryItem key={item.objectId} {...item} />
          ))}
        </DragDropContext>
      </div>
    </Col>
  );
}
