import { Form, Input, Space, InputNumber, Switch } from "antd";

const { Item, List } = Form;

function Variant({
  name: variantName,
  fieldKey,
  remove: removeVariant,
  ...rest
}) {
  return (
    <div className="section">
      <h3>Variant {variantName + 1}</h3>
      <Item
        {...rest}
        name={[variantName, "title"]}
        style={{ marginBottom: "0px" }}
        label="Variation Title"
      >
        <Input size="large" placeholder="Title" />
      </Item>
      <Space style={{ marginBottom: "10px" }}>
        <Item {...rest} name={[variantName, "min"]} label="Minimum">
          <InputNumber
            placeholder="Min"
            style={{ width: "150px" }}
            size="large"
            min={0}
          />
        </Item>
        <Item {...rest} name={[variantName, "max"]} label="Maximum">
          <InputNumber
            placeholder="Max"
            style={{ width: "150px" }}
            size="large"
            min={0}
          />
        </Item>
      </Space>
      <h3>Items</h3>
      <List name={[variantName, "items"]}>
        {(fields, { add, remove }, i) => (
          <div key={i} style={{ position: "relative" }}>
            {fields.map(({ key, name, fieldKey, ...rest }) => (
              <Space
                key={key}
                style={{
                  display: "flex",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <Item
                  {...rest}
                  name={[name, "name"]}
                  style={{ marginBottom: "0px" }}
                >
                  <Input size="large" placeholder="Item Name" />
                </Item>
                <Item
                  {...rest}
                  name={[name, "price"]}
                  style={{ marginBottom: "0px", display: "none" }}
                >
                  <InputNumber size="large" placeholder="+Price" />
                </Item>
                <Item
                  {...rest}
                  name={[name, "total"]}
                  style={{ marginBottom: "0px" }}
                >
                  <InputNumber
                    placeholder="Total Price"
                    style={{ width: "100px" }}
                    min={0}
                  />
                </Item>

                <Item
                  {...rest}
                  name={[name, "availability"]}
                  style={{ marginBottom: "0px" }}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren="available"
                    unCheckedChildren="unavailable"
                  />
                </Item>
              </Space>
            ))}
          </div>
        )}
      </List>
    </div>
  );
}

export default function Variants() {
  return (
    <div>
      <List name={["price", "variants"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, ...rest }) => (
              <Variant key={key} remove={remove} {...rest} />
            ))}
          </>
        )}
      </List>
    </div>
  );
}
