import { useContext } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import { Switch } from "antd";
import RaiseIssue from "./RaiseIssue";

export default function EditMode() {
  const { editMode, order, editModeHandler } = useContext(DetailsContext);

  const onSwitch = () => {
    if (
      order.data &&
      ["created", "confirmed", "preparing", "ready", "picked"].includes(
        order.data.status
      )
    ) {
      if (editMode) editModeHandler("off");
      else editModeHandler("on");
    }
  };

  const isDisabled = (status) => {
    if (
      ![
        "created",
        "confirmed",
        "preparing",
        "ready",
        "picked",
        "delivered",
      ].includes(status)
    ) {
      if (editMode) {
        editModeHandler("off");
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="edit-mode">
      <div className="title">Edit mode </div>
      <Switch
        disabled={isDisabled(order.data.status)}
        checked={editMode}
        onChange={onSwitch}
      />
      {["delivered", "cancelled", "rejected"].includes(order.data.status) &&
        !order.data.issue && (
          <div className="issue">
            <RaiseIssue data={order.data}>Raise Issue</RaiseIssue>
          </div>
        )}
    </div>
  );
}
