import { useContext, useState } from "react";
import { Button, Col, Drawer, Space, Table } from "antd";
import { RidersActivity } from "../../../pages/Rider/Attendance";
import { localDateTime } from "../../../utils";

export default function Attendance() {
  const { data, isLate, setActiveDate } = useContext(RidersActivity);
  const [visible, setVisible] = useState(false);
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "date",
      width: "130px",
      render: (time) => localDateTime(time),
    },
    {
      title: "Actions",
      key: "actions",
      render: (action, data) => {
        return (
          <Space>
            <Button
              onClick={() => {
                setActiveDate(data.createdAt);
                setVisible(false);
              }}
            >
              Orders
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Col span={24}>
      <Button type="primary" onClick={() => setVisible(true)}>
        Attendance
      </Button>
      <Drawer
        title="Attendance"
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
        width={500}
      >
        {data && (
          <Table
            columns={columns}
            dataSource={data.attendance}
            rowClassName={(record) => {
              return isLate(record.createdAt) ? "late" : "";
            }}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50", "100", "500"],
              total: data.count,
              position: ["topRight"],
            }}
            scroll={{ y: "400px" }}
          />
        )}
      </Drawer>
    </Col>
  );
}
