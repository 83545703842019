import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Parse from "parse";
import { message, Table, Row, Col, Divider, Popover } from "antd";

export default function SalesDetails({ id }) {
  const [sale, setSale] = useState(null);
  const { paymentId = id } = useParams();

  const fetchSale = async () => {
    try {
      message.loading("Fetching data...", 0);
      const res = await new Parse.Query("restaurant_payment")
        .equalTo("objectId", paymentId)
        .select([
          "items_total",
          "total_collected",
          "vat",
          "vat_collection",
          "commission_rate",
          "commission",
          "penalty",
          "orders",
          "revenue",
          "failed_order_amount",
          "paid",
          "payment_date",
          "vat_included",
          "rejected_orders",
          "product_discount",
          "promo_discount",
          "restaurant.commission_type",
          "restaurant.type",
          "refund",
        ])
        .first({ sessionToken: Parse.User.current()?.getSessionToken() });

      message.destroy();
      if (res) {
        setSale(res.toJSON());
      } else {
        message.error("Not found!");
      }
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchSale();

    return () => {
      setSale(null);
    };
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "time",
      key: "time",
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: "OrderID",
      dataIndex: "orderId",
      key: "orderId",
      render: (id) => <Link to={`/order/list/${id}`}>{id}</Link>,
    },
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      key: "orderNumber",
    },
    {
      title: "Penalty",
      dataIndex: "penalty",
      key: "penalty",
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
    },
    {
      title: "Issue",
      dataIndex: "issue",
      key: "issue",
      render: (issue) =>
        issue && (
          <Popover
            content={<div style={{ maxWidth: "300px" }}>{issue.details}</div>}
          >
            <div style={{ textTransform: "capitalize" }}>{issue.type}</div>
          </Popover>
        ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => total + " tk",
    },
  ];

  const getStatistics = ({
    items_total,
    total_collected: total,
    vat,
    vat_collection,
    commission,
    commission_rate,
    penalty = 0,
    refund = 0,
    orders,
    revenue = 0,
    failed_order_amount = 0,
    paid,
    vat_included,
    restaurant,
    product_discount,
    promo_discount,
    rejected_orders,
  }) => {
    const payable = Math.round(
      total -
        vat_collection -
        commission -
        penalty -
        refund +
        failed_order_amount
    );

    return {
      "Products value": items_total + " tk",
      "Product discount": product_discount + " tk",
      "Promo discount": promo_discount + " tk",
      "Total sales": total + " tk",
      "Vat percentage": vat + "%",
      "Vat ": Math.round(vat_collection) + " tk",
      "Commission rate": commission_rate,
      Commission: Math.round(commission) + " tk",
      "Success orders": orders?.length,
      "Failed orders": rejected_orders?.length ?? 0,
      Penalty: sale.penalty + " tk",
      Refund: refund + " tk",
      "Failed order amount": sale.failed_order_amount + " tk",
      Revenue:
        restaurant.commission_type === "percentage"
          ? null
          : Math.round(revenue) + " tk",
      Payable: payable + " tk",
      "Is Paid": paid ? "Yes" : "No",
    };
  };

  return (
    <div>
      {sale && (
        <Row gutter={[24, 8]} style={{ marginBottom: "20px" }}>
          {Object.entries(getStatistics(sale)).map(
            ([key, value]) =>
              value !== null && (
                <Col span={24} sm={12} md={8} lg={6} xl={4}>
                  <div
                    style={{
                      padding: "10px 20px",
                      background: "#fff",
                      boxShadow: "0px 1px 2px rgba(0,0,0,.1)",
                      borderRadius: "5px",
                    }}
                  >
                    <span style={{ color: "#808080", fontWeight: "400" }}>
                      {key}
                    </span>
                    <div style={{ fontWeight: "500", fontSize: "16px" }}>
                      {value}
                    </div>
                  </div>
                </Col>
              )
          )}
        </Row>
      )}

      <h3>Successful Orders</h3>
      <Table columns={columns} dataSource={sale?.orders} pagination={false} />
      {sale && sale.rejected_orders?.length > 0 && (
        <>
          <Divider>Rejected Orders</Divider>
          <Table
            columns={columns}
            dataSource={sale?.rejected_orders}
            scroll={{ x: "max-content" }}
          />
        </>
      )}
    </div>
  );
}
