import { useContext, useEffect, useState } from "react";
import { Table, Switch, Image } from "antd";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import { CategoriesContext } from "../../pages/Categories/CategoriesContext";
import EditCategory from "./EditCategory";
import CustomButton from "./CustomButton";
import DeleteCategory from "./DeleteCategory";
import { compressedImage } from "../../common/utils";
import useSearch from "../inventory/utils/useSearch";

const SwitchableField = ({ refs, value, update, name }) => {
  const [checked, setChecked] = useState(value);

  const updateHandler = async (ref, v) => {
    setChecked(v);
    update(ref, name, v, (data) => {
      setChecked(data);
    });
  };

  return <Switch onChange={(v) => updateHandler(refs, v)} checked={checked} />;
};

export default function CategoryTable() {
  const { categories, getCategories, fieldUpdate } =
    useContext(CategoriesContext);
  const { loading, data } = categories;
  const [editDrawer, setEditDrawer] = useState({
    visible: false,
  });
  const [, searchProps] = useSearch();

  useEffect(() => {
    getCategories({
      select: ["name", "image", "is_restricted", "prep_time", "isTobacco"],
      limit: 20,
    });
  }, []);

  const editOnClose = () => {
    setEditDrawer({
      visible: false,
    });
  };

  const editHandler = (args) => {
    setEditDrawer({
      ...args,
      visible: true,
    });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return image ? (
          <Image src={image} alt="" preview className="table-image" />
        ) : (
          <div
            style={{
              width: "100px",
              minHeight: "50px",
              textAlign: "center",
              color: "#ccc",
              padding: "10px",
            }}
          >
            <InboxOutlined style={{ fontSize: "36px" }} />
            <div>No Image</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...searchProps("name"),
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Prepare time",
      dataIndex: "prep_time",
      key: "prep_time",
    },
    {
      title: "Restricted For App",
      dataIndex: "is_restricted",
      key: "is_restricted",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => {
        return record.is_restricted === value;
      },
      render: (is_restricted, { ref }) => (
        <SwitchableField
          value={is_restricted}
          refs={ref}
          update={fieldUpdate}
          name="is_restricted"
        />
      ),
    },
    {
      title: "IS NID Required",
      dataIndex: "isTobacco",
      key: "isTobacco",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => {
        return record.isTobacco === value;
      },
      render: (isTobacco, { ref }) => (
        <SwitchableField
          value={isTobacco}
          refs={ref}
          update={fieldUpdate}
          name="isTobacco"
        />
      ),
    },
    {
      title: "Action",
      key: 5,
      dataIndex: "action",
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <CustomButton
            onClick={(e) => editHandler(record)}
            icon={<EditOutlined />}
          >
            Edit
          </CustomButton>
          <DeleteCategory {...record} />
        </div>
      ),
    },
  ];

  const parseData = (data) => {
    return data.map((item, i) => {
      return {
        key: i,
        ...item,
      };
    });
  };

  return (
    <div className="table-wrapper">
      <Table
        loading={loading}
        dataSource={parseData(data.results)}
        columns={columns}
        pagination={{
          total: data.count,
          defaultPageSize: 20,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topRight"],
        }}
        style={{ width: "100%" }}
        onChange={(pagination, filters) => {
          getCategories({
            select: [
              "name",
              "image",
              "is_restricted",
              "prep_time",
              "isTobacco",
            ],
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            search: filters.name ? filters.name[0] : null,
            is_restricted: filters.is_restricted,
            isTobacco: filters.isTobacco,
          });
        }}
        scroll={{ x: "max-content" }}
      />
      <EditCategory editOnClose={editOnClose} editDrawer={editDrawer} />
    </div>
  );
}
