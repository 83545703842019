import { message, Table } from "antd";
import Parse from "parse";
import { useEffect, useState } from "react";
import useSearch from "../../components/inventory/utils/useSearch";
import { localDateTime, parser } from "../../utils";

export default function OtpStore() {
  const [data, setData] = useState({
    results: [],
    loading: true,
    count: 0,
  });
  const [, getSearchProps] = useSearch();

  const fetchOtp = async ({ limit, skip, phone, date }) => {
    try {
      const query = new Parse.Query("otp_store")
        .limit(limit)
        .skip(skip)
        .descending("createdAt")
        .withCount();
      if (phone) {
        query.matches("phone", phone, "i");
      }
      if (Array.isArray(date) && date.length === 2) {
        const [start, end] = date.map((d) => new Date(d));
        end.setHours(23, 59, 59);
        query.greaterThanOrEqualTo("createdAt", start);
        query.lessThanOrEqualTo("createdAt", end);
      }
      const data = await query.find();
      setData({
        results: parser(data.results),
        loading: false,
        count: data.count,
      });
    } catch (err) {
      message.error(err.message);
      setData((data) => ({ ...data, loading: false }));
    }
  };

  useEffect(() => {
    fetchOtp({ limit: 20, skip: 0 });
  }, []);

  const columns = [
    {
      title: "Generated At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (time) => localDateTime(time),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getSearchProps("phone"),
    },
    {
      title: "OTP",
      dataIndex: "otp",
      key: "otp",
    },
    {
      title: "Expired At",
      dataIndex: "expired_at",
      key: "expired_at",
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: "Status",
      dataIndex: "result",
      key: "status",
      render: (result) => result?.status,
    },
    {
      title: "Error Message",
      dataIndex: "result",
      key: "error_message",
      render: (result) => result?.error_message || "-",
    },
  ];

  return (
    <div>
      <h2>OTP Store</h2>
      <Table
        columns={columns}
        dataSource={data.results}
        loading={data.loading}
        pagination={{
          total: data.count,
          defaultPageSize: 20,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        onChange={(pagination, filter) => {
          const { current, pageSize } = pagination;
          const { phone, createdAt } = filter;
          fetchOtp({
            limit: pageSize,
            skip: (current - 1) * pageSize,
            phone: phone?.[0],
            date: createdAt,
          });
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
