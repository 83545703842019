import moment from "moment";
import Parse from "parse";

const defaultFunc = () => {};

export default function useRestaurants() {
  const repoName = "restaurant";

  const getRestaurants = async (props = {}, callback = defaultFunc) => {
    try {
      const {
        limit = 100,
        skip = 0,
        managedBy,
        search,
        availability,
        sortBy,
        name,
        phone,
        isAffiliated,
        is_hub,
        cuisines,
        select,
        hub,
        type,
        ids,
        pinned,
        sortOrder,
        customQuery = (query) => {},
        mou,
        mou_expired_date,
      } = props;
      const query = new Parse.Query(repoName);
      customQuery(query);
      query.notEqualTo("delete", true);
      if (select && Array.isArray(select)) {
        query.select(...select);
      }
      if (managedBy) {
        query.equalTo("managedBy", {
          __type: "Pointer",
          className: "_User",
          objectId: managedBy,
        });
      }

      if (Array.isArray(ids) && ids.length > 0) {
        query.containedIn("objectId", ids);
      }

      if (Array.isArray(pinned) && pinned.length > 0) {
        query.containedIn("objectId", pinned);
      }

      if (Array.isArray(type)) {
        query.containedIn("type", type);
      } else if (type) {
        query.equalTo("type", type);
      }

      if (Number.isInteger(limit)) query.limit(limit);
      if (Number.isInteger(skip)) query.skip(skip);
      query.withCount();
      if (sortBy) {
        if (sortOrder === "desc") {
          query.descending(sortBy);
        } else {
          query.ascending(sortBy);
        }
      }
      if (Array.isArray(availability) && availability.length > 0)
        query.containedIn("availability", availability);
      else if (availability && typeof availability === "string")
        query.equalTo("availability", availability);
      if (search) query.matches("name", search, "i");
      if (name) query.matches("name", name, "i");
      if (phone) query.matches("phone", phone, "i");
      if (is_hub !== undefined && is_hub !== null)
        query.equalTo("is_hub", is_hub);
      if (cuisines) query.matches("cuisines", cuisines, "i");
      if (mou) query.matches("mou", mou, "i");
      if (mou_expired_date)
        query.matches("mou_expired_date", mou_expired_date, "i");
      if (isAffiliated !== undefined && isAffiliated !== null)
        query.equalTo("isAffiliated", isAffiliated);
      if (typeof hub === "string") {
        query.equalTo("hub", {
          __type: "Pointer",
          className: "hub",
          objectId: hub,
        });
      } else if (Array.isArray(hub)) {
        query.containedIn(
          "hub",
          hub.map((id) => Parse.Object.extend("hub").createWithoutData(id))
        );
      }

      const results = await query.find();
      if (pinned && pinned.length > 0) {
        delete props.pinned;
        getRestaurants(props, (err, res) => {
          if (err) {
            callback(err, null);
          } else {
            res.results = results.results.concat(
              res.results.filter((r) => !pinned.includes(r.id))
            );
            callback(null, res);
          }
        });
      } else {
        callback(null, results);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getById = async (
    { id, select, exclude } = {},
    callback = defaultFunc
  ) => {
    if (!id) {
      callback("Id can't be empty!", null);
      return;
    }
    try {
      const query = new Parse.Query(repoName);
      query.includeAll();
      query.equalTo("objectId", id);
      if (Array.isArray(exclude)) query.exclude(...exclude);
      if (Array.isArray(select)) query.select(...select);
      const restaurant = await query.first();
      if (restaurant) {
        if (
          moment().isAfter(moment(restaurant.get("voucher_title_validity")))
        ) {
          restaurant.set("voucher_title", null);
          restaurant.set("voucher_title_validity", null);
        }
        if (
          moment().isAfter(moment(restaurant.get("voucher_sub_title_validity")))
        ) {
          restaurant.set("voucher_sub_title", null);
          restaurant.set("voucher_sub_title_validity", null);
        }
        callback(null, restaurant);
      } else {
        callback("Restaurant not found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getByName = async (name, callback = defaultFunc) => {
    try {
      const restaurant = await new Parse.Query(repoName)
        .equalTo("name", name)
        .first();
      if (restaurant) {
        callback(null, restaurant);
      } else {
        callback("Restaurant not found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getBySlug = async (slug, callback = defaultFunc) => {
    try {
      const restaurant = await new Parse.Query(repoName)
        .equalTo("slug", slug)
        .first();
      if (restaurant) {
        callback(null, restaurant);
      } else {
        callback("Restaurant not found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const addNew = async (data = {}, callback = defaultFunc) => {
    try {
      const create = await Parse.Cloud.run("addRestaurant", data);
      callback(null, create);
    } catch (err) {
      callback(
        err.message ??
          "Something wrong happend, please check log to get the error message!",
        null
      );
    }
  };

  const updateItem = async (params, callback = defaultFunc) => {
    try {
      const res = await Parse.Cloud.run("editRestaurant", params);
      if (res) {
        callback(null, res);
      } else {
        callback(
          "Something wrong happend, please check log to get the error message!"
        );
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  return {
    getRestaurants,
    getById,
    getByName,
    getBySlug,
    addNew,
    updateItem,
  };
}
