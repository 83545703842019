import React, { useEffect, useState } from "react";
import RoutingMachine from "./RoutingComponent";
import { Typography } from "antd";
import moment from "moment";

import { MapContainer, TileLayer, Popup, CircleMarker } from "react-leaflet";
import { useHistory } from "react-router-dom";
import LiveMap from "./LiveMap";

export const TrackingMap = ({
  orderLocations,
  orderId,
  riderId,
  loading,
  live,
}) => {
  const history = useHistory();
  const getColorBySubject = (subject) => {
    switch (subject) {
      case "got_order":
        return "green";
      case "in_hub":
        return "orange";
      case "picked":
        return "blue";
      case "delivered":
        return "red";
      default:
        return "black";
    }
  };

  const setTrackUntrack = (oId) => {
    if (!orderId) {
      history.push("/rider/tracking?orderId=" + oId);
    } else {
      history.push("/rider/tracking");
    }
  };

  // useEffect(() => {
  //   setRender(!render);
  // }, [orderId, riderId, orderLocations]);

  return (
    <MapContainer
      center={[23.78, 90.39]}
      zoom={12}
      style={{ height: "600px", width: "100%" }}
    >
      <TileLayer
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {!loading && !live
        ? orderLocations.map((rider, i) => (
          <RoutingMachine
            key={i}
            routes={rider.locations.map((geoPoint) => [
              geoPoint.latitude,
              geoPoint.longitude,
            ])}
            color={rider.color}
          />
        ))
        : null}

      {!live &&
        orderLocations.map((rider) => {
          return rider.locations.map((geoPoint, i) => {
            return (
              <CircleMarker
                center={[geoPoint.latitude, geoPoint.longitude]}
                pathOptions={{
                  color: getColorBySubject(rider.subjects[i]),
                }}
                radius={5}
              >
                <Popup>
                  <Typography.Link
                    href={`/order/list/${rider.orderId}`}
                    style={{
                      color: rider.color,
                    }}
                  >
                    #{rider.orderId}{" "}
                  </Typography.Link>
                  <Typography.Text
                    onClick={(e) => setTrackUntrack(rider.orderId)}
                    type="warning"
                  >
                    {orderId ? "Untrack" : "Track"}
                  </Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {rider.username}
                  </Typography.Text>

                  <br />
                  <Typography.Text type="secondary">
                    {rider.timeFrames.map((e, j) => (
                      <Typography.Text
                        style={{
                          color: getColorBySubject(rider.subjects[j]),
                        }}
                      >
                        {moment(e).format("hh:mm A")}
                        {j !== rider.timeFrames.length - 1 ? " -> " : ""}
                      </Typography.Text>
                    ))}
                  </Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    Total Time:{" "}
                    {moment(rider.timeFrames[rider.timeFrames.length - 1])
                      .diff(moment(rider.timeFrames[0]), "minutes", true)
                      .toFixed(2)}{" "}
                    minutes
                  </Typography.Text>

                  <br />
                  <Typography.Text
                    style={{
                      color: getColorBySubject(rider.subjects[i]),
                    }}
                  >
                    {rider.subjects[i]}
                  </Typography.Text>
                </Popup>
              </CircleMarker>
            );
          });
        })}

      {live && <LiveMap rider={riderId} />}
    </MapContainer>
  );
};
