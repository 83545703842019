import { DatePicker, Form, Input } from "antd";

const { Item } = Form;
const Voucher = ({ index }) => {
  return (
    <div className="section">
      <h3 className="section-title">Primary Voucher</h3>
      <Item name={`voucher_title`} label="Voucher Title">
        <Input placeholder={`voucher: xyz`} />
      </Item>
      <Item name={`voucher_sub_title`} label="Voucher Sub Title">
        <Input placeholder="voucher sub title" />
      </Item>
      <Item name={`voucher_title_validity`} label="Voucher Title Validity">
        <DatePicker format="DD-MM-YYYY HH:mm:ss" showTime />
      </Item>
    </div>
  );
};

export default Voucher;
