import { useContext, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import SectionItem from "./SectionItem";
import AddSectionItem from "./AddSectionItem";
import { Box } from "../Common/Boxer";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, message } from "antd";
import { SectionsContext } from "./SectionsContext";
import moment from "moment";

export default function Section(props) {
  const {
    id: objectId,
    items,
    name,
    sort_order,
    hubs,
    activeHours,
    expire_date,
  } = props;
  const { updateSectionItemExpireDate } = useContext(SectionsContext);
  const [addNew, setAddNew] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [expireDate, setExpiredDate] = useState("");
  const handleSelect = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleClick = async () => {
    try {
      if (!expireDate) {
        message.error("Please select an expire Date");
        return;
      }
      updateSectionItemExpireDate(objectId, selectedItems, expireDate);
      message.success("Successfully Updated");
      setSelectedItems([]);
      setExpiredDate("");
    } catch (error) {
      message.error(error.message);
    }
  };

  const onDateChange = (date, dateString) => {
    setExpiredDate(dateString);
  };

  return (
    <Box
      title={`[${sort_order + 1}] ${name}`}
      metaTitle="section"
      maxHeight="500px"
      rightMenu={<PlusOutlined onClick={() => setAddNew(true)} />}
    >
      <div className="section">
        {addNew && (
          <AddSectionItem
            sectionId={objectId}
            {...props}
            close={(e) => setAddNew(false)}
          />
        )}

        <div className="section-header">
          <div className="hubs">
            {hubs &&
              hubs.map((hub) => (
                <div key={hub.id} className="hubs-item">
                  {hub?.get ? hub?.get("name") : hub?.name}
                </div>
              ))}
          </div>
        </div>

        {selectedItems.length > 0 && (
          <>
            <div>
              Expire Date:{" "}
              <DatePicker
                onChange={onDateChange}
                disabledDate={(current) => moment().add(-1, "days") >= current}
              />
            </div>

            <Button onClick={handleClick}>Update</Button>
          </>
        )}

        <Droppable droppableId={objectId}>
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="droppable"
              >
                {items.map((item, i) => {
                  return (
                    <SectionItem
                      key={i}
                      section={objectId}
                      {...item}
                      selected={selectedItems.includes(item.id)}
                      onSelect={handleSelect}
                      index={i}
                      expire_date={expire_date}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </Box>
  );
}
