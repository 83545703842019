import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { BiEdit, BiCart, BiBarChart } from "react-icons/bi";

const CenterText = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
);

export const MenuItems = ({ id, type }) => (
  <>
    <Menu.Item key="u-details">
      <Link style={{ fontSize: "14px", fontWeight: "500" }} to={`/user/add?id=${id}`}>
        <CenterText>
          <BiEdit />
          <span style={{ marginLeft: "10px" }}>Details</span>
        </CenterText>
      </Link>
    </Menu.Item>
    {type === "customer" && (
      <Menu.Item key="u-orders">
        <Link style={{ fontSize: "14px", fontWeight: "500" }} to={`/order/list?user=${id}`}>
          <CenterText>
            <BiCart />
            <span style={{ marginLeft: "10px" }}>Orders</span>
          </CenterText>
        </Link>
      </Menu.Item>
    )}
    {type === "rider" && (
      <Menu.Item key="r-orders">
        <Link style={{ fontSize: "14px", fontWeight: "500" }} to={`/order/list?rider=${id}`}>
          <CenterText>
            <BiCart />
            <span style={{ marginLeft: "10px" }}>Assign Orders</span>
          </CenterText>
        </Link>
      </Menu.Item>
    )}
    <Menu.Item key="u-analytics-dashboard">
      <Link style={{ fontSize: "14px", fontWeight: "500" }} to={`/user/analytics-dashboard?userId=${id}`}>
        <CenterText>
          <BiBarChart />
          <span style={{ marginLeft: "10px" }}>Analytics Dashboard</span>
        </CenterText>
      </Link>
    </Menu.Item>
  </>
);

export default function UserLinks({ children, id, type, ...props }) {
  return (
    <Dropdown
      placement="bottom"
      arrow
      trigger={["click"]}
      overlay={() => (

        <Menu style={{ width: "160px" }} theme="white">


          <MenuItems id={id} type={type} />
        </Menu>
      )}
      {...props}
    >
      <div style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer" }}>
        {children}
      </div>
    </Dropdown>
  );
}
