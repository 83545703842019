import { message, notification } from "antd";
import Parse from "parse";
import { client } from "../../AuthProvider";

declare global {
  interface Window {
    autoAssign: any;
  }
}

const betweenHours = (
  start: number,
  end: number,
  hour: number,
  minute: number
): boolean => {
  if (minute > 0) {
    hour += minute / 100;
  }

  if (start < end) {
    return start <= hour && end >= hour;
  } else {
    return start <= hour || end >= hour;
  }
};

const dayStartHour = (date: string | Date): Date => {
  const time = new Date(date);
  if (time.toString() === "Invalid Date") {
    throw new Error("Invalid date");
  }
  time.setHours(9, 0, 0, 0);
  return time;
};

const operationalDate = (date: string | Date): Date => {
  const d = new Date(date);
  const h = d.getHours();
  const m = d.getMinutes();
  if (betweenHours(0, 9, h, m)) {
    d.setDate(d.getDate() - 1);
  }
  return dayStartHour(d);
};

const toRad = (deg: number) => {
  return deg * (Math.PI / 180);
};

type Geo = {
  latitude: number;
  longitude: number;
};

const calcDistance = (geo1: Geo, geo2: Geo): number => {
  let lat1 = Number(geo1.latitude);
  let lon1 = Number(geo1.longitude);
  let lat2 = Number(geo2.latitude);
  let lon2 = Number(geo2.longitude);

  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

class Order {
  id: string = "";
  status: string = "";
  customer_area: string = "";
  customer_name: string = "";
  customer_phone: string = "";
  geoPoint: Geo = {
    latitude: 0,
    longitude: 0,
  };
  completedAt: string | Date = "";
  createdAt: string | Date = "";
  isPriority: boolean = false;
  pickups: {
    id: string;
    type: string;
    name: string;
    address: Geo;
  }[] = [];

  hub: {
    latitude: number;
    longitude: number;
    id: string;
  } = {
    latitude: 0,
    longitude: 0,
    id: "",
  };
  payment_method: string = "";
  user: {
    id: string;
    order_count: number;
  } = {
    id: "",
    order_count: 0,
  };
  riderId: string = "";
  suggestedRider: null | Rider = null;
  dispatchHour: {
    text: string;
    h: number;
  };
  ridersDistance: {
    [key: string]: {
      rider: Rider;
      customerToCustomer: number;
      pickupToPickup: number;
      riderToPickup: number;
    };
  } = {};

  constructor(order: any) {
    if (order instanceof Parse.Object) {
      order = order.toJSON();
    }

    const {
      objectId: id,
      status,
      customer_area,
      customer_name,
      customer_phone,
      geoPoint,
      completedAt,
      createdAt,
      isPriority,
      hub,
      payment_method,
      user,
      rider,
      pickups,
      dispatch_hour,
    } = order || {};

    this.id = id;
    this.status = status;
    this.customer_area = customer_area;
    this.customer_name = customer_name;
    this.customer_phone = customer_phone;
    this.geoPoint = geoPoint;
    this.completedAt = completedAt ? new Date(completedAt.iso) : "";
    this.createdAt = new Date(createdAt);
    this.isPriority = isPriority;
    this.hub = {
      id: hub.objectId,
      latitude: Number(hub?.address?.latitude),
      longitude: Number(hub?.address?.longitude),
    };
    this.payment_method = payment_method;
    this.user = {
      id: user.objectId,
      order_count: user.order_count || 1,
    };
    this.riderId = rider?.objectId;
    this.pickups = pickups.map((pickup: any) => ({
      id: pickup.id,
      name: pickup.name,
      type: pickup.type,
      address: {
        latitude: Number(pickup?.address?.latitude),
        longitude: Number(pickup?.address?.longitude),
      },
    }));
    this.dispatchHour = dispatch_hour;
  }
}

class Rider {
  id: string = "";
  name: string = "";
  phone: string = "";
  availability: boolean = false;
  deliveredOrders: Order[] = [];
  assignedOrders: Order[] = [];
  suggestedOrders: Order[] = [];
  location: Geo = {
    latitude: 0,
    longitude: 0,
  };
  hubs: string[] = [];

  constructor(rider: any) {
    const { objectId: id, name, phone, riderHub, rider_availability } = rider;
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.availability = rider_availability;
    if (riderHub) {
      this.hubs.push(riderHub.objectId);
    }
  }

  totalOrders() {
    return this.deliveredOrders.length + this.assignedOrders.length;
  }

  lastDelivered(): Date {
    let lastDelivered: Date = operationalDate(new Date());

    this.deliveredOrders.forEach((order) => {
      if (!lastDelivered && order.completedAt) {
        lastDelivered = new Date(order.completedAt);
      } else if (order.completedAt && order.completedAt > lastDelivered) {
        lastDelivered = new Date(order.completedAt);
      }
    });

    return lastDelivered;
  }

  isOrderInSuggestions(order: Order): boolean {
    return this.suggestedOrders.some(
      (suggestedOrder) => suggestedOrder.id === order.id
    );
  }

  removeSuggestion(order: Order): void {
    this.suggestedOrders = this.suggestedOrders.filter(
      (suggestedOrder) => suggestedOrder.id !== order.id
    );
  }

  removeOrderFromAssigned(order: Order): void {
    this.assignedOrders = this.assignedOrders.filter(
      (assignedOrder) => assignedOrder.id !== order.id
    );
  }

  removeOrderFromDelivered(order: Order): void {
    this.deliveredOrders = this.deliveredOrders.filter(
      (deliveredOrder) => deliveredOrder.id !== order.id
    );
  }

  clearSuggestions(): void {
    this.suggestedOrders.forEach((order) => {
      order.suggestedRider = null;
    });

    this.suggestedOrders = [];
  }
}

class AutoRiderAssign {
  orders: Order[] = [];
  queue: Order[] = [];
  riders: {
    [key: string]: Rider;
  } = {};

  rules: {
    [key: string]: any;
  } = {};

  constructor() {
    this.rules = {
      autoAssign: false,
      autoAssignMinOrderCount: 3,
      maxAssign: 7,
      maxSuggest: 3,
      autoConfirm: false,
      autoConfirmUserMinOrderCount: 3,
      autoInitInterval: 1000 * 60 * 20,
      clearQueueInterval: 1000 * 10,
      autoAssignStatus: ["confirmed", "preparing", "ready"],
      autoScroll: false,
    };
  }

  newOrder(order: any): Order {
    if (!order) {
      throw new Error("Order is not defined");
    } else if (order instanceof Parse.Object) {
      order = order.toJSON();
    }

    const newOrder: Order = new Order(order);
    return new Proxy<Order>(newOrder, {
      get(target, prop, receiver) {
        return Reflect.get(target, prop, receiver);
      },

      set(target, prop, value, receiver) {
        return Reflect.set(target, prop, value, receiver);
      },
    });
  }

  newRider(rider: any): Rider {
    if (rider instanceof Parse.User) {
      rider = rider.toJSON();
    }
    const newRider: Rider = new Rider(rider);
    return new Proxy<Rider>(newRider, {});
  }

  /**
   * Fetch orders that are not completed in last 2 days
   */
  async fetchOrders() {
    const orderKeys = [
      "status",
      "rider",
      "customer_area",
      "customer_name",
      "customer_phone",
      "pickups",
      "geoPoint",
      "isPriority",
      "hub.address",
      "user.order_count",
      "completedAt",
      "payment_method",
    ];
    const twoDaysBefore = new Date();
    twoDaysBefore.setDate(twoDaysBefore.getDate() - 2);

    const notCompleted = new Parse.Query("order")
      .doesNotExist("completedAt")
      .greaterThan("createdAt", twoDaysBefore);

    const operationHour = operationalDate(new Date());
    const recentCompleted = new Parse.Query("order").greaterThanOrEqualTo(
      "completedAt",
      operationHour
    );

    const orders = await Parse.Query.or(notCompleted, recentCompleted)
      .notContainedIn("status", ["cancelled", "pending"])
      .limit(500)
      .ascending("createdAt")
      .select(orderKeys)
      .find();

    this.orders = orders.map((order) => this.newOrder(order));
    this.queue = this.orders.filter(
      (order) =>
        !order.riderId &&
        !["pending", "cancelled", "rejected", "delivered"].includes(
          order.status
        )
    );
  }

  async fetchRiders() {
    const ridersLastLocations = await Parse.Cloud.run("riderLastLocations");
    const locations: {
      [key: string]: Geo;
    } = {};

    ridersLastLocations.forEach(
      (rider: {
        id: string;
        location: { latitude: number; longitude: number };
      }) => {
        locations[rider.id] = rider.location;
      }
    );

    const riders = await new Parse.Query("_User")
      .equalTo("type", "rider")
      .equalTo("active", true)
      .select(["name", "phone", "riderHub", "rider_availability"])
      .limit(500)
      .find();

    riders.forEach((rider) => {
      this.riders[rider.id] = this.newRider(rider);
      if (locations[rider.id]) {
        this.riders[rider.id].location = locations[rider.id];
      }
    });

    // Push delivered and assigned orders to riders
    this.orders.forEach((order) => {
      const { status, riderId } = order;
      if (riderId && this.riders[riderId]) {
        if (status === "delivered") {
          this.riders[riderId].deliveredOrders.push(order);
        } else if (!["cancelled", "rejected"].includes(status)) {
          this.riders[riderId].assignedOrders.push(order);
        }
      }
    });
  }

  async listenRiderTrack() {
    const query = new Parse.Query("ridertrack");
    const user = Parse.User.current();
    const subscription = await client.subscribe(query, user?.getSessionToken());
    if (subscription) {
      subscription.on("create", (object: any) => {
        const rider = object.get("user");
        if (rider && object.get("location") && this.riders[rider.id]) {
          const { coordinates }: { coordinates: [number, number] } =
            object.get("location");
          this.riders[rider.id].location = {
            latitude: coordinates[1],
            longitude: coordinates[0],
          };
        }
      });
    }
  }

  async init() {
    await this.fetchOrders();
    await this.fetchRiders();
    this.listenRiderTrack();

    window.autoAssign = this;

    if (this.rules.autoInitInterval > 0) {
      setInterval(async () => {
        await this.fetchOrders();
        await this.fetchRiders();
      }, this.rules.autoInitInterval);
    }

    this.clearQueue();
    if (this.rules.clearQueueInterval > 0) {
      let i = 1;
      setInterval(() => {
        this.clearQueue(i);
        if (i < 15) {
          i++;
        }
      }, this.rules.clearQueueInterval);
    }
  }

  async assignRider(order: Order, rider: Rider) {
    try {
      if (order.riderId) return false;
      if (rider.assignedOrders.length >= this.rules.maxAssign) return false;
      if (
        ["cancelled", "rejected", "delivered", "pending"].includes(order.status)
      ) {
        notification.error({
          message: "Assigning rider",
          description: `Order ${order.id} is ${order.status}.`,
        });
        return false;
      }

      await Parse.Cloud.run("assignRiderHandler", {
        order_id: order.id,
        rider_id: rider.id,
      });

      notification.info({
        message: "Assigning rider",
        description: `Assigning rider ${rider.name} to order ${order.id} at ${order.customer_area}.`,
      });
    } catch (err: any) {
      message.error(err.message);
    }
  }

  clearQueue(looping: number = 0) {
    const lookupThroughQueue = (index: number) => {
      const order = this.queue[index];
      if (!order) return;
      if (order.riderId || ["cancelled", "rejected"].includes(order.status)) {
        this.queue.splice(index, 1);

        if (order.suggestedRider) {
          order.suggestedRider.suggestedOrders =
            order.suggestedRider.suggestedOrders.filter(
              (suggestedOrder) => suggestedOrder.id !== order.id
            );
          order.suggestedRider = null;
        }

        lookupThroughQueue(index);
      } else if (!order.suggestedRider) {
        const rider = this.lookupRider(order, looping);
        if (rider) {
          if (
            this.rules.autoAssign &&
            order.user.order_count >= this.rules.autoAssignMinOrderCount &&
            this.rules.autoAssignStatus.includes(order.status)
          ) {
            this.assignRider(order, rider);
          } else {
            order.suggestedRider = rider;
            rider.suggestedOrders.push(order);
          }
        }
      }

      lookupThroughQueue(index + 1);
    };

    lookupThroughQueue(0);
  }

  getFreeRider(order: Order): Rider | undefined {
    const availableRiders: Rider[] = [];

    for (let riderId in this.riders) {
      if (order.suggestedRider) continue;
      const rider = this.riders[riderId];
      if (rider.hubs.length !== 0 && !rider.hubs.includes(order.hub.id)) {
        continue;
      } else if (rider.suggestedOrders.length !== 0) {
        continue;
      }

      if (rider.assignedOrders.length === 0) {
        availableRiders.push(rider);
      }
    }

    // first sort by total in ascending order
    availableRiders.sort((a, b) => a.totalOrders() - b.totalOrders());
    const sameTotal = availableRiders.filter(
      (rider) => rider.totalOrders() === availableRiders[0].totalOrders()
    );

    sameTotal.sort(
      (a, b) => a.lastDelivered().getTime() - b.lastDelivered().getTime()
    );
    return sameTotal[0];
  }

  /*
      - Find out is there any rider who is delivering near area (ignore who is already requested)
        * still food not picked 
        * restaurants is near to each other 
        * total assigned (not picked) order max less then 3 
        * calculate the pickup distance between rider and pickup location
        * If the distance within half km, send assign request
     */
  nearestDeliveringRider(
    order: Order,
    customerRadius: number,
    pickupRadius: number
  ): null | Rider {
    if (!order) return null;

    const { geoPoint, pickups } = order;
    const haveNearestDelivery: {
      rider: Rider;
      distance: number;
    }[] = [];

    for (let riderId in this.riders) {
      if (order.suggestedRider) continue;

      const rider = this.riders[riderId];
      if (rider.hubs.length !== 0 && !rider.hubs.includes(order.hub.id)) {
        continue;
      } else if (rider.suggestedOrders.length > this.rules.maxSuggest) {
        continue;
      }

      if (rider.assignedOrders.length < this.rules.maxAssign) {
        let distances: number[] = [];

        const currentPickup = pickups.find(({ type }) => type === "restaurant");

        // Check is there any nearest delivery
        const nearestOrders = rider.assignedOrders.filter((order) => {
          const distance = calcDistance(order.geoPoint, geoPoint);
          order.ridersDistance[rider.id] = {
            rider: rider,
            customerToCustomer: distance,
            pickupToPickup: 0,
            riderToPickup: 0,
          };

          let nearestPickup = 0;

          if (currentPickup) {
            // Check if two pickup is near to each other
            let pickupDistance: number = 0;
            let riderToPickupDistance: number = 0;

            const pickup = order.pickups.find(
              (pickup) =>
                pickup.type === "restaurant" &&
                pickup.address.latitude &&
                pickup.address.longitude
            );

            if (pickup && pickup.address.latitude && pickup.address.longitude) {
              const currentPickupGeoPoint = {
                latitude: currentPickup.address?.latitude,
                longitude: currentPickup.address?.longitude,
              };

              if (currentPickupGeoPoint.latitude && pickup.address.latitude) {
                pickupDistance = calcDistance(
                  pickup.address,
                  currentPickupGeoPoint
                );
                order.ridersDistance[rider.id].pickupToPickup = pickupDistance;
              }
            }

            // Check if rider is near to pickup location
            if (rider.location.latitude && rider.location.longitude) {
              riderToPickupDistance = calcDistance(
                rider.location,
                currentPickup.address
              );
              order.ridersDistance[rider.id].riderToPickup =
                riderToPickupDistance;
              if (riderToPickupDistance < pickupRadius) {
                nearestPickup = riderToPickupDistance;
              }
            }

            // If rider is near to pickup location, then check if pickup location is near to each other
            if (!nearestPickup && pickupDistance < pickupRadius) {
              nearestPickup = pickupDistance;
            }
          }

          distances.push(distance + nearestPickup);
          return distance < customerRadius && nearestPickup > 0;
        });

        if (nearestOrders.length) {
          haveNearestDelivery.push({
            rider,
            distance: Math.min(...distances),
          });
        }
      }
    }

    haveNearestDelivery.sort((a, b) => a.distance - b.distance);

    // 2
    if (haveNearestDelivery.length) {
      return haveNearestDelivery[0].rider;
    }

    return null;
  }

  lookupRider(order: Order, looping: number = 0): Rider | null {
    if (!order) return null;

    // if is there any rider who is delivering radius .5km for customer and 1.5km for pickup
    const nearDeliveryRider1 = this.nearestDeliveringRider(order, 0.5, 1.5); // .5km for customer and 1.5km for pickup
    if (nearDeliveryRider1) {
      return nearDeliveryRider1;
    }

    // check is there any free rider
    const freeRider = this.getFreeRider(order);
    if (freeRider) {
      return freeRider;
    }
    // if is there any rider who is delivering radius 1km for customer and 2km for pickup
    const nearDeliveryRider = this.nearestDeliveringRider(
      order,
      0.35 * (looping * 0.5),
      0.5 * (looping * 0.5)
    );
    if (nearDeliveryRider) {
      return nearDeliveryRider;
    }

    return null;
  }

  async confirmOrder(order: Order) {
    try {
      await Parse.Cloud.run("updateStatus", {
        orderId: order.id,
        status: "confirmed",
      });
    } catch (err: any) {
      message.error(err.message);
    }
  }

  pushNewOrder(order: any) {
    if (!order) return null;

    if (order instanceof Parse.Object) {
      order = order.toJSON();
    }
    if (order.status === "pending") return null;

    const newOrder: Order = this.newOrder(order);
    this.queue.push(newOrder);
    this.orders.push(newOrder);

    if (
      this.rules.autoConfirm &&
      newOrder.user.order_count >= this.rules.autoConfirmUserMinOrderCount
    ) {
      this.confirmOrder(newOrder);
    }
  }

  updateRider(object: any) {
    if (!object) return null;

    let rider: Rider;
    if (object instanceof Parse.Object) {
      rider = this.newRider(object.toJSON());
    } else {
      rider = this.newRider(object);
    }

    const originalRider = this.riders[rider.id];
    if (originalRider) {
      // if rider is not active, remove from riders
      if (!object.get("active")) {
        originalRider.clearSuggestions();
        delete this.riders[rider.id];
        return;
      }

      originalRider.availability =
        object.rider_availability ?? object.get("rider_availability");
    } else {
      this.riders[rider.id] = rider;
    }
  }

  updateOrder(object: any) {
    if (!object) return null;

    let order: Order;
    if (object instanceof Parse.Object) {
      order = this.newOrder(object.toJSON());
    } else {
      order = this.newOrder(object);
    }

    const originalOrder = this.orders.find(({ id }) => id === order.id);
    if (originalOrder) {
      /*
        1. if rider newly assigned update the rider in order and add to rider assigned
        2. if rider removed from the order, remove the rider
        3. if rider is updated, update the rider in order and remove order from rider and update new rider 
        4. if order is delivered, remove order from rider assigned and push to delivered
        5. if order is cancelled, remove order from rider assigned 
      */

      if (
        order.completedAt.toString() !== originalOrder.completedAt.toString()
      ) {
        originalOrder.completedAt = order.completedAt;
      }

      if (order.riderId && !originalOrder.riderId) {
        originalOrder.riderId = order.riderId;
        originalOrder.suggestedRider = null;
        const rider = this.riders[order.riderId];
        if (rider) {
          rider.assignedOrders.push(originalOrder);
          rider.removeSuggestion(order);
        }

        // remove from queue
        const index = this.queue.findIndex(({ id }) => id === order.id);
        if (index > -1) {
          this.queue.splice(index, 1);
        }
      } else if (!order.riderId && originalOrder.riderId) {
        const rider = this.riders[originalOrder.riderId];
        if (rider) {
          rider.removeOrderFromAssigned(originalOrder);
          rider.removeOrderFromDelivered(originalOrder);
        }

        originalOrder.riderId = "";
        originalOrder.suggestedRider = null;
      } else if (
        order.riderId &&
        originalOrder.riderId &&
        order.riderId !== originalOrder.riderId
      ) {
        const rider = this.riders[originalOrder.riderId];
        if (rider) {
          rider.removeOrderFromAssigned(originalOrder);
          rider.removeOrderFromDelivered(originalOrder);
        }

        originalOrder.riderId = order.riderId;
        originalOrder.suggestedRider = null;
        const newRider = this.riders[order.riderId];
        if (newRider) {
          newRider.assignedOrders.push(originalOrder);
          newRider.removeSuggestion(originalOrder);
        }
      }

      if (
        ["cancelled", "rejected"].includes(order.status) &&
        order.status !== originalOrder.status
      ) {
        originalOrder.status = order.status;
        const rider = this.riders[originalOrder.riderId];
        if (rider) {
          rider.removeOrderFromAssigned(originalOrder);
          rider.removeOrderFromDelivered(originalOrder);
        }
        originalOrder.riderId = "";
        originalOrder.suggestedRider = null;
      } else if (
        order.status === "delivered" &&
        originalOrder.status !== "delivered"
      ) {
        originalOrder.status = "delivered";
        const rider = this.riders[originalOrder.riderId];
        if (rider) {
          rider.removeOrderFromAssigned(originalOrder);
          rider.deliveredOrders.push(originalOrder);
        }
      } else if (
        order.status !== "delivered" &&
        originalOrder.status === "delivered"
      ) {
        originalOrder.status = order.status;
        const rider = this.riders[originalOrder.riderId];
        if (rider) {
          rider.removeOrderFromDelivered(originalOrder);
          if (
            ["confirmed", "ready", "preparing", "picked"].includes(order.status)
          ) {
            rider.assignedOrders.push(originalOrder);
          }
        }
      }
    } else {
      this.orders.push(order);
    }
  }

  listen(fn: Function, duration: number = 1000) {
    return setInterval(() => {
      fn(this);
    }, duration);
  }
}

export default new AutoRiderAssign();
