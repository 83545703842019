import { useState, useContext } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
  InputNumber,
} from "antd";
import { CategoriesContext } from "../../pages/Categories/CategoriesContext";
import UploadMedia from "../media/UploadMedia";

export default function CreateCategory({ visible, setVisible }) {
  const { createCategoryHandler } = useContext(CategoriesContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState({
    visible: false,
    images: [],
    loading: false,
    fileList: [],
    multiple: false,
  });

  const onFinish = async ({ name }) => {
    setLoading(true);

    createCategoryHandler({ name, image: media.images[0] }, () => {
      form.resetFields();
      setLoading(false);
      media.images = [];
      setMedia({ ...media, fileList: [] });
      message.success("Category created successfully");
    });
  };

  return (
    <Drawer
      zIndex={1012}
      width="50%"
      title="Create new category"
      placement="right"
      onClose={(e) => setVisible(false)}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ visibility: visible ? "visible" : "hidden" }}
        size="large"
      >
        <Form.Item>
          <UploadMedia state={media} setState={setMedia} />
          <Button
            style={{ width: "100%" }}
            onClick={(e) => setMedia({ ...media, visible: true })}
            type="dashed"
          >
            Upload image
          </Button>
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prepare Time"
              name="prep_time"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "160px" }} placeholder="Prep time" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            style={{ width: "100%" }}
            loading={loading}
            disabled={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
