import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import React, { useEffect, useState, useCallback } from "react";
import "leaflet/dist/leaflet.css";
import {
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Skeleton,
  Space,
  Typography,
  Button,
} from "antd";
import Parse from "parse";
import moment from "moment";
import { TrackingMap } from "./Tracking.map";
import { useHistory, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RiderTravelMap = () => {
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    let i = 0;
    while (i < 6) {
      color += letters[Math.floor(Math.random() * 16)];
      i++;
    }
    return color;
  };

  // get order id from params
  let queryHook = useQuery();

  const history = useHistory();

  const [riderTravels, setRiderTravels] = useState([]);
  const [riders, setRiders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredRiders, setFilteredRiders] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    moment().subtract(1, "days"),
    moment(),
  ]);
  const [riderId, setRiderId] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const handleDatePickerChange = useCallback((dates) => {
    setSelectedDate(dates);
  }, []);

  const [live, setLive] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchRiders = async () => {
      const riders = await new Parse.Query(Parse.User)
        .equalTo("type", "rider")
        .select(["username", "image"])
        .find({
          sessionToken: Parse.User.current().getSessionToken(),
        });

      setRiders(riders.map((rider) => rider.toJSON()));

      setFilteredRiders(riders.map((rider) => rider.toJSON()));
    };

    const fetchRiderTravels = async () => {
      const query = new Parse.Query("rider_travel")
        .include(["rider"])
        .limit(150)
        .select([
          "subject",
          "geoPoint",
          "rider.username",
          "rider.image",
          "order",
        ]);

      const rId = queryHook.get("riderId") ?? null;
      const oId = queryHook.get("orderId") ?? null;
      const liveQuery = queryHook.get("live") ?? false;

      setRiderId(rId);
      setOrderId(oId);
      setLive(liveQuery === "true" ? true : false);

      if (rId) {
        query.equalTo("rider", {
          __type: "Pointer",
          className: "_User",
          objectId: rId,
        });
      }
      if (oId) {
        query.equalTo("order", {
          __type: "Pointer",
          className: "order",
          objectId: oId,
        });
      } else {
        query.greaterThanOrEqualTo("createdAt", selectedDate[0].toDate());
        query.lessThanOrEqualTo("createdAt", selectedDate[1].toDate());
      }

      const riderLogs = await query.find();

      console.log(riderLogs.map((riderLog) => riderLog.toJSON()));

      setRiderTravels(riderLogs.map((riderLog) => riderLog.toJSON()));
      setLoading(false);
    };
    fetchRiderTravels();
    fetchRiders();

    return () => { };
  }, [orderId, queryHook, selectedDate]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row>
        <DatePicker.RangePicker
          showTime
          format={"YYYY-MM-DD hh:mm A"}
          onChange={handleDatePickerChange}
          value={selectedDate}
        />
        <Button
          onClick={(e) => {
            if (orderId || riderId) {
              history.replace(`tracking`);
            }
            setLive(!live);
          }}
          type={live ? "danger" : "primary"}
        >
          {live ? "Stop Live" : "Start Live"}
        </Button>
      </Row>
      <Row gutter={16}>
        <Col flex={24}>
          <TrackingMap
            orderLocations={Object.values(
              riderTravels?.reduce((acc, curr) => {
                if (!acc[curr.order?.objectId]) {
                  acc[curr.order?.objectId] = {
                    orderId: curr.order?.objectId,
                    locations: [],
                    color: getRandomColor(),
                    subjects: [],
                    timeFrames: [],
                    username: curr.rider?.username,
                  };
                }
                acc[curr.order?.objectId].locations.push(curr.geoPoint);
                acc[curr.order?.objectId].subjects.push(curr.subject);
                acc[curr.order?.objectId].timeFrames.push(curr.createdAt);
                return acc;
              }, {})
            )}
            riderId={riderId}
            orderId={orderId}
            loading={loading}
            live={live}
            setRiderTravels={setRiderTravels}
          />
        </Col>
        <Col flex={1}>
          <Row gutter={20}>
            <Card
              title="Riders"
              style={{
                width: "100%",
                height: "350px",
                overflow: "scroll",
              }}
            >
              <Input
                placeholder="Search"
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilteredRiders(
                    riders.filter((rider) =>
                      rider.username.toLowerCase().includes(value.toLowerCase())
                    )
                  );
                }}
              />
              {filteredRiders.map((rider) => (
                <Card
                  style={{
                    textAlign: "center",
                    height: "80px",
                    backgroundColor:
                      riderId === rider.objectId ? "#e6f7ff" : "white",
                  }}
                  hoverable
                  onClick={() => {
                    if (riderId === rider.objectId) {
                      if (live) {
                        setRiderId(null);
                      } else {
                        history.replace(`tracking`);
                      }
                    } else {
                      if (live) {
                        setRiderId(rider.objectId);
                      } else {
                        history.replace(`tracking?riderId=${rider.objectId}`);
                        window.location.reload(true);
                      }
                    }
                  }}
                >
                  <Row>
                    <Col flex={2}>
                      <img
                        src={rider.image}
                        alt="rider"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </Col>
                    <Col flex={8}>
                      <p>{rider.username}</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Card>
            <Card
              title="Rider Activities"
              style={{ width: "100%", height: "250px" }}
            >
              {/* <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p> */}
            </Card>
          </Row>
        </Col>
      </Row>
    </Space>
  );
};

export default RiderTravelMap;
