import React from "react";
import { MapContainer, TileLayer, Polygon, Popup } from "react-leaflet";

const ZoneMaps = ({ zones, selectedAreas, height }) => {
  return (
    <MapContainer
      center={[23.8103, 90.4125]}
      zoom={12}
      style={{ height: height || "500px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {zones.map((zone) =>
        zone.areas.map((area) => (
          <Polygon
            key={area._id}
            positions={area.location.coordinates[0].map((coord) => [
              coord[1],
              coord[0],
            ])}
            pathOptions={{
              color: selectedAreas.includes(area._id) ? "#3388ff" : "#ff0000",
            }}
          >
            <Popup>
              <h4>
                {area.name}, {zone.name}
              </h4>
              <p
                style={{
                  color: selectedAreas.includes(area._id)
                    ? "#3388ff"
                    : "#ff0000",
                }}
              >
                {selectedAreas.includes(area._id) ? "Operational" : "inactive"}
              </p>
            </Popup>
          </Polygon>
        ))
      )}
    </MapContainer>
  );
};

export default ZoneMaps;
