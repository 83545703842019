import { useState, useEffect } from "react";
import { message, Table, DatePicker } from "antd";
import Parse from "parse";
import { Link } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";
import useOrders from "../../hooks/useOrders";
import useSearch from "../../components/inventory/utils/useSearch";
import { localDateTime } from "../../utils";

export default function ArchivedOrders() {
  const [orders, setOrders] = useState({
    count: 0,
    loading: true,
    results: [],
  });
  const { getOrders } = useOrders();
  const [, getColumnSearchProps] = useSearch();

  const fetchOrders = async (params = { limit: 100, skip: 0 }) => {
    try {
      getOrders(
        {
          ...params,
          select: [
            "customer_name",
            "customer_phone",
            "customer_area",
            "status",
            "delete",
          ],
          isDelete: true,
        },
        (err, data) => {
          if (err) return message.error(err.message);
          setOrders((orders) => ({
            ...orders,
            count: data.count,
            results: data.results.map((order) => order.toJSON()),
            loading: false,
          }));
        }
      );
    } catch (err) {
      message.error(err.message);
      setOrders((orders) => ({ ...orders, loading: false }));
    }
  };

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  });

  const status = [
    "created",
    "confirmed",
    "preparing",
    "ready",
    "picked",
    "delivered",
    "cancelled",
    "rejected",
  ];

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
      ...getColumnSearchProps("objectId"),
      render: (id) => (
        <Link target="__blank" to={`/order/list/${id}`}>
          {id}
        </Link>
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      ...dateSearchProps("createdAt"),
      render: (date) => localDateTime(date),
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      ...getColumnSearchProps("customer_name"),
      render: (name, record) => {
        return (
          <Link
            style={{ color: "black" }}
            to={`/user/add?id=${record.user?.id}`}
            title={name}
          >
            {name !== "null" ? name : record.customer_phone}
          </Link>
        );
      },
    },
    {
      title: "Customer Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
      ...getColumnSearchProps("customer_phone"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      filters: status.map((i) => ({ text: i, value: i })),
      onFilter: (value, record) => {
        return record.status === value;
      },
      render: (status) => {
        return (
          <div
            style={{
              background: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
              textTransform: "capitalize",
              letterSpacing: "1px",
            }}
          >
            {status}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        loading={orders.loading}
        columns={columns}
        dataSource={orders.results}
        pagination={{
          total: orders.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        scroll={{ x: "max-content" }}
        onChange={(pagination, filters) => {
          fetchOrders({
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            createdAt:
              filters.createdAt &&
              filters.createdAt[0]?.filter((i) => i).length > 0
                ? filters.createdAt[0]
                : undefined,
            customer_name: filters.customer_name
              ? filters.customer_name[0]
              : "",
            customer_phone: filters.customer_phone
              ? filters.customer_phone[0]
              : "",
            customer_area: filters.customer_area
              ? filters.customer_area[0]
              : "",
            status: filters.status,
          });
        }}
      />
    </div>
  );
}
