import { useReducer, useEffect, createContext, useState } from "react";
import useOrders from "../../hooks/useOrders";
import useUser from "../../hooks/useUser";
import { parser } from "../../utils";
import OrderListTable from "../../components/order/List";
import Parse from "parse";
import { message, Drawer, Button } from "antd";
import OrderDetails from "./Details";
import useRestaurants from "../../hooks/useRestaurants";
import useProduct from "../../hooks/useProduct";
import { CSVLink } from "react-csv";
import formatData from "../../utils/libs/formatData";

export const OrderListContext = createContext();

const Types = {
  GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",
  ADD_NEW_ORDER: "ADD_NEW_ORDER",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  GET_RIDER_REQUEST: "GET_RIDER_REQUEST",
  GET_RIDER_SUCCESS: "GET_RIDER_SUCCESS",
  GET_RIDER_FAILURE: "GET_RIDER_FAILURE",
  SET_SELECTED_ITEMS: "SET_SELECTED_ITEMS",
  SET_HUBS: "SET_HUBS",
};

const initialState = {
  orders: { loading: false, data: { count: 0, results: [] } },
  user: { loading: false, data: null },
  rider: { loading: false, data: null },
  selectedRowKeys: [],
  selectedItems: [],
  hubs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_ORDERS_REQUEST:
      state.orders.loading = true;
      return { ...state };
    case Types.GET_ORDERS_SUCCESS:
      state.orders.loading = false;
      state.orders.data = action.payload;
      return { ...state };
    case Types.GET_ORDERS_FAILURE:
      state.orders.loading = false;
      state.orders.data = initialState.orders.data;
      return { ...state };
    case Types.ADD_NEW_ORDER:
      state.orders.data.results.unshift(action.payload);
      state.orders.data.count++;
      return { ...state };
    case Types.GET_USER_REQUEST:
      state.user.loading = true;
      return { ...state };
    case Types.GET_USER_SUCCESS:
      state.user.loading = false;
      state.user.data = action.payload;
      return { ...state };
    case Types.GET_USER_FAILURE:
      state.user.loading = false;
      state.user.data = null;
      return { ...state };
    case Types.GET_RIDER_REQUEST:
      state.rider.loading = true;
      return { ...state };
    case Types.GET_RIDER_SUCCESS:
      state.rider.loading = false;
      state.rider.data = action.payload;
      return { ...state };
    case Types.GET_RIDER_FAILURE:
      state.rider.loading = false;
      state.rider.data = null;
      return { ...state };
    case Types.SET_SELECTED_ITEMS:
      state.selectedRowKeys = action.payload.selectedRowKeys;
      state.selectedItems = action.payload.selectedItems;
      return { ...state };
    case Types.SET_HUBS:
      state.hubs = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default function OrderList() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getOrders, deleteOrders } = useOrders();
  const { getById: getUserById } = useUser();
  const search = new URL(window.location).searchParams;
  const userId = search.get("user");
  const riderId = search.get("rider");
  const ids = search.get("ids");
  const [orderId, setOrderId] = useState(null);
  const { getRestaurants } = useRestaurants();
  const { getProducts } = useProduct();
  const [restaurants, setRestaurants] = useState([]);
  const [products, setProducts] = useState([]);

  const getOrdersList = (params) => {
    dispatch({ type: Types.GET_ORDERS_REQUEST });
    getOrders(params, (err, res) => {
      if (err) {
        dispatch({ type: Types.GET_ORDERS_FAILURE });
      } else {
        dispatch({
          type: Types.GET_ORDERS_SUCCESS,
          payload: {
            count: res.count,
            results: parser(res.results),
          },
        });
      }
    });
  };

  const getUser = (id) => {
    dispatch({ type: Types.GET_USER_REQUEST });
    getUserById(
      { id, type: "customer", select: ["username", "name", "type"] },
      (err, res) => {
        if (err) {
          dispatch({ type: Types.GET_USER_FAILURE });
          message.error(err);
        } else {
          dispatch({ type: Types.GET_USER_SUCCESS, payload: parser(res) });
        }
      }
    );
  };

  const getRider = (id) => {
    dispatch({ type: Types.GET_RIDER_REQUEST });
    getUserById(
      { id, type: "rider", select: ["username", "name", "phone", "type"] },
      (err, res) => {
        if (err) {
          dispatch({ type: Types.GET_RIDER_FAILURE });
          message.error(err);
        } else {
          dispatch({ type: Types.GET_RIDER_SUCCESS, payload: parser(res) });
        }
      }
    );
  };

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub")
        .select(["name"])
        .find({ sessionToken: Parse.User.current().getSessionToken() });
      if (hubs) {
        dispatch({ type: Types.SET_HUBS, payload: parser(hubs) });
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchRestaurants = async (params) => {
    try {
      getRestaurants(params, (err, res) => {
        if (err) {
          message.error(err);
        } else {
          setRestaurants(parser(res.results));
        }
      });
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchProducts = async (params) => {
    try {
      getProducts(params, (err, res) => {
        if (err) {
          message.error(err);
        } else {
          setProducts(parser(res.results));
        }
      });
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    const params = {
      limit: 100,
      skip: 0,
      select: [
        "status",
        "customer_phone",
        "customer_name",
        "payment_method",
        "platform",
        "charge",
        "user.name",
        "promo.code",
        "customer_area",
        "completedAt",
        "rider.name",
        "rejection_reason",
        "hub.name",
        "issue.type",
        "issue.refund_status",
        "issue.status",
        "returnInfo",
        "delivery_time",
      ],
      exclude: ["timeline", "order_items", "pickups"],
    };

    if (userId) {
      params["user"] = userId;
      getUser(userId);
    }
    if (riderId) {
      params["rider"] = riderId;
      getRider(riderId);
    }
    if (ids) {
      params["ids"] = ids.split(",");
    }

    getOrdersList(params);

    fetchHubs();
    fetchRestaurants({ limit: 10, skip: 0, select: ["name", "hub.name"] });

    return () => {
      if (state.user.data)
        dispatch({ type: Types.GET_USER_SUCCESS, payload: null });
      if (state.rider.data)
        dispatch({ type: Types.GET_RIDER_SUCCESS, payload: null });
      dispatch({ type: Types.GET_ORDERS_FAILURE });
    };
  }, [userId, riderId]);

  const onSelectChange = (selectedRowKeys, selectedItems) => {
    dispatch({
      type: Types.SET_SELECTED_ITEMS,
      payload: { selectedRowKeys, selectedItems },
    });
  };

  const deleteOrdersFunc = (params) => {
    const user = Parse.User.current();
    if (!user || user.get("username") !== "tech") {
      message.error("You are not authorized to delete orders.");
      return;
    }

    deleteOrders(params, (err, res) => {
      if (err) {
        return message.error(err);
      }

      const deletedOrders = res.map((i) => i.id);

      const newOrders = state.orders.data.results?.filter(
        (order) => !deletedOrders.includes(order.id)
      );
      dispatch({
        type: Types.GET_ORDERS_SUCCESS,
        payload: {
          count: state.orders.data.count - deletedOrders.length,
          results: newOrders,
        },
      });
      dispatch({
        type: Types.SET_SELECTED_ITEMS,
        payload: { selectedRowKeys: [], selectedItems: [] },
      });
      message.success(`Delete successfully ${res.length} orders!`);
    });
  };
  return (
    <OrderListContext.Provider
      value={{
        ...state,
        getOrdersList,
        dispatch,
        userId,
        riderId,
        onSelectChange,
        deleteOrders: deleteOrdersFunc,
        orderId,
        setOrderId,
        restaurants,
        setRestaurants,
        fetchRestaurants,
        fetchProducts,
        products,
      }}
    >
      <CSVLink
        data={formatData(state.orders.data.results)}
        filename={"orders.csv"}
        style={{ float: "right", marginRight: 20 }}
      >
        <Button type="primary">Export</Button>
      </CSVLink>
      <OrderListTable />

      <Drawer
        title="Order Details"
        placement="right"
        visible={Boolean(orderId)}
        onClose={() => setOrderId(null)}
        width={1200}
      >
        {orderId && <OrderDetails orderId={orderId} />}
      </Drawer>
    </OrderListContext.Provider>
  );
}
