import { useContext } from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import { Dropdown, Divider } from "antd";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { AuthContext } from "../../AuthProvider";
import { FaPowerOff } from "react-icons/fa";

const ProfileMenuWrapper = Styled.div`
	width: 200px;
	min-height: 100px;
	background: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

	.name {
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 0px;
	}

	.username, .type {
		margin-bottom: 0px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1px;
		color: #999;
	}

	.type{
		color: black;
		text-transform: capitalize;
	}

	.edit-info {
		display: flex;
		align-items: center;
	}
`;

const ProfileMenu = ({ objectId, name, username, type }) => (
  <ProfileMenuWrapper>
    <h2 className="name">{name}</h2>
    <p className="username">@{username}</p>
    <Divider style={{ margin: "10px 0" }} />
    <p className="type">
      <strong>Role: </strong>
      {type}
    </p>
    <Divider style={{ margin: "10px 0" }} />
    <div>
      <Link to={`/user/add?id=${objectId}`} className="edit-info">
        {" "}
        <EditOutlined style={{ marginRight: "5px" }} /> Edit info
      </Link>
    </div>
  </ProfileMenuWrapper>
);

export default function Logout(props) {
  const {
    logoutHandler,
    login: { data },
  } = useContext(AuthContext);
  const width = window.innerWidth;

  return (
    <Wrapper>
      <div onClick={logoutHandler} className="logout">
        <span style={{ fontSize: "18px" }}>
          <FaPowerOff />
        </span>
        {width > 768 && <span style={{ marginLeft: "5px" }}>logout</span>}
      </div>
      <Dropdown overlay={<ProfileMenu {...data} />} trigger={["click"]} arrow>
        <div className="user-icon">
          <UserOutlined />
        </div>
      </Dropdown>
    </Wrapper>
  );
}

const Wrapper = Styled.div`
	min-width: 100px;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	.user-icon {
		width: 40px;
		height: 40px;
		background: #e7e7e7;
		color: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin-left: 10px;
		cursor: pointer;
		font-size: 16px;
		transition: all 0.3s ease;

		&:hover {
			background: #d3d3d3;
		}
	}
	
	.logout{
		display: flex;
		padding: 3px 10px;
		min-width: 40px;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: calc(64px * .60);
		background: #262626;
        color: #fff;
		font-weight: 500;
		cursor: pointer;
		letter-spacing: 1px;
		border-radius: 4px;
		transition: .3s;
		display: flex;
		align-items: center;
	}

`;
