import { useState, useContext, useEffect, useCallback } from "react";
import {
  Modal,
  Button,
  Select,
  Input,
  InputNumber,
  Row,
  Col,
  Checkbox,
  Form,
  Space,
  message,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { DetailsContext } from "../../../pages/Order/Details";
import Styled from "styled-components";
import Parse from "parse";

export default function UpdateIssue({ issue }) {
  const {
    order: { data },
  } = useContext(DetailsContext);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { Item } = Form;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const onFinish = useCallback(async (values) => {
    try {
      setLoading(true);
      const {
        type,
        refund_status,
        details,
        accountable,
        restaurant_penalty,
        rider_penalty,
        refund_items,
      } = values;

      const object = {
        issueId: issue.objectId,
        type,
        refundStatus: refund_status,
        details,
        accountable,
        refundItems:
          typeof refund_items === "object"
            ? Object.entries(refund_items).map(([id, { quantity, addons }]) => {
                const orderItem = data.order_items.find(
                  (item) => item.id === id
                );
                const res = {
                  id,
                  quantity,
                  name: orderItem.name,
                };

                if (typeof addons === "object") {
                  res.addons = Object.entries(addons).map(([id, quantity]) => ({
                    id,
                    quantity,
                    name: orderItem.addons.find((addon) => addon.id === id)
                      .name,
                  }));
                }

                return res;
              })
            : null,
      };

      if (accountable) {
        if (
          accountable === "restaurant" &&
          typeof restaurant_penalty === "object"
        ) {
          object.restaurantPenalty = Object.entries(restaurant_penalty).map(
            ([id, amount]) => ({ id, amount })
          );
        } else if (accountable === "rider") {
          object.riderPenalty = rider_penalty;
        }
      }

      await Parse.Cloud.run("updateOrderIssue", object);
      // form.resetFields();
      setLoading(false);
      // setOpen(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const {
      type,
      refund_status,
      details,
      accountable,
      refund_items,
      restaurant_penalty,
      rider_penalty,
    } = issue;

    const object = {
      type,
      refund_status,
      details,
      accountable,
      refund_items: refund_items?.reduce((acc, item) => {
        acc[item.id] = {
          quantity: item.quantity,
        };

        if (item.addons) {
          acc[item.id].addons = item.addons.reduce((acc, addon) => {
            acc[addon.id] = addon.quantity;
            return acc;
          }, {});
        }
        return acc;
      }, {}),
      restaurant_penalty: restaurant_penalty?.reduce((acc, item) => {
        acc[item.id] = item.amount;
        return acc;
      }, {}),
      rider_penalty,
    };

    const items_index = refund_items?.map((item) => {
      return data.order_items.findIndex((i) => i.id === item.id);
    });

    const items_index_addons = refund_items?.reduce((acc, item) => {
      if (Array.isArray(item.addons)) {
        const i = data.order_items.findIndex((i) => i.id === item.id);
        acc[i] = item.addons.map((addon) => {
          return {
            id: addon.id,
            quantity: addon.quantity,
          };
        });
      }

      return acc;
    }, {});

    const refund_things = data.order_items.reduce((acc, item, i) => {
      if (items_index?.includes(i)) {
        if (items_index_addons?.[i]) {
          item.selectedAddons = items_index_addons?.[i].map((id) =>
            item.addons.find((a) => a.id === id)
          );
        }

        acc.push({ ...item, index: i });
      }

      return acc;
    }, []);

    object.items_index = items_index;
    object.refund_things = refund_things;
    object.items_index_addons = items_index_addons;

    setFormData(object);
    setTimeout(() => {
      form.setFieldsValue(object);
    }, 200);
  }, [issue]);

  const refundItemsRestaurant = (pickups, items = []) => {
    if (items.length === 0) return pickups;
    return pickups.filter(({ id }) =>
      items.find((item) => item.restaurant.id === id)
    );
  };

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        icon={<EditOutlined />}
        style={{ border: "none" }}
      >
        Edit
      </Button>
      <Modal
        forceRender={issue ? true : false}
        title="Raise Issue"
        visible={open}
        onCancel={() => setOpen(false)}
        width={800}
        footer={null}
      >
        <FormWrapper
          form={form}
          layout="vertical"
          name="issue"
          onFinish={onFinish}
          onChange={() => {
            const v = form.getFieldsValue();
            if (v.items_index?.length) {
              v.refund_things = data.order_items.reduce((acc, item, i) => {
                if (v.items_index.includes(i)) {
                  if (v.items_index_addons?.[i]) {
                    item.selectedAddons = v.items_index_addons?.[i].map((id) =>
                      item.addons.find((a) => a.id === id)
                    );
                  }
                  acc.push({ ...item, index: i });
                }

                return acc;
              }, []);
            } else {
              v.refund_things = [];
            }
            setFormData(v);
          }}
        >
          <Row gutter={[16]}>
            <Col span={12}>
              <Item label="Issue Type" name="type">
                <Select placeholder="Select issue type" allowClear>
                  <Select.Option value="missing item">
                    Missing Item
                  </Select.Option>
                  <Select.Option value="wrong item">Wrong Item</Select.Option>
                  <Select.Option value="late delivery">
                    Late Delivery
                  </Select.Option>
                  <Select.Option value="damaged item">
                    Damaged Item
                  </Select.Option>
                  <Select.Option value="prep time exceeded">
                    Restaurant Prep Time Exceeded
                  </Select.Option>
                  <Select.Option value="rejected by restaurant">
                    Order Rejected By Restaurant
                  </Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </Item>
              <Item label="Refund Status" name="refund_status">
                <Select placeholder="Select refund status" allowClear>
                  <Select.Option value="requested">Request</Select.Option>
                  <Select.Option value="initiated">Initiate</Select.Option>
                  <Select.Option value="completed">Complete</Select.Option>
                </Select>
              </Item>
              {formData.refund_things?.length > 0 && (
                <div>
                  <Space direction="vertical">
                    {formData.refund_things.map((item, i) => {
                      return (
                        <Space key={i} direction="vertical">
                          <Item label={<strong>{item.name}</strong>}>
                            <div style={{ marginLeft: "16px" }}>
                              <Item
                                label="Quantity"
                                name={["refund_items", item.id, "quantity"]}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <InputNumber
                                  style={{ width: "200px" }}
                                  placeholder="Quantity"
                                  min={0}
                                  max={item.quantity}
                                />
                              </Item>
                              {item.selectedAddons?.length > 0 && (
                                <Item label="Addons">
                                  <Space direction="vertical">
                                    {item.selectedAddons.map((addon, i) => {
                                      if (!addon) return null;
                                      return (
                                        <Item
                                          key={i}
                                          name={[
                                            "refund_items",
                                            item.id,
                                            "addons",
                                            addon.id,
                                          ]}
                                          title={addon.name}
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            style={{ width: "200px" }}
                                            placeholder={"Qty: " + addon.name}
                                            min={0}
                                            max={item.quantity}
                                            defaultValue={1}
                                          />
                                        </Item>
                                      );
                                    })}
                                  </Space>
                                </Item>
                              )}
                            </div>
                          </Item>
                        </Space>
                      );
                    })}
                  </Space>
                </div>
              )}
              <Item label="Details" name="details">
                <Input.TextArea rows={2} />
              </Item>
              <Item label="Accountable" name="accountable">
                <Select
                  onChange={(value) => {
                    setFormData({ ...formData, accountable: value });
                  }}
                  placeholder="Select accountable"
                  allowClear
                >
                  <Select.Option value="rider">Rider</Select.Option>
                  <Select.Option value="restaurant">Restaurant</Select.Option>
                  <Select.Option value="csr">CSR</Select.Option>
                </Select>
              </Item>
              {formData.accountable === "rider" && (
                <Item label="Rider Penalty ?" name="rider_penalty">
                  <InputNumber style={{ width: "200px" }} min={0} />
                </Item>
              )}
              {formData.accountable === "restaurant" &&
                refundItemsRestaurant(data.pickups, formData.refund_things).map(
                  (pickup) => (
                    <Item
                      key={pickup.id}
                      label={
                        <span>
                          <strong>Penalty: </strong> {pickup.name}
                        </span>
                      }
                      name={["restaurant_penalty", pickup.id]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Penalty amount"
                        style={{ width: "200px" }}
                        min={0}
                      />
                    </Item>
                  )
                )}
            </Col>
            <Col span={12}>
              <Item label="Refund Items" name="items_index">
                <Checkbox.Group style={{ width: "100%" }}>
                  <Space direction="vertical">
                    {data.order_items.map((item, i) => {
                      return (
                        <Checkbox key={i} value={i} style={{ width: "100%" }}>
                          <Item
                            label={<strong>{item.name}</strong>}
                            style={{
                              padding: "10px 20px",
                              background: "#f7f7f7",
                              width: "100%",
                            }}
                          >
                            <div>
                              Price: {item.sale_unit} x {item.quantity} ={" "}
                              {item.total}tk
                            </div>
                            {item.refund && (
                              <div>Refund: {item.refund.total} tk</div>
                            )}
                            {item.variant?.length > 0 && (
                              <Item label={<strong>Variant</strong>}>
                                {item.variant.map((v) => {
                                  return (
                                    <>
                                      {v.items.map((i) => (
                                        <div key={i.id}>
                                          {i.name} - {i.price}tk
                                        </div>
                                      ))}
                                    </>
                                  );
                                })}
                              </Item>
                            )}
                            {formData.refund_things?.[i] &&
                              item.addons?.length > 0 && (
                                <Item
                                  label={<strong>Addons</strong>}
                                  name={["items_index_addons", i]}
                                >
                                  <Checkbox.Group>
                                    <Space direction="vertical">
                                      {item.addons.map((addon) => (
                                        <Checkbox
                                          key={addon.id}
                                          value={addon.id}
                                        >
                                          {addon.name}
                                        </Checkbox>
                                      ))}
                                    </Space>
                                  </Checkbox.Group>
                                </Item>
                              )}
                          </Item>
                        </Checkbox>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </Item>
            </Col>
          </Row>

          <Item style={{ textAlign: "right" }}>
            <Space>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button loading={loading} htmlType="submit" type="primary">
                Update
              </Button>
            </Space>
          </Item>
        </FormWrapper>
      </Modal>
    </div>
  );
}

const FormWrapper = Styled(Form)`
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;

    
    &::-webkit-scrollbar {
        background: #cccccc;
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        background: #f7f7f7;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 7px;
    }


    .ant-form-item {
        margin-bottom: 10px;
    }

    .ant-form-item-label {
        padding-bottom: 0px;
    }

    .space-v {
      margin-bottom: 10px;
    }
`;
