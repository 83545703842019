import { useContext, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { DashboardContext } from "../../pages/Dashboard/DashboardContext";
import Skeleton from "./Skeleton";
import DateHandler from "./DateHandler";
import moment from "moment";
import useOrders from "../../hooks/useOrders";
import Statistics from "./Statistics";
import ProgressReport from "./ProgressReport";
import { Space, Tag } from "antd";
import { localDate } from "../../utils";
import WeekDayProgress from "./WeekDayProgress";

function NotFound() {
  return (
    <div style={{ textAlign: "center", padding: "30px 0" }}>
      <InboxOutlined
        style={{ color: "#c7c3c3", fontSize: "50px", marginBottom: "20px" }}
      />
      <h1 style={{ fontSize: "22px", color: "gray" }}>No sales found</h1>
    </div>
  );
}

export default function DashboardComponents() {
  const {
    salesAnalytics: { loading, error, data },
    weekDayAnalytics: {
      // loading: weekDayLoading,
      // error: weekDayError,
      data: weekDayData,
    },
    getSalesAnalytics,
    getWeekdayOrderAnalytics,
    getDailyOrderAverage,
  } = useContext(DashboardContext);
  const { addDay, startTime } = useOrders();
  const [date, setDate] = useState([
    moment(addDay(startTime(new Date()), -30), "yyyy-MM-DD"),
    moment(startTime(new Date()), "yyyy-MM-DD"),
  ]);

  useEffect(() => {
    getSalesAnalytics(date);
    getWeekdayOrderAnalytics(date);
    getDailyOrderAverage(date);
  }, [date]);

  return (
    <div style={{ padding: "30px 0" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DateHandler setDate={setDate} defaultValue={date} />
        {data?.date?.length > 1 && (
          <Space>
            <Tag>{localDate(data.date[0])}</Tag>
            <div>to</div>
            <Tag>{localDate(data.date[data.date?.length - 1])}</Tag>
          </Space>
        )}
      </div>
      {data && <Statistics />}
      {data && <ProgressReport growth={data.growth} />}
      {weekDayData && (
        <WeekDayProgress
          data={weekDayData.order}
          title="Order heatmap by Weekday hours"
          size={true}
        />
      )}
      {weekDayData && (
        <WeekDayProgress
          data={weekDayData.area}
          title="Order Heatmap by area hourly"
          height={900}
        />
      )}
      {!data && loading && <Skeleton />}
      {error && <NotFound />}
    </div>
  );
}
