import { Card, DatePicker, Space, message } from "antd";
import React, { useEffect } from "react";
import Parse from "parse";
import { useLocation } from "react-router-dom";
import moment from "moment";
import TopUserByMonth from "./components/TopUserByMonth";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const UserDashboard = () => {
  const [users, setUsers] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const query = useQuery();

  useEffect(() => {
    const getUserDashboard = async () => {
      setLoading(true);
      try {
        let start_date,
          end_date = null;

        if (query.get("start_date") && query.get("end_date")) {
          start_date = new Date(query.get("start_date")!);
          end_date = new Date(query.get("end_date")!);
        } else {
          start_date = new Date();
          end_date = new Date();
        }

        const result = await Parse.Cloud.run("getUserDashboard", {
          start_date,
          end_date,
        });

        if (result) {
          setUsers(result);
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        message.error(error.message);
      }
    };
    getUserDashboard();
  }, [query]);

  return (
    <Card
      title="🔥 User Dashboard"
      extra={
        <DatePicker.RangePicker
          value={[
            query.get("start_date")
              ? moment(query.get("start_date"))
              : moment(),
            query.get("end_date") ? moment(query.get("end_date")) : moment(),
          ]}
          onChange={(date, dateString) => {
            window.location.href = `/user/dashboard?start_date=${date?.[0]}&end_date=${date?.[1]}`;
          }}
        />
      }
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <TopUserByMonth users={users} loading={loading} />
      </Space>
    </Card>
  );
};

export default UserDashboard;
