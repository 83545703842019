import { Space } from "antd";
import RaiseIssue from "../../Details/RaiseIssue";
import Charge from "./Charge";
import { ExpandOutlined } from "@ant-design/icons";

export default function RightSide(props) {
  const { status } = props;
  return (
    <div className="right-side">
      <Space>
        {["delivered", "rejected", "cancelled"].includes(status) && (
          <RaiseIssue data={props} shape="circle" />
        )}
        <Charge {...props} />
        <div style={{ cursor: "pointer", color: "#fff", marginLeft: "10px" }}>
          <ExpandOutlined
            style={{ fontSize: "22px" }}
            onClick={() => props.setOrderId(props.id)}
          />
        </div>
      </Space>
    </div>
  );
}
