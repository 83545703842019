import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  InputNumber,
  message,
  Space,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import OrderDetails from "../../../pages/Order/Details";
import {
  fetchTravelLogs,
  updateTravelLog,
} from "../../../store/rider/riderSlices";
import Parse from "parse";

function UpdateLog({ id, traveled, orderId }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(traveled);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    try {
      if (!value) throw new Error("Value is required");
      setLoading(true);
      const object = await new Parse.Query("rider_travel")
        .equalTo("objectId", id)
        .first();
      if (object) {
        object.set("traveled", value);
        await object.save();
        dispatch(updateTravelLog({ orderId, id: object.id, value }));
        setOpen(false);
      } else {
        throw new Error("Something went wrong");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <div>
      {!open && (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => setOpen(true)}
        />
      )}
      {open && (
        <Space>
          <InputNumber
            onChange={(v) => {
              setValue(v);
            }}
            value={value}
            style={{ width: "150px" }}
          />
          <Button
            type="danger"
            icon={<CloseOutlined />}
            shape="circle"
            onClick={() => setOpen(false)}
          ></Button>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            shape="circle"
            onClick={handleUpdate}
            loading={loading}
          ></Button>
        </Space>
      )}
    </div>
  );
}

export default function TravelLogs() {
  const { data, loading } = useSelector((state) => state.rider.travelLogs);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [date, setDate] = useState([]);
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    dispatch(
      fetchTravelLogs({
        riderId: id,
        date,
      })
    );
  }, [id, date]);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "objectId",
      key: "objectId",
      render: (id) =>
        window.innerWidth < 768 ? (
          <Link
            target="__blank"
            style={{ color: "black" }}
            to={`/order/list/${id}`}
          >
            {id}
          </Link>
        ) : (
          <div onClick={() => setOrderId(id)} style={{ cursor: "pointer" }}>
            {id}
          </div>
        ),
    },
    {
      title: "Traveled",
      dataIndex: "traveled",
      key: "traveled",
      render: (traveled = 0) => <div>{traveled.toFixed(2)} km</div>,
    },
  ];

  const expandedRowRender = ({ items = [], objectId }) => {
    const columns = [
      {
        title: "Status",
        dataIndex: "subject",
        key: "subject",
        width: "160px",
      },
      {
        title: "Traveled",
        dataIndex: "traveled",
        key: "traveled",
        width: "160px",
      },
      {
        title: "Update Log",
        key: "UpdateLog",
        width: "160px",
        render: (record) => <UpdateLog {...record} orderId={objectId} />,
      },
      {
        title: "Map",
        key: "map",
        width: "160px",
        render: ({ geo, prevGeo }) =>
          (geo || prevGeo) && (
            <a
              href={`https://www.google.com/maps/dir/${
                geo?.latitude + "," + geo?.longitude
              }/${prevGeo ? prevGeo.latitude + "," + prevGeo.longitude : ""}`}
              target="__blank"
            >
              Open Map
            </a>
          ),
      },
    ];

    return (
      <Table
        dataSource={items.map((item, i) => ({
          ...item,
          key: i,
        }))}
        columns={columns}
        pagination={false}
        size="small"
      />
    );
  };

  return (
    <Wrapper>
      <Table
        dataSource={data.orders?.map((o, i) => ({ ...o, key: i })) || []}
        columns={columns}
        pagination={false}
        loading={loading}
        title={() => (
          <div className="flex-between">
            <h2>Total {data.total.toFixed(2)} KM</h2>
            <DatePicker.RangePicker
              size="small"
              onChange={(d, dateStr) => {
                setDate(dateStr);
              }}
            />
          </div>
        )}
        scroll={{
          y: "400px",
        }}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        rowClassName={(record) => (record.traveled > 20 ? "bg-red" : "")}
      />
      <Drawer
        title="Order Details"
        placement="right"
        closable={true}
        onClose={() => setOrderId("")}
        visible={orderId}
        width={1000}
      >
        {orderId && <OrderDetails orderId={orderId} />}
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 400px;
  max-height: 600px;

  .title {
    font-size: 16px;
  }

  .ant-table {
    box-shadow: none;
  }

  .bg-red {
    background-color: #ff7875;
  }
`;
