import { useState, useCallback } from "react";
import { Form, Row, Col, Input, message } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import Map from "./Map";
import axios from "axios";

const { Item } = Form;

export default function Address({ form, formData, setFormData }) {
  const [places, setPlaces] = useState([]);
  const [counter, setCounter] = useState(0);
  const [mapOpen, setMapOpen] = useState(false);

  const onChange = useCallback(
    (e) => {
      if (!formData.address?.latitude) {
        const value = e.target.value;
        if (value.length === 0 && places.length) {
          setPlaces([]);
        }

        setCounter(counter + 1);

        if (value.length < 10 || counter < 3) {
          return;
        }
        setCounter(0);

        const link = `https://barikoi.xyz/v1/api/search/verify/autocomplete/${process.env.REACT_APP_BARIKOI_API_KEY}/place?q=${e.target.value}`;

        fetch(link)
          .then((res) => res.json())
          .then((data) => {
            if (Array.isArray(data.places)) {
              setPlaces(data.places);
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
    },
    [formData, counter]
  );

  const getAddress = async (lat, lng, done) => {
    if (
      lat !== formData?.address?.latitude ||
      lng !== formData?.address?.longitude
    ) {
      const link = `https://barikoi.xyz/v1/api/search/reverse/${process.env.REACT_APP_BARIKOI_API_KEY}/geocode?longitude=${lng}&latitude=${lat}&city=true&post_code=true&country=true&address=true&area=true`;
      const { data } = await axios.get(link, {
        timeout: 30 * 1000,
      });
      if (data.status === 200 && data.place) {
        setAddress({ ...data.place, latitude: lat, longitude: lng });
        setMapOpen(false);
      }
    } else {
      setMapOpen(false);
    }
    if (typeof done === "function") {
      done();
    }
  };

  const setAddress = useCallback(
    (place) => {
      form.setFieldsValue({
        address: place,
      });

      setFormData({ ...formData, address: place });
      setPlaces([]);
    },
    [form, formData]
  );

  return (
    <div className="section">
      <h3 className="section-title">Address</h3>
      <Item>
        <div className="address">
          <Item name={["address", "address"]} label="Address">
            <Input
              suffix={
                <EnvironmentOutlined
                  onClick={() => {
                    if (
                      formData.address.latitude &&
                      formData.address.longitude
                    ) {
                      setMapOpen(true);
                    }
                  }}
                />
              }
              onChange={onChange}
            />
          </Item>

          <Map
            setAddress={getAddress}
            open={mapOpen && formData?.address?.latitude}
            close={() => {
              setMapOpen(false);
            }}
            lat={formData?.address?.latitude}
            lng={formData?.address?.longitude}
          />

          {formData?.address?.address && places.length > 0 && (
            <div className="places customScroll">
              {places.map((place) => (
                <div
                  onClick={() => setAddress(place)}
                  className="place"
                  key={place.id}
                >
                  {place.address}
                </div>
              ))}
            </div>
          )}
        </div>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Item
              name={["address", "area"]}
              label="Area"
              rules={[
                {
                  required: true,
                  message: "Please select a location from map",
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item name={["address", "city"]} label="City">
              <Input disabled />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name={["address", "latitude"]}
              label="Latitude"
              rules={[
                {
                  required: true,
                  message: "Please select a location from map",
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name={["address", "longitude"]}
              label="Longitude"
              rules={[
                {
                  required: true,
                  message: "Please select a location from map",
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item name={["address", "postCode"]} label="Post Code">
              <Input />
            </Item>
          </Col>
          <Col span={12} style={{ display: "none" }}>
            <Item name={["address", "pType"]} label="PType">
              <Input />
            </Item>
          </Col>
        </Row>
      </Item>
    </div>
  );
}
