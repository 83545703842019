import { useState, useEffect } from "react";
import Parse from "parse";
import { Row, message, Table, Space, Affix, Button } from "antd";
import { addDays } from "date-fns";
import RestaurantLinks from "../../components/Common/RestaurantLinks";
import { Link } from "react-router-dom";
import StatisticsItem from "../../components/Common/StatisticsItem";
import DateHandler from "../../components/dashboard/DateHandler";
import moment from "moment";
import useSearch from "../../components/inventory/utils/useSearch";
import { downloadCsv } from "../../utils";
import { DownloadOutlined } from "@ant-design/icons";

function Statistics({
  total,
  delivered,
  rejected,
  failedAmount,
  discount,
  commission,
}) {
  return (
    <Row
      gutter={[16, 16]}
      className="statistics"
      style={{ marginBottom: "20px" }}
    >
      <StatisticsItem
        title="Delivered Order"
        value={delivered}
        isAmount={false}
        value2={
          <div direction="vertical" style={{ textAlign: "center" }}>
            <div>{rejected}</div>
            <div style={{ color: "#fff" }}>Rejected</div>
          </div>
        }
      />
      <StatisticsItem
        title="Total Sales"
        value={total}
        value2={
          <div direction="vertical" style={{ textAlign: "center" }}>
            <div>{commission} tk</div>
            <div style={{ color: "#fff" }}>Commission</div>
          </div>
        }
      />
      <StatisticsItem title="Failed Amount" value={failedAmount} />
      <StatisticsItem title="Discount" value={discount} />
    </Row>
  );
}

export default function RestaurantSales() {
  const [sales, setSales] = useState(null);
  const [date, setDate] = useState([
    moment(addDays(new Date(), -1), "yyyy-MM-DD"),
    moment(new Date(), "yyyy-MM-DD"),
  ]);
  const [hubs, setHubs] = useState([]);
  const [, searchProps] = useSearch();

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select(["name"]).find();
      setHubs(hubs.map((h) => h.toJSON()));
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchSales = async ({ date, ...rest }) => {
    try {
      message.loading("Loading...", 0);
      const sales = await Parse.Cloud.run("restaurantSalesByDate", {
        date: date.map((d) =>
          d instanceof moment ? d.format("YYYY-MM-DD") : d
        ),
        ...rest,
      });
      setSales(sales);
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchHubs();
    fetchSales({ date });
  }, [date]);

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: "50px",
    },
    {
      title: "Image",
      dataIndex: "banner_image",
      key: "banner_image",
      width: "50px",
      render: (img) => (
        <img style={{ width: "50px", borderRadius: "5px" }} src={img} alt="" />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "300px",
      ...searchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, { id }) => {
        return (
          <RestaurantLinks id={id}>
            <Space>
              <div>
                <div>{name}</div>
              </div>
            </Space>
          </RestaurantLinks>
        );
      },
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
      width: "150px",
      filters: hubs.map((h) => ({ text: h.name, value: h.objectId })),
      onFilter: (value, record) => record.hub?.objectId === value,
      render: (hub) => hub.name,
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      sorter: (a, b) => a.delivered - b.delivered,
    },
    {
      title: "Rejected",
      dataIndex: "rejected",
      key: "rejected",
    },
    {
      title: "Sales",
      dataIndex: "total",
      key: "sales",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      sorter: (a, b) => a.commission - b.commission,
    },
    {
      title: "View",
      key: "action",
      render: ({ id }) => (
        <Link
          target="_blank"
          to={`/restaurant/${id}/payments?date=${date
            .map((d) => (d instanceof moment ? d.format("YYYY-MM-DD") : d))
            .join("to")}`}
        >
          Sales
        </Link>
      ),
    },
  ];

  const addKey = (data) => {
    if (!Array.isArray(data)) return [];
    data.sort((a, b) => b.total - a.total);
    return data.map((item, i) => ({ ...item, key: i + 1 }));
  };

  const downloadHandler = () => {
    downloadCsv(
      [
        {
          header: [
            {
              title: "Name",
              key: "name",
            },
            {
              title: "Hub",
              key: "hub",
            },
            {
              title: "Delivered",
              key: "delivered",
            },
            {
              title: "Rejected",
              key: "rejected",
            },
            {
              title: "Sales",
              key: "total",
            },
            {
              title: "Discount",
              key: "discount",
            },
            {
              title: "Commission",
              key: "commission",
            },
            {
              title: "Revenue",
              key: "revenue",
            },
          ],
          data: sales?.restaurants.map((r) => ({
            name: r.name,
            hub: r.hub.name,
            delivered: r.delivered,
            rejected: r.rejected,
            discount: r.discount,
            revenue: r.revenue,
            total: r.total,
            commission: r.commission,
          })),
        },
      ],
      "Sales Data"
    );
  };

  return (
    <div>
      <DateHandler setDate={setDate} defaultValue={date} />
      {sales && <Statistics {...sales} />}
      <Affix offsetTop={64}>
        <Table
          title={() => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={downloadHandler}
              >
                Download
              </Button>
            </div>
          )}
          loading={!sales}
          columns={columns}
          dataSource={addKey(sales?.restaurants)}
          pagination={{
            defaultPageSize: sales?.restaurants.length,
          }}
          onChange={(pagination, filters, sorter) => {
            fetchSales({ date, hub: filters.hub });
          }}
          scroll={{ x: "max-content", y: 600 }}
        />
      </Affix>
    </div>
  );
}
