import {
  Row,
  Col,
  Table,
  message,
  Popover,
  Form,
  Input,
  Select,
  Button,
  Space,
  DatePicker,
  InputNumber,
  Affix,
  Tag,
} from "antd";
import { useReducer, useState, useEffect } from "react";
import Parse from "parse";
import styled from "styled-components";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import useRestaurants from "../../hooks/useRestaurants";
import useCategories from "../../hooks/useCategories";
import useProduct from "../../hooks/useProduct";
import useUser from "../../hooks/useUser";
import { days, hours } from "../Sections/Scheduling";
import { Link } from "react-router-dom";
import { downloadCsv } from "../../utils";

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_ORDERS_REQUEST":
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
        },
      };
    case "GET_ORDERS_SUCCESS":
      return {
        ...state,
        orders: action.payload,
      };
    case "GET_ORDERS_ERROR":
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
        },
      };
    default:
      return state;
  }
};

function StringItems({ name, args }) {
  const [type, setType] = useState("contains");
  return (
    <>
      <Col span={7}>
        <Form.Item {...args} name={[name, "type"]}>
          <Select placeholder="Select Type" onChange={(v) => setType(v)}>
            <Select.Option value="in" selected>
              Contains
            </Select.Option>
            <Select.Option value="search" selected>
              Search
            </Select.Option>
            <Select.Option value="btw" selected>
              Between
            </Select.Option>
            <Select.Option value="eq">Equals</Select.Option>
            <Select.Option value="ne">Not Equals</Select.Option>
            <Select.Option value="ex">Exists</Select.Option>
            <Select.Option value="nex">Not Exists</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      {type && !["ex", "nex", "in"].includes(type) && (
        <Col span={7}>
          <Form.Item {...args} name={[name, "value"]}>
            <Input placeholder="Enter Value" />
          </Form.Item>
        </Col>
      )}
      {type && type === "in" && (
        <Col span={7}>
          <Form.Item {...args} name={[name, "value"]}>
            <Select mode="tags" placeholder="tag1, tag2, ..." />
          </Form.Item>
        </Col>
      )}
    </>
  );
}

function NumberItems({ name, args }) {
  const [type, setType] = useState("equals");

  return (
    <>
      <Col span={7}>
        <Form.Item {...args} name={[name, "type"]}>
          <Select placeholder="Select Type" onChange={(v) => setType(v)}>
            <Select.Option value="eq" selected>
              Equals
            </Select.Option>
            <Select.Option value="in">Contains</Select.Option>
            <Select.Option value="ne">Not Equals</Select.Option>
            <Select.Option value="gt">Greater Than</Select.Option>
            <Select.Option value="gte">Greater Than Or Equal</Select.Option>
            <Select.Option value="lt">Less Than</Select.Option>
            <Select.Option value="lte">Less Than Or Equal</Select.Option>
            <Select.Option value="ex">Exists</Select.Option>
            <Select.Option value="nex">Not Exists</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {type !== "in" && !["ex", "nex"].includes(type) && (
        <Col span={7}>
          <Form.Item {...args} name={[name, "value"]}>
            <InputNumber style={{ width: "160px" }} placeholder="Enter Value" />
          </Form.Item>
        </Col>
      )}
      {type === "in" && (
        <Col span={7}>
          <Form.Item {...args} name={[name, "value"]}>
            <Select
              placeholder="Search here..."
              mode={"tags"}
              allowClear
            ></Select>
          </Form.Item>
        </Col>
      )}
    </>
  );
}

function MultiSelect({ name, filters = [], onChange, filterType, args }) {
  const [items, setItems] = useState(filters);
  return (
    <Col span={14}>
      <Form.Item {...args} name={[name, "value"]}>
        <Select
          placeholder="Search here..."
          mode={filterType || "multiple"}
          showSearch
          onSearch={(v) => {
            if (typeof onChange === "function") {
              onChange(v, setItems);
            }
          }}
          optionFilterProp="children"
          filterOption={true}
          allowClear
        >
          {items.map((filter, i) => (
            <Select.Option key={i} value={filter.value}>
              {filter.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
}

function DateItems({ name, args }) {
  return (
    <>
      <Col span={14}>
        <Form.Item {...args} name={[name, "value"]}>
          <DatePicker.RangePicker format="DD-MM-YYYY" />
        </Form.Item>
      </Col>
    </>
  );
}

function FilterHistory({ setFilter }) {
  const [history, setHistory] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    let queries = localStorage.getItem("order-filters");
    if (queries) {
      queries = JSON.parse(queries);
      setHistory(Object.values(queries));
    }
  }, [reload]);

  const deleteItem = (idx) => {
    history.splice(idx, 1);
    setHistory([...history]);

    const newHistory = {};
    history.forEach((item) => {
      newHistory[item.title] = item;
    });

    localStorage.setItem("order-filters", JSON.stringify(newHistory));
  };

  return (
    <Col span={24} xl={6}>
      <div className="filter-history customScroll">
        <div className="top">
          <h3>Saved Queries</h3>
          <Button
            onClick={() => setReload(!reload)}
            shape="circle"
            icon={<ReloadOutlined />}
          />
        </div>
        {history.map((item, i) => {
          return (
            <div key={i} className="item">
              <div>{item.title}</div>
              <Space>
                <Button
                  onClick={() => deleteItem(i)}
                  icon={<CloseOutlined />}
                  shape="circle"
                  danger
                  size="small"
                />
                <Button
                  onClick={() => setFilter(item)}
                  icon={<CheckOutlined />}
                  shape="circle"
                  size="small"
                />
              </Space>
            </div>
          );
        })}
      </div>
    </Col>
  );
}

const select = [
  {
    text: "Order ID",
    value: "order_id",
  },
  {
    text: "Date",
    value: "date",
  },
  {
    text: "Customer Name",
    value: "customer_name",
  },
  {
    text: "User ID",
    value: "user_id",
  },
  {
    text: "Order ID",
    value: "order_id",
  },
  {
    text: "Customer Phone",
    value: "customer_phone",
  },
  {
    text: "Customer Address",
    value: "customer_address",
  },
  {
    text: "User Order No",
    value: "user_order_no",
  },
  {
    text: "User Order Gap",
    value: "user_gap_in_order",
  },
  {
    text: "Platform",
    value: "platform",
  },
  {
    text: "Hub Name",
    value: "hub_name",
  },
  {
    text: "Order Items",
    value: "products",
  },
  {
    text: "Restaurants",
    value: "restaurants",
  },
  {
    text: "Categories",
    value: "categories",
  },
  {
    text: "Store Revenue",
    value: "store_revenue",
  },
  {
    text: "Items Quantity",
    value: "itemsQuantity",
  },
  {
    text: "Items Total",
    value: "itemsTotal",
  },
  {
    text: "Order Day",
    value: "order_day",
  },
  {
    text: "Order Hour",
    value: "order_hour",
  },
  {
    text: "Total Delivery Time",
    value: "delivered_time",
  },
  {
    text: "Customer Area",
    value: "customer_area",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "Order Total",
    value: "order_total",
  },
  {
    text: "Discount",
    value: "discount",
  },
  {
    text: "Customer Email",
    value: "customer_email",
  },
  {
    text: "Customer Gender",
    value: "customer_gender",
  },
  {
    text: "Payment Method",
    value: "payment_method",
  },
  {
    text: "Promo",
    value: "promo",
  },
  {
    text: "Rider Name",
    value: "rider_name",
  },
  {
    text: "Delivery Charge",
    value: "delivery_charge",
  },
  {
    text: "Rejection Reason",
    value: "rejection_reason",
  },
  {
    text: "Order Month",
    value: "order_month",
  },
  {
    text: "Order Year",
    value: "order_year",
  },
  {
    text: "Payment TrxID",
    value: "payment_trxID",
  },
  {
    text: "Confirmation Time",
    value: "confirmation_time",
  },
  {
    text: "Restaurant Confirmation Time",
    value: "res_confirmation_time",
  },
  {
    text: "Ready To Pickup Time",
    value: "ready_to_picked_time",
  },
  {
    text: "Restaurant Preparation Time",
    value: "res_preparation_time",
  },
  {
    text: "Rider Assigned By",
    value: "rider_assigned_by",
  },
  {
    text: "Picked To Delivery Time",
    value: "picked_to_delivery_time",
  },
  {
    text: "Rider Note",
    value: "rider_note",
  },
  {
    text: "Restaurant Note",
    value: "note",
  },
  {
    text: "Is Priority",
    value: "isPriority",
  },
];

function Filter({ fetchData }) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const { getRestaurants } = useRestaurants();
  const { getMany } = useCategories();
  const { getProducts } = useProduct();
  const { getUsers } = useUser();

  const columns = [
    {
      title: "Date",
      key: "date",
      type: "date",
    },
    {
      title: "Order ID",
      key: "order_id",
      type: "text",
    },
    {
      title: "Customer ID",
      key: "user_id",
      type: "text",
    },
    {
      title: "Customer Name",
      key: "customer_name",
      type: "text",
    },
    {
      title: "Customer Phone",
      key: "customer_phone",
      type: "text",
    },
    {
      title: "Customer Address",
      key: "customer_address",
      type: "text",
    },
    {
      title: "Customer Order No",
      key: "user_order_no",
      type: "number",
    },
    {
      title: "Customer Order Gaps (days)",
      key: "user_gap_in_order",
      type: "number",
    },
    {
      title: "Platform",
      key: "platform",
      type: "select",
      filters: [
        {
          text: "Web",
          value: "web",
        },
        {
          text: "App",
          value: "app",
        },
      ],
    },
    {
      title: "Hub Name",
      key: "hub_name",
      type: "select",
      filterType: "tags",
    },
    {
      title: "Restaurant",
      key: "restaurantId",
      type: "select",
      filters: [],
      onChange: (v, setItems) => {
        getRestaurants(
          { search: v, select: ["name"], limit: 10 },
          (err, res) => {
            if (res) {
              setItems(
                res.results.map((r) => ({ text: r.get("name"), value: r.id }))
              );
            }
          }
        );
      },
    },
    {
      title: "Restaurant Name",
      key: "restaurantName",
      type: "text",
    },
    {
      title: "Product",
      key: "id",
      type: "select",
      filters: [],
      onChange: (v, setItems) => {
        getProducts({ search: v, select: ["name"], limit: 5 }, (err, res) => {
          if (res) {
            setItems(
              res.results.map((r) => ({ text: r.get("name"), value: r.id }))
            );
          }
        });
      },
    },
    {
      title: "Product Name",
      key: "name",
      type: "text",
    },
    {
      title: "Items Quantity",
      key: "itemsQuantity",
      type: "number",
    },
    {
      title: "Items Total",
      key: "itemsTotal",
      type: "number",
    },
    {
      title: "Store Revenue",
      key: "store_revenue",
      type: "number",
    },
    {
      title: "Category",
      key: "categoryId",
      type: "select",
      filters: [],
      onChange: (v, setItems) => {
        getMany({ search: v, select: ["name"], limit: 5 }, (err, res) => {
          if (res) {
            setItems(
              res.results.map((r) => ({ text: r.get("name"), value: r.id }))
            );
          }
        });
      },
    },
    {
      title: "Category Name",
      key: "categoryName",
      type: "text",
    },
    {
      title: "Order Day",
      key: "order_day",
      type: "select",
      filters: days.map((d) => ({ text: d.label, value: d.label })),
    },
    {
      title: "Order Hour",
      key: "order_hour",
      type: "select",
      filters: hours.map((h) => ({ text: h.label, value: h.value })),
    },
    {
      title: "Total Delivery Time",
      key: "delivered_time",
      type: "number",
    },
    {
      title: "Customer Area",
      key: "customer_area",
      type: "text",
    },
    {
      title: "Status",
      key: "status",
      type: "select",
      filters: [
        {
          text: "Delivered",
          value: "delivered",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
    },
    {
      title: "Order Total",
      key: "order_total",
      type: "number",
    },
    {
      title: "Discount",
      key: "discount",
      type: "number",
    },
    {
      title: "Customer Email",
      key: "customer_email",
      type: "text",
    },
    {
      title: "Customer Gender",
      key: "customer_gender",
      type: "select",
      filters: [
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Female",
          value: "Female",
        },
        {
          text: "male",
          value: "male",
        },
        {
          text: "female",
          value: "female",
        },
      ],
    },
    {
      title: "Payment Method",
      key: "payment_method",
      type: "select",
      filters: [
        {
          text: "Cod",
          value: "cod",
        },
        {
          text: "Online",
          value: "online",
        },
        {
          text: "Bkash",
          value: "bkash",
        },
      ],
    },
    {
      title: "Promo",
      key: "promo",
      type: "text",
    },
    {
      title: "Rider",
      key: "rider_id",
      type: "select",
      filters: [],
      onChange: (v, setItems) => {
        getUsers({ name: v, select: ["name"], type: "rider" }, (err, res) => {
          if (res) {
            setItems(
              res.results.map((r) => ({ text: r.get("name"), value: r.id }))
            );
          }
        });
      },
    },
    {
      title: "Delivery Charge",
      key: "delivery_charge",
      type: "number",
    },
    {
      title: "Rejection Reason",
      key: "rejection_reason",
      type: "text",
    },
    {
      title: "Order Month",
      key: "order_month",
      type: "number",
    },
    {
      title: "Order Year",
      key: "order_year",
      type: "number",
    },
    {
      title: "Payment TrxID",
      key: "payment_trxID",
      type: "text",
    },
    {
      title: "Confirmation Time",
      key: "confirmation_time",
      type: "number",
    },
    {
      title: "Restaurant Confirmation Time",
      key: "res_confirmation_time",
      type: "number",
    },
    {
      title: "Restaurant Preparation Time",
      key: "res_preparation_time",
      type: "number",
    },
    {
      title: "Rider Assigned By",
      key: "rider_assigned_by",
      type: "text",
    },
    {
      title: "Ready to Pickup Time",
      key: "ready_to_picked_time",
      type: "number",
    },
    {
      title: "Picked To Delivery Time",
      key: "picked_to_delivery_time",
      type: "number",
    },
    {
      title: "Rider Note",
      key: "rider_note",
      type: "text",
    },
    {
      title: "Restaurant Note",
      key: "note",
      type: "text",
    },
    {
      title: "Is Priority",
      key: "isPriority",
      type: "select",
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
    },
  ];

  const onFinish = (values) => {
    fetchData(values);
  };

  const getType = (key, name, args) => {
    if (!formData[key]) return null;

    const { values } = formData[key];
    if (values) {
      const type = values.type;
      if (typeof type === "string" && type === "text") {
        return <StringItems name={name} args={args} />;
      } else if (typeof type === "string" && type === "number") {
        return <NumberItems name={name} args={args} />;
      } else if (typeof type === "string" && type === "select") {
        return <MultiSelect name={name} {...values} args={args} />;
      } else if (typeof type === "string" && type === "date") {
        return <DateItems name={name} {...values} args={args} />;
      }
    }

    return null;
  };

  return (
    <FilterStyle gutter={[24, 24]}>
      <Col span={24} xl={18}>
        <div className="filter-form">
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[24, 10]} style={{ marginBottom: "10px" }}>
              <Col span={8}>
                <Form.Item name="title">
                  <Input placeholder="Filter Title" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="limit">
                  <InputNumber placeholder="limit" />
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="filters">
              {(fields, { add, remove }) => {
                return (
                  <Row gutter={[24, 10]}>
                    {fields.map((field, i) => {
                      return (
                        <Col span={24} key={field.key}>
                          <Row gutter={[24, 16]}>
                            <Col span={7}>
                              <Form.Item
                                {...field}
                                key={field.key}
                                name={[field.name, "name"]}
                              >
                                <Select
                                  placeholder="Select Item"
                                  onChange={(value) => {
                                    setFormData({
                                      ...formData,
                                      [field.fieldKey]: {
                                        key: value,
                                        values: columns.find(
                                          (c) => c.key === value
                                        ),
                                      },
                                    });
                                  }}
                                  showSearch
                                  optionFilterProp="children"
                                >
                                  {columns.map((col, i) => (
                                    <Select.Option key={i} value={col.key}>
                                      {col.title}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            {formData[field.fieldKey] &&
                              getType(field.fieldKey, field.name, {
                                ...field,
                                key: field.key,
                              })}
                            <Col span={3}>
                              <Form.Item>
                                <Button
                                  icon={<MinusOutlined />}
                                  onClick={() => {
                                    remove(field.name);
                                    delete formData[field.fieldKey];
                                    setFormData({ ...formData });
                                  }}
                                  shape="circle"
                                ></Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                    <Col span={24}>
                      <Form.Item>
                        <Button icon={<PlusOutlined />} onClick={() => add()}>
                          Add Filter
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            </Form.List>
            <Row gutter={[24, 10]} style={{ marginTop: "10px" }}>
              <Col span={24}>
                <Form.Item name="select" label="Necessary Fields">
                  <Select
                    placeholder="Select Necessary Fields"
                    mode="multiple"
                    showSearch
                    filterOption={true}
                    allowClear
                    optionFilterProp="children"
                  >
                    {select.map((filter, i) => (
                      <Select.Option key={i} value={filter.value}>
                        {filter.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sortBy" label="Sort By">
                  <Select
                    placeholder="Sort By"
                    showSearch
                    filterOption={true}
                    allowClear
                    optionFilterProp="children"
                  >
                    {columns.map((col, i) => (
                      <Select.Option key={i} value={col.key}>
                        {col.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="sortOrder" label="Sorting Order">
                  <Select placeholder="Sort Order">
                    <Select.Option value="asc">Ascending</Select.Option>
                    <Select.Option value="desc">Descending</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="distinct" label="Distinct">
                  <Select
                    placeholder="Distinct"
                    showSearch
                    filterOption={true}
                    allowClear
                    optionFilterProp="children"
                  >
                    {columns.map((col, i) => (
                      <Select.Option key={i} value={col.key}>
                        {col.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ marginTop: "10px" }}>
              <Space>
                <Space>
                  <Form.Item>
                    <Button
                      onClick={() => {
                        const data = form.getFieldsValue();
                        if (!data.title) {
                          message.error("Please enter filter title");
                          return;
                        }
                        let filters = localStorage.getItem("order-filters");
                        if (!filters) filters = "{}";
                        filters = JSON.parse(filters);
                        filters[data.title] = data;
                        localStorage.setItem(
                          "order-filters",
                          JSON.stringify(filters)
                        );
                      }}
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Space>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Col>
      <FilterHistory
        setFilter={(data) => {
          form.resetFields();
          form.setFieldsValue(data);
          let formData = {};
          data.filters?.forEach((item, i) => {
            const col = columns.find((c) => c.key === item.name);
            if (col) {
              formData[i] = {
                key: col.key,
                values: col,
              };
            }
          });
          setFormData(formData);
        }}
      />
    </FilterStyle>
  );
}

const FilterStyle = styled(Row)`
  margin-bottom: 20px;

  .filter-form {
    background: #fff;
    padding: 16px 20px;
    margin-bottom: 20px;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .filter-history {
    background: #fff;
    padding: 20px;
    min-height: 200px;
    max-height: 400px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      background: #f7f7f7;
      padding: 8px 16px;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid lightgray;
    }
  }
`;

export default function OrderDashboard() {
  const [state, dispatch] = useReducer(reducer, {
    orders: {
      count: 0,
      loading: false,
      results: [],
    },
  });

  const fetchData = async (props) => {
    try {
      dispatch({
        type: "GET_ORDERS_REQUEST",
      });
      if (!props.filters) {
        props.filters = [];
      }
      const filters = [];

      props.filters.forEach((item) => {
        if (item.name === "date" && Array.isArray(item.value)) {
          filters.push({
            ...item,
            value: item.value.map((v) => new Date(v)),
          });
        } else {
          filters.push(item);
        }
      });

      const date = filters.find((f) => f.name === "date");
      if (date?.value) {
        if (date.value[0]) {
          date.value[0] = new Date(date.value[0]);
        }
        if (date.value[1]) {
          date.value[1] = new Date(date.value[1]);
        }
      }

      const res = await Parse.Cloud.run("fetchAnalyticalData", {
        ...props,
        filters,
      });
      dispatch({
        type: "GET_ORDERS_SUCCESS",
        payload: {
          count: res.count,
          loading: false,
          results: res.results,
          props,
        },
      });
    } catch (err) {
      message.error(err.message);
      dispatch({
        type: "GET_ORDERS_ERROR",
      });
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "120px",
      render: (id) => (
        <Link target="__blank" to={`/order/list/${id}`}>
          {id}
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "120px",
      render: (date) =>
        date ? new Date(date?.iso || date).toLocaleString() : null,
    },
    {
      title: "Customer ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "120px",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: "120px",
    },
    {
      title: "Customer Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
      width: "120px",
    },
    {
      title: "Customer Address",
      dataIndex: "customer_address",
      key: "customer_address",
      width: "120px",
    },
    {
      title: "Customer Area",
      dataIndex: "customer_area",
      key: "customer_area",
      width: "120px",
    },
    {
      title: "Customer Email",
      dataIndex: "customer_email",
      key: "customer_email",
      width: "120px",
    },
    {
      title: "Customer Gender",
      dataIndex: "customer_gender",
      key: "customer_gender",
      width: "120px",
    },
    {
      title: "Customer Order No",
      dataIndex: "user_order_no",
      key: "user_order_no",
      width: "120px",
    },
    {
      title: "Customer Order Gap (Days)",
      dataIndex: "user_gap_in_order",
      key: "user_gap_in_order",
      width: "120px",
    },
    {
      title: "Hub Name",
      dataIndex: "hub_name",
      key: "hub_name",
      width: "120px",
    },
    {
      title: "Order Items",
      dataIndex: "products",
      key: "products",
      width: "120px",
      render: (products) => {
        return (
          <Popover
            content={
              <div
                style={{ maxHeight: "400px", minWidth: "300px" }}
                className="customScroll"
              >
                {products?.map((p) => (
                  <div key={p.id}>
                    <div
                      style={{
                        padding: "10px",
                        marginBottom: "5px",
                        background: "#f7f7f7",
                        borderRadius: "10px",
                      }}
                    >
                      <div style={{ fontWeight: "500" }}>
                        [{p.quantity}] {p.name} ({p.total} tk)
                      </div>
                      <div>Restaurant: {p.restaurantName}</div>
                      <div>Category: {p.categoryName}</div>
                    </div>
                  </div>
                ))}
              </div>
            }
          >
            <Button type="primary">Items</Button>
          </Popover>
        );
      },
    },
    {
      title: "Restaurants",
      dataIndex: "restaurants",
      key: "restaurants",
      render: (res) => {
        return (
          <Space>
            {res?.map((r, i) => (
              <Tag key={i}>{r}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Categories",
      dataIndex: "categories",
      key: "categories",
      render: (cats) => {
        return (
          <Space>
            {cats?.map((r, i) => (
              <Tag key={i}>{r}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
    },
    {
      title: "Order Total",
      dataIndex: "order_total",
      key: "order_total",
      width: "120px",
      render: (total) => total + " BDT",
    },
    {
      title: "Store Revenue (tk)",
      dataIndex: "store_revenue",
      key: "store_revenue",
      width: "120px",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: "120px",
      render: (discount) => discount + " BDT",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: "120px",
    },
    {
      title: "Promo",
      dataIndex: "promo",
      key: "promo",
      width: "120px",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      width: "120px",
    },
    {
      title: "Rider Name",
      dataIndex: "rider_name",
      key: "rider_name",
      width: "120px",
    },
    {
      title: "Delivery Charge",
      dataIndex: "delivery_charge",
      key: "delivery_charge",
      width: "120px",
      render: (charge) => charge + " BDT",
    },
    {
      title: "Completed At",
      dataIndex: "completedAt",
      key: "completedAt",
      width: "120px",
      render: (date) =>
        date ? new Date(date?.iso || date).toLocaleString() : null,
    },
    {
      title: "Rejection Reason",
      dataIndex: "rejection_reason",
      key: "rejection_reason",
      width: "120px",
    },
    {
      title: "Order Hour",
      dataIndex: "order_hour",
      key: "order_hour",
      width: "120px",
      render: (hr) =>
        hr % 12 === 0 ? 12 : (hr % 12) + " " + (hr < 12 ? "AM" : "PM"),
    },
    {
      title: "Order Day",
      dataIndex: "order_day",
      key: "order_day",
      width: "120px",
    },
    {
      title: "Order Month",
      dataIndex: "order_month",
      key: "order_month",
      width: "120px",
    },
    {
      title: "Order Year",
      dataIndex: "order_year",
      key: "order_year",
      width: "120px",
    },
    {
      title: "Confirmed At",
      dataIndex: "confirmedAt",
      key: "confirmedAt",
      width: "120px",
      render: (date) =>
        date ? new Date(date?.iso || date).toLocaleString() : null,
    },
    {
      title: "Confirmation Time (min)",
      dataIndex: "confirmation_time",
      key: "confirmation_time",
      width: "120px",
      render: (time) => `${time || 0}`,
    },
    {
      title: "Restaurant Confirmation Time",
      dataIndex: "res_confirmation_time",
      key: "res_confirmation_time",
      width: "120px",
      render: (time) => `${time || 0} min`,
    },
    {
      title: "Restaurant Preparation Time",
      dataIndex: "res_preparation_time",
      key: "res_preparation_time",
      width: "120px",
      render: (time) => `${time || 0} min`,
    },
    {
      title: "Rider Assigned By",
      dataIndex: "rider_assigned_by",
      key: "rider_assigned_by",
      width: "120px",
    },
    {
      title: "Ready to Pickup Time",
      dataIndex: "ready_to_picked_time",
      key: "ready_to_picked_time",
      width: "120px",
    },
    {
      title: "Rider Assigned At",
      dataIndex: "rider_assigned_at",
      key: "rider_assigned_at",
      width: "120px",
      render: (date) => (date ? new Date(date).toLocaleString() : null),
    },
    {
      title: "Picked To Delivery Time",
      dataIndex: "picked_to_delivery_time",
      key: "picked_to_delivery_time",
      width: "120px",
      render: (time) => `${time || 0} min`,
    },
    {
      title: "Total Delivery Time",
      dataIndex: "delivered_time",
      key: "delivered_time",
      width: "120px",
      render: (time) => `${time || 0} min`,
    },
    {
      title: "Rider Note",
      dataIndex: "rider_note",
      key: "rider_note",
      width: "120px",
    },
    {
      title: "Restaurant Note",
      dataIndex: "note",
      key: "note",
      width: "120px",
    },
    {
      title: "Is Priority",
      dataIndex: "isPriority",
      key: "isPriority",
      width: "120px",
      render: (isPriority) => (isPriority ? "Yes" : "No"),
    },
  ];

  const filterColumns = (columns, select) => {
    if (!select) return columns;
    return columns.filter((c) => select.includes(c.key));
  };

  const downloadHandler = (state) => {
    if (!state.orders.results?.length) return;
    const selects = state.orders.props?.select || select.map((c) => c.value);
    downloadCsv(
      [
        {
          title: "Users Orders Report",
          header: selects.map((s) => {
            const col = select.find((c) => c.value === s);
            return {
              key: col.value,
              title: col.text,
            };
          }),
          data: state.orders.results.map((item) => {
            const newItem = { ...item };
            if (item.restaurants) {
              newItem.restaurants = newItem.restaurants.join(" & ");
            }

            if (newItem.categories) {
              newItem.categories = newItem.categories.join(" & ");
            }

            if (newItem.products) {
              newItem.products = newItem.products
                .map((i) => `[${i.quantity}] ${i.name} (${i.total} tk)`)
                .join("+");
            }

            newItem.isPriority = newItem.isPriority ? "Yes" : "No";
            return newItem;
          }),
        },
      ],
      "users-orders-report"
    );
  };

  return (
    <div>
      <Filter fetchData={fetchData} />
      <div style={{ marginBottom: "10px" }}>
        <Button
          onClick={() => downloadHandler(state)}
          icon={<DownloadOutlined />}
          type="primary"
        >
          Download
        </Button>
      </div>
      <Affix offsetTop={0}>
        <Table
          loading={state.orders.loading}
          columns={filterColumns(columns, state.orders.props?.select)}
          dataSource={state.orders.results}
          pagination={{
            total: state.orders.count,
            defaultPageSize: state.orders.props?.limit || 100,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: [
              "10",
              "20",
              "50",
              "100",
              "200",
              "500",
              "1000",
              "2000",
              "3000",
              "5000",
              "10000",
            ],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            position: ["topLeft", "topRight"],
          }}
          scroll={{ x: "max-content", y: 570 }}
          onChange={(pagination) => {
            fetchData({
              ...state.orders.props,
              limit: pagination.pageSize,
              skip: (pagination.current - 1) * pagination.pageSize,
            });
          }}
        />
      </Affix>
    </div>
  );
}
