import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRider } from "../../store/rider/riderCrudSlices";
import ProfileInfo from "../../components/rider/profile/ProfileInfo";
import TravelLogs from "../../components/rider/profile/TravelLogs";
import Boxer, { Box } from "../../components/Common/Boxer";
import RatingReviews from "../../components/rider/profile/RatingReviews";

export default function RiderProfile() {
  const {
    rider: { loading, data },
  } = useSelector((state) => state.riderCrud);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getRider({
        id,
        cb: (query) => {
          query.select([
            "name",
            "username",
            "email",
            "phone",
            "image",
            "date_of_birth",
          ]);
        },
      })
    );
  }, [dispatch, id]); // Add dispatch and id to the dependency array

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <Boxer>
        <Box title="Profile">
          <ProfileInfo data={data} />
        </Box>
        <Box title="Travel Logs">
          <TravelLogs />
        </Box>
        <Box>
          <RatingReviews />
        </Box>
      </Boxer>
    </div>
  );
}
