import { useState, useEffect, useReducer } from "react";
import useRestaurant from "../../hooks/useRestaurants";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Switch,
  Select,
  message,
  InputNumber,
  Space,
  Radio,
  Tag,
  DatePicker,
  Card,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import useUser from "../../hooks/useUser";
import { parser, v3Axios } from "../../utils";
import Parse from "parse";
import { useHistory } from "react-router-dom";
import Address from "../../components/restaurant/form/Address";
import Delete from "../../components/restaurant/form/Delete";
import AddImage from "../../components/restaurant/form/AddImage";
import GroupRestaurants from "../../components/restaurant/GroupRestaurants";
import moment from "moment";
import DeliveryAreaRestaurant from "./DeliveryArea";
import RestaurantMou from "../../components/restaurant/mou";
import useMedia from "../../hooks/useMedia";
import Voucher from "./Voucher";
import SecondaryVoucher from "./SecondaryVoucher";

const { Item } = Form;

const bannerReducer = (state, action) => {
  return { ...state, ...action };
};

export default function AddNew({ resId }) {
  const user = Parse.User.current()?.toJSON();
  const [form] = Form.useForm();
  const { addNew, getById, updateItem } = useRestaurant();
  const { getUsers } = useUser();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]); // partner users
  const [stores, setStores] = useState(null);
  let [formData, setFormData] = useState({});
  const [restaurant, setRestaurant] = useState(null);
  const url = new URL(window.location.href);
  const id = resId || url.searchParams.get("id");
  const [banner, dispatch] = useReducer(bannerReducer, {
    loading: false,
    image: null,
  });
  const [hubs, setHubs] = useState([]);
  const [group, setGroup] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  // GEO LOCATION HUB LIST
  const [areas, setAreas] = useState([]);
  const [zones, setZones] = useState([]);
  const [file, setMouFile] = useState(null);
  const [mou, setMou] = useState("");
  const [secondaryVoucher, setSecondaryVoucher] = useState([]);
  const [mouExpireDate, setMouExpireDate] = useState(null);
  const [restaurantLogs, setRestaurantLogs] = useState([]);
  const [showLogId, setShowLogId] = useState(null);

  const showLogOnClick = (logId) => {
    setShowLogId(logId);
  };
  const history = useHistory();
  const { getSignedUrl, uploadFile } = useMedia();

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      setHubs(parser(hubs));
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchLayers = async () => {
    try {
      const { data } = await v3Axios.get("/api/v1/areas");

      const zoneLists = {};

      for (const item of data) {
        if (!zoneLists[item?.zone._id]) {
          zoneLists[item?.zone._id] = {
            ...item.zone,
            areas: [],
          };
        }
        zoneLists[item?.zone._id].areas.push(item);
      }
      setZones(Object.values(zoneLists));
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  const fetchCuisines = async () => {
    try {
      const cuisines = await new Parse.Query("cuisines").select("name").find();
      setCuisines(parser(cuisines));
    } catch (err) {
      message.error(err.message);
    }
  };

  const updateBanner = (obj) => {
    dispatch(obj);
  };

  useEffect(() => {
    if (id) {
      message.loading("Loading...", 0);
      getById({ id }, (err, res) => {
        if (!res) {
          message.destroy(0);
          message.error(err);
          return;
        }
        const data = res.toJSON();

        if (data.operating_hours) {
          data.operating_hours = data.operating_hours.join("-");
        }

        if (data.cuisines) {
          data.cuisines = data.cuisines.split(",");
        }

        if (data.name) {
          data.restaurant_name = data.name;
        }

        if (data.parent_store) {
          data.parent_store = data.parent_store.objectId;
        }

        if (data.banner_image) {
          updateBanner({
            image: data.banner_image,
          });
        }

        if (data.hub) {
          data.hub = data.hub.objectId;
        }

        if (data.group) {
          setGroup(data.group);
        }

        if (data.voucher_title_validity) {
          data.voucher_title_validity = moment(
            new Date(data.voucher_title_validity.iso)
          );
        }
        if (data.voucher_sub_title_validity) {
          data.voucher_sub_title_validity = moment(
            new Date(data.voucher_sub_title_validity.iso)
          );
        }

        if (data.secondary_vouchers?.length > 0) {
          setSecondaryVoucher(data.secondary_vouchers);
        }
        if (data.areas) {
          setAreas(data.areas);
        }
        if (data.mou) {
          setMou(data.mou);
        }
        if (data.mou_expired_date) {
          setMouExpireDate(data.mou_expired_date);
        }
        getUsers(
          {
            type: ["partner"],
            select: ["name", "username"],
            ids: [data.managedBy?.objectId],
          },
          (err, res) => {
            if (err) message.error(err);
            if (res) {
              setUsers(parser(res.results));
            }
          }
        );

        setRestaurant({ ...res.toJSON(), object: res });

        const formData = {
          ...data,
          managedBy: data.managedBy?.objectId,
        };

        setFormData(formData);
        form.setFieldsValue(formData);
        message.destroy(0);
      });
      const getRestaurantLogs = async (id) => {
        try {
          const logs = await new Parse.Query("logs")
            .equalTo("target", id)
            .select([
              "msg",
              "createdAt",
              "target",
              "username",
              "changes",
              "previous",
            ])
            .descending("createdAt")
            .find();
          setRestaurantLogs(logs.map((log) => log.toJSON()));
        } catch (err) {
          message.error(err.message);
        } finally {
          setLoading(false);
        }
      };
      getRestaurantLogs(id);
    } else if (restaurant) {
      setRestaurant(null);
      setFormData({});
      form.resetFields();
      dispatch({ image: null, thumbUrl: null, loading: null });
      setGroup([]);
    }
  }, [id]);

  useEffect(() => {
    fetchLayers();
  }, []);

  useEffect(() => {
    if (!id) {
      getUsers(
        { type: ["partner"], select: ["name", "username"], limit: 10 },
        (err, res) => {
          if (err) message.error(err);
          if (res) {
            setUsers(parser(res.results));
          }
        }
      );
    }

    fetchHubs();
    fetchCuisines();
  }, []);

  const submitHandler = async (values) => {
    // check image
    if (!banner.image) {
      message.error("Please upload image");
      return;
    }

    setLoading(true);
    const operating_hours = values.operating_hours
      .split("-")
      .map((i) => Number(i));

    if (values.voucher_title_validity) {
      values.voucher_title_validity = new Date(
        values.voucher_title_validity
      ).toISOString();
    }
    if (values.voucher_sub_title_validity) {
      values.voucher_sub_title_validity = new Date(
        values.voucher_sub_title_validity
      ).toISOString();
    }

    if (values.address) {
      if (typeof values.address.latitude === "number") {
        values.address.latitude = values.address.latitude.toString();
      }
      if (typeof values.address.longitude === "number") {
        values.address.longitude = values.address.longitude.toString();
      }
    }
    if (restaurant) {
      const requestBody = {
        id,
        ...values,
        areas,
        banner_image: banner.image ?? "",
        operating_hours,
        group,
        cuisines: values.cuisines.join(","),
        secondary_vouchers: secondaryVoucher || [],
      };

      if (mou) {
        requestBody.mou = mou;
      }

      if (mouExpireDate) {
        requestBody.mou_expired_date = mouExpireDate;
      }
      updateItem(requestBody, (err, res) => {
        setLoading(false);
        if (!res) {
          message.error(err);
          return;
        }
        message.success("Update Successful!");
      });
    } else {
      addNew(
        {
          ...values,
          areas,
          is_hub: values.is_hub ?? false,
          availability: values.availability ?? false,
          operating_hours,
          banner_image: banner.image,
          group,
          cuisines: values.cuisines.join(","),
        },
        (err, res) => {
          if (err) {
            message.error(err);
          }

          if (res) {
            message.success("Restaurant added successfully");
            form.resetFields();
            updateBanner({ image: null });
          }
          setLoading(false);
        }
      );
    }
  };

  const fetchStore = async () => {
    try {
      const strs = await new Parse.Query("restaurant")
        .equalTo("type", "store")
        .find();
      if (strs) {
        setStores(strs.map((i) => i.toJSON()));
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const formOnChange = async () => {
    const formData = form.getFieldsValue();
    setFormData({ ...formData });
    if (formData.type === "sub_store") {
      fetchStore();
    }
  };

  const onStoreEdit = (value) => {
    setFormData({ ...formData, storeEdit: value });
    if (value) {
      fetchStore();
    }
    form.setFieldsValue({ parent_store: formData.parent_store });
  };

  // Add voucher field
  const [voucherCount, setVoucherCount] = useState(0);

  const handleAddVoucher = () => {
    setVoucherCount(voucherCount + 1);
    setSecondaryVoucher((prevVouchers) => [
      ...prevVouchers,
      {
        secondary_voucher_title: "",
        secondary_voucher_sub_title: "",
        secondary_voucher_title_validity: "",
      },
    ]);
  };
  const handleRemoveVoucher = (indexToRemove) => {
    setSecondaryVoucher((prevVouchers) =>
      prevVouchers.filter((_, index) => index !== indexToRemove)
    );
    setVoucherCount((prevCount) => prevCount - 1);
  };

  const handleVoucherChange = (index, field, value) => {
    setSecondaryVoucher((prevVouchers) =>
      prevVouchers.map((voucher, i) =>
        i === index ? { ...voucher, [field]: value } : voucher
      )
    );
  };
  return (
    <Wrapper
      form={form}
      layout="vertical"
      onFinish={submitHandler}
      size="large"
      scrollToFirstError
      onChange={formOnChange}
    >
      <div style={{ textAlign: "right", marginBottom: "20px" }}>
        <Space>
          {restaurant && user?.type === "admin" && (
            <Delete restaurant={restaurant} setRestaurant={setRestaurant} />
          )}
          <Button
            loading={loading}
            disabled={loading}
            style={{ minWidth: "160px" }}
            type="primary"
            htmlType="submit"
          >
            {restaurant ? "Update" : "Submit"}
          </Button>
        </Space>
      </div>

      {(!id || (id && restaurant)) && (
        <Row gutter={[16, 16]}>
          {/* Primary Section  */}

          <Col span={24} md={12} lg={8}>
            <div className="section">
              <h3 className="section-title">Primary Information</h3>
              <AddImage banner={banner} setBanner={updateBanner} />
              <Item
                name="restaurant_name"
                label="Restaurant Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Item>
              <Item name="email" label="Email">
                <Input placeholder="example@email.com" />
              </Item>

              {restaurant && (
                <Item label="User">
                  <div
                    className="restaurant"
                    onClick={() =>
                      history.push(
                        `/user/add?id=${restaurant.managedBy.objectId}`
                      )
                    }
                  >
                    <h3 title="Group name" className="name">
                      {restaurant.managedBy?.name}
                    </h3>
                    <p title="Username" className="username">
                      @ {restaurant.managedBy?.username}
                    </p>
                  </div>
                </Item>
              )}

              <Item
                name="managedBy"
                label="Select Account"
                rules={[
                  { required: true, message: "Please select partner account" },
                ]}
              >
                <Select
                  placeholder="Select Account"
                  showSearch
                  onSearch={(value) => {
                    getUsers(
                      {
                        type: ["partner"],
                        select: ["name", "username"],
                        username: value,
                        limit: 10,
                      },
                      (err, res) => {
                        if (err) message.error(err);
                        if (res) {
                          setUsers(parser(res.results));
                        }
                      }
                    );
                  }}
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users.map((user, i) => {
                    return (
                      <Select.Option value={user.id} key={i}>
                        {user.username}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Item>

              <Item
                name="hub"
                label="Hub"
                rules={[
                  {
                    required: true,
                    message: "Please select hub",
                  },
                ]}
              >
                <Select
                  placeholder="Select Hub"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {hubs.map((hub, i) => {
                    return (
                      <Select.Option value={hub.id} key={i}>
                        {hub.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Item>

              <Item
                name="type"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select type",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="restaurant">Restaurant</Radio>
                  <Radio value="store">Store</Radio>
                  <Radio value="sub_store">Sub Store</Radio>
                </Radio.Group>
              </Item>

              {((!restaurant && formData.type === "sub_store") ||
                (restaurant && formData.storeEdit) ||
                formData.type === "sub_store") && (
                <Item
                  name="parent_store"
                  label={
                    <Space>
                      Store{" "}
                      {restaurant ? (
                        <Tag color="red" onClick={() => onStoreEdit(false)}>
                          Close
                        </Tag>
                      ) : null}
                    </Space>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please select store",
                    },
                  ]}
                >
                  <Select placeholder="Select store">
                    {Array.isArray(stores) &&
                      stores.map((store, i) => {
                        return (
                          <Select.Option key={i} value={store.objectId}>
                            {store.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Item>
              )}

              {restaurant?.parent_store &&
                !formData.storeEdit &&
                formData.type === "sub_store" && (
                  <Item label="Parent Store">
                    <div className="parent-store">
                      <h4 className="name">{restaurant.parent_store.name}</h4>
                      <Button
                        onClick={() => onStoreEdit(true)}
                        type="link"
                        icon={<EditOutlined />}
                        shape="circle"
                      ></Button>
                    </div>
                  </Item>
                )}

              <Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Item>

              <Item
                name="commission_type"
                label="Commission Type"
                rules={[
                  {
                    required: true,
                    message: "Please select commission type",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="percentage">Percentage</Radio>
                  <Radio value="flat">Flat</Radio>
                </Radio.Group>
              </Item>

              <Item
                name="commission"
                label="Commission"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Item>
              <Item
                name="prep_penalty"
                label="Preparation Time Exceed Penalty (in percentage)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Item>
              <Item
                name="failed_order_percentage"
                label="Failed Order Percentage"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber max={100} style={{ width: "100%" }} min={0} />
              </Item>
            </div>
            <div className="section">
              <h3 className="section-title">Bank Details</h3>
              <Item name={["payments_info", "bank_name"]} label="Bank Name">
                <Input />
              </Item>
              <Item
                name={["payments_info", "account_name"]}
                label="Account Name"
              >
                <Input />
              </Item>
              <Item
                name={["payments_info", "account_number"]}
                label="Account Number"
              >
                <Input />
              </Item>
              <Item
                name={["payments_info", "beneficiary_number"]}
                label="Account Beneficiary Number"
              >
                <Input />
              </Item>
              <Item name={["payments_info", "branch_name"]} label="Branch Name">
                <Input />
              </Item>
            </div>
          </Col>

          {/* Meta Section */}
          <Col span={24} md={12} lg={8}>
            {restaurant && (
              <div className="section">
                <div className="section">
                  <h3 className="section-title">Primary Voucher</h3>
                  <Item name={`voucher_title`} label="Voucher Title">
                    <Input placeholder={`voucher: xyz`} />
                  </Item>
                  <Item name={`voucher_sub_title`} label="Voucher Sub Title">
                    <Input placeholder="voucher sub title" />
                  </Item>
                  <Item
                    name={`voucher_title_validity`}
                    label="Voucher Title Validity"
                  >
                    <DatePicker
                      format="DD-MM-YYYY HH:mm:ss"
                      showTime
                      disabledDate={(current) =>
                        moment().add(-1, "days") >= current
                      }
                    />
                  </Item>
                  <Item
                    name={`voucher_sub_title_validity`}
                    label="Voucher Sub Title Validity"
                  >
                    <DatePicker
                      format="DD-MM-YYYY HH:mm:ss"
                      showTime
                      disabledDate={(current) =>
                        moment().add(-1, "days") >= current
                      }
                    />
                  </Item>
                </div>
                {secondaryVoucher.length > 0 &&
                  secondaryVoucher.map((voucher, index) => (
                    <SecondaryVoucher
                      index={index}
                      key={index}
                      secondaryVoucher={secondaryVoucher}
                      setSecondaryVoucher={setSecondaryVoucher}
                      onRemove={() => handleRemoveVoucher(index)}
                      onChange={handleVoucherChange}
                    />
                  ))}

                <Button type="primary" onClick={handleAddVoucher}>
                  Add Voucher
                </Button>
              </div>
            )}
            <div className="section">
              <h3 className="section-title">Control Section</h3>
              {restaurant && (
                <Row>
                  <Col span={24}>
                    <Item
                      name="concurrent_order_capacity"
                      label="Concurrent Order Capacity"
                      style={{ marginBottom: "0px" }}
                    >
                      <InputNumber placeholder="0" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      name="showRating"
                      label="Show Rating"
                      valuePropName={
                        restaurant && restaurant.showRating ? "checked" : ""
                      }
                      style={{ marginBottom: "0px" }}
                    >
                      <Switch />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      name="showReview"
                      label="Show Review"
                      valuePropName={
                        restaurant && restaurant.showReview ? "checked" : ""
                      }
                      style={{ marginBottom: "0px" }}
                    >
                      <Switch />
                    </Item>
                  </Col>
                  <Col style={{ display: "none" }}>
                    <Item
                      name={["rating", "value"]}
                      label="Rating Value"
                      valuePropName={
                        restaurant && restaurant.showReview ? "checked" : ""
                      }
                      style={{ marginBottom: "0px" }}
                    >
                      <Switch />
                    </Item>
                  </Col>
                  <Col style={{ display: "none" }}>
                    <Item
                      name={["rating", "count"]}
                      label="Rating Count"
                      valuePropName={
                        restaurant && restaurant.showReview ? "checked" : ""
                      }
                      style={{ marginBottom: "0px" }}
                    >
                      <Switch />
                    </Item>
                  </Col>

                  <Col span={8}>
                    <Item label="Rating" style={{ marginBottom: "0px" }}>
                      <InputNumber
                        value={
                          restaurant?.rating?.value
                            ? Number(
                                (
                                  restaurant.rating.value /
                                  restaurant.rating.count
                                ).toFixed(1)
                              )
                            : 0
                        }
                        onChange={(v) => {
                          if (v <= 5 && v > 0) {
                            if (!restaurant.rating?.value) {
                              if (!restaurant.rating) {
                                restaurant.rating = {
                                  value: v,
                                  count: 1,
                                };
                              } else {
                                restaurant.rating.value = v;
                                restaurant.rating.count = 1;
                              }
                              setRestaurant({ ...restaurant });

                              form.setFieldsValue({
                                rating: restaurant.rating,
                              });
                            } else {
                              const value = v * restaurant.rating.count;
                              restaurant.rating.value = value;
                              setRestaurant({ ...restaurant });
                              form.setFieldsValue({
                                rating: {
                                  value,
                                  count: restaurant.rating.count,
                                },
                              });
                            }
                          }
                        }}
                        max={5}
                      />
                    </Item>
                  </Col>
                </Row>
              )}
              <Row gutter={[16]}>
                <Col span={12}>
                  <Item
                    name="availability"
                    label="Availability"
                    valuePropName={
                      restaurant && restaurant.availability ? "checked" : ""
                    }
                    style={{ marginBottom: "0px" }}
                  >
                    <Switch />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    name="vat"
                    label="Vat"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Item>
                </Col>
              </Row>

              <Item
                name="operating_hours"
                label="Operating Hours"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="20-6" />
              </Item>

              <Item
                name="cuisines"
                label="Cuisines"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select mode="tags">
                  {cuisines.map((cuisine) => (
                    <Select.Option key={cuisine.name}>
                      {cuisine.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </div>
            <Address
              form={form}
              setFormData={setFormData}
              formData={formData}
            />
            <div className="section">
              <h3 className="section-title">Meta Info</h3>
              <Item name="meta_title" label="Meta Title">
                <Input />
              </Item>
              <Item label="Meta Tags" name="meta_tags">
                <Select
                  placeholder="tag one, tag two..."
                  mode="tags"
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                ></Select>
              </Item>
              <Item name="meta_description" label="Meta Description">
                <Input.TextArea />
              </Item>
            </div>
          </Col>
          <Col span={24} md={12} lg={8}>
            <GroupRestaurants
              group={group}
              setGroup={setGroup}
              hub={restaurant?.hub?.objectId}
              id={id}
            />
            <div className="section">
              <RestaurantMou
                expireDate={mouExpireDate}
                setExpiredDate={setMouExpireDate}
                mou={mou}
                setMouFile={setMouFile}
                setMou={setMou}
              />
            </div>
          </Col>

          <Col span={24} md={12} lg={8}>
            <div className="section section-map">
              <h3 className="section-title">Delivery Area</h3>
              <DeliveryAreaRestaurant
                zones={zones}
                setAreas={setAreas}
                areas={areas}
              />
            </div>
          </Col>
          {id && (
            <Card
              title="Restaurant logs"
              style={{ width: "100%", marginTop: "20px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  maxWidth: "100%",
                }}
              >
                {restaurantLogs.map((log, index) => (
                  <Tag
                    color="blue"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      padding: "10px",
                      maxWidth: "100%",
                    }}
                    key={log.id ?? index}
                  >
                    <div style={{ maxWidth: "100%", wordWrap: "break-word" }}>
                      {/* Set maximum width for the content */}
                      <Tag color="orange">{log.msg}</Tag>
                      {showLogId !== (log.id ?? index) && (
                        <Button onClick={() => showLogOnClick(log.id ?? index)}>
                          Show Logs
                        </Button>
                      )}
                      {showLogId === (log.id ?? index) && (
                        <>
                          <div
                            style={{ maxWidth: "100%", wordWrap: "break-word" }}
                          >
                            <strong>Updated:</strong>{" "}
                            <span style={{ wordWrap: "break-word" }}>
                              <pre style={{ color: "blue" }}>
                                {JSON.stringify(log.changes, null, 2)}
                              </pre>
                            </span>
                          </div>
                          <div style={{ wordWrap: "break-word" }}>
                            <strong>Previous:</strong>{" "}
                            <span style={{ wordWrap: "break-word" }}>
                              <pre style={{ color: "blue" }}>
                                {JSON.stringify(log.previous, null, 2)}
                              </pre>
                            </span>
                          </div>
                        </>
                      )}
                      <Tag color="green">By {log.username}</Tag>
                    </div>

                    <div>
                      <p>
                        {moment(log.createdAt).format("DD MMM YYYY hh:mm A")}
                      </p>
                    </div>
                  </Tag>
                ))}
              </div>
            </Card>
          )}
        </Row>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Form)`
  position: relative;

  .section-map {
    width: 1000px;
  }

  .profile-picture {
    position: relative;
    margin-bottom: 20px;

    img {
      width: 100%;
      max-height: 250px;
      border-radius: 10px;
    }

    .progress {
      width: 35px;
      height: 35px;
      position: absolute;
      top: 0;
      left: 0;
      margin: 10px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: green;
      border-radius: 50%;
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
    }
  }

  .address {
    position: relative;

    .places {
      position: absolute;
      top: 32px;
      left: 0px;
      width: 100%;
      min-height: 200px;
      max-height: 300px;
      background: #fff;
      border: 1px solid #e8e8e8;
      z-index: 1000;
      padding: 10px 0px;
      border-radius: 10px;
      margin-top: 40px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

      .place {
        padding: 3px 10px;
        cursor: pointer;
        letter-spacing: 0.5px;

        &:not(:last-child) {
          border-bottom: 1px solid #e8e8e8;
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-image-img,
  .ant-image-mask {
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }

  .section {
    padding: 20px;
    border: 1px solid #e8e8e8;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;

    &-title {
      text-align: right;
    }
  }

  .add-restaurant {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #f6f6f6;

    .select {
      width: 100%;
    }

    button {
      margin-left: 10px;
      border-radius: 5px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      margin-top: -10px;
    }
    to {
      opacity: 1;
      margin-top: 0px;
    }
  }

  .group-items {
    padding: 10px 20px;
    min-height: 400px;
    max-height: 600px;
    background: #fff;
    overflow-y: auto;

    .item {
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      background: #546e7a;
      border-radius: 5px;
      position: relative;
      animation: fadeIn 0.5s ease-in-out;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .title,
      .subtitle {
        color: #fff;
        margin-bottom: 0px;
        letter-spacing: 1px;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 16px;
        border: none;
        transition: all 0.3s ease;

        &:hover {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .restaurant {
    background: #001529;
    padding: 10px 16px;
    border-radius: 16px;
    min-height: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    .name,
    .username {
      color: #18e5ff;
      letter-spacing: 1px;
      font-weight: 500;
      margin-bottom: 0;
    }

    .username {
      font-size: 16px;
      color: #fff;
    }

    &:hover {
      opacity: 0.8;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
  }

  .parent-store {
    background: #fff;

    .name {
      margin-bottom: 0px;
    }
  }

  label {
    font-weight: 500;
    font-size: 14px;
    height: 24px !important;
    color: #7c7c7c;
  }

  input:not([type="password"]):not(.ant-input-number-input):not(
      [type="search"]
    ),
  .ant-input-password,
  .ant-input-number,
  .ant-select-selector {
    border-radius: 5px !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    overflow: hidden;
  }

  textarea {
    border-radius: 5px !important;
  }
`;
