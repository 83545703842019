import { Fragment, useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Marker,
  Polyline,
} from "react-leaflet";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L, { DivIcon } from "leaflet";
import { DatePicker, Space, message } from "antd";
import Parse from "parse";
import { useParams } from "react-router-dom";
import { localDateTime } from "../../utils";

const osmProviders = {
  mapTiler: {
    url: "https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=bnXSr34Mr2RrjF5FnaUt",
    attribution:
      "Map data: &copy; <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>, Imagery © <a href='https://maptiler.com/'>MapTiler</a>",
  },
};

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("../Zone/geo_location.png"),
  iconUrl: require("../Zone/geo_location.png"),
  shadowUrl: require("../Zone/marker-shadow.png"),
});

const markerIcon = new L.Icon({
  iconUrl: require("../Zone/geo_location.png"),
  iconSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export default function RiderIndividualTracking() {
  const center = { lat: 23.770112, lng: 90.410154 };
  const zoom = 15;
  const mapRef = useRef(null);
  const [logs, setRiders] = useState([]);
  const { id } = useParams();
  const [date, setDate] = useState(null);
  let [timeOutId, setTimeOutId] = useState(null);

  const fetchData = async (date) => {
    try {
      const res = await Parse.Cloud.run("individualRiderTrackingLogs", {
        riderId: id,
        date: date.format("YYYY-MM-DD"),
      });



      let i = 0;
      const timeOutId = setInterval(() => {
        const log = res[i];
        if (!log) {
          clearInterval(timeOutId);
          return;
        }

        setRiders((logs) => [...logs, log]);
        i++;
      }, 150);

      setTimeOutId(timeOutId);
    } catch (err) {
      message.error(err.message);
    }
  };

  const clearData = () => {
    clearInterval(timeOutId);
    setTimeOutId(null);
    setRiders([]);
  };

  useEffect(() => {
    if (date) {
      setRiders([]);
      fetchData(date);
    }
  }, [date]);

  return (
    <Wrapper>
      <div className="flex-between header">
        <h1>Rider Tracking</h1>
        <Space>
          <DatePicker
            onChange={(date) => {
              clearData();
              setDate(date);
            }}
            format="YYYY-MM-DD"
          />
        </Space>
      </div>
      <MapContainer center={center} zoom={zoom} ref={mapRef}>
        <FeatureGroup></FeatureGroup>
        <TileLayer
          url={osmProviders.mapTiler.url}
          attribution={osmProviders.mapTiler.attribution}
        />

        {logs.map(({ status, location, createdAt, objectId, pickups }) => {
          const customIcon = status
            ? L.icon({
                iconUrl: require(`./${status}.png`),
                iconSize: [20, 20],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
                title: `${status ?? "Log"}- ${localDateTime(createdAt)}}`,
              })
            : new DivIcon({
                className: "custom-icon",
                html: `<div class="dot"></div>`,
              });

          return (
            <Fragment key={objectId}>
              <Marker
                position={location}
                icon={customIcon}
                title={`${status ?? "Log"}-${new Date(
                  createdAt
                ).toLocaleTimeString()}`}
              ></Marker>
            </Fragment>
          );
        })}

        <Polyline
          positions={logs.map((log) => log.location)}
          pathOptions={{ color: "red" }}
        />
      </MapContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .header {
    margin-bottom: 10px;
  }

  .leaflet-container {
    width: 100%;
    height: 600px;
  }

  .zone {
    height: calc(100vh - 140px);
    padding-right: 10px;
    &-item {
      padding: 10px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      background: #fff;

      .icon {
        cursor: pointer;
        color: #ff4d4f;
        width: 20px;
      }
    }
  }

  .custom-icon {
    .dot {
      width: 10px;
      height: 10px;
      background: black;
      border-radius: 50%;
      transition: transform 0.5s ease-in-out;

      &:hover {
        transform: scale(2);
        background: red;
      }
    }
  }
`;
