import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import Parse from "parse";

const initialState = {
  settings: {
    loading: false,
    data: null,
    updateLoading: false,
  },
  travelLogs: {
    loading: false,
    data: {
      orders: [],
      total: 0,
    },
  },
};

export const fetchTravelLogs = createAsyncThunk(
  "rider/fetchTravelLogs",
  async (params) => {
    const res = await Parse.Cloud.run("orderTravelLog", params);
    return res;
  }
);

export const fetchSettings = createAsyncThunk(
  "rider/fetchSettings",
  async () => {
    return await new Parse.Query("config")
      .equalTo("key", "rider_settings")
      .first();
  }
);

export const updateSettings = createAsyncThunk(
  "rider/updateSettings",
  async (params) => {
    const { object, ...values } = params;
    if (object instanceof Parse.Object) {
      object.set("value", values);
      return await object.save();
    }
    return null;
  }
);

const riderSlices = createSlice({
  name: "rider",
  initialState,
  reducers: {
    updateTravelLog(state, action) {
      console.log("updateTravelLog -> action", action);
      const log = state.travelLogs.data.orders?.find(
        (item) => item.objectId === action.payload.orderId
      );
      if (log) {
        log.items.forEach((item) => {
          if (item.id === action.payload.id) {
            log.traveled -= item.traveled;
            state.travelLogs.data.total -= item.traveled;
            item.traveled = action.payload.value;
            log.traveled += item.traveled;
            state.travelLogs.data.total += item.traveled;
          }
        });
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSettings.pending, (state) => {
      state.settings.loading = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings.loading = false;
      if (!action.payload) {
        message.error("Settings not found");
        return;
      }
      state.settings.data = {
        ...action.payload.toJSON(),
        refs: action.payload,
      };
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.settings.loading = false;
      message.error(action.error.message);
    });

    builder.addCase(updateSettings.pending, (state) => {
      state.settings.updateLoading = true;
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.settings.updateLoading = false;
      if (action.payload) {
        message.success("Settings updated");
      }
    });
    builder.addCase(updateSettings.rejected, (state, action) => {
      state.settings.updateLoading = false;
      message.error(action.error.message);
    });

    // Travel Logs
    builder.addCase(fetchTravelLogs.pending, (state) => {
      state.travelLogs.loading = true;
    });
    builder.addCase(fetchTravelLogs.fulfilled, (state, action) => {
      state.travelLogs.loading = false;
      state.travelLogs.data = action.payload;
    });
    builder.addCase(fetchTravelLogs.rejected, (state, action) => {
      state.travelLogs.loading = false;
      message.error(action.error.message);
    });
  },
});

export default riderSlices.reducer;

export const { updateTravelLog } = riderSlices.actions;
