import styled from "styled-components";
import { message, Table } from "antd";
import { useEffect, useCallback, useReducer } from "react";
import Parse from "parse";
import { client } from "../../AuthProvider";
import useSearch from "../../components/inventory/utils/useSearch";

const initialState = {
  searches: [],
  count: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update-searches":
      state.searches = action.payload.results;
      state.count = action.payload.count;
      return { ...state };
    case "update-search":
      const findIndex = state.searches.findIndex(
        (i) => i.objectId === action.payload.objectId
      );
      if (findIndex > -1) {
        state.searches.splice(findIndex, 1, action.payload);
      } else {
        state.searches.unshift(action.payload);
      }
      return { ...state };
    case "new-search":
      state.searches.unshift(action.payload);
      state.searches.count++;
      return { ...state };
    default:
      return state;
  }
};

export default function Searches() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [, getSearchProps] = useSearch();

  const searchLiveQuery = new Parse.Query("searches");

  let searchSubs;
  const initLiveQuery = async () => {
    searchSubs = await client.subscribe(
      searchLiveQuery,
      Parse.User.current()?.getSessionToken()
    );

    if (searchSubs) {
      searchSubs.on("update", (search) => {
        dispatch({
          type: "update-search",
          payload: { ...search.toJSON(), key: search.id },
        });
      });

      searchSubs.on("create", (search) => {
        dispatch({
          type: "new-search",
          payload: { ...search.toJSON(), key: search.id },
        });
      });
    }
  };

  const columns = [
    {
      title: "Search",
      key: "search",
      dataIndex: "search",
      ...getSearchProps("search"),
      sorter: (a, b) => a.search.localeCompare(b.search),
    },
    {
      title: "Count",
      key: "count",
      dataIndex: "count",
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Success",
      key: "success",
      dataIndex: "success",
      sorter: (a, b) => a.success - b.success,
    },
    {
      title: "Zero Result",
      key: "zero_result",
      dataIndex: "zero_result",
      sorter: (a, b) => a.zero_result - b.zero_result,
    },
  ];

  const fetchSearches = async ({ limit, skip, search, sortBy, sortOrder }) => {
    try {
      message.loading("Loading...", 0);
      const searchQuery = new Parse.Query("searches")
        .limit(limit)
        .skip(skip)
        .withCount();

      if (typeof search === "string") {
        searchQuery.matches("search", new RegExp(search, "i"));
      }

      if (sortBy) {
        if (sortOrder === "ascend") {
          searchQuery.ascending(sortBy);
        } else {
          searchQuery.descending(sortBy);
        }
      } else {
        searchQuery.descending("zero_result");
      }

      const searches = await searchQuery.find({
        sessionToken: Parse.User.current().getSessionToken(),
      });
      dispatch({
        type: "update-searches",
        payload: {
          results: searches.results.map((s) => ({ ...s.toJSON(), key: s.id })),
          count: searches.count,
        },
      });
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  const clearSubs = useCallback(() => {
    if (searchSubs) searchSubs.unsubscribe();
  }, [searchSubs]);

  useEffect(() => {
    initLiveQuery();
    return () => {
      clearSubs();
    };
  }, []);

  useEffect(() => {
    fetchSearches({ limit: 100, skip: 0 });
  }, []);

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={[...state.searches]}
        pagination={{
          defaultPageSize: 200,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["100", "200", "300", "500", "1000"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: state.count,
          position: ["topLeft", "topRight"],
        }}
        scroll={{ x: "max-content" }}
        onChange={(pagination, filters, sorter) => {
          fetchSearches({
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            search: filters.search ? filters.search[0] : null,
          });
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
