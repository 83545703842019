import React, { useEffect, useState } from "react";
import { Heatmap } from "@ant-design/plots";
import { Card, Typography } from "antd";
const WeekDayProgress = ({ data, title, height = 400, size = undefined }) => {
  const [d, setD] = useState(data);
  useEffect(() => {
    setD(data);
  }, [data]);
  const config = {
    data: d,
    xField: "y",
    yField: "x",
    colorField: "order",
    sizeField: size ? "order" : undefined,
    height: height,
    shape: "square",
    scale: 1.5,
    color: ["#78C0E0", "#449DD1", "#150578", "#0E0E52"],
    label: {
      style: {
        fill: "#fff",
        shadowBlur: 2,
        shadowColor: "rgba(0, 0, 0, .45)",
      },
    },
  };

  return (
    <Card
      style={{
        marginTop: "2rem",
        borderRadius: "1rem",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <Typography.Title
        level={4}
    
      >
        {title}
      </Typography.Title>
      <Heatmap {...config} />
    </Card>
  );
};

export default WeekDayProgress;
