import {
  Button,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { useState } from "react";
import useCategories from "../../hooks/useCategories";
import useProduct from "../../hooks/useProduct";
import useRestaurants from "../../hooks/useRestaurants";
import Parse from "parse";
import styled from "styled-components";

export default function SalesReport() {
  const [type, setType] = useState("product");
  const [date, setDate] = useState([]);
  const [ids, setIds] = useState([]);
  const [options, setOptions] = useState([]);
  const { getProducts } = useProduct();
  const { getMany: getCategories } = useCategories();
  const { getRestaurants } = useRestaurants();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onSearch = (value) => {
    setLoading(true);
    if (type === "product") {
      getProducts(
        { name: value, limit: 10, select: ["name", "restaurant.name"] },
        (err, data) => {
          setLoading(false);

          if (err) return;
          setOptions(
            data.results.map((item) => ({
              id: item.id,
              name: `${item.get("name")} @${item
                .get("restaurant")
                ?.get("name")}`,
            }))
          );
        }
      );
    } else if (type === "category") {
      getCategories(
        { name: value, limit: 10, select: ["name"] },
        (err, data) => {
          setLoading(false);

          if (err) return;
          setOptions(
            data.results.map((item) => ({
              id: item.id,
              name: item.get("name"),
            }))
          );
        }
      );
    } else if (type === "restaurant") {
      getRestaurants(
        { name: value, limit: 10, select: ["name", "hub.name"] },
        (err, data) => {
          setLoading(false);

          if (err) return;
          setOptions(
            data.results.map((item) => ({
              id: item.id,
              name: `${item.get("name")} @${item.get("hub")?.get("name")}`,
            }))
          );
        }
      );
    } else {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      const res = await Parse.Cloud.run("productSalesReport", {
        type,
        ids,
        date,
      });
      setData(res);
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  };

  return (
    <Wrapper>
      <h3>Sales Report</h3>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Space>
            <Select
              defaultValue="product"
              style={{ width: 120 }}
              onChange={(value) => {
                setType(value);
                setIds([]);
                setOptions([]);
              }}
              size="large"
              value={type}
            >
              <Select.Option value="product">Product</Select.Option>
              <Select.Option value="category">Category</Select.Option>
              <Select.Option value="restaurant">Restaurant</Select.Option>
            </Select>
            <DatePicker.RangePicker
              size="large"
              onChange={(date, dateStr) => {
                setDate(dateStr);
              }}
            />
            <Button onClick={onSubmit} size="large" type="primary">
              Search
            </Button>
          </Space>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%", marginTop: "10px" }}
            onSearch={onSearch}
            size="large"
            onChange={setIds}
            placeholder="Search"
            filterOption={false}
            loading={loading}
            value={ids}
          >
            {options.map((option) => {
              return (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={24}>
          <Table
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
                width: "300px",
                sorter: (a, b) => a.name.localeCompare(b.name),
              },
              {
                title: "Order",
                dataIndex: "order",
                key: "order",
                sorter: (a, b) => a.order - b.order,
              },
              {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
                sorter: (a, b) => a.quantity - b.quantity,
              },
              {
                title: "Sales",
                dataIndex: "sales",
                key: "sales",
                sorter: (a, b) => a.sales - b.sales,
              },
              {
                title: "Discount",
                dataIndex: "discount",
                key: "discount",
                sorter: (a, b) => a.discount - b.discount,
              },
              {
                title: "Revenue",
                dataIndex: "revenue",
                key: "revenue",
                sorter: (a, b) => a.revenue - b.revenue,
              },
            ]}
            dataSource={data}
            pagination={false}
            rowClassName={(record, index) => {
              if (record.objectId === "total") {
                return "total-row";
              }
            }}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .total-row {
    background: #ff7875;
  }
`;
