import Parse from "parse";

export default function useInvoice() {
  const repoName = "invoice";
  const defaultFunc = (err, data) => {};

  const getInvoices = async (
    { select, ids, createdAt, restaurant, limit = 100, skip = 0 },
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      if (select) query.select(select);

      if (createdAt) {
        if (Array.isArray(createdAt)) {
          if (createdAt[0])
            query.greaterThanOrEqualTo("createdAt", new Date(createdAt[0]));
          if (createdAt[1])
            query.lessThanOrEqualTo("createdAt", new Date(createdAt[1]));
        } else {
          query.equalTo("createdAt", new Date(createdAt));
        }
      }

      if (typeof restaurant === "string")
        query.equalTo("restaurant", {
          __type: "Pointer",
          className: "restaurant",
          objectId: restaurant,
        });

      if (Array.isArray(restaurant)) {
        query.containedIn(
          "restaurant",
          restaurant.map((id) => ({
            __type: "Pointer",
            className: "restaurant",
            objectId: id,
          }))
        );
      }

      query.limit(limit);
      query.skip(skip);
      query.withCount();
      query.include("restaurant");
      query.descending("createdAt");
      if (ids && Array.isArray(ids)) query.containedIn("objectId", ids);

      const res = await query.find();
      callback(null, res);
    } catch (err) {
      callback(err.message);
    }
  };

  const getById = async ({ id, select } = {}, callback = defaultFunc) => {
    try {
      const query = new Parse.Query(repoName);
      query.equalTo("objectId", id);
      if (select) query.select(select);
      const res = await query.first();
      if (!res) return callback(`Invoice not found!`);
      callback(null, res);
    } catch (err) {
      callback(err.message);
    }
  };

  return {
    getInvoices,
    getById,
  };
}
