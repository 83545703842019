import { Card, DatePicker, Space, message, Table } from "antd";
import React, { useEffect } from "react";
import Parse, { Error } from "parse";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { get } from "../../helpers/api_helper";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const UserAnalyticsDashboard = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const query = useQuery();

  useEffect(() => {
    const fetchUserAnalyticsDashboard = async () => {
      setLoading(true);
      try {
        let start_date = new Date();
        let end_date = new Date();

        const start_date_param = query.get("start_date");
        const end_date_param = query.get("end_date");

        if (start_date_param && end_date_param) {
          start_date = new Date(start_date_param);
          end_date = new Date(end_date_param);
        }

        let userId = query.get("userId");

        userId = "_User$"+userId ;

        


        if (!userId) {
          message.error("User ID is missing.");
          setLoading(false);
          return;
        }

        const result = await Parse.Cloud.run("getUserAnalyticsDashboard", {
          start_date,
          end_date,
          userId,
        });

        if (result) {
          setData(result);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("no data");
      }
    };

    fetchUserAnalyticsDashboard();
  }, [query]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Order Count",
      dataIndex: "orderCount",
      key: "orderCount",
    },
    {
      title: "Delivered Order Count",
      dataIndex: "deliveredOrderCount",
      key: "deliveredOrderCount",
    },
  ];

  return (
    <Card
      title="📊 User Analytics Dashboard"
      extra={
        <DatePicker.RangePicker
          value={[
            query.get("start_date") ? moment(query.get("start_date")) : moment(),
            query.get("end_date") ? moment(query.get("end_date")) : moment(),
          ]}
          onChange={(dates, dateStrings) => {
            window.location.href = `/user/analytics-dashboard?start_date=${dateStrings[0]}&end_date=${dateStrings[1]}&userId=${query.get("userId")}`;
          }}
        />
      }
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="phone"
        />
      </Space>
    </Card>
  );
};

export default UserAnalyticsDashboard;
