import React from "react";
import Parse from "parse";
import { useParseQuery } from "@parse/react";
import moment from "moment";
import { CircleMarker, Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
import { Divider, Row, Space, Typography } from "antd";
import RoutingMachine from "./RoutingComponent";
import L from "leaflet";

Parse.liveQueryServerURL = "wss://wss.munchies.com.bd";
Parse.enableLocalDatastore();

const scooterIcon = new L.Icon({
  iconUrl: require("./scooter.png"),
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const PopUpBody = ({ pickup, order }) => {
  return (
    <div className="text-center">
      <h6 className="text-primary">{pickup.name}</h6>
      <p className="text-muted">{pickup.address.address}</p>
      <p className="text-muted">{pickup.order_number}</p>
      <Link to={`/order/list/${order.objectId}`}>
        <button className="btn btn-sm btn-primary">View Order</button>
      </Link>
    </div>
  );
};

const RouteRider = ({ riderId, pickups, geo, color }) => {
  const parseQuery = new Parse.Query("ridertracks")
    .equalTo("user", {
      __type: "Pointer",
      className: "_User",
      objectId: riderId,
    })
    .descending("createdAt")
    .limit(1);

  const {
    isLive, // Indicates that Parse Live Query is connected
    isLoading, // Indicates that the initial load is being processed
    isSyncing, // Indicates that the library is getting the latest data from Parse Server
    results, // Stores the current results in an array of Parse Objects
    count, // Stores the current results count
    error, // Stores any error
    reload, // Function that can be used to reload the data
  } = useParseQuery(
    parseQuery, // The Parse Query to be used
    {
      enabled: true, // Enables the parse query (default: true)
      enableLocalDatastore: true, // Enables cache in local datastore (default: true)
      enableLiveQuery: true, // Enables live query for real-time update (default: true)
    }
  );

  const createWaypoints = (result, pickups, geo) => {
    const waypoints = [];
    const riderCurrentLocation = result?.toJSON();
    if (!riderCurrentLocation) return null;
    const locations = riderCurrentLocation.location.coordinates;
    waypoints.push([locations[1], locations[0]]);
    pickups?.forEach((pickup) => {
      const { latitude, longitude } = pickup.address;
      if (!pickup.picked) {
        waypoints.push([latitude, longitude]);
      }
    });
    waypoints.push(geo);

    return waypoints;
  };

  return (
    isLive &&
    !isLoading &&
    !isSyncing &&
    results?.map((result) => {
      if (!result) return null;

      return (
        <>
          <RoutingMachine
            key={riderId + "route"}
            routes={createWaypoints(result, pickups, geo)}
            color={color}
          />
          <Marker
            key={riderId}
            icon={scooterIcon}
            riseOnHover={true}
            position={[
              result?.toJSON().location.coordinates[1],
              result?.toJSON().location.coordinates[0],
            ]}
          >
            <Popup>
              <Space direction="horizontal">
                <Row justify="center">
                  <img
                    src={result?.toJSON().user.image}
                    alt={result?.toJSON().user.name}
                    style={{ width: "100px", height: "100px" }}
                  />
                </Row>
                <Row>
                  <Typography.Paragraph type="secondary" strong>
                    {result?.toJSON().user.username}
                  </Typography.Paragraph>
                  <Divider />
                  <Typography.Paragraph type="success">
                    {pickups?.filter((pickup) => !pickup.picked).length > 0
                      ? "Going to pick up"
                      : "Going to drop off"}
                  </Typography.Paragraph>
                  <Typography.Paragraph type="secondary" strong>
                    {result?.toJSON().user.name}
                  </Typography.Paragraph>
                  <Typography.Text strong>
                    {result?.toJSON().user.phone}
                  </Typography.Text>

                  <Typography.Paragraph type="danger">
                    Last seen:
                    {moment(new Date(result?.toJSON().createdAt)).format(
                      "D MMM hh:mm:ss A"
                    )}
                  </Typography.Paragraph>
                </Row>
              </Space>
            </Popup>
          </Marker>
        </>
      );
    })
  );
};

const OrderMap = ({ rider }) => {
  const startDay = moment().startOf("day").add(9, "hours").toDate();
  if (moment().hour() < 9) {
    startDay.setDate(startDay.getDate() - 1);
  }

  const endDay = moment().endOf("day").add(9, "hour").toDate();
  if (moment().hour() < 9) {
    endDay.setDate(endDay.getDate() - 1);
  }
  const parseQuery = new Parse.Query("order")
    .select([
      "status",
      "createdAt",
      "updatedAt",
      "rider",
      "pickups",
      "geo",
      "customer_address",
    ])
    .notContainedIn("status", ["cancelled", "delivered", "rejected"])
    .greaterThanOrEqualTo("createdAt", startDay)
    .lessThanOrEqualTo("createdAt", endDay);

  if (rider) {
    parseQuery.equalTo("rider", {
      __type: "Pointer",
      className: "_User",
      objectId: rider,
    });
  }

  const {
    isLive, // Indicates that Parse Live Query is connected
    isLoading, // Indicates that the initial load is being processed
    isSyncing, // Indicates that the library is getting the latest data from Parse Server
    results, // Stores the current results in an array of Parse Objects
    count, // Stores the current results count
    error, // Stores any error
    reload, // Function that can be used to reload the data
  } = useParseQuery(
    parseQuery, // The Parse Query to be used
    {
      enabled: true, // Enables the parse query (default: true)
      enableLocalDatastore: true, // Enables cache in local datastore (default: true)
      enableLiveQuery: true, // Enables live query for real-time update (default: true)
    }
  );

  const generateColor = () => {
    const color = "#" + Math.floor(Math.random() * 16777215).toString(16);
    return color;
  };

  return (
    isLive &&
    !isLoading &&
    results.map((order) => {
      order = order.toJSON();

      return order.pickups?.map((pickup) => {
        const { latitude, longitude } = pickup.address;
        if (!latitude || !longitude) return null;
        return (
          <>
            <CircleMarker
              color="blue"
              radius={5}
              key={pickup.objectId}
              center={[latitude, longitude]}
            >
              <Popup>
                <PopUpBody pickup={pickup} order={order} />
              </Popup>
            </CircleMarker>
            <CircleMarker
              color={"red"}
              radius={5}
              key={order.objectId}
              center={order.geo}
            >
              <Popup>
                <div className="text-center">
                  <Link to={`/order/list/${order.objectId}`}>
                    <Typography.Link
                      style={{
                        color: generateColor(),
                      }}
                    >
                      #{order.objectId}{" "}
                    </Typography.Link>
                  </Link>
                  <Divider />
                  <Typography.Text strong>
                    {order.customer_address}
                  </Typography.Text>

                  <Typography.Paragraph type="secondary">
                    {order.rider
                      ? "Rider: " + order.rider.username
                      : "No Rider"}
                  </Typography.Paragraph>
                  <br />
                  <Typography.Paragraph type="warning">
                    {order.status}
                  </Typography.Paragraph>
                  <br />
                  <Typography.Paragraph type="secondary">
                    {moment(order.createdAt).format("DD MMM hh:mm A")} -{" "}
                    {/* show time of last update */}
                    {moment(order.createdAt)
                      .diff(moment(), "minutes")
                      .toFixed()}{" "}
                    minute(s) ago
                  </Typography.Paragraph>
                </div>
              </Popup>
            </CircleMarker>

            {order.rider && (
              <RouteRider
                riderId={order.rider?.objectId}
                geo={order.geo}
                color={generateColor()}
                pickups={order.pickups}
              />
            )}
          </>
        );
      });
    })
  );
};

const TrackSingleRider = ({ rider }) => {
  const parseQuery = new Parse.Query("ridertracks")
    .equalTo("user", {
      __type: "Pointer",
      className: "_User",
      objectId: rider.objectId,
    })
    .select(["location", "createdAt"])
    .descending("createdAt")
    .limit(1);

  const {
    isLive, // Indicates that Parse Live Query is connected
    isLoading, // Indicates that the initial load is being processed
    isSyncing, // Indicates that the library is getting the latest data from Parse Server
    results, // Stores the current results in an array of Parse Objects
  } = useParseQuery(
    parseQuery, // The Parse Query to be used
    {
      enabled: true, // Enables the parse query (default: true)
      enableLocalDatastore: true, // Enables cache in local datastore (default: true)
      enableLiveQuery: true, // Enables live query for real-time update (default: true)
    }
  );

  return (
    isLive &&
    !isLoading &&
    !isSyncing &&
    results.map((result) => {
      const riderCurrentLocation = result?.toJSON();
      if (!riderCurrentLocation) return null;
      const locations = riderCurrentLocation.location.coordinates;

      return (
        <Marker
          key={rider.id}
          icon={scooterIcon}
          riseOnHover={true}
          position={[locations[1], locations[0]]}
        >
          <Popup>
            <Space direction="horizontal">
              <Row justify="center">
                <img
                  src={rider.image?.url}
                  alt={rider.name}
                  style={{ width: "100px", height: "100px" }}
                />
              </Row>
              <Typography.Paragraph type="secondary" strong>
                {rider.username}
              </Typography.Paragraph>
              <Divider />
              <Row>
                <Typography.Text type="secondary" strong>
                  {rider.name}
                </Typography.Text>
                <br />
                <Typography.Text strong>{rider.phone}</Typography.Text>

                <br />
                <Typography.Paragraph type="warning">
                  {rider.rider_availability
                    ? "Available & Idle"
                    : "Unavailable"}
                </Typography.Paragraph>
                <br />

                <Typography.Paragraph type="secondary">
                  {riderCurrentLocation.isRequested}
                </Typography.Paragraph>

                <Typography.Paragraph type="danger">
                  Last seen:
                  {moment(riderCurrentLocation.createdAt).format(
                    "DD MMM hh:mm A"
                  )}
                </Typography.Paragraph>
              </Row>
            </Space>
          </Popup>
        </Marker>
      );
    })
  );
};

const ActiveRiderMap = ({ riders, isLoading, isSyncing }) => {
  const availableRiders = riders
    ?.filter((rider) => rider.get("rider_availability") === true)
    .map((rider) => rider.toJSON());

  return (
    !isLoading &&
    !isSyncing &&
    availableRiders?.map((rider) => {
      return <TrackSingleRider key={rider.objectId} rider={rider} />;
    })
  );
};

const LiveMap = ({ rider }) => {
  const query = new Parse.Query("_User");
  if (rider) {
    query.equalTo("objectId", rider);
  } else {
    query.equalTo("type", "rider");
  }
  query.select(
    "name",
    "collection",
    "rider_availability",
    "phone",
    "image",
    "isRequested"
  );
  query.equalTo("active", true);

  const {
    isLive, // Indicates that Parse Live Query is connected
    isLoading, // Indicates that the initial load is being processed
    isSyncing, // Indicates that the library is getting the latest data from Parse Server
    results, // Stores the current results in an array of Parse Objects
  } = useParseQuery(
    query, // The Parse Query to be used
    {
      enabled: true, // Enables the parse query (default: true)
      enableLocalDatastore: true, // Enables cache in local datastore (default: true)
      enableLiveQuery: true, // Enables live query for real-time update (default: true)
    }
  );

  return (
    <>
      <OrderMap rider={rider} />
      <ActiveRiderMap
        riders={results}
        isLoading={isLoading}
        isSyncing={isSyncing}
      />
    </>

  );
};

export default LiveMap;
