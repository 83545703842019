import { useState, useEffect } from "react";
import { Table, message, DatePicker, Button, Space, Row, Col } from "antd";
import Parse from "parse";
import useSearch from "../../components/inventory/utils/useSearch";
import { downloadCsv } from "../../utils";

export default function InventoryRecords() {
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [, getSearchParams] = useSearch();
  const [brands, setBrands] = useState([]);
  const [total, setTotal] = useState({
    priceTotal: 0,
    unitTotal: 0,
  });

  const fetchBrands = async () => {
    try {
      const brands = await new Parse.Query("restaurant")
        .containedIn("type", ["store", "sub_store"])
        .select(["name", "hub.name"])
        .exclude("managedBy", "timeline")
        .find();

      setBrands(brands.map((brand) => brand.toJSON()));
    } catch (err) {
      message.error(err.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getSearchParams("name"),
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
      filters: brands.map((brand) => ({
        text: `${brand.name} | ${brand.hub?.name}`,
        value: brand.objectId,
      })),
      render: (rest) => rest?.name,
    },
    {
      title: "stock",
      dataIndex: "stock",
      key: "stock",
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: "Current Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price?.amount - b.price?.amount,
      render: (price) => price?.amount,
    },
    {
      title: "Avg Unit price",
      key: "unitPrice",
      render: (r) => Math.round(r.unitPrice / r.stock),
    },
    {
      title: "Unit Total",
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: "Total",
      key: "total",
      render: (record) => record.stock * record.price?.amount,
    },
  ];

  if (date) {
    columns.push({
      title: "Sold",
      dataIndex: "sold",
      key: "sold",
      sorter: (a, b) => a.sold - b.sold,
    });
  }

  const fetchData = async ({ limit = 500, skip = 0, date, ...rest }) => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("inventoryRecords", {
        limit,
        skip,
        date,
        ...rest,
      });

      setData(res);

      const total = res.reduce(
        (acc, item) => {
          acc.priceTotal += item.stock * item.price?.amount;
          acc.unitTotal += item.unitPrice;
          return acc;
        },
        {
          priceTotal: 0,
          unitTotal: 0,
        }
      );
      setTotal(total);

      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  const downloadHandler = () => {
    downloadCsv(
      [
        {
          header: [
            { key: "name", title: "Name" },
            { key: "stock", title: "Stock" },
            { key: "price", title: "Price" },
            { key: "sold", title: "Sold" },
          ],
          data: data.map((item) => {
            item.price = item.price?.amount;
            return item;
          }),
        },
      ],
      `inventory_records_${date}.csv`
    );
  };

  useEffect(() => {
    fetchBrands();
    fetchData({ date });
  }, [date]);

  return (
    <div>
      <Row
        style={{ marginBottom: "20px" }}
        gutter={[16, 16]}
        justify="space-between"
      >
        <Col span={24} lg={8}>
          <Space>
            <DatePicker
              size="large"
              showTime
              onChange={(date, dateString) => setDate(dateString)}
              placeholder="Previous date to get sold records"
            />
            <Button size="large" type="primary" onClick={downloadHandler}>
              Download
            </Button>
          </Space>
        </Col>
        <Col span={24} lg={16} style={{ textAlign: "right" }}>
          <Space>
            <Space>
              <div>Price Total</div>
              <div
                style={{
                  padding: "10px 20px",
                  background: "#fff",
                  boxShadow: "0px 1px 2px rgba(0,0,0,.2)",
                  borderRadius: "5px",
                  fontWeight: "500",
                }}
              >
                {data && Math.round(total.priceTotal)} tk
              </div>
            </Space>
            <Space>
              <div>Unit Total</div>
              <div
                style={{
                  padding: "10px 20px",
                  background: "#fff",
                  boxShadow: "0px 1px 2px rgba(0,0,0,.2)",
                  borderRadius: "5px",
                  fontWeight: "500",
                }}
              >
                {data && Math.round(total.unitTotal)} tk
              </div>
            </Space>
            <Space>
              <div>Potential Revenue</div>
              <div
                style={{
                  padding: "10px 20px",
                  background: "#fff",
                  boxShadow: "0px 1px 2px rgba(0,0,0,.2)",
                  borderRadius: "5px",
                  fontWeight: "500",
                }}
              >
                {data &&
                  Math.round(total.priceTotal) -
                    Math.round(total.unitTotal)}{" "}
                tk
              </div>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data || []}
            loading={loading}
            pagination={{
              defaultPageSize: 200,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["100", "200", "500", "1000"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              total: data.length,
              position: ["topLeft", "topRight"],
            }}
            onChange={(pagination, filters, sorter) => {
              if (Array.isArray(filters.restaurant)) {
                fetchData({
                  date,
                  brands: filters.restaurant,
                });
              }
            }}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </div>
  );
}
