import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Area } from "@ant-design/plots";

export default function SalesPerDate() {
  const { data } = useContext(SalesReportContext);
  const salesPerDay = Object.entries(data?.salesPerDate || {}).map(
    ([date, order]) => {
      return { date, value: order };
    }
  );

  const config = {
    data: salesPerDay,
    xField: "date",
    yField: "value",
    smooth: true,
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      };
    },
  };

  return (
    <div id="sales-per-day">
      <h2>Sales Per Day</h2>
      <Area {...config} />
    </div>
  );
}
