import { CloseOutlined } from "@ant-design/icons";
import { Row, Button } from "antd";
import { useState } from "react";
import styled from "styled-components";
import EmailForm from "./EmailForm";
import EmailView from "./EmailView";

export default function EmailTemplate({ observer }) {
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);

  return (
    <EmailWrapper>
      <Button block type="primary" onClick={() => setVisible(true)}>
        Email Template
      </Button>
      <div
        style={{ display: visible ? "block" : "none" }}
        className="container customScroll"
      >
        <Button
          className="close"
          icon={<CloseOutlined />}
          shape="circle"
          danger
          onClick={() => setVisible(false)}
        />
        <div className="contents">
          <Row gutter={[24, 24]}>
            <EmailForm observer={observer} data={data} setData={setData} />
            <EmailView data={data} />
          </Row>
        </div>
      </div>
    </EmailWrapper>
  );
}

const EmailWrapper = styled.div`
  .container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: #000000e0;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1001;
    }
  }

  .contents {
    background: #f9f9f9;
    padding: 20px;
  }

  .section {
    background: #fff;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

    h2,
    h3 {
      text-align: center;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
`;
