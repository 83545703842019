import { useContext } from "react";
import { DashboardContext } from "../../pages/Dashboard/DashboardContext";
import { Row, Space } from "antd";
import Item from "../../components/Common/StatisticsCard";

export default function SalesAnalytics() {
  const {
    salesAnalytics: { data, loading },
    dailyOrderAverage: { data: dailyOrderAverage },
  } = useContext(DashboardContext);

  return !loading && data ? (
    <Row gutter={[24, 24]}>
      <Item
        title="Total Orders"
        icon="orders.png"
        isAmount={false}
        value={data.order_count}
      />
      <Item
        title="Order Delivered"
        icon="delivered.png"
        isAmount={false}
        value={data.order_delivered}
      />
      <Item
        icon="total-sales.png"
        title="Total Sales"
        value={data.total_sales}
        lg={8}
        xl={6}
      />
      <Item
        icon="shipping.png"
        title="Shipping"
        value={data.delivery_charge}
        lg={6}
        xl={5}
      />
      <Item
        title="Average Order Value"
        icon="order-value.png"
        value={
          data.total_sales > 0
            ? Math.round(data.total_sales / data.order_delivered)
            : 0
        }
        xl={5}
      />
      <Item
        title="Munchies Discount"
        icon="munchies-discount.png"
        value={data.munchies_discount}
      />
      <Item
        icon="discount.png"
        title="Restaurant Discount"
        value={data.restaurant_discount}
        xl={5}
      />
      <Item
        icon="vat.png"
        title="Vat Collected"
        value={Math.round(data.vat)}
        xl={5}
      />
      <Item
        title="Commission"
        icon="commission.png"
        value={Math.round(data.commission)}
        value2={
          <Space direction="vertical" style={{ textAlign: "center" }} size={0}>
            <div>{Math.round(data.store_commission)} tk</div>
            <div className="title">Store Commission</div>
          </Space>
        }
        lg={7}
        xl={5}
      />
      <Item
        title="Store Commission"
        icon="commission.png"
        value={Math.round(data.store_commission)}
        lg={7}
        xl={5}
      />
      <Item
        title="Service Charge"
        icon="service-charge.png"
        value={data.service_charge}
        lg={7}
        xl={5}
      />
      <Item
        title="Failed Order Amount"
        icon="failed-amount.png"
        value={data.failed_order_amount}
        lg={7}
        xl={5}
      />
      <Item
        title="Restaurant Penalty"
        icon="penalty.png"
        value={data.restaurant_penalty}
        lg={7}
        xl={5}
      />
      <Item
        title="Restaurant Refund"
        icon="refund.png"
        value={data.restaurant_refund}
      />
      <Item
        title="Total Refund"
        icon="total-refund.png"
        value={data.total_refund}
        lg={7}
        xl={5}
      />
      <Item
        title="Net Sales"
        icon="net-sales.png"
        htmlTitle="total - (shipping + vat + service charge)"
        value={Math.round(
          data.total_sales -
            (data.delivery_charge + data.vat + data.service_charge)
        )}
        lg={8}
        xl={6}
      />
      <Item
        isAmount={false}
        title="Average Delivery Time (Minutes) "
        value={dailyOrderAverage?.total?.averageDeliveryTimeMinutes}
      />
      <Item
        isAmount={false}
        title="Max Delivery Time (Minutes) "
        value={dailyOrderAverage?.total?.maxDeliveryTimeMinutes}
      />
      <Item
        isAmount={false}
        title="Min Delivery Time (Minutes) "
        value={dailyOrderAverage?.total?.minDeliveryTimeMinutes}
      />
    </Row>
  ) : null;
}
