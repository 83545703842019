import PricingType from "./PricingType";
import PriceAmount from "./PriceAmount";

export default function Pricing() {
  return (
    <div className="section">
      <PricingType />
      <PriceAmount />
    </div>
  );
}
