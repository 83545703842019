import { message } from "antd";
import { useReducer, createContext, useState } from "react";
import useUser from "../../hooks/useUser";
import { downloadCsv, localDate, parser } from "../../utils";
import UsersTable from "../../components/user/UsersTable";
import Parse from "parse";

export const UserListContext = createContext();

const Types = {
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
};

const initialState = {
  users: { loading: false, data: { count: 0, results: [] } },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_USERS_REQUEST:
      state.users.loading = true;
      return { ...state };
    case Types.GET_USERS_SUCCESS:
      state.users.loading = false;
      state.users.data = action.payload;
      return { ...state };
    case Types.GET_USERS_FAILURE:
      state.users.loading = false;
      return { ...state };
    default:
      return state;
  }
};

export default function UserList(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getUsers, updateUser, deleteUsers } = useUser();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchUsers = (params) => {
    dispatch({ type: Types.GET_USERS_REQUEST });
    getUsers(params, (err, users) => {
      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_USERS_FAILURE });
        return;
      }

      if (users) {
        console.log(parser(users.results));
        dispatch({
          type: Types.GET_USERS_SUCCESS,
          payload: {
            count: users.count,
            results: parser(users.results),
          },
        });
      }
    });
  };

  const activeHandler = async ({ id, active }) => {
    updateUser({ id, active }, (err, user) => {
      if (err) {
        return message.error(err);
      }
      message.success(
        `User ${user.get("username")} is ${active ? "activated" : "deactivated"
        }!`
      );
      const updatedUser = state.users.data.results.find(
        (user) => user.id === id
      );
      updatedUser.active = active;
      updatedUser.ref.set("active", active);
      dispatch({ type: Types.GET_USERS_SUCCESS, payload: state.users.data });
    });
  };

  const setPriorityOrder = ({ id, no }, done = () => { }) => {
    updateUser({ id, priorityOrder: no }, (err) => {
      if (err) {
        done();
        return message.error(err);
      }
      message.success(`Updated!`);
      const updatedUser = state.users.data.results.find(
        (user) => user.id === id
      );
      if (updatedUser) {
        updatedUser.priorityOrder = no;
        updatedUser.ref.set("priorityOrder", no);
        dispatch({ type: Types.GET_USERS_SUCCESS, payload: state.users.data });
      }
      done();
    });
  };

  const setNote = ({ id, note }, done = () => { }) => {
    updateUser({ id, note }, (err) => {
      if (err) {
        done();
        return message.error(err);
      }
      message.success(`Updated!`);
      const updatedUser = state.users.data.results.find(
        (user) => user.id === id
      );
      if (updatedUser) {
        updatedUser.note = note;
        updatedUser.ref.set("note", note);
        dispatch({ type: Types.GET_USERS_SUCCESS, payload: state.users.data });
      }
      done();
    });
  };

  const setMarketingNote = ({ id, marketing_note }, done = () => { }) => {
    updateUser({ id, marketing_note }, (err) => {
      if (err) {
        done();
        return message.error(err);
      }
      message.success(`Updated!`);
      const updatedUser = state.users.data.results.find(
        (user) => user.id === id
      );
      if (updatedUser) {
        updatedUser.marketing_note = marketing_note;
        updatedUser.ref.set("marketing_note", marketing_note);
        dispatch({ type: Types.GET_USERS_SUCCESS, payload: state.users.data });
      }
      done();
    });
  };
  const setFeedbackNote = ({ id, call_feedback }, done = () => { }) => {
    updateUser({ id, call_feedback }, (err) => {
      if (err) {
        done();
        return message.error(err);
      }
      message.success(`Updated!`);
      const updatedUser = state.users.data.results.find(
        (user) => user.id === id
      );
      if (updatedUser) {
        updatedUser.call_feedback = call_feedback;
        updatedUser.ref.set("call_feedback", call_feedback);
        dispatch({ type: Types.GET_USERS_SUCCESS, payload: state.users.data });
      }
      done();
    });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const deleteUsersHandler = ({ ids }, callback) => {
    deleteUsers({ ids }, (err, res) => {
      if (res) {
        message.success(`${res.length} users deleted!`);
        const results = state.users.data.results.filter(
          (user) => !ids.includes(user.id)
        );
        dispatch({
          type: Types.GET_USERS_SUCCESS,
          payload: {
            count: state.users.data.count - res.length,
            results,
          },
        });
        setSelectedRowKeys([]);
        setSelectedRows([]);
        if (typeof callback === "function") callback();
      } else {
        message.error(err);
        if (typeof callback === "function") callback();
      }
    });
  };

  const downloadUsers = async (params) => {
    // if (!Array.isArray(params.createdAt)) {
    //   message.info("Please select a date range to download users");
    //   return;
    // }

    params.type = ["customer"];
    const count = await new Parse.Query(Parse.User)
      .containedIn("type", params.type)
      .count();
    params.limit = count;

    getUsers(params, (err, users) => {
      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_USERS_FAILURE });
        return;
      }

      if (users) {
        downloadCsv(
          [
            {
              title: `Munchies Users downloaded on ${new Date().toLocaleString()}`,
              header: [
                { key: "name", title: "Name" },
                { key: "email", title: "Email" },
                { key: "username", title: "Phone" },
                { key: "createdAt", title: "Created At" },
                { key: "gender", title: "Gender" },
                { key: "date_of_birth", title: "Date of Birth" },
                { key: "platform", title: "Platform" },
                { key: "order_count", title: "Order Count" },
              ],
              data: users.results.map((user) => {
                const {
                  name,
                  email,
                  username,
                  createdAt,
                  gender,
                  date_of_birth,
                  device_info,
                  order_count,
                } = user.toJSON();

                return {
                  name,
                  email: email || "N/A",
                  username,
                  createdAt: localDate(createdAt),
                  gender,
                  date_of_birth: date_of_birth
                    ? localDate(date_of_birth.iso)
                    : "N/A",
                  platform: device_info ? device_info.type : "Website",
                  order_count,
                };
              }),
            },
          ],
          `munchies-users-${new Date().toLocaleString()}.csv`
        );
      }
    });
  };

  return (
    <UserListContext.Provider
      value={{
        ...state,
        fetchUsers,
        activeHandler,
        selectedRowKeys,
        onSelectChange,
        selectedRows,
        setSelectedRowKeys,
        setSelectedRows,
        deleteUsers: deleteUsersHandler,
        downloadUsers,
        setPriorityOrder,
        setNote,
        setMarketingNote,
        setFeedbackNote,
        nidUpdated: (id) => {
          dispatch({ type: Types.NID_UPDATE, payload: id });
        },
      }}
    >
      <UsersTable />
    </UserListContext.Provider>
  );
}
