import { Button, DatePicker, Form, Input, Space, message, Select } from "antd";
import React, { useState, useEffect } from "react";
import UploadMedia from "../../components/media/UploadMedia";
import Parse from "parse";
import moment from "moment";
import DeliveryAreaRestaurant from "../Restaurant/DeliveryArea";
import { v3Axios } from "../../utils";

const { Option } = Select;

const CRUDStory = ({ story, setData }) => {
  const [form] = Form.useForm();
  const [restaurants, setRestaurants] = useState([]);
  const [zones, setZones] = useState([]);
  const [areas, setAreas] = useState([]);

  const [media, setMedia] = useState({
    visible: false,
    images: [],
    loading: false,
    fileList: [],
    multiple: false,
  });

  useEffect(() => {
    const fetchLayers = async () => {
      try {
        const { data } = await v3Axios.get("/api/v1/areas");

        const zoneLists = {};

        for (const item of data) {
          if (!zoneLists[item?.zone._id]) {
            zoneLists[item?.zone._id] = {
              ...item.zone,
              areas: [],
            };
          }
          zoneLists[item?.zone._id].areas.push(item);
        }
        setZones(Object.values(zoneLists));
      } catch (err) {
        console.log(err);
        message.error("Something went wrong");
      }
    };
    const fetchRestaurants = async () => {
      const results = await new Parse.Query("restaurant")
        .select("name", "banner_image", "objectId") // Include "objectId" to get the restaurant's ID.
        .limit(1000)
        .find();

      if (!results) {
        message.error("No restaurants found");
        return;
      }
      setRestaurants(results?.map((result) => result.toJSON()));
      setAreas(story?.areas || []);
    };
    fetchRestaurants();
    fetchLayers();
  }, [story?.areas]);

  useEffect(() => {
    if (story) {
      const _restaurant = restaurants.find(
        (restaurant) => restaurant.objectId === story.restaurant.objectId
      );

      form.setFieldsValue({
        title: story.title,
        restaurant: _restaurant?.objectId,
        expired_at: story.expired_at ? moment(story.expired_at.iso) : null,
        media: story.media,
      });

      setMedia({
        ...media,
        images: story.media ? [story.media] : [],
        fileList: story.media ? [story.media] : [],
      });

      setAreas(story.areas || []);
    }
  }, [form, story, restaurants]);

  const handleFormSubmit = async (values) => {
    if (!media.images.length) {
      message.error("Please upload the image");
      return;
    }

    values.media = media.images[0];

    values.expired_at = values.expired_at?.toDate();

    let _story = null;

    if (story) {
      _story = await new Parse.Query("story")
        .equalTo("objectId", story.objectId)
        .first();

      if (!_story) {
        message.error("Story not found");
        return;
      }
    } else {
      _story = new Parse.Object("story");
    }

    _story.set("title", values.title);
    _story.set("restaurant", {
      __type: "Pointer",
      className: "restaurant",
      objectId: values.restaurant,
    });
    _story.set("expired_at", values.expired_at);
    _story.set("media", values.media);
    _story.set("areas", areas);

    try {
      await _story.save();
      message.success("Story saved successfully");
      setData((data) => {
        if (story) {
          const index = data.findIndex(
            (item) => item.objectId === story.objectId
          );
          _story = _story.toJSON();
          _story.restaurant = {
            objectId: values.restaurant,
            name: restaurants.find(
              (restaurant) => restaurant.objectId === values.restaurant
            ).name,
          };
          data[index] = _story;
          return [...data];
        }
        _story = _story.toJSON();
        _story.restaurant = {
          objectId: values?.restaurant,
          name: restaurants?.find(
            (restaurant) => restaurant.objectId === values.restaurant
          ).name,
        };
        return [_story, ...data];
      });
    } catch (error) {
      message.error(error.message);
    }

    form.resetFields();
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Space
        direction="vertical"
        style={{
          width: "90%",
        }}
        size={"middle"}
      >
        <Form.Item
          name="title"
          label="Story Title"
          rules={[{ required: true, message: "Please enter the title" }]}
        >
          <Input placeholder="Story Title" />
        </Form.Item>
        <Form.Item
          name="restaurant"
          label="Select a Restaurant"
          rules={[{ required: true, message: "Please select a restaurant" }]}
        >
          <Select
            showSearch
            placeholder="Select a restaurant"
            optionFilterProp="children"
          >
            {restaurants.map((restaurant) => (
              <Option key={restaurant.objectId} value={restaurant.objectId}>
                {restaurant.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="expired_at"
          label="Expiry date"

          rules={[{ required: true, message: "Please select the expiry date" }]}
        >
          <DatePicker
            placeholder="Expiry date"
            format="DD-MMM-YYYY hh:mm A"
            showTime
          />
        </Form.Item>
        <Form.Item
          label="Upload Media"
          name="media"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
        >
          <UploadMedia state={media} setState={setMedia} />
          <Button
            style={{ width: "100%" }}
            type="dashed"
            onClick={() => setMedia({ ...media, visible: true })}
          >
            Select Media/Image
          </Button>
        </Form.Item>

        <div
          style={{
            width: "100%",
          }}
          className="section section-map"
        >
          <h3 className="section-title">Delivery Area</h3>
          <DeliveryAreaRestaurant
            zones={zones}
            setAreas={setAreas}
            areas={areas}
          />
        </div>

        <Button style={{ width: "100%" }} type="primary" htmlType="submit">
          {story ? "Update Story" : "Create Story"}
        </Button>
      </Space>
    </Form>
  );
};

export default CRUDStory;
