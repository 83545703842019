import { useState, useContext } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import ContentHeader from "./ContentHeader";
import Styled from "styled-components";
import { AuthContext } from "../../AuthProvider";

const { Content, Footer } = Layout;

export default function Structure({ children }) {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("menu-collapsed")
  );
  const {
    login: { data },
    pages,
  } = useContext(AuthContext);
  const width = window.innerWidth;

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    if (collapsed) {
      localStorage.setItem("menu-collapsed", true);
    } else {
      localStorage.removeItem("menu-collapsed");
    }
  };

  return (
    <Layout>
      <Sidebar
        authData={data}
        collapsed={collapsed}
        onCollapse={onCollapse}
        pages={pages}
        width={width}
      />
      <Layout
        style={{
          marginLeft: width > 768 ? (collapsed ? "80px" : "200px") : "",
        }}
      >
        <ContentHeader width={width} onCollapse={onCollapse} />
        <ContentWrapper>{children}</ContentWrapper>
      </Layout>
    </Layout>
  );
}

const ContentWrapper = Styled(Content)`
    min-height: calc(100vh - 64px);
    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;
