import styled from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { CarouselContext } from "../../pages/Carousel/Context";
import {
  Row,
  Col,
  Image,
  Button,
  Space,
  Upload,
  Select,
  message,
  Popconfirm,
  Tag,
  Drawer,
} from "antd";
import { compressedImage, getImageKey } from "../../common/utils";
import RestaurantLinks from "../Common/RestaurantLinks";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  InboxOutlined,
  CloseOutlined,
  CheckOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Parse from "parse";
import useMedia from "../../hooks/useMedia";
import { FaCheckDouble } from "react-icons/fa";
import AddCarousel from "./AddCarousel";

function EditBanner() {
  const {
    restaurants,
    dispatch,
    searchRestaurants,
    editBanner,
    setEditBanner,
  } = useContext(CarouselContext);
  const { restaurantId, refs, idx, platform } = editBanner || {};
  const [restaurant, setRestaurant] = useState("");
  const [newPlatform, setPlatform] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchRestaurants("", [restaurantId]);
    setRestaurant(restaurantId);
    setPlatform(platform);

    return () => {
      setRestaurant("");
      setPlatform("");
    };
  }, [restaurantId]);

  const onSubmit = async () => {
    if (refs instanceof Parse.Object) {
      try {
        setLoading(true);
        const banner = refs.get("banner");
        if (!banner[idx]) return message.error("Banner not found");

        banner[idx].restaurantId = restaurant;
        banner[idx].name =
          restaurants.find((r) => r.objectId === restaurant)?.name ?? "";
        banner[idx].platform = newPlatform;
        refs.set("banner", banner);
        refs.set(
          "images",
          banner.map((i) => i.image)
        );

        const res = await refs.save(null, {
          sessionKey: Parse.User?.current()?.getSessionToken(),
        });

        if (res) {
          message.success("Saved");
          dispatch({
            type: "UPDATE_BANNER",
            payload: {
              id: refs.id,
              banner: res.get("banner"),
            },
          });

          setEditBanner(null);
        }
      } catch (err) {
        message.error(err.message);
      }
      setLoading(false);
    } else {
      message.error("Invalid refernce");
    }
  };

  if (!editBanner) return null;

  return (
    <div className="edit-box">
      <div>
        <h3>Select Restaurant</h3>
        <Select
          size="large"
          value={restaurant}
          onChange={(value) => setRestaurant(value)}
          style={{
            maxWidth: "100%",
            fontWeight: "500",
            minWidth: "150px",
          }}
          onSearch={(value) => searchRestaurants(value)}
          showSearch
          allowClear
          filterOption={(input, option) => {
            if (Array.isArray(option.children)) {
              return (
                option.children[0]
                  ?.toLowerCase()
                  ?.indexOf(input.toLowerCase()) >= 0
              );
            } else {
              return option.children?.indexOf(input?.toLowerCase()) >= 0;
            }
          }}
        >
          {!restaurant && (
            <Select.Option value="">Select Restaurant</Select.Option>
          )}
          {restaurants.map((restaurant, i) => {
            return (
              <Select.Option
                style={{ fontWeight: "500" }}
                key={i}
                value={restaurant.objectId}
              >
                {restaurant.name}
                <br />{" "}
                <span style={{ color: "gray" }}>({restaurant.hub?.name})</span>
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div style={{ marginTop: "10px" }}>
        <div style={{ fontSize: "16px", fontWeight: "500" }}>Platform</div>
        <Select
          style={{ width: "120px" }}
          value={newPlatform}
          onChange={(e) => setPlatform(e)}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="web">Web</Select.Option>
          <Select.Option value="app">App</Select.Option>
        </Select>
      </div>
      <Space style={{ marginTop: "20px" }}>
        <Button
          icon={<CloseOutlined />}
          danger
          onClick={() => setEditBanner(null)}
        >
          Cancel
        </Button>
        <Button loading={loading} onClick={onSubmit} icon={<CheckOutlined />}>
          Submit
        </Button>
      </Space>
    </div>
  );
}

function Banner(props) {
  const { image, name, restaurantId, platform, idx, deleteHandler } = props;
  const { setEditBanner } = useContext(CarouselContext);

  return (
    <Draggable draggableId={`droppable-${idx}`} index={idx}>
      {(provided) => {
        return (
          <div
            className="banner border"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="banner-image">
              <Image src={compressedImage(image)} alt="" />
            </div>
            <div className="banner-content">
              {restaurantId && name ? (
                <RestaurantLinks id={restaurantId}>
                  <h3 className="title">{name}</h3>
                </RestaurantLinks>
              ) : (
                <div>
                  <strong>Restaurant</strong>: N/A
                </div>
              )}
              {platform && (
                <div>
                  <strong>Platform</strong>:{" "}
                  <Tag style={{ textTransform: "capitalize" }}>{platform}</Tag>
                </div>
              )}
            </div>
            <div className="action">
              <Space direction="vertical">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => setEditBanner(props)}
                  type="primary"
                ></Button>
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteHandler(idx)}
                  danger
                ></Button>
              </Space>
            </div>
            {provided.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
}

function AddBanner({ id, setAddNew }) {
  const {
    restaurants,
    dispatch,
    searchRestaurants,
    carousels: { data },
  } = useContext(CarouselContext);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [imageKey, setImageKey] = useState(null);
  const [restaurant, setRestaurant] = useState("");
  const [platform, setPlatform] = useState("all");
  const { getSignedUrl, uploadFile, deleteFile } = useMedia();
  const [uploading, setUploading] = useState(0);
  const [loading, setLoading] = useState(false);

  const uploadHandler = async (file) => {
    if (file) {
      try {
        const { url, key } = await getSignedUrl(file.type);
        if (url) {
          uploadFile({ url, file }, (err, progress) => {
            if (err) {
              message.error(err);
            } else if (progress) {
              setUploading(progress);
              if (progress === 100) {
                const imgUrl = url.split("?")[0];
                setImage(imgUrl);
                setImageKey(key);
              }
            }
          });
        }
      } catch (err) {
        message.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (file?.image) {
      uploadHandler(file.image);
    }
  }, [file]);

  const uploadOnChange = async ({ file, fileList }) => {
    if (fileList[0]?.originFileObj) {
      const src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileList[0]?.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
      setFile({
        image: fileList[0],
        thumbUrl: src,
      });
    }
  };

  const submitHandler = useCallback(() => {
    if (!image) return message.error("Image not uploaded yet!");

    let res;
    if (restaurant) {
      res = restaurants.find((r) => r.objectId === restaurant);
    }

    if (file.image.size > 1024 * 1024 * 10) {
      return message.error("File size should not be more than 10MB");
    }

    const carousel = data.results?.find((item) => item.id === id);
    if (carousel) {
      setLoading(true);

      const bannerItem = {
        restaurantId: restaurant,
        image,
        name: res?.name ?? "",
        restaurantSlug: res?.slug ?? "",
        platform,
      };

      carousel.banner.unshift(bannerItem);

      if (carousel.ref instanceof Parse.Object) {
        carousel.ref.set("banner", carousel.banner);
        carousel.ref.set(
          "images",
          carousel.banner.map((i) => i.image)
        );

        try {
          const res = carousel.ref.save(null, {
            sessionToken: Parse.User.current().getSessionToken(),
          });
          if (res) {
            dispatch({
              type: "ADD_BANNER",
              payload: {
                id,
                banner: carousel.banner,
              },
            });
            setAddNew(false);
          }
          setLoading(false);
        } catch (err) {
          message.error(err.message);
          setLoading(false);
        }
      } else {
        message.error("Something went wrong!");
        setLoading(false);
      }
    }
  }, [restaurant, platform, image, id, file, data, restaurants]);

  return (
    <div className="add-banner">
      {!file?.image && (
        <div>
          <Upload.Dragger
            beforeUpload={() => false}
            style={{
              marginBottom: "10px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1);",
              background: "#fff",
            }}
            name="files"
            listType="picture"
            multiple={false}
            type="file"
            showUploadList={false}
            onChange={uploadOnChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
          <Button
            className="close"
            icon={<CloseOutlined />}
            shape="circle"
            onClick={() => setAddNew(false)}
          ></Button>
        </div>
      )}

      {file && (
        <div className="banner">
          <div className="banner-image">
            <Image src={file.thumbUrl} alt="" />
            {uploading > 0 && (
              <div className="progress">
                {uploading < 100 && <span>{uploading}</span>}
                {uploading === 100 && (
                  <FaCheckDouble style={{ color: "green" }} />
                )}
              </div>
            )}
          </div>
          <div className="banner-content">
            <Space direction="vertical">
              <label style={{ fontSize: "16px", fontWeight: "500" }}>
                Select restaurant
              </label>
              <Select
                size="large"
                value={restaurant}
                onChange={(value) => setRestaurant(value)}
                style={{
                  maxWidth: "100%",
                  fontWeight: "500",
                  minWidth: "150px",
                }}
                showSearch
                onSearch={(value) => searchRestaurants(value)}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (Array.isArray(option.children)) {
                    return (
                      option.children[0]
                        ?.toLowerCase()
                        ?.indexOf(input.toLowerCase()) >= 0
                    );
                  } else {
                    return option.children?.indexOf(input?.toLowerCase()) >= 0;
                  }
                }}
              >
                {!restaurant && (
                  <Select.Option value="">Select Restaurant</Select.Option>
                )}
                {restaurants.map((restaurant, i) => {
                  return (
                    <Select.Option
                      style={{ fontWeight: "500" }}
                      key={i}
                      value={restaurant.objectId}
                    >
                      {restaurant.name}
                      <br />{" "}
                      <span style={{ color: "gray" }}>
                        ({restaurant.hub?.name})
                      </span>
                    </Select.Option>
                  );
                })}
              </Select>
              <div>
                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                  Platform
                </div>
                <Select
                  style={{ width: "120px" }}
                  value={platform}
                  onChange={(e) => setPlatform(e)}
                >
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="web">Web</Select.Option>
                  <Select.Option value="app">App</Select.Option>
                </Select>
              </div>
            </Space>
          </div>
          <Space className="action" direction="vertical">
            <Button
              icon={<CloseOutlined />}
              shape="circle"
              style={{ color: "red" }}
              onClick={() => {
                deleteFile(imageKey);
                setAddNew(false);
              }}
            ></Button>
            <Button
              icon={!loading ? <CheckOutlined /> : <LoadingOutlined />}
              type="primary"
              shape="circle"
              disabled={uploading !== 100}
              onClick={submitHandler}
            ></Button>
          </Space>
        </div>
      )}
    </div>
  );
}

function DeleteCarousel({ id }) {
  const { deleteHandler } = useContext(CarouselContext);

  return (
    <Popconfirm
      title="Are you sure you want to delete this carousel?"
      onConfirm={() => {
        deleteHandler(id);
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button icon={<DeleteOutlined />} shape="circle" danger />
    </Popconfirm>
  );
}

function Carousel({ id, name, banner, refs }) {
  const {
    dispatch,
    carousels: { data },
  } = useContext(CarouselContext);
  const [addNew, setAddNew] = useState(false);
  const { deleteFile } = useMedia();

  const deleteHandler = async (idx) => {
    if (refs instanceof Parse.Object) {
      try {
        const items = [...banner];
        const item = items[idx];

        items.splice(idx, 1);
        refs.set("banner", items);
        refs.set(
          "images",
          items.map((i) => i.image)
        );

        const res = await refs.save(null, {
          sessionToken: Parse.User.current().getSessionToken(),
        });

        if (res) {
          // delete from s3 bucket
          deleteFile(getImageKey(item.image));

          dispatch({
            type: "UPDATE_BANNER",
            payload: {
              id,
              banner: items,
            },
          });

          message.success("Banner deleted");
        }
      } catch (err) {
        message.error(err.message);
      }
    }
  };

  const onDragEnd = async ({ source, destination }) => {
    const { droppableId, index: sIdx } = source;
    const { index: dIdx, droppableId: ddid } = destination ?? {};

    if (sIdx !== dIdx || droppableId !== ddid) {
      const carousel = data.results.find((i) => i.id === droppableId);
      if (carousel) {
        const items = [...carousel.banner];
        const [removed] = items.splice(sIdx, 1);
        items.splice(dIdx, 0, removed);

        carousel.ref?.set("banner", items);
        carousel.ref?.set(
          "images",
          items.map((i) => i.image)
        );

        dispatch({
          type: "UPDATE_BANNER",
          payload: {
            id: droppableId,
            banner: items,
          },
        });

        if (carousel.ref instanceof Parse.Object) {
          try {
            await carousel.ref.save(null, {
              sessionToken: Parse.User.current().getSessionToken(),
            });
          } catch (err) {
            message.error(err.message);
          }
        }
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id}>
        {(provided) => {
          return (
            <Col
              span={24}
              lg={12}
              xl={8}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="carousel">
                <div className="carousel-header">
                  <h3 className="name">{name}</h3>
                  <Space>
                    <DeleteCarousel id={id} />
                    <Button
                      onClick={() => setAddNew(true)}
                      shape="circle"
                      icon={<PlusOutlined />}
                      size="large"
                    ></Button>
                  </Space>
                </div>
                {addNew && <AddBanner id={id} setAddNew={setAddNew} />}
                <div className="banners customScroll">
                  {banner?.map((item, idx) => {
                    return (
                      <Banner
                        key={idx}
                        deleteHandler={deleteHandler}
                        idx={idx}
                        {...item}
                        refs={refs}
                      />
                    );
                  })}
                </div>
              </div>
              {provided.placeholder}
            </Col>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

export default function CarouselsV2() {
  const {
    carousels: { data },
    fetchCarousels,
    editBanner,
    setEditBanner,
  } = useContext(CarouselContext);

  useEffect(() => {
    fetchCarousels({ limit: 20 });
  }, []);

  return (
    <Wrapper>
      <div className="top-section">
        <AddCarousel />
      </div>
      <Row gutter={[24, 24]}>
        {data &&
          data.results.map((carousel) => {
            return (
              <Carousel key={carousel.id} {...carousel} refs={carousel.ref} />
            );
          })}
      </Row>
      <Drawer
        title="Edit Banner"
        placement="right"
        visible={editBanner}
        onClose={() => setEditBanner(null)}
      >
        <EditBanner />
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .top-section {
    margin-bottom: 16px;
    padding: 20px 0;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 0 16px;
    margin: 0 10px;
    .icon {
      line-height: 10px;
      margin-right: 8px;
    }

    &.add {
      color: #009688;
      &:hover,
      &.active {
        color: #fff;
        background: #009688e0;
        border-radius: 0;
        transition: 0.3s ease;
      }
      &.active:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      }
    }

    &.delete {
      color: #ff3054;
      &:hover {
        background: #ff6782;
        border-radius: 0;
        color: #fff;
      }
    }
  }

  .carousel {
    padding: 10px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;

      .name {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    @keyframes fadeIn {
      from {
        margin: -15px;
        opacity: 0;
      }
      to {
        margin: 0;
        opacity: 1;
      }
    }

    .add-banner {
      animation: fadeIn 0.4s ease-in;
      position: relative;

      .close {
        position: absolute;
        top: 16px;
        right: 16px;
        color: red;

        &:hover {
          border: 1px solid red;
        }
      }

      .banner {
        height: 170px;
      }
    }

    .banners {
      min-height: 500px;
      max-height: 500px;
      overflow-y: auto;
      padding: 10px;
    }

    .edit-box {
      padding: 10px;
      width: calc(100% - 120px - 20px - 40px);
    }

    .banner {
      background: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      margin-bottom: 10px;
      min-height: 100px;
      position: relative;
      animation: fadeIn 0.5s ease-in;

      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }

      .action {
        position: absolute;
        right: 16px;
        top: 10px;
      }

      &-image {
        padding: 10px;
        display: flex;
        align-items: center;
        width: 120px;
        height: 100px;
        position: relative;

        img {
          height: 75px;
          border-radius: 10px;
        }

        .progress {
          position: absolute;
          width: 32px;
          height: 32px;
          color: green;
          top: 16px;
          left: 16px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
          border-radius: 100%;
        }
      }

      .banner-content {
        padding: 10px;
        width: calc(100% - 120px - 20px - 40px);

        .title {
          font-size: 18px;
        }
      }
    }
  }
`;
