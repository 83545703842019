import { Card, message } from "antd";
import React from "react";
import { v3Axios } from "../../utils";
import DeliveryAreaRestaurant from "../Restaurant/DeliveryArea";
import Parse from "parse";
import moment from "moment";

const ZoneControl = () => {
  const [zones, setZones] = React.useState([]);
  const [areas, setAreas] = React.useState([]);

  React.useEffect(() => {
    const fetchLayers = async () => {
      try {
        const { data } = await v3Axios.get("/api/v1/areas");

        const zoneLists = {};

        for (const item of data) {
          if (!zoneLists[item?.zone._id]) {
            zoneLists[item?.zone._id] = {
              ...item.zone,
              areas: [],
            };
          }
          zoneLists[item?.zone._id].areas.push(item);
        }
        setAreas(
          data?.filter((item) => item.isOn === true)?.map((item) => item._id) ??
            []
        );
        setZones(Object.values(zoneLists));
      } catch (err) {
        console.log(err);
        message.error("Something went wrong");
      }
    };
    fetchLayers();
  }, []);

  const handleOnCheck = (unChecked = [], checked = []) => {
    const isOn = checked.length > 0;

    console.log({
      unChecked,
      checked,
      isOn,
      total: unChecked.concat(checked),
    });

    const currentUser = Parse.User.current();

    unChecked.concat(checked).forEach(async (item) => {
      try {
        await v3Axios.patch(`/api/v1/areas/${item}`, {
          isOn,
          updatedBy: `turned ${
            isOn ? "on" : "off"
          } by ${currentUser?.getUsername()} at ${moment().format(
            "hh:mm A DD/MM/YYYY"
          )}`,
        });
      } catch (err) {
        console.log(err);
        message.error("Something went wrong");
      }
    });
  };

  return (
    <Card title="Operation Zone Control">
      <DeliveryAreaRestaurant
        zones={zones}
        areas={areas}
        setAreas={setAreas}
        handleOnCheck={handleOnCheck}
      />
    </Card>
  );
};

export default ZoneControl;
