//charge, user, payments, hub,returninfo,items,ref, inventory , rider,

function flattenObject(obj, parentKey = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const prefixedKey = parentKey ? `${parentKey}_${key}` : key;

    if (Array.isArray(obj[key])) {
      // Handling arrays within the object
      if (
        obj[key].length > 0 &&
        typeof obj[key][0] === "object" &&
        !Array.isArray(obj[key][0])
      ) {
        // Handling array of objects
        obj[key].forEach((item, index) => {
          Object.assign(acc, flattenObject(item, `${prefixedKey}_${index}`));
        });
      } else {
        // Handling other types of arrays (e.g., array of strings or numbers)
        acc[prefixedKey] = obj[key].join(", "); // Convert array to string for display
      }
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], prefixedKey));
    } else {
      acc[prefixedKey] = obj[key];
    }

    return acc;
  }, {});
}

function extractFieldsFromFlattenedObject(obj, fieldsToExtract = []) {
  const extractedFields = {};

  fieldsToExtract.forEach((field) => {
    if (obj.hasOwnProperty(field)) {
      extractedFields[field] = obj[field];
    }
  });

  return extractedFields;
}

export default function formatData(data) {
  const flattenedArray = data.map((obj) => flattenObject(obj)); // Flatten objects

  //   const fieldsToExtract = []; // Specify the fields to extract

  //   const extractedFieldsArray = flattenedArray.map((obj) =>
  //     extractFieldsFromFlattenedObject(obj, fieldsToExtract)
  //   );

  return flattenedArray;
}
