import { Col, Row, message } from "antd";
import { useState } from "react";
import styled from "styled-components";
import Parse from "parse";
import HistoryFilter from "../../components/user/history/Filter";
import HistoryUser from "../../components/user/history/User";

export default function UserHistory() {
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [listeners, setListeners] = useState([]);

  const pusher = (data) => {
    listeners.forEach((fn) => fn(data));
  };

  const observer = (fn) => {
    if (typeof fn === "function") {
      setListeners((prev) => [...prev, fn]);
    }
  };

  const fetchUsers = async (values, done = () => {}) => {
    try {
      const data = await Parse.Cloud.run("userOrderHistory", values);
      if (!values.download) {
        setUsers(data);
        setSkip(values.skip || 0);
      }
      done(data);
    } catch (err) {
      message.error(err.message);
      done();
    }
  };

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col span={24} lg={4}>
          <HistoryFilter
            observer={observer}
            fetchData={fetchUsers}
            pusher={pusher}
          />
        </Col>
        <Col span={24} lg={20}>
          <div className="user-list customScroll">
            {users.map((user, i) => (
              <HistoryUser
                pusher={pusher}
                index={i + 1 + skip}
                {...user}
                key={user.id}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .filter {
    background: #fff;
    border-radius: 10px;
    padding: 8px;
    height: calc(100vh - 110px);
    position: relative;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);

    .ant-form-item {
      margin-bottom: 5px;
      input,
      .ant-input-number,
      .ant-select-selector {
        border-radius: 10px;
      }
    }

    &-footer {
      position: sticky;
      bottom: 0px;
      left: -8px;
      right: -8px;
      background: #e1e8eb;
      border-top: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .user-list {
    height: calc(100vh - 110px);
  }

  .user {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 5px;

    .name {
      font-size: 20px;
      text-transform: capitalize;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .index {
        background: #faad1491;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        margin-right: 10px;
      }
    }

    .info-item {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      color: #777;
      margin-bottom: 8px;

      .title {
        width: 40%;
        margin-right: 10px;
      }

      .value {
        width: 60%;
        text-align: right;

        .items {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;

          .item {
            margin-right: 8px;
            background: #e1e8eb;
            padding: 0px 3px;
            border-radius: 10px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .restaurants,
    .products {
      height: 300px;
      padding: 10px 16px;
      background: #e1e8eb;
      border-radius: 10px;
      padding-bottom: 5px;
    }

    .categories {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 16px;
      background: #e1e8eb;
      border-radius: 10px;
      justify-content: space-around;
      padding-bottom: 5px;
    }

    .restaurants,
    .products,
    .categories {
      .item {
        background: #fff;
        padding: 5px 10px;
        border-radius: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      }
    }
  }
`;
