import {
  Col,
  Popover,
  Row,
  Space,
  Switch,
  Tag,
  message,
  Button,
  InputNumber,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import Parse from "parse";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  SettingOutlined,
  UserOutlined,
  OrderedListOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { RidersContext } from "../../../pages/Rider/Riders";
import { Link } from "react-router-dom";
import { BsFillGeoAltFill } from "react-icons/bs";

const Item = ({ title, value = 0, tk, ...rest }) => {
  return (
    <Col span={12} {...rest}>
      <div className="item">
        <div className="title">{title}</div>
        <div className="value">
          <span>{value ?? 0} </span> {tk ? <span>tk</span> : ""}
        </div>
      </div>
    </Col>
  );
};

function CollectionUpdate({ riderId, amount }) {
  const { updateRiders } = useContext(RidersContext);
  const [open, setOpen] = useState(false);
  const [collection, setCollection] = useState(amount);
  const [loading, setLoading] = useState(false);

  const submitHandler = async (collection) => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("cashReceivedFromRider", {
        riderId,
        collection,
      });
      if (res) {
        updateRiders((riders) => {
          const rider = riders.find((r) => r.objectId === riderId);
          rider.collection = collection;
          return riders;
        });
        setOpen(false);
        message.success("Collection updated!");
        setLoading(false);
      }
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {!open ? (
        <>
          <span>{amount} tk</span>
          <Space size={10}>
            <Button
              shape="circle"
              size="small"
              style={{ border: "none" }}
              disabled={loading}
              icon={<EditOutlined onClick={() => setOpen(true)} />}
              className="collection-edt-btn"
            />
            <Button
              style={{ border: "none" }}
              loading={loading}
              size="small"
              shape="circle"
              icon={<CheckOutlined onClick={() => submitHandler(0)} />}
              className="collection-edt-btn success"
            />
          </Space>
        </>
      ) : (
        <>
          <InputNumber
            onChange={(value) => setCollection(value)}
            value={collection}
            placeholder="Collection"
            style={{ width: "120px", border: "0px" }}
          />
          <Space style={{ marginLeft: "10px" }}>
            <Button
              size="small"
              loading={loading}
              onClick={() => submitHandler(collection)}
              icon={<CheckOutlined />}
              shape="circle"
              style={{ cursor: "pointer" }}
              type="primary"
              className="collection-edt-btn success"
            ></Button>
            <Button
              size="small"
              onClick={(e) => setOpen(false)}
              icon={<CloseOutlined />}
              shape="circle"
              danger
            ></Button>
          </Space>
        </>
      )}
    </div>
  );
}

function ActiveHandler({ data, id }) {
  const { updateRiders } = useContext(RidersContext);
  const [active, setActive] = useState(data);
  const [loading, setLoading] = useState(false);

  const activationHandler = async (value) => {
    try {
      setLoading(true);
      setActive(value);
      await Parse.Cloud.run("riderActivationHandler", {
        id,
        active: value,
      });

      updateRiders((riders) => {
        const rider = riders.find((r) => r.objectId === id);
        rider.active = value;
        return riders;
      });

      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
      setActive(!value);
    }
  };

  return (
    <>
      <Switch loading={loading} checked={active} onClick={activationHandler} />
    </>
  );
}

function RiderCard({
  position,
  name,
  phone,
  assigned,
  delivered,
  rejected,
  attendance,
  late,
  collection,
  deliveryCharge,
  active,
  rider_availability,
  username,
  codCollection,
  objectId,
}) {
  const { setRiderId, deleteRider } = useContext(RidersContext);
  const [showAttendance, setShowAttendance] = useState(false);

  const spans = useCallback(() => {
    return showAttendance
      ? { span: 24, lg: 12, xl: 12 }
      : { span: 24, sm: 12, lg: 8, xl: 6 };
  }, [showAttendance]);

  return (
    <Col {...spans()}>
      <div className={`rider-card ${collection <= 0 ? "zero-collection" : ""}`}>
        <div className="content">
          <div className="header">
            <div className="left">
              <Space>
                <h2 className="name">
                  {" "}
                  <span className="position">{position}</span> {name}
                </h2>
              </Space>
              <p className="username">@{username}</p>
              <p className="phone">{phone || "N/A"}</p>
            </div>
            <div>
              <Space direction="vertical" style={{ textAlign: "right" }}>
                <Space>
                  <Popover
                    content={`${
                      !showAttendance ? "Show" : "Close"
                    } Attendance List`}
                  >
                    <div className="att-icon">
                      <img
                        onClick={() => setShowAttendance(!showAttendance)}
                        src="/icons/calender.png"
                        alt=""
                      />
                    </div>
                  </Popover>
                  <Dropdown
                    overlay={
                      <Menu style={{ minWidth: "120px" }}>
                        <Menu.Item>
                          <Link to={`/rider/update/${objectId}`}>
                            <EditOutlined /> Update
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            target="_blank"
                            to={`/rider/profile/${objectId}`}
                          >
                            <UserOutlined /> Profile
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            target="_blank"
                            to={`/rider/list/${objectId}/attendance`}
                          >
                            <OrderedListOutlined /> Orders
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            target="_blank"
                            to={`/rider/tracking/${objectId}`}
                          >
                            <BsFillGeoAltFill /> Tracking
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Popconfirm
                            title="Move to trash?"
                            onConfirm={() => deleteRider({ id: objectId })}
                          >
                            <div>
                              <DeleteOutlined /> Archive
                            </div>
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <Button
                      style={{ border: "none" }}
                      icon={<SettingOutlined />}
                      shape="circle"
                    ></Button>
                  </Dropdown>
                </Space>
                <ActiveHandler id={objectId} data={active} />
                <Tag color={rider_availability ? "green" : "red"}>
                  {rider_availability ? "Available" : "Busy"}
                </Tag>
              </Space>
            </div>
          </div>
          <Row className="infos" gutter={[16, 16]}>
            <Item title="Assigned" value={assigned} />
            <Item title="Delivered" value={delivered} />
            <Item title="Rejected" value={rejected} />
            <Item title="COD Collection" value={codCollection} tk={true} />
            <Item title="Delivery Charge" value={deliveryCharge} tk={true} />
            <Item title="Late" value={late} />
            <Item
              title="Collection Due"
              className="collection"
              value={
                <CollectionUpdate riderId={objectId} amount={collection} />
              }
              span={16}
            />
            <Item
              title="Orders"
              value=""
              span={8}
              onClick={() => setRiderId(objectId)}
              style={{ cursor: "pointer" }}
            />
          </Row>
        </div>

        {showAttendance && (
          <div className="attendance-list customScroll">
            {attendance?.map((item, i) => (
              <div key={i} className={`a-item ${item.isLate ? "late" : ""}`}>
                <Space>
                  <div>{item.date}</div>
                  <div>{item.time}</div>
                </Space>
                <Tag>{item.order}</Tag>
              </div>
            ))}
          </div>
        )}
      </div>
    </Col>
  );
}

export default function GridView() {
  const {
    riders: { loading, results },
    setRiderId,
  } = useContext(RidersContext);

  return (
    <Wrapper>
      {loading ? <div>Loading...</div> : ""}
      <Row gutter={[16, 24]}>
        {results.map((rider, i) => {
          return (
            <RiderCard
              key={rider.id}
              {...rider}
              position={i + 1}
              setRiderId={setRiderId}
            />
          );
        })}
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .rider-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    height: 480px;

    &.zero-collection {
    }

    .content {
      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          .name {
            margin-bottom: 0;
            background: #b87d095c;
            border-radius: 30px;
            padding: 2px 10px 2px 4px;
            letter-spacing: 0.5px;
            font-size: 14px;
            color: brown;
            min-width: 160px;
            display: flex;
            align-items: center;

            .position {
              width: 24px;
              height: 24px;
              display: inline-block;
              text-align: center;
              background: #4f4f4f;
              border-radius: 50%;
              color: #fff;
              margin-right: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              letter-spacing: 0px;
            }
          }
          .username {
            font-size: 14px;
            color: #8c8c8c;
            margin-bottom: 0;
          }
          .phone {
            font-size: 14px;
            margin-bottom: 0;
            color: #8c8c8c;
          }
        }

        .att-icon {
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: end;

          img {
            cursor: pointer;
            width: 30px;
            height: 30px;
          }
        }
      }

      .infos {
        .item {
          padding: 10px;
          background-color: #fafafa;
          border-radius: 10px;
          border: 1px solid #e8e8e8;
          height: 70px;

          .title {
            font-weight: 500;
            font-size: 12px;
            margin-bottom: 5px;
          }
          .value {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
          }
        }

        .collection {
          .item {
            background: #faad144d;
            color: brown;
            border-color: #faad14;
          }
        }

        .collection-edt-btn {
          background: #faad14;
          color: #fff;
          border: none;

          &.success {
            background: #52c41a;
          }
        }
      }
    }
  }

  .attendance-list {
    width: 270px;
    border-left: 1px solid #e8e8e8;
    margin-left: 10px;
    max-height: 440px;

    .a-item {
      font-size: 12px;
      text-align: center;
      padding: 3px 10px;
      display: flex;
      justify-content: space-between;

      &:nth-child(even) {
        background-color: #e9e9e9;
      }
      &.late {
        background-color: #ff92ce;
        font-weight: 500;
      }
    }
  }
`;
