import { message } from "antd";
import Parse from "parse";
import { useEffect, useState, createContext } from "react";
import InvoiceDetails from "../../components/payments/Invoice";
import PaymentsInfo from "../../components/payments/PaymentsInfo";
import { useHistory, useParams } from "react-router-dom";
import { downloadCsvFromText } from "../../utils";

export const InvoiceContext = createContext();

export const downloadDetails = async (params) => {
  try {
    const res = await Parse.Cloud.run("invoiceDownloadDetails", params);
    const { text, name } = res || {};
    if (!text || !name) {
      message.error("No data found");
      return;
    }
    downloadCsvFromText(text, name);
  } catch (err) {
    message.error(err.message);
  }
};

export default function Invoice() {
  const searchParams = new URLSearchParams(window.location.search);
  const { invoiceId } = useParams();
  const ids = searchParams.get("ids")?.split(",");
  const restaurantId = searchParams.get("restaurant");
  const [details, setDetails] = useState(null);
  const [reqPayloads, setReqPayloads] = useState(null);
  const [visible, setVisible] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const history = useHistory();
  const [params, setParams] = useState();

  const fetchInvoiceDetails = async ({
    paymentIds,
    note,
    vat_included,
    restaurant,
  }) => {
    message.loading("Loading...", 0);
    try {
      const res = await Parse.Cloud.run("invoiceDetails", {
        paymentIds,
        restaurant,
        note,
        vat_included,
      });

      setDetails(res);
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  const paymentHandler = async (values) => {
    try {
      setPaymentLoading(true);
      const payment = await Parse.Cloud.run("makePayment", {
        paymentIds: ids,
        restaurant: restaurantId,
        ...values,
      });
      if (payment) {
        setDetails(payment);
        setVisible(false);
        message.success("Payment Successful");
        history.push(`/invoice/${payment.objectId}`);
      }
      setPaymentLoading(false);
    } catch (err) {
      message.error(err.message);
      setPaymentLoading(false);
    }
  };

  const fetchInvoice = async (id) => {
    message.loading("Loading...", 0);
    try {
      const invoice = await new Parse.Query("invoice")
        .equalTo("objectId", id)
        .includeAll()
        .first();
      if (invoice) {
        setDetails(invoice.toJSON());
      } else {
        message.error("Invoice not found");
      }
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  useEffect(() => {
    if (invoiceId === "new") {
      if (ids && restaurantId) {
        const params = {
          paymentIds: ids,
          restaurant: restaurantId,
          note: "",
          vat_included: false,
        };
        fetchInvoiceDetails(params);
        setParams(params);
      } else {
        message.error("Invalid Invoice");
      }
    } else {
      fetchInvoice(invoiceId);
    }
  }, []);

  return (
    <InvoiceContext.Provider
      value={{
        details,
        paymentHandler,
        reqPayloads,
        setReqPayloads,
        ids,
        restaurantId,
        visible,
        setVisible,
        paymentLoading,
        setPaymentLoading,
        invoiceId,
        params,
        downloadDetails: () => {
          if (invoiceId === "new") {
            downloadDetails(params);
          } else if (invoiceId) {
            downloadDetails({ invoiceId });
          }
        },
      }}
    >
      {details && <InvoiceDetails />}
      {details && <PaymentsInfo />}
    </InvoiceContext.Provider>
  );
}
