import { useState, useContext, useEffect } from "react";
import { Drawer, Input, Button, Form, Row, Col, InputNumber } from "antd";
import { CategoriesContext } from "../../pages/Categories/CategoriesContext";
import UploadMedia from "../media/UploadMedia";

export default function EditCategory({ editOnClose, editDrawer }) {
  const { updateCategoryHandler } = useContext(CategoriesContext);
  const { id, name, image, prep_time, visible } = editDrawer;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState({
    visible: false,
    images: [],
    loading: false,
    fileList: [],
    multiple: false,
  });

  const onFinish = async ({ name, prep_time }) => {
    setLoading(true);
    updateCategoryHandler(
      { id, name, prep_time, image: media.images[0] },
      () => {
        setLoading(false);
        editOnClose();
      }
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      name,
      prep_time,
    });

    if (image) {
      media.images = [image];
      setMedia({ ...media });
    }

    return () => {
      form.resetFields();
    };
  }, [name, image, prep_time]);

  return (
    <Drawer
      zIndex={1012}
      width="50%"
      title="Edit category"
      placement="right"
      onClose={editOnClose}
      visible={visible}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        style={{ visibility: visible ? "visible" : "hidden" }}
      >
        <Form.Item>
          <UploadMedia state={media} setState={setMedia} />
          <Button
            style={{ width: "100%" }}
            onClick={(e) => setMedia({ ...media, visible: true })}
            type="dashed"
          >
            Upload image
          </Button>
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Prepare Time"
              name="prep_time"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "160px" }} placeholder="Prep time" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            style={{ width: "100%" }}
            loading={loading}
            disabled={loading}
            type="primary"
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
