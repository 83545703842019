import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, message, Row, Table } from "antd";
import styled from "styled-components";
import { downloadCsv } from "../../utils";
import Boxer, { Box } from "../../components/Common/Boxer";
import { useEffect, useState } from "react";
import Parse from "parse";
import { RiderJobType } from "../../constants/rider";

function Item({ label, value }) {
  return (
    <Col span={24} lg={12}>
      <div className="item">
        <div className="label">{label}</div>
        <div className="value">{value ?? "-"}</div>
      </div>
    </Col>
  );
}

function Rider({
  name,
  employeeType,
  jobType,
  delivered,
  assigned,
  salary,
  totalLate: late,
  joiningTime,
  days,
  totalDuty,
  totalLeave,
  totalCommission,
  balance,
}) {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      key: "assigned",
      width: 80,
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      width: 80,
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      key: "inTime",
      width: 100,
    },
    {
      title: "Penalty",
      dataIndex: "penalty",
      key: "penalty",
      width: 80,
    },
    {
      title: "Late",
      dataIndex: "late",
      key: "late",
      width: 80,
      render: (late) => (late ? "Yes" : "No"),
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      width: 80,
      render: (commission) => commission ?? "-",
    },
    {
      title: "rate",
      dataIndex: "rate",
      key: "rate",
      width: 80,
      render: (rate) => rate ?? "-",
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      width: 80,
      render: (attendance) => attendance ?? "-",
    }
  ];

  const onDownload = () => {
    downloadCsv(
      [
        {
          header: columns,
          data: days,
        },
      ],
      name
    );
  };

  return (
    <Box
      title={name}
      rightMenu={
        <Button
          icon={<DownloadOutlined />}
          type="primary"
          onClick={onDownload}
          shape="circle"
          size="small"
        ></Button>
      }
    >
      <Table
        title={() => (
          <div className="header">
            <Row gutter={[5, 5]} className="items">
              <Item label={"Employee Type"} value={employeeType} />
              <Item label={"Job Type"} value={jobType} />
              <Item label={"Joining Time"} value={joiningTime} />
              <Item label={"Salary"} value={salary} />
              <Item label={"Assigned"} value={assigned} />
              <Item label={"Delivered"} value={delivered} />
              <Item label={"Present"} value={totalDuty} />
              <Item label={"Late"} value={late} />
              <Item label={"Leave"} value={totalLeave} />
              {jobType === RiderJobType.NewCommission && (
                <>
                  <Item label={"Total Commission"} value={totalCommission} />
                  <Item label={"Balance"} value={balance} />
                </>
              )}
            </Row>
          </div>
        )}
        rowClassName={(record, i) => record.late && "late"}
        columns={columns}
        dataSource={days}
        pagination={false}
      />
    </Box>
  );
}

export default function SalarySheet() {
  const [date, setDate] = useState([]);
  const [data, setData] = useState([]);

  const fetchData = async (date) => {
    try {
      message.loading("Loading...", 0);
      const res = await Parse.Cloud.run("riderSalarySheet", { date });
      setData(res);
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchData(date);
  }, [date]);

  return (
    <Wrapper>
      <div style={{ marginBottom: "20px" }}>
        <DatePicker.RangePicker onChange={(d, str) => setDate(str)} />
      </div>
      <Boxer open={false}>
        {data.map((rider) => (
          <Rider key={rider.objectId} {...rider} />
        ))}
      </Boxer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .header {
    .items {
      .item {
        display: flex;
        justify-content: space-between;
        background: #e0e0e0;
        padding: 5px 20px;
        border: 1px solid #b3b3b3;
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }

  .ant-table-content {
    border-top: 2px solid #b3b3b3;
  }

  .ant-table-cell {
    border: 1px solid #b3b3b3;
    border-left: none;
    border-top: none;

    &:last-child {
      border-right: none;
    }
  }

  .late {
    background: #ff7875;
  }
`;
