import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  message,
  Tag,
  Space,
  Switch,
} from "antd";
import styled from "styled-components";
import GoBack from "../../components/Common/GoBack";
import useUser from "../../hooks/useUser";
import { parser } from "../../utils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {fetchUserDetails} from "../userDashboard/page";
const { Item } = Form;

export default function AddUser() {
  const [form] = Form.useForm();
  const { createUser, getById, updateUser, fetchRoles } = useUser();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    loading: false,
    data: null,
  });
  const [roles, setRoles] = useState([]);
  const [passUpdate, setPassUpdate] = useState(false);
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");

  const onFinish = (values) => {
    setLoading(true);

    if (user.data) {
      if (values.locations) {
        values.locations = values.locations.map((item) =>
          JSON.parse(item, null, 4)
        );
      }
      updateUser({ id, ...values }, (err, data) => {
        if (err) {
          message.error(err);
        } else if (data) {
          message.success("User updated successfully!");
          form.setFieldsValue({
            password: "",
          });
          setPassUpdate(false);
        }
        setLoading(false);
      });
    } else {
      createUser(values, (err, data) => {
        if (err) {
          message.error(err);
        }

        if (data) {
          message.success("User created successfully");
          form.resetFields();
        }

        setLoading(false);
      });
    }
  };

  useEffect(()=>{
    fetchUserDetails(id);
  })

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setUser({ ...user, loading: true });

        getById({ id }, (err, data) => {
          if (err) {
            setUser({ ...user, loading: false });
            message.error(err);
          }

          if (data) {
            const user = parser(data);
            setUser({
              loading: false,
              data: user,
            });

            form.setFieldsValue({
              name: user.name,
              type: user.type,
              username: user.username,
              email: user.email,
              phone: user.phone,
              locations:
                user.locations?.map((i) => JSON.stringify(i, undefined, 4)) ??
                [],
            });
          }
        });
      }

      if (!id && user.data) {
        form.resetFields();
        setUser({ ...user, data: null });
      }
    };

    fetchData(); // Call the function
  }, []); // Added form, getById, and user to the dependency array

  useEffect(() => {
    fetchRoles({}, (err, data) => {
      if (err) message.error(err);
      else {
        setRoles(parser(data));
      }
    });
  }, []); // Added fetchRoles to the dependency array

  const PasswordLabel = () => (
    <span>
      <span>Password</span>
      {user.data && (
        <Tag
          style={{ marginLeft: "10px" }}
          onClick={() => setPassUpdate(!passUpdate)}
          color={passUpdate ? "magenta" : "lightgrey"}
        >
          {" "}
          update
        </Tag>
      )}
    </span>
  );

  return (
    <div>
      <Row justify="center">
        {!(id && !user.data) && (
          <FormWrapper md={18} lg={16} xl={12}>
            <div className="header">
              <div className="left-section">
                <GoBack />
                <h3 className="title">
                  {user.data ? "Update User Info" : "Create New User"}
                </h3>
              </div>
            </div>
            <Form
              form={form}
              layout="vertical"
              name="addUser"
              onFinish={onFinish}
            >
              <Row gutter={16}>
                {user.data && user.data.type === "customer" && (
                  <Col span={24}>
                    <Item name="verified" label="Verified">
                      <Switch defaultChecked={user.data?.verified ?? false} />
                    </Item>
                  </Col>
                )}
                <Col span={12}>
                  <Item
                    name="name"
                    label="Name"
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <Input size="large" placeholder="Full name" />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    name="username"
                    label="Username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Username" />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    name="email"
                    label="Email"
                    rules={[{ type: "email", message: "Invalid email!" }]}
                  >
                    <Input size="large" placeholder="E-mail" />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item name="phone" label="Phone">
                    <Input size="large" placeholder="Phone number" />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    name="password"
                    label={<PasswordLabel />}
                    rules={[
                      {
                        required: !user.data || passUpdate ? true : false,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      disabled={id && user.data && !passUpdate}
                      size="large"
                      placeholder="Password"
                    />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item
                    name="type"
                    label="User Type"
                    rules={[{ required: true, message: "Please user type!" }]}
                  >
                    <Select size="large" placeholder="Select user type">
                      {roles.map((role, i) => (
                        <Select.Option
                          key={i}
                          style={{ textTransform: "capitalize" }}
                          value={role.name}
                        >
                          {role.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </Col>
                {user.data && (
                  <Col span={24}>
                    <Form.List name="locations">
                      {(fields, { add, remove }) => {
                        return (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...rest }, index) => (
                                <Item
                                  key={key}
                                  name={[name]}
                                  {...rest}
                                  label={
                                    <Space align="start">
                                      <span>Location {index + 1}</span>
                                      <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => {
                                          remove(name);
                                        }}
                                      />
                                    </Space>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input location!",
                                      validator: (rule, value) => {
                                        try {
                                          const v = JSON.parse(value, null, 4);
                                          return Promise.resolve(true);
                                        } catch (err) {
                                          return Promise.reject(
                                            "Invalid location!"
                                          );
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Input.TextArea rows={5} />
                                </Item>
                              )
                            )}

                            <Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "green",
                                }}
                              >
                                <PlusOutlined /> Add Location
                              </Button>
                            </Item>
                          </>
                        );
                      }}
                    </Form.List>
                  </Col>
                )}
                <Col span={24}>
                  <Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      {user.data ? "Update" : "Submit"}
                    </Button>
                  </Item>
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        )}
      </Row>
    </div>
  );
}

// export default function AddUser() {
//   // const [form] = Form.useForm();
//   const [user, setUser] = useState();

//   const { createUser, getById, updateUser, getUserById
//   } = useUser();

//   useEffect(() => {
//     const fetchData = async () => {
//       const url = new URL(window.location.href);
//       const id = url.searchParams.get("id");
//       if (id) {
//         try {
//           const user = await getUserById(id)
//           setUser(user);
//           console.log(user);
//           // form.setFieldsValue(user);
//         } catch (error) {
//           console.error(error);
//           message.error(error);
//         }
//       }
//     };

//     fetchData();
//   }, []);

//   return <div>{
//     JSON.stringify(user, null, 2)
//   }</div>;
// }

const FormWrapper = styled(Col)`
  padding: 20px;
  background-color: #fff;
  margin-top: 30px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

  .header {
    padding: 20px 0;

    .left-section {
      display: flex;
      align-items: center;

      .title {
        font-size: 22px;
      }
    }
  }
`;
