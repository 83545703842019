import Parse from "parse";

const repoName = "restaurant";

export const getRestaurants = ({
  limit = 100,
  skip = 0,
  managedBy,
  search,
  availability,
  sortBy,
  name,
  phone,
  isAffiliated,
  is_hub,
  cuisines,
  select,
  hub,
  type,
  ids,
  mou,
  mou_expired_date,
} = {}) => {
  const query = new Parse.Query(repoName);
  query.notEqualTo("delete", true);
  if (select && Array.isArray(select)) {
    query.select(...select);
  }
  if (managedBy) {
    query.equalTo("managedBy", {
      __type: "Pointer",
      className: "_User",
      objectId: managedBy,
    });
  }

  if (Array.isArray(ids)) {
    query.containedIn("objectId", ids);
  }

  if (Array.isArray(type)) {
    query.containedIn("type", type);
  } else if (type) {
    query.equalTo("type", type);
  }

  if (Number.isInteger(limit)) query.limit(limit);
  if (Number.isInteger(skip)) query.skip(skip);
  query.withCount();
  if (sortBy) query.ascending(sortBy);
  if (Array.isArray(availability) && availability.length > 0)
    query.containedIn("availability", availability);
  else if (availability && typeof availability === "string")
    query.equalTo("availability", availability);
  if (search) query.matches("name", search, "i");
  if (name) query.matches("name", name, "i");
  if (phone) query.matches("phone", phone, "i");
  if (is_hub !== undefined && is_hub !== null) query.equalTo("is_hub", is_hub);
  if (cuisines) query.matches("cuisines", cuisines, "i");
  if (isAffiliated !== undefined && isAffiliated !== null)
    query.equalTo("isAffiliated", isAffiliated);
  if (typeof hub === "string") {
    query.equalTo("hub", {
      __type: "Pointer",
      className: "hub",
      objectId: hub,
    });
  }
  if (mou) query.matches("mou", mou, "i");
  if (mou_expired_date instanceof Date)
    query.matches("mou_expired_date", mou_expired_date, "i");
  return query.find();
};

export const getById = ({ id, select, exclude } = {}) => {
  const query = new Parse.Query(repoName);
  query.includeAll();
  query.equalTo("objectId", id);
  if (Array.isArray(exclude)) query.exclude(...exclude);
  if (Array.isArray(select)) query.select(...select);
  return query.first();
};

export const getByName = (name) => {
  return new Parse.Query(repoName).equalTo("name", name).first();
};

export const getBySlug = (slug) => {
  return new Parse.Query(repoName).equalTo("slug", slug).first();
};

export const addNew = (params) => {
  return Parse.Cloud.run("addRestaurant", params);
};

export const updateItem = (params) => {
  return Parse.Cloud.run("editRestaurant", params);
};
