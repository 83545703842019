import { useContext, useEffect, useState } from "react";
import { ProductListContext } from "../../../pages/Product/ProductList";
import {
  Table,
  Space,
  Affix,
  Tag,
  Button,
  Select,
  Row,
  Col,
  message,
} from "antd";
import getColumns from "./columns";
import useSearch from "../../inventory/utils/useSearch";
import AddDiscount from "./AddDiscount";
import { mapProducts } from "./helpers";
import DeleteProducts from "./DeleteProducts";
import AvailabilityHandler from "./AvailabilityHandler";
// import ProductsGridView from "../Grid";
import Modal from "antd/lib/modal/Modal";
import useRestaurants from "../../../hooks/useRestaurants";
import Parse from "parse";

function DuplicateProducts({
  selectedProducts,
  setSelectedProducts,
  clearSelectedRows,
}) {
  const [visible, setVisible] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [to, setTo] = useState(null);
  const { getRestaurants } = useRestaurants();
  const [loading, setLoading] = useState(false);

  const onSearch = (value) => {
    getRestaurants(
      { search: value, limit: 10, select: ["name", "hub.name"] },
      (err, res) => {
        if (res) {
          setRestaurants(res.results.map((i) => i.toJSON()));
        }
      }
    );
  };

  const submitHandler = async (to) => {
    try {
      setLoading(true);
      const payload = {
        to,
        ids: selectedProducts.map((i) => i.id),
      };
      await Parse.Cloud.run("menuDuplication", payload);
      setLoading(false);
      setVisible(false);
      clearSelectedRows([]);
      setSelectedProducts([]);
      setTo(null);
      message.success("Products duplicated successfully");
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <div>
      <Button onClick={() => setVisible(true)} type="primary">
        Duplicate Products
      </Button>
      <Modal
        title="Duplicate Products"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Row gutter={[16]}>
          <Col span={20}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a restaurant"
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={true}
              onChange={(e) => {
                setTo(e);
              }}
            >
              {restaurants.map((res, i) => {
                return (
                  <Select.Option key={i} value={res.objectId}>
                    {`${res.name} - ${res.hub?.name}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col span={4}>
            <Button
              loading={loading}
              onClick={() => submitHandler(to)}
              type="primary"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default function ProductList() {
  const {
    products,
    restaurants,
    restaurant,
    restaurantId,
    categories,
    getProducts,
    getCategories,
    setProductId,
    openInventory,
    openSales,
    properties,
  } = useContext(ProductListContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [, getColumnSearchProps] = useSearch();
  const reqPayloads = {
    limit: 100,
    skip: 0,
    approval_status: "approved",
  };

  const columns = getColumns({
    products,
    reqPayloads,
    openInventory,
    openSales,
    getColumnSearchProps,
    restaurants,
    restaurant: restaurant.data,
    categories,
    setProductId,
  });

  const onSelectChange = (keys, record) => {
    setSelectedProducts(record);
    setSelectedRowKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  useEffect(() => {
    getCategories({ limit: 1000, select: ["name"] });
  }, [restaurantId]);

  useEffect(() => {
    if (restaurantId) {
      reqPayloads["restaurant"] = restaurantId;
    }

    getProducts({
      ...reqPayloads,
      select: properties,
      exclude: ["timeline"],
      orderBy: "sold",
      order: "desc",
    });
  }, [restaurantId]);

  const tableOnChange = (pagination, filters, orderBy) => {
    const params = {
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      restaurants: restaurant.data ? [restaurant.data.id] : filters.restaurant,
      name: filters.name ? filters.name[0] : null,
      category: filters.category ? filters.category : null,
      price: filters.price ? filters.price[0] : null,
      availability: filters.availability ? filters.availability : null,
      vat: filters.vat ? parseInt(filters.vat[0]) : null,
      discount: filters.discount ? parseInt(filters.discount[0]) : null,
      is_restricted: filters.is_restricted ? filters.is_restricted : null,
    };

    if (orderBy.column && ["name", "sold"].includes(orderBy.column.dataIndex)) {
      params.orderBy = orderBy.column.dataIndex;
      if (orderBy.order === "descend") {
        params.order = "desc";
      } else {
        params.order = "asc";
      }
    }

    getProducts(params);
  };

  return (
    <div>
      {selectedProducts?.length > 0 && (
        <Affix offsetTop={64}>
          <div
            style={{
              background: "#fff",
              padding: "5px 20px",
              boxShadow: "0px 1px 10px rgba(0,0,0,.3)",
            }}
          >
            <Space style={{ margin: "20px 0" }}>
              <strong
                style={{ borderRight: "1px solid gray", paddingRight: "10px" }}
              >
                Selected: <Tag>{selectedProducts.length}</Tag>
              </strong>
              <AvailabilityHandler
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                clearSelectedRows={setSelectedRowKeys}
              />
              <AddDiscount
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                clearSelectedRows={setSelectedRowKeys}
                reqPayloads={reqPayloads}
              />
              <DeleteProducts
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                clearSelectedRows={setSelectedRowKeys}
              />
              <DuplicateProducts
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                clearSelectedRows={setSelectedRowKeys}
              />
            </Space>
          </div>
        </Affix>
      )}
      {/* <ProductsGridView /> */}
      <Table
        loading={products.loading}
        columns={columns}
        rowSelection={rowSelection}
        dataSource={mapProducts(products.data.results)}
        pagination={{
          defaultPageSize: reqPayloads.limit,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: products.data.count,
          position: ["topLeft", "topRight"],
          pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
        }}
        scroll={{ x: 1000, y: 1000 }}
        style={{ minHeight: "calc(100vh - 100px)" }}
        onChange={tableOnChange}
      />
    </div>
  );
}
