import {
  Button,
  Modal,
  Divider,
  Form,
  InputNumber,
  Select,
  DatePicker,
  message,
} from "antd";
import { useState } from "react";
import useProduct from "../../../hooks/useProduct";

export default function AddDiscount({
  selectedProducts,
  setSelectedProducts,
  clearSelectedRows,
}) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { updateProductsDiscount } = useProduct();

  const onFinish = (values) => {
    if (values.type === "percent" && values.amount > 100) {
      message.error("Percent discount cannot be more than 100");
      setLoading(false);
      return;
    }

    setLoading(true);
    updateProductsDiscount(
      {
        products: selectedProducts.map((i) => i.id),
        ...values,
        validity: values.validity
          ? new Date(values.validity).toISOString()
          : null,
      },
      (err, res) => {
        setLoading(false);
        if (res) {
          clearSelectedRows([]);
          setSelectedProducts([]);
          setVisible(false);
          message.success("Discount updated successfully");
          form.resetFields();
        }
        if (err) {
          message.error(err);
        }
      }
    );
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    selectedProducts &&
    selectedProducts.length > 0 && (
      <div>
        <Button onClick={() => setVisible(true)} type="primary">
          Add Discount
        </Button>
        <Modal
          title="Bulk Add Product Discount"
          visible={visible}
          onCancel={handleCancel}
          confirmLoading={loading}
          footer={null}
        >
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              style={{ marginBottom: "8px" }}
              label="Discount Type"
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select size="large" style={{ width: "200px" }}>
                <Select.Option value="fixed">Fixed</Select.Option>
                <Select.Option value="percent">Percent</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "8px" }}
              label="Discount"
              name="amount"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber size="large" style={{ width: "200px" }} min={0} />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "8px" }}
              label="Discount Validity"
              name="validity"
            >
              <DatePicker
                style={{ width: "200px" }}
                format="YYYY-MM-DD HH:mm"
                showTime
                size="large"
              />
            </Form.Item>
            <Divider />
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                onClick={() => setVisible(false)}
                style={{ margin: "0 10px" }}
                danger
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                style={{ margin: "0 10px" }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  );
}
