import { Button, DatePicker, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { downloadCsv } from "../../utils";
import { DownloadOutlined } from "@ant-design/icons";
import useSearch from "../../components/inventory/utils/useSearch";
import { Link } from "react-router-dom";

export default function RestaurantPrepTimes() {
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, searchColumnsParams] = useSearch();

  const fetchData = async (params) => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("prepTimes", params);
      setData(
        res.map((i) => ({
          ...i,
          key: i.objectId,
          confirmationTime: i.confirmationTime.toFixed(2),
          preparationTime: i.preparationTime.toFixed(2),
        }))
      );
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({ date });
  }, [date]);

  const columns = [
    {
      title: "Restaurant",
      dataIndex: "name",
      key: "name",
      width: 250,
      ...searchColumnsParams("name"),
      render: (name, { objectId }) => (
        <Link target="_blank" to={`/restaurant/add?id=${objectId}`}>
          {name}
        </Link>
      ),
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
      width: 200,
      ...searchColumnsParams("hub"),
    },
    {
      title: "Connected Hubs",
      dataIndex: "hubs",
      key: "hubs",
      width: 200,
      ...searchColumnsParams("hubs"),
    },
    {
      title: "Operating Hours",
      dataIndex: "operating_hours",
      key: "operating_hours",
      width: 200,
    },
    {
      title: "Order Count",
      dataIndex: "count",
      key: "count",
      width: 160,
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Confirmation Time",
      dataIndex: "confirmationTime",
      key: "confirmationTime",
      sorter: (a, b) => a.confirmationTime - b.confirmationTime,
    },
    {
      title: "Preparation Time",
      dataIndex: "preparationTime",
      key: "preparationTime",
      sorter: (a, b) => a.preparationTime - b.preparationTime,
    },
  ];

  const downloadHandler = () => {
    downloadCsv(
      [
        {
          header: columns,
          data,
        },
      ],
      "restaurant-prep-time"
    );
  };

  return (
    <div>
      <Table
        loading={loading}
        title={() => (
          <div className="flex-between">
            <h3>Average Preparation and Confirmation time</h3>
            <Space>
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                onClick={downloadHandler}
              >
                Download
              </Button>
              <DatePicker.RangePicker
                onChange={(d, str) => {
                  setDate(str);
                }}
              />
            </Space>
          </div>
        )}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
}
