import { Form, Input, Select } from "antd";

const { Item } = Form;

export function MetaTitle() {
  return (
    <Item
      className="section"
      label="Meta Title"
      name="meta_title"
      style={{ marginBottom: "0" }}
    >
      <Input size="large" type="text" placeholder="Meta title" />
    </Item>
  );
}

export function MetaTags() {
  return (
    <Item
      className="section"
      label="Meta Tags"
      name="meta_tags"
      style={{ marginBottom: "0" }}
    >
      <Select
        size="large"
        placeholder="tag one, tag two..."
        mode="tags"
        style={{ width: "100%" }}
        tokenSeparators={[","]}
      ></Select>
    </Item>
  );
}

export function MetaDescription() {
  return (
    <Item className="section" label="Meta Description" name="meta_description">
      <Input.TextArea size="large" placeholder="Description" />
    </Item>
  );
}
