import { useEffect, useState, useContext, useCallback } from "react";
import {
  Row,
  Col,
  Space,
  Tag,
  Tooltip,
  Switch,
  Card,
  Badge,
  Typography,
} from "antd";
import { OrdersContext } from "../../../pages/Order/OrderProcessor";
import { intervalToDuration } from "date-fns";
// import AutoAssign from "./AutoAssign";
import { useHistory } from "react-router-dom";
import { FaLocationArrow } from "react-icons/fa";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import { Popup } from "react-leaflet";

const getColorByStatus = (status) => {
  switch (status) {
    case "pending":
      return "cyan";
    case "confirmed":
      return "blue";
    case "preparing":
      return "geekblue";
    case "ready":
      return "purple";
    case "picked":
      return "lime";

    case "delivered":
      return "green";
    case "cancelled":
      return "red";
    case "rejected":
      return "red";
    default:
      return "cyan";
  }
};
const getIconByStatus = (status) => {
  switch (status) {
    case "pending":
      return "🕒";
    case "confirmed":
      return "👍";
    case "preparing":
      return "🍳";
    case "ready":
      return "📦";
    case "picked":
      return "🛵";

    case "delivered":
      return "🚀";
    case "cancelled":
      return "❌";
    default:
      return "🕒";
  }
};

function Returning({ completedAt, lastArea }) {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const { hours, minutes, seconds } = time;

  const setTimer = useCallback(() => {
    return setInterval(() => {
      const { hours, minutes, seconds } = intervalToDuration({
        start: new Date(completedAt),
        end: new Date(),
      });

      setTime({ hours, minutes, seconds });
    }, 1000);
  }, [completedAt]);

  useEffect(() => {
    const interval = setTimer();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="returning">
      <div>
        Last Delivered: <strong>{lastArea || ""} </strong>{" "}
      </div>

      <Tag color={hours * 60 + minutes > 30 ? "red" : "blue"}>
        <GiAnticlockwiseRotation />
        {hours ? hours + "h :" : ""} {minutes || "0"}m : {seconds || "0"}s ago
      </Tag>
    </div>
  );
}

function Rider({ data }) {
  const {
    name,
    phone,
    rider_availability,
    isRequested,
    orders = [],
    completedAt,
    lastArea,
    delivered,
    objectId,
  } = data;
  const history = useHistory();
  return (
    <Badge.Ribbon color="cyan" text={`Delivered: ${delivered}`} showZero>
      <Card
        style={{
          borderRadius: "10px",
          display: "flex",
          // overflow in case of long name
          overflow: "hidden",
          overflowX: "auto",
          // change the scroll bar color
        }}
        className="rider"
      >
        <Space>
          <div
            className={`avail ${rider_availability ? "active" : "deActive"} ${
              isRequested ? "requested" : ""
            }`}
          ></div>
          <div>
            <Typography.Title level={5}>{name}</Typography.Title>
            {isRequested && (
              <Tag color="red" style={{ borderRadius: "30px" }}>
                Requested For Order
              </Tag>
            )}
            {orders?.length > 0 && (
              <Space
                wrap
                style={{
                  display: "flex",
                }}
                direction="horizontal"
              >
                {orders.map((order, i) => {
                  return (
                    <Tooltip
                      title={`${getIconByStatus(order.status)} ${order.status}`}
                      key={i}
                    >
                      <Tag
                        color={getColorByStatus(order.status)}
                        style={{ borderRadius: "30px", cursor: "pointer" }}
                        onClick={() => {
                          window.open(`/order/list/${order.objectId}`);
                        }}
                        key={i}
                      >
                        {getIconByStatus(order.status)}
                        {order.customer_area}-{" "}#{order.objectId}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </Space>
            )}
            <div>{phone}</div>
            <Tag
              color="purple"
              style={{
                cursor: "pointer",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // dont extend the width
                width: "8.2rem",
              }}
              onClick={() =>
                window.open(`/rider/tracking?riderId=${objectId}&live=true`)
              }
            >
              <FaLocationArrow /> View Live Location
            </Tag>
          </div>
        </Space>
        <div>
          {/* {orders.length > 0 && (
            <Row className="orders" gutter={[4, 8]}>
              {orders.map((order, i) => (
                <Col key={i}>
                  <Tooltip title={order.status}>
                    <Tag className="orders-item">{order.customer_area}</Tag>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          )} */}
          {orders.length === 0 && completedAt ? (
            <Returning completedAt={completedAt} lastArea={lastArea} />
          ) : null}
        </div>
      </Card>
    </Badge.Ribbon>
  );
}

export default function Riders({ fold, setFold }) {
  const {
    riders: { data },
  } = useContext(OrdersContext);

  return (
    <div className={`riders-panel customScroll ${fold ? "fold" : "unfold"}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!fold && <h2 style={{ marginBottom: "0" }}>Riders </h2>}
        <Space direction="horizontal">
          <Tag style={{ borderRadius: "100%" }} color="green">
            {data?.length > 0
              ? data.filter((rider) => rider.rider_availability).length
              : 0}
          </Tag>
          <Tag style={{ borderRadius: "100%" }} color="red">
            {data?.length > 0
              ? data.filter((rider) => !rider.rider_availability).length
              : 0}
          </Tag>
        </Space>
        <Switch
          unCheckedChildren="Riders"
          checkedChildren="Fold"
          checked={!fold}
          onClick={(value) => setFold(!value)}
        />
      </div>
      {/* <AutoAssign /> */}
      <Space
        direction="vertical"
        className="riders"
        size="small"
        style={{ display: fold ? "none" : "flex" }}
      >
        {data?.map((rider) => (
          <Rider key={rider.id} data={rider}></Rider>
        ))}
      </Space>
    </div>
  );
}
