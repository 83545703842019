import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";

interface Props {
  users: any[];
  loading: boolean;
}

const TopUserByMonth: React.FC<Props> = ({ users, loading }) => {
  const columns: ColumnsType<any> = [
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      filterSearch: true,
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      render: (userId: string) => {
        const userIdSplit = userId.split("$");
        userId = userIdSplit[userIdSplit.length - 1];
        return (
          <a href={`/user/${userId}`} target="_blank" rel="noreferrer">
            {userId}
          </a>
        );
      },
    },
    {
      title: "name",
      dataIndex: "name",
      onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
      key: "name",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      onFilter: (value: any, record: any) => record.phone.indexOf(value) === 0,
      key: "phone",
    },
    {
      title: "Order Count",
      dataIndex: "orderCount",
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
      key: "orderCount",
    },
  ];

  return (
    <Card title="Top user by total orders">
      <Table
        dataSource={users}
        columns={columns}
        loading={loading}
        rowKey="id"
      />
    </Card>
  );
};

export default TopUserByMonth;
