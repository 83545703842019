import { Form, Image } from "antd";

const { Item } = Form;

export default function Images({ image }) {
  return (
    <Item className="section">
      <Image src={image} alt="" style={{ maxHeight: "280px" }} />
    </Item>
  );
}
