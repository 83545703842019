import { createContext, useReducer } from "react";
import { message } from "antd";
import useProduct from "../../hooks/useProduct";
import useRestaurants from "../../hooks/useRestaurants";
import useCategories from "../../hooks/useCategories";
import { parser } from "../../utils";
import CreateForm from "../../components/product/create";
import { randomId } from "../../utils";
import Parse from "parse";

const Types = {
  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  GET_RESTAURANTS_REQUEST: "GET_RESTAURANTS_REQUEST",
  GET_RESTAURANTS_SUCCESS: "GET_RESTAURANTS_SUCCESS",
  GET_RESTAURANTS_FAILURE: "GET_RESTAURANTS_FAILURE",
  GET_ATTRIBUTES_REQUEST: "GET_ATTRIBUTES_REQUEST",
  GET_ATTRIBUTES_SUCCESS: "GET_ATTRIBUTES_SUCCESS",
  GET_ATTRIBUTES_FAILURE: "GET_ATTRIBUTES_FAILURE",
  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  GET_PRODUCT_RESET: "GET_PRODUCT_RESET",
  UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
};

const initialState = {
  categories: { loading: false, data: { count: 0, results: [] }, error: null },
  restaurants: { loading: false, data: { count: 0, results: [] }, error: null },
  attributes: { loading: false, data: { count: 0, results: [] }, error: null },
  product: { loading: false, data: null, error: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_CATEGORIES_REQUEST:
      state.categories.loading = true;
      return { ...state };
    case Types.GET_CATEGORIES_SUCCESS:
      state.categories.loading = false;
      state.categories.data = action.payload;
      return { ...state };
    case Types.GET_CATEGORIES_FAILURE:
      state.categories.loading = false;
      state.categories.error = action.payload;
      return { ...state };
    case Types.GET_RESTAURANTS_REQUEST:
      state.restaurants.loading = true;
      return { ...state };
    case Types.GET_RESTAURANTS_SUCCESS:
      state.restaurants.loading = false;
      state.restaurants.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANTS_FAILURE:
      state.restaurants.loading = false;
      state.restaurants.error = action.payload;
      return { ...state };
    case Types.GET_ATTRIBUTES_REQUEST:
      state.attributes.loading = true;
      return { ...state };
    case Types.GET_ATTRIBUTES_SUCCESS:
      state.attributes.loading = false;
      state.attributes.data = action.payload;
      return { ...state };
    case Types.GET_ATTRIBUTES_FAILURE:
      state.attributes.loading = false;
      state.attributes.error = action.payload;
      return { ...state };
    case Types.GET_PRODUCT_REQUEST:
      state.product.loading = true;
      return { ...state };
    case Types.GET_PRODUCT_SUCCESS:
      state.product.loading = false;
      state.product.data = action.payload;
      if (action.payload.restaurant) {
        state.restaurants.data.results = [action.payload.restaurant.toJSON()];
      }
      return { ...state };
    case Types.GET_PRODUCT_FAILURE:
      state.product.loading = false;
      state.product.data = null;
      state.product.err = true;
      return { ...state };
    case Types.GET_PRODUCT_RESET:
      state.product.loading = false;
      state.product.data = null;
      state.product.error = null;
      return { ...state };
    default:
      return state;
  }
};

export const CreateContext = createContext();

export default function CreateContextProvider({ productId, changeReqCb }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addProduct, getById, getAttributes } = useProduct();
  const { getRestaurants } = useRestaurants();
  const { getMany: getManyCategories } = useCategories();

  const getCategories = async (params = {}) => {
    dispatch({ type: Types.GET_CATEGORIES_REQUEST });

    getManyCategories(params, (err, categories) => {
      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_CATEGORIES_FAILURE, payload: err });
        return;
      }

      if (categories) {
        dispatch({
          type: Types.GET_CATEGORIES_SUCCESS,
          payload: {
            ...categories,
            results: parser(categories.results),
          },
        });
      }
    });
  };

  const getRestaurantsFunc = async (params) => {
    dispatch({ type: Types.GET_RESTAURANTS_REQUEST });

    getRestaurants(params, (err, restaurants) => {
      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_RESTAURANTS_FAILURE, payload: err });
        return;
      }

      if (restaurants) {
        dispatch({
          type: Types.GET_RESTAURANTS_SUCCESS,
          payload: {
            count: restaurants.count,
            results: restaurants.results.map((r) => r.toJSON()),
          },
        });
      }
    });
  };

  const addProductFunc = async (data, callback) => {
    if (data.price.type === "variant") {
      data.price.variants?.forEach((variant) => {
        variant.id = randomId();

        variant.items?.forEach((item) => {
          item.id = randomId();
        });
      });
    }

    if (data.addons?.items) {
      data.addons.items.forEach((addon) => {
        addon.id = randomId();
      });
    }

    addProduct(data, (err, product) => {
      if (product) {
        message.success("Product added successfully");
        callback(null, product);
        return;
      }

      callback(err, null);
    });
  };

  const getAttributesFunc = async (payloads) => {
    dispatch({ type: Types.GET_ATTRIBUTES_REQUEST });

    getAttributes(payloads, (err, attributes) => {
      if (attributes) {
        dispatch({
          type: Types.GET_ATTRIBUTES_SUCCESS,
          payload: {
            ...attributes,
            results: parser(attributes),
          },
        });
      }

      if (err) {
        message.error(err.message);
        dispatch({ type: Types.GET_ATTRIBUTES_FAILURE, payload: err });
      }
    });
  };

  const getProductById = async (id) => {
    dispatch({ type: Types.GET_PRODUCT_REQUEST });

    getById(
      {
        id,
        select: [
          "addons",
          "category.name",
          "restaurant.name",
          "restaurant.banner_image",
          "restaurant.vat",
          "restaurant.hub.name",
          "price",
          "images",
          "timeline",
          "details",
          "meta_description",
          "meta_tags",
          "meta_title",
          "name",
          "slug",
          "sold",
          "stock",
          "approval_status",
          "availability",
          "is_inv",
          "delete",
          "change_request",
        ],
      },
      (err, product) => {
        if (product) {
          dispatch({
            type: Types.GET_PRODUCT_SUCCESS,
            payload: parser(product),
          });

          getCategories({ ids: [product.get("category").id] });
        } else {
          message.error("Product not found!");
          dispatch({ type: Types.GET_PRODUCT_FAILURE, payload: err });
        }
      }
    );
  };

  const updateProductFunc = async (id, data, callback) => {
    try {
      await Parse.Cloud.run("updateProduct", { id, ...data });
      message.success("Product updated successfully");
      callback(null);
    } catch (err) {
      callback(err.message);
    }
  };

  return (
    <CreateContext.Provider
      value={{
        ...state,
        dispatch,
        getCategories,
        getRestaurants: getRestaurantsFunc,
        addProductFunc,
        getAttributes: getAttributesFunc,
        getProductById,
        updateProduct: updateProductFunc,
        changeReqCb,
        setProduct: (payload) => {
          dispatch({
            type: Types.GET_PRODUCT_SUCCESS,
            payload,
          });
        },
      }}
    >
      <div className="create-product-page">
        <CreateForm productId={productId} />

      </div>
    </CreateContext.Provider>
  );
}
