import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Area } from "@ant-design/plots";

export default function OrdersPerDate() {
  const { data } = useContext(SalesReportContext);

  const config = {
    data: Object.entries(data?.ordersPerDate || {}).map(([date, value]) => ({
      date,
      value,
    })),
    xField: "date",
    yField: "value",
    smooth: true,
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      };
    },
  };

  return (
    <div id="orders-per-day">
      <h2>Orders Per Day</h2>
      <Area {...config} />
    </div>
  );
}
