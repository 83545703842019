import { Form, InputNumber, Select, DatePicker } from "antd";
import { isFloat } from "../../../../common/utils";
const { Item } = Form;

export default function PriceAmount() {
  return (
    <>
      <Item
        name={["price", "amount"]}
        label="Price Amount"
        wrapperCol={{ span: 8 }}
      >
        <InputNumber style={{ width: "200px" }} placeholder="Amount" />
      </Item>
      <Item
        name={["price", "discount", "type"]}
        label="Discount Type"
        wrapperCol={{ span: 8 }}
      >
        <Select placeholder="Select one" style={{ minWidth: "200px" }}>
          <Select.Option value="fixed">Fixed</Select.Option>
          <Select.Option value="percent">Percent</Select.Option>
        </Select>
      </Item>
      <Item
        name={["price", "discount", "amount"]}
        label="Discount Amount"
        wrapperCol={{ span: 8 }}
      >
        <InputNumber
          min={0}
          placeholder="Discount Amount"
          style={{ minWidth: "200px" }}
        />
      </Item>
      <Item
        name={["price", "discount", "validity"]}
        label="Discount Validity"
        wrapperCol={{ span: 8 }}
      >
        <DatePicker
          placeholder="Discount Validity"
          style={{ minWidth: "200px" }}
          showTime
          format="YYYY-MM-DD HH:mm:ss"
        />
      </Item>
    </>
  );
}
