import { Button, Col, Form, Input, Row, message } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

export default function NoticeBoard() {
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const notices = await new Parse.Query("config")
        .equalTo("key", "partner_noticeboard")
        .first();
      if (notices) {
        setData(notices);
        const data = notices.get("value");
        form.setFieldsValue(data);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const onFinish = async ({ notices }) => {
    try {
      if (!notices) return message.error("Please add notices");
      setLoading(true);
      const value = data.get("value") || {};
      value.notices = notices;

      data.set("value", value);
      await data.save();
      message.success("Notice board updated successfully");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Form
        form={form}
        name="noticeboard"
        onFinish={onFinish}
        layout="horizontal"
      >
        <Row gutter={[10, 10]} justify="center">
          <Col span={24} md={14}>
            <h1 style={{ textAlign: "center" }}>Notice Board</h1>
            <Form.List name="notices">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row
                      className="notice"
                      justify="space-between"
                      style={{ alignItems: "center" }}
                    >
                      <Col span={20}>
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          label="Title"
                          rules={[{ required: true, message: "Missing title" }]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "text"]}
                          label="Text"
                          rules={[{ required: true, message: "Missing text" }]}
                        >
                          <Input.TextArea placeholder="Text" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button loading={loading} htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .notice {
    background: #fff;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  }
`;
