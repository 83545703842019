import "chart.js/auto";
// import { Line } from "@ant-design/plots";
import { Column } from '@ant-design/plots';

export default function ProgressReport({ growth }) {
  const data = growth.reduce((acc, cur) => {
    const { date, total, delivered } = cur;
    acc.push({ date, value: total, category: "Total" });
    acc.push({ date, value: delivered, category: "Delivered" });

    return acc;
  }, []);

  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "category",
    xAxis: {
      type: "time",
    },
    padding: "auto",
    smooth: true,
    annotations: [
      {
        type: "line",
        start: ["min", "median"],
        end: ["max", "median"],
        style: {
          stroke: "#F4664A",
          lineDash: [2, 2],
        },
      },
    ],
    slider: {
      start: 0.5,
      end: 1,
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div style={{ margin: "50px 0" }}>
        <h3 style={{ textAlign: "right" }}>Orders</h3>
        {/* <Line {...config} /> */}
        <Column {...config} />
      </div>
    </div>
  );
}
