import Parse from "parse";

export default function usePurchaseRecord() {
  const repoName = "inventory";
  const defaultFunc = () => {};

  const addNewPurchaseRecord = async (params, callback = defaultFunc) => {
    try {
      const res = await Parse.Cloud.run("addInventory", params);
      if (res) {
        callback(null, res);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getByInventoryId = async (id, callback = defaultFunc) => {
    try {
      const query = new Parse.Query(repoName);
      query.equalTo("innventory", {
        __type: "Pointer",
        className: "inventory",
        objectId: id,
      });
      query.include("inventory");
      query.include("product");
      const inv = await query.first();
      if (inv) {
        callback(null, inv);
      } else {
        callback("No record found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getById = async (id, callback = defaultFunc) => {
    try {
      const query = new Parse.Query(repoName);
      query.equalTo("objectId", id);
      query.select([
        "quantity",
        "unit_price",
        "selling_price",
        "expired_date",
        "product.name",
        "product.price",
        "variants",
      ]);
      const inv = await query.first();
      if (inv) {
        callback(null, inv);
      } else {
        callback("No record found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getPurchaseRecords = async (
    { limit = 5, skip = 0, select, product, name } = {},
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      if (product) {
        query.equalTo("product", {
          __type: "Pointer",
          className: "product",
          objectId: product,
        });
      } else if (name) {
        const productsByName = await new Parse.Query("product")
          .matches("name", name, "i")
          .select("name")
          .limit(5)
          .find();
        query.containedIn(
          "product",
          productsByName.map((item) => item.id)
        );
      }

      query.include("product");
      query.limit(limit);
      query.skip(skip);
      query.withCount();
      query.descending("createdAt");
      if (select && Array.isArray(select)) {
        query.select(select);
      }
      const res = await query.find({
        sessionToken: Parse.User.current()?.getSessionToken(),
      });
      if (res) {
        callback(null, res);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const deletePurchaseRecord = async (id, callback = defaultFunc) => {
    try {
      const deleted = await Parse.Cloud.run("deleteInventory", { id });
      if (deleted) {
        callback(null, deleted);
      } else {
        callback("Something went wrong");
      }
    } catch (err) {
      callback(err.message);
    }
  };

  const updatePurchaseRecord = async (params, callback = defaultFunc) => {
    try {
      const updated = await Parse.Cloud.run("updateInventory", params);
      if (updated) {
        callback(null, updated);
      } else {
        callback("Something went wrong!");
      }
    } catch (err) {
      callback(err.message);
    }
  };

  return {
    addNewPurchaseRecord,
    getPurchaseRecords,
    getById,
    getByInventoryId,
    deletePurchaseRecord,
    updatePurchaseRecord,
  };
}
