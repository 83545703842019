import { CloseOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import DateHandlerVoucher from "./DateHandlerVoucher";

const { Item } = Form;

type SecondaryVoucherType = {
  secondary_voucher_title: string;
  secondary_voucher_sub_title: string;
  secondary_voucher_title_validity: string;
}[];

const SecondaryVoucher = ({
  index,
  onRemove,
  secondaryVoucher,
  setSecondaryVoucher,
  onChange,
}: {
  index: number;
  onRemove: (index: number) => void;
  secondaryVoucher: SecondaryVoucherType;
  setSecondaryVoucher: React.Dispatch<
    React.SetStateAction<SecondaryVoucherType>
  >;
  onChange: (index: number, field: string, value: string) => void;
}) => {
  const [date, setDate] = useState<string | null>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | string,
    field: string
  ) => {
    if (typeof e === "string") {
      onChange(index, field, e);
    } else {
      const { value } = e.target;
      onChange(index, field, value);
    }
  };
  useEffect(() => {
    if (secondaryVoucher[index]) {
      onChange(
        index,
        "secondary_voucher_title",
        secondaryVoucher[index].secondary_voucher_title || ""
      );
      onChange(
        index,
        "secondary_voucher_sub_title",
        secondaryVoucher[index].secondary_voucher_sub_title || ""
      );
    }
  }, []);

  const onDateChange = (date: moment.Moment | null, dateString: string) => {
    setDate(dateString);
    onChange(index, "secondary_voucher_title_validity", dateString);
  };

  return (
    <div className="section">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* <div style={{ cursor: "pointer" }} onClick={() => onRemove(index)}>
          <CloseOutlined style={{ color: "red" }} />
          <span style={{ color: "red", marginLeft: "8px" }}>
            Delete Voucher
          </span>
        </div> */}
        <h3 className="section-title">Voucher {index + 1}</h3>
      </div>
      <Item label="Voucher Title">
        <Input
          placeholder={`voucher ${index + 1}:xyz`}
          onChange={(e) => handleInputChange(e, "secondary_voucher_title")}
          name="secondary_voucher_title"
          value={secondaryVoucher[index]?.secondary_voucher_title || ""}
        />
      </Item>
      <Item label="Voucher Sub Title">
        <Input
          placeholder="voucher sub title"
          onChange={(e) => handleInputChange(e, "secondary_voucher_sub_title")}
          name="secondary_voucher_sub_title"
          value={secondaryVoucher[index]?.secondary_voucher_sub_title || ""}
        />
      </Item>
      <Item
        name="secondary_voucher_title_validity"
        label="Voucher Title Validity"
      >
        <DateHandlerVoucher
          onDateChange={onDateChange}
          defaultValue={
            secondaryVoucher[index]?.secondary_voucher_title_validity ?? date
          }
        />
      </Item>
    </div>
  );
};

export default SecondaryVoucher;
