import axios from "axios";

export const getErrorMessage = (error) =>
  error.response ? error.response.data.error : error.message;

export const parser = (data) => {
  if (Array.isArray(data)) {
    return data.map((i, x) => ({
      ...i.attributes,
      id: i.id,
      ref: i,
      key: i.id || x,
    }));
  } else if (typeof data === "object") {
    return {
      ...data.attributes,
      id: data.id,
      ref: data,
    };
  } else {
    return null;
  }
};

export function randomId() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

/**
 * Download csv file
 * @param { Array } segments
 */

export function downloadCsv(segments, filename = "export") {
  let a = document.createElement("a");

  const text = segments
    .map((segment) => {
      const { header = [], data = [], title = "" } = segment;

      const keys = header.map((s) => s.key);
      let segHeader = header.map((s, i) => s.title).join(",") + "\n";

      const segBody = data
        .map((item) => {
          return keys
            .map((k) => {
              let v = item[k] ?? "";
              if (typeof v === "boolean") v = v ? "Yes" : "No";
              return v;
            })
            .join(",");
        })
        .join("\n");

      return title + "\n " + segHeader + segBody;
    })
    .join("\n \n");

  a.href = "data:application/octet-stream, " + encodeURIComponent(text);
  a.download = `${filename}.csv`;
  a.click();
}

export function downloadCsvFromText(text, name) {
  let a = document.createElement("a");
  a.href = "data:application/octet-stream, " + encodeURIComponent(text);
  a.download = `${name}.csv`;
  a.click();
}

export function localDate(date) {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}

export function localDateTime(date) {
  if (!date) return "-";
  return new Date(date).toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export function getTimeAgo(date) {
  date = new Date(date);
  const currentTime = new Date();
  const timeDiff = Math.abs(currentTime - date);
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return seconds + " sec ago";
  } else if (minutes < 60) {
    return minutes + " min ago";
  } else if (hours < 24) {
    return hours + " hour ago";
  } else if (days < 7) {
    return days + " day ago";
  } else {
    return localDateTime(date); // Return the date in a different format if it's older than 7 days
  }
}

export const v3Axios = axios.create({
  baseURL: "https://apiv3.munchies.com.bd",
  timeout: 30 * 1000,
});
