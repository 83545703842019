import { message } from "antd";
import { createContext, useEffect, useReducer, useState } from "react";
import Parse from "parse";
import PaymentsTable from "../../components/payments/PaymentsTable";
import { isAfter } from "date-fns";
import { downloadCsv } from "../../utils";

const Types = {
  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAILURE: "GET_PAYMENTS_FAILURE",
  UPDATE_DATE_RANGE: "UPDATE_DATE_RANGE",
  SET_UNIVERSAL_DATE: "SET_UNIVERSAL_DATE",
};

const initialState = {
  payments: {
    loading: false,
    data: [],
  },
  statistics: {
    total: 0,
    vat: 0,
    commission: 0,
    penalty: 0,
    payable: 0,
    failed_order_amount: 0,
    netPayable: 0,
  },
  paymentHistory: {
    totalPlacedOrder: 0,
    successfullyDelivered: 0,
    canceledOrders: 0,
    totalSales: 0,
    vendorDiscount: 0,
    penaltyAmount: 0,
    failedOrderAmount: 0,
    totalPaid: 0,
    totalNetPayable: 0,
  },
};

const sumStatisticsData = (restaurants) => {
  let obj = { ...initialState.statistics };

  restaurants.forEach((restaurant) => {
    const { payments, active } = restaurant;

    if (!active || !Array.isArray(payments) || !payments.length) return obj;

    payments.forEach((payment) => {
      if (
        isAfter(
          new Date(payment.payment_date),
          new Date(restaurant.paymentLastDate)
        )
      ) {
        return;
      }

      const {
        total_collected,
        vat_collection,
        commission,
        penalty,
        failed_order_amount,
      } = payment;

      const payable = total_collected - (vat_collection + commission);

      obj.total += total_collected;
      obj.vat += vat_collection;
      obj.commission += commission;
      obj.payable += payable;
      obj.penalty += penalty;
      obj.failed_order_amount += failed_order_amount;
      obj.netPayable += payable + failed_order_amount - penalty;
    });
  });

  return obj;
};

const sumupPayments = (restaurant, date) => {
  const { payments } = restaurant;

  if (!date && payments.length) {
    date = payments[payments.length - 1]?.payment_date;
  }

  const initialState = {
    ...restaurant,
    total: 0,
    vat: 0,
    penalty: 0,
    payable: 0,
    revenue: 0,
    commission: 0,
    failedOrderAmount: 0,
    netPayable: 0,
    totalOrders: 0,
    revenue: 0,
    paymentLastDate: date,
    dates: [],
    paymentDate: [],
    active: false,
  };

  if (!payments.length) return initialState;

  if (!Array.isArray(payments)) return initialState;

  return payments.reduce(
    (acc, payment, i) => {
      const {
        payment_date,
        total_collected,
        vat_collection,
        commission,
        totalOrders,
        penalty = 0,
        failed_order_amount = 0,
        revenue,
      } = payment;

      acc.dates.push({
        id: payment.objectId,
        date: payment_date,
      });

      if (!isAfter(new Date(payment_date), new Date(date))) {
        acc.active = true;

        const payableAmount = Math.round(
          total_collected - (vat_collection + commission + penalty)
        );

        acc.total += total_collected;
        acc.revenue += revenue ?? 0;
        acc.vat += vat_collection;
        acc.totalOrders += totalOrders;
        acc.commission += commission;
        acc.payable += payableAmount;
        acc.revenue += revenue;
        acc.penalty += penalty ?? 0;
        acc.failedOrderAmount += failed_order_amount;
        acc.netPayable += failed_order_amount + payableAmount;
      }
      return acc;
    },
    { ...initialState }
  );
};

const organizeData = (data /* payments[{ restaurant, payments[] }] */) => {
  if (!Array.isArray(data)) return [];

  const res = data.map((restaurant, i) => {
    return {
      key: i,
      ...sumupPayments(restaurant),
    };
  });
  return res;
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_PAYMENTS_REQUEST:
      state.payments.loading = true;
      return { ...state };
    case Types.GET_PAYMENTS_SUCCESS:
      state.payments.loading = false;
      state.payments.data = action.payload;
      return { ...state };
    case Types.GET_PAYMENTS_FAILURE:
      state.payments.loading = false;
      state.payments.data = initialState.payments.data;
      return { ...state };
    case Types.UPDATE_DATE_RANGE:
      const idx = state.payments.data.findIndex(
        ({ objectId }) => objectId === action.payload.restaurantId
      );

      if (idx === -1) return { ...state };
      state.payments.data[idx] = sumupPayments(
        state.payments.data[idx],
        action.payload.date
      );
      return { ...state };
    case Types.SET_UNIVERSAL_DATE:
      const data = state.payments.data.map((restaurant) =>
        sumupPayments(restaurant, action.payload)
      );
      state.payments.data = data;
      return { ...state };
    default:
      return state;
  }
};

export const PaymentsContext = createContext();

export default function Payments() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [reqPayloads, setReqPayloads] = useState(null);
  const [details, setDetails] = useState(null);

  const fetchPayments = async () => {
    dispatch({ type: Types.GET_PAYMENTS_REQUEST });
    try {
      const payments = await Parse.Cloud.run("getPaymentList");
      dispatch({
        type: Types.GET_PAYMENTS_SUCCESS,
        payload: organizeData(payments),
      });
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.GET_PAYMENTS_FAILURE });
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchInvoiceDetails = async (
    { paymentIds, note, paymentsInfo, vat_included, restaurant },
    callback = () => {}
  ) => {
    try {
      const res = await Parse.Cloud.run("invoiceDetails", {
        paymentIds,
        restaurant,
        note,
        paymentsInfo,
        vat_included,
      });

      callback(null, res);
    } catch (err) {
      callback(err.message);
    }
  };

  const paymentHandler = async () => {
    try {
      const payment = await Parse.Cloud.run("makePayment", reqPayloads);
      if (payment) {
        setDetails(payment);

        const restIndex = state.payments.data.findIndex(
          (res) => res.restaurant?.id === payment.restaurant.id
        );

        if (restIndex === -1) return;
        let restaurant = state.payments.data[restIndex];

        if (restaurant) {
          restaurant.payments = restaurant.payments.filter(
            (p) => !payment.payment_ids.includes(p.objectId)
          );

          state.payments.data.splice(restIndex, 1, sumupPayments(restaurant));

          dispatch({
            type: Types.GET_PAYMENTS_SUCCESS,
            payload: state.payments.data,
          });
        }

        message.success("Payment Successful");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <PaymentsContext.Provider
      value={{
        ...state,
        dispatch,
        fetchInvoiceDetails,
        selectedRestaurant,
        setSelectedRestaurant,
        Types,
        paymentHandler,
        reqPayloads,
        setReqPayloads,
        details,
        setDetails,
        sumStatisticsData,
      }}
    >
      <PaymentsTable />
    </PaymentsContext.Provider>
  );
}
