import { useContext, useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Select, message } from "antd";
import { SectionsContext } from "./SectionsContext";
import { v3Axios } from "../../utils";
import DeliveryAreaRestaurant from "../../pages/Restaurant/DeliveryArea";
import Parse from "parse";
export default function UpdateSection({
  close,
  name,
  sectionId,
  activeHours,
  hubs: hubItems,
}) {
  const [form] = Form.useForm();
  const [zones, setZones] = useState([]);
  const [areas, setAreas] = useState([]);
  const {
    updateSectionNameFunc,
    updateSectionName: { loading },
    hubs,
  } = useContext(SectionsContext);

  const onFinish = (values) => {
    console.log(values);
    values.areas = areas;
    updateSectionNameFunc(sectionId, values, () => {
      form.resetFields();
      close();
    });
  };

  useEffect(() => {
    const fetchLayers = async () => {
      try {
        const { data } = await v3Axios.get("/api/v1/areas");

        const zoneLists = {};

        for (const item of data) {
          if (!zoneLists[item?.zone._id]) {
            zoneLists[item?.zone._id] = {
              ...item.zone,
              areas: [],
            };
          }
          zoneLists[item?.zone._id].areas.push(item);
        }
        const section = await new Parse.Query("section")
          .equalTo("objectId", sectionId)
          .select("areas")
          .first();

        const areas = section.get("areas");
        setAreas(areas);
        setZones(Object.values(zoneLists));
      } catch (err) {
        console.log(err);
        message.error("Something went wrong");
      }
    };
    fetchLayers();
  }, [sectionId]);

  return (
    <div className="update-section">
      <div className="header">
        <h3 className="title">Update Section Name</h3>
        <div onClick={close} className="close-x">
          <CloseOutlined />
        </div>
      </div>

      <Form
        form={form}
        style={{ width: "100%" }}
        layout="vertical"
        name="update-section"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        initialValues={{
          name,
          hubs: hubItems.map((h) => h.id),
          activeHours,
        }}
        size="large"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input section name!" }]}
          style={{ marginBottom: "10px" }}
        >
          <Input placeholder="Section Name" />
        </Form.Item>
        <Form.Item
          label="Select Hubs"
          name="hubs"
          style={{ marginBottom: "0px" }}
        >
          <Select placeholder="Select hub" mode="multiple">
            {hubs.data.map((hub) => {
              return (
                <Select.Option key={hub.id} value={hub.id}>
                  {hub.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Divider />
        <Form.Item
          label="Active hours"
          name="activeHours"
          style={{ marginBottom: "0px" }}
        >
          <Input size="large" placeholder="0-8" />
        </Form.Item>

        <Divider />
        <Col span={24}>
          {
            <DeliveryAreaRestaurant
              zones={zones}
              setAreas={setAreas}
              height={500}
              areas={areas}
            />
          }
        </Col>
        <Divider />
        <Form.Item style={{ marginTop: "10px" }}>
          <Button
            loading={loading}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
