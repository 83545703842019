import { useEffect, useState } from "react";
import { message, Select } from "antd";
import axios from "axios";
import LeaderBoard from "../../components/rider/list/LeaderBoard";
import Boxer from "../../components/Common/Boxer";

export default function RidersLeaderBoard() {
  const [riders, setRiders] = useState({});
  const [month, setMonth] = useState(null);
  const months = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const fetchRiders = async (month) => {
    try {
      const { data: riders } = await axios.get(
        `https://apiv2.munchies.com.bd/api/riders/leaderboard/admin?month=${month}`,
        {
          headers: {
            "app-version": "20.0.30",
          },
          timeout: 30 * 1000,
        }
      );
      setRiders(riders);
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchRiders(month);
  }, [month]);

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Select placeholder="Select Month" onChange={(v) => setMonth(v)}>
          {months.map(({ value, label }) => (
            <Select.Option value={value}>{label}</Select.Option>
          ))}
        </Select>
      </div>
      <Boxer>
        {riders.bike && <LeaderBoard title="Bike" data={[...riders.bike]} />}
        {riders.cycle && <LeaderBoard title="Cycle" data={[...riders.cycle]} />}
      </Boxer>
    </div>
  );
}
