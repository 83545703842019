import { Button, Table, message } from "antd";
import Parse from "parse";
import { useEffect, useState } from "react";
import useSearch from "../../components/inventory/utils/useSearch";
import { downloadCsv } from "../../utils";
import { DownloadOutlined } from "@ant-design/icons";

export default function RestaurantHubList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, searchParams] = useSearch();

  const fetchData = async () => {
    try {
      setLoading(true);
      const hubs = await new Parse.Query("hub")
        .select(["name", "brands"])
        .find();
      const list = hubs.reduce((acc, hub) => {
        const { name, brands = [] } = hub.toJSON();

        brands.forEach((id) => {
          if (!acc[id]) {
            acc[id] = [];
          }

          acc[id].push(name);
        });

        return acc;
      }, {});

      const restaurants = await new Parse.Query("restaurant")
        .select(["name", "hub.name"])
        .limit(1000)
        .find();

      const data = restaurants.map((r) => {
        return {
          key: r.id,
          name: r.get("name"),
          hub: r.get("hub").get("name"),
          hubs: (list[r.id] || []).join(", "),
        };
      });

      setData(data);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Restaurant",
      dataIndex: "name",
      key: "name",
      ...searchParams("name"),
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
      ...searchParams("hub"),
    },
    {
      title: "Connected Hub List",
      dataIndex: "hubs",
      key: "hubs",
      ...searchParams("hubs"),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Table
        title={() => (
          <div className="flex-between">
            <h2>Connected Hub List</h2>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => {
                downloadCsv(
                  [
                    {
                      header: columns,
                      data: data,
                    },
                  ],
                  "Connected Hub List"
                );
              }}
            >
              Download
            </Button>
          </div>
        )}
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
}
