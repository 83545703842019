import { Menu, Dropdown, Space, Popover } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { BiBox, BiCategory, BiCloudUpload } from "react-icons/bi";
import { IoMdAnalytics } from "react-icons/io";
import styled from "styled-components";
import { FcSalesPerformance } from "react-icons/fc";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsListOl } from "react-icons/bs";

const CenterText = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
);

const menu = [
  { name: "Details", icon: <EditOutlined />, link: `/restaurant/add?id=:id` },
  {
    name: "Report",
    icon: <IoMdAnalytics />,
    link: `/restaurant/:id/sales-report`,
  },
  {
    name: "Categories",
    icon: <BiCategory />,
    link: `/restaurant/:id/categories`,
  },
  { name: "Products", icon: <BiBox />, link: `/product/list?restaurant=:id` },
  {
    name: "Product Upload",
    icon: <BiCloudUpload />,
    link: `/restaurant/:id/product-upload`,
  },
  {
    name: "Sales",
    icon: <FcSalesPerformance />,
    link: `/restaurant/:id/payments`,
  },
  {
    name: "Invoice History",
    icon: <FaFileInvoiceDollar />,
    link: `/payments/history?restaurant=:id`,
  },
  {
    name: "Top Selling Products",
    icon: <BsListOl />,
    link: `/restaurant/:id/top-selling-products`,
  },
];

export const RestaurantTopMenu = ({ id, ...rest }) => {
  return (
    <TopMenu {...rest}>
      {menu.map((item) => (
        <Popover key={item.name} content={item.name}>
          <div className="item">
            <Link to={item.link.replace(":id", id)}>{item.icon}</Link>
          </div>
        </Popover>
      ))}
    </TopMenu>
  );
};

const TopMenu = styled(Space)`
  margin-bottom: 10px;
  font-size: 20px;
  .item {
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    border-radius: 5px;
    border: 1px solid #e8e8e8;

    a {
      color: inherit;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const MenuItems = ({ id }) => (
  <>
    {menu.map((item, i) => (
      <Menu.Item key={i}>
        <Link
          style={{ fontSize: "14px", fontWeight: "500" }}
          to={item.link.replace(":id", id)}
        >
          <CenterText>
            {item.icon} <span style={{ marginLeft: "10px" }}>{item.name}</span>
          </CenterText>
        </Link>
      </Menu.Item>
    ))}
  </>
);

export default function RestaurantLinks({ children, id, ...props }) {
  return (
    <Dropdown
      placement="bottom"
      arrow
      trigger={["click"]}
      overlay={() => (
        <Menu theme="light" style={{ width: "180px" }}>
          <MenuItems id={id} />
        </Menu>
      )}
      {...props}
    >
      <div style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer" }}>
        {children}
      </div>
    </Dropdown>
  );
}
