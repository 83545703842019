import { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, message, Modal, Row, Table } from "antd";
import Parse from "parse";
import useSearch from "../../components/inventory/utils/useSearch";
import { localDateTime } from "../../utils";

function ShowObject({ previous = {}, changes = {}, type, target, msg }) {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <div onClick={() => setVisible(true)} style={{ cursor: "pointer" }}>
        Show
      </div>
      <ShowObjectStyled
        title={`${type}:${target} - ${msg}`}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="pre">
              <strong>Previous</strong>
              <pre>{JSON.stringify(previous, null, 4)}</pre>
            </div>
          </Col>
          <Col span={12}>
            <div className="pre">
              <strong>Changes</strong>
              <pre>{JSON.stringify(changes, null, 4)}</pre>
            </div>
          </Col>
        </Row>
      </ShowObjectStyled>
    </div>
  );
}

const ShowObjectStyled = styled(Modal)`
  .pre {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
  }
`;

export default function LogsList() {
  const [logs, setLogs] = useState({
    count: 0,
    results: [],
  });
  const [, getSearchProps] = useSearch();

  const columns = [
    {
      title: "Message",
      dataIndex: "msg",
      key: "message",
      width: 300,
      ...getSearchProps("msg"),
      render: (msg, { createdAt }) => (
        <div>
          <div title={msg} className="truncate">
            {msg}
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#999",
            }}
          >
            {localDateTime(createdAt)}
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ...getSearchProps("type"),
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      ...getSearchProps("target"),
    },
    {
      title: "Changes",
      key: "changes",
      render: (props) => <ShowObject {...props} />,
    },
  ];

  const fetchLogs = async ({ limit = 100, skip = 0, type, target }) => {
    try {
      message.loading("Loading logs...", 0);
      const query = new Parse.Query("logs");
      query.limit(limit);
      query.skip(skip);
      if (type) {
        query.matches("type", type, "i");
      }
      if (target) {
        query.matches("target", target, "i");
      }
      query.select([
        "target",
        "type",
        "user.name",
        "msg",
        "previous",
        "changes",
      ]);
      query.withCount();
      query.descending("createdAt");
      const data = await query.find();
      if (data) {
        data.results = data.results.map((log) => log.toJSON());
        setLogs(data);
        message.destroy(0);
      }
    } catch (err) {
      message.destroy(0);
      message.error(err.message);
    }
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const params = { limit: 100 };
    if (search.get("type")) {
      params.type = search.get("type");
    }
    if (search.get("target")) {
      params.target = search.get("target");
    }
    if (search.get("msg")) {
      params.msg = search.get("msg");
    }
    fetchLogs(params);
  }, []);

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={logs.results}
        pagination={{
          total: logs.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "200", "300", "500"],
        }}
        onChange={(pagination, filters) => {
          fetchLogs({
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            type: filters.type?.length > 0 ? filters.type[0] : undefined,
            target: filters.target?.length > 0 ? filters.target[0] : undefined,
            msg: filters.msg?.length > 0 ? filters.msg[0] : undefined,
          });
        }}
        scroll={{ x: "1200" }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
