import { useEffect, useState } from "react";
import { Form, Button, message, Row, Col } from "antd";
import styled from "styled-components";
import RiderRegistrationForm from "../../components/rider/registration/Form";
import { getSignedUrl, uploadFile } from "../../api/media";
import Parse from "parse";
import { getHubs } from "../../api/hub";

/**
 * @typedef {object} RiderType
 *  @property {string} objectId
 * @property {string} name
 * @property {string} username
 * @property {string} phone
 * @property {string} email
 * @property {string} date_of_birth
 * @property {string} jobType
 * @property {string} employeeType
 * @property {string} dutyHour
 * @property {string[]} nid
 * @property {string} nidNumber
 * @property {string[]} bikeDocuments
 * @property {string[]} grantorNid
 * @property {string} grantorContact
 * @property {string} grantorRelation
 * @property {string[]} license
 * @property {object} referredBy
 * @property {string} presentAddress
 * @property {string} permanentAddress
 * @property {string} joiningDate
 * @property {string} endingDate
 * @property {string} vehicleType
 */

export const uploadFiles = async (fileList = []) => {
  fileList = fileList.filter((file) => file);
  return Promise.all(
    fileList.map(async (file) => {
      const { url } = await getSignedUrl(file.type);
      await uploadFile({ url, file });
      const image = url.split("?")[0];
      return image;
    })
  );
};

export const mergeTypesData = (types, values) => {
  return types.reduce((acc, [key, defaultValue, required, validator]) => {
    if (required && !values[key]) {
      throw new Error(`${key} is required`);
    } else if (
      required &&
      Array.isArray(values[key]) &&
      values[key].length === 0
    ) {
      throw new Error(`${key} is required`);
    } else if (values[key] && typeof defaultValue !== typeof values[key]) {
      throw new Error(`${key} type is not valid`);
    } else if (values[key] && validator) {
      values[key] = validator(key, values[key]);
    }

    acc[key] = values[key] || defaultValue;
    return acc;
  }, {});
};

export const isDate = (keyName, value) => {
  const is =
    new Date(value).toString() !== "Invalid Date" && !isNaN(new Date(value));
  if (!is) {
    throw new Error(`${keyName} is not valid date`);
  }
  return new Date(value).toISOString();
};

export const riderTypes = [
  ["image", [], true],
  ["name", "", true],
  ["username", "", true],
  ["password", "", true],
  ["phone", "", true],
  ["email", "", false],
  ["date_of_birth", "", false, isDate],
  ["jobType", "salary", true],
  ["employeeType", "fulltime", true],
  ["dutyHour", "", true],
  ["hub", "", true],
  ["nid", [], false],
  ["nidNumber", "", true],
  ["bikeDocuments", [], false],
  ["grantorNid", [], false],
  ["grantorContact", "", false],
  ["grantorRelation", "", false],
  ["vehicleType", "bike", true],
  ["vehicleNumber", "", false],
  ["license", [], false],
  ["referredBy", "", false],
  ["presentAddress", "", false],
  ["permanentAddress", "", false],
  ["joiningDate", "", false, isDate],
  ["endingDate", "", false, isDate],
];

export default function RiderRegistration() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hubs, setHubs] = useState([]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (values.date_of_birth)
        values.date_of_birth = values.date_of_birth.toISOString();
      if (values.joiningDate)
        values.joiningDate = values.joiningDate.toISOString();
      if (values.endingDate)
        values.endingDate = values.endingDate.toISOString();
      const images = await uploadFiles(values.images);
      values.image = images;
      values = mergeTypesData(riderTypes, values);

      if (!values.email) delete values.email;

      values.nid = await uploadFiles(values.nid);
      values.bikeDocuments = await uploadFiles(values.bikeDocuments);
      values.license = await uploadFiles(values.license);
      values.grantorNid = await uploadFiles(values.grantorNid);

      await Parse.Cloud.run("newRider", { ...values, image: images[0] });
      form.resetFields();
      message.success("Rider added successfully");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const hubs = await getHubs({ select: ["name"] });
        setHubs(hubs.results.map((r) => r.toJSON()));
      } catch (err) {
        message.error(err.message);
      }
    })();
  }, []);

  return (
    <Wrapper md={18} lg={16} xl={12}>
      <div className="header">
        <h3 className="title">Add New Rider</h3>
      </div>
      <Row justify="center">
        <Col span={24} lg={20} xl={16}>
          <Form
            form={form}
            size="large"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="addUser"
            onFinish={onFinish}
            scrollToFirstError={true}
          >
            <RiderRegistrationForm hubs={hubs} />
            <div className="footer">
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                size="large"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .section {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    min-height: 450px;
    border-radius: 10px;
  }

  .header {
    padding: 20px 0;
    .title {
      font-size: 22px;
      margin-bottom: 0;
      text-align: center;
      text-transform: uppercase;
      color: gray;
    }
  }

  .ant-form-item-label {
    line-height: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .profile-picture {
    position: relative;

    img {
      height: 150px;
      width: 150px;
      border-radius: 10px;
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      color: red;
    }
  }

  .footer {
    padding: 20px 0;
    margin-top: 10px;
    text-align: right;
  }
`;
