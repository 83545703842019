import { Button, Col, Input, message, Row, Space, Table } from "antd";
import { useState } from "react";
import Parse from "parse";
import { ScanOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";

function InventoryInStoreScan({ newItem = () => {} }) {
  const [isScan, setIsScan] = useState(false);
  const [scan, setScan] = useState("");

  const scanHandler = async (e) => {
    const value = e.target.value;
    setScan(value);
    if (value.length === 10) {
      try {
        setScan("");
        const res = await Parse.Cloud.run("barCodeScanForInStore", {
          code: value,
        });
        newItem(res);
        message.success("Scanned");
      } catch (err) {
        console.log(err);
        message.error(err.message);
        setScan("");
      }
    }
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {isScan && (
        <Space>
          <Input
            autoSize={{ minRows: 3, maxRows: 3 }}
            value={scan}
            allowClear
            onChange={scanHandler}
            placeholder="In Store Scan"
            style={{ border: "3px solid  #1890ff", height: "50px" }}
          />
        </Space>
      )}

      {!isScan && (
        <Button
          icon={<ScanOutlined />}
          type="primary"
          onClick={() => setIsScan(true)}
        >
          Barcode Scan For In Store
        </Button>
      )}
    </div>
  );
}

function PickupItem({ id, name, image, quantity, category }) {
  return (
    <div className="pickup__item">
      <div className="pickup__item__info">
        <img src={image} alt="" width="50px" className="pickup__item__image" />
        <div>
          <div className="pickup__item__name">{name}</div>
          <div className="pickup__item__category">@{category?.name}</div>
        </div>
      </div>
      <div className="pickup__item__quantity">{quantity}</div>
    </div>
  );
}

function Pickup({ order_number, name, image, items, ...rest }) {
  return (
    <div className="pickup">
      <div className="pickup__header">
        <div className="pickup__name">{name}</div>
        <div className="pickup__order_number">{order_number}</div>
      </div>
      <div className="pickup__items">
        {items.map((item) => (
          <PickupItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
}

function ScanForOrder({ id, scanned }) {
  const [isScan, setIsScan] = useState(false);
  const [scan, setScan] = useState("");

  const scanHandler = async (e) => {
    if (e.keyCode) return;
    const value = e.target.value;
    setScan(value);
    if (value.length === 10) {
      setScan("");
      try {
        const res = await Parse.Cloud.run("barcodeScanForOrder", {
          orderId: id,
          barCode: value,
        });
        scanned(res);
        message.success("Scanned");
      } catch (err) {
        message.error(err.message);
      }
    }
  };

  return (
    <div>
      {isScan && (
        <Input
          value={scan}
          onChange={scanHandler}
          placeholder="Scan"
          className="order__scan"
        />
      )}

      {!isScan && (
        <Button type="primary" onClick={() => setIsScan(true)}>
          Scan
        </Button>
      )}
    </div>
  );
}

function Order({ id, status, pickups, hub }) {
  const [scannedItems, setScannedItems] = useState([]);
  return (
    <Col span={24} xl={12}>
      <div className="order">
        <div className="header">
          <div>
            <div className="order__id">{id}</div>
            <div className="order__hub">@{hub}</div>
          </div>
          <Space>
            <div className="order__status">{status}</div>
            <ScanForOrder
              scanned={(item) => {
                const index = scannedItems.findIndex(
                  (i) => i.product.id === item.product.id
                );
                if (index !== -1) {
                  scannedItems[index].quantity += 1;
                  setScannedItems([...scannedItems]);
                  return;
                }
                scannedItems.push({ ...item, quantity: 1 });
                setScannedItems([...scannedItems]);
              }}
              id={id}
            />
          </Space>
        </div>
        {scannedItems.length > 0 && (
          <div className="scanned__items">
            <h3>Scanned Items</h3>
            {scannedItems.map((item) => {
              return (
                <div key={item.id} className="scanned__items_item">
                  <div>{item.product?.name}</div>
                  <div>{item.quantity}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className="order__pickups">
          {pickups.map((pickup) => (
            <Pickup key={pickup.order_number} {...pickup} />
          ))}
        </div>
      </div>
    </Col>
  );
}

function ScannedInStoreItems({ items = [] }) {
  return (
    <div>
      <Table
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
          },
        ]}
        dataSource={items}
      />
    </div>
  );
}

export default function InventoryScanning() {
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [scannedItems, setScannedItems] = useState([]);

  const searchOrder = async (search) => {
    if (!search) return;
    try {
      setLoading(true);
      const byOrderId = new Parse.Query("order").equalTo("objectId", search);
      const byOrderNumber = new Parse.Query("order").containedIn(
        "pickups.order_number",
        [search, search.toUpperCase()]
      );

      const res = await Parse.Query.or(byOrderNumber, byOrderId)
        .notContainedIn("status", ["created", "pending"])
        .containedIn("pickups.type", ["store", "sub_store"])
        .select(["pickups", "status", "hub.name"])
        .find();

      const result = res.map((order) => {
        let pickups = order.get("pickups");
        pickups = pickups.filter((pickup) =>
          ["store", "sub_store"].includes(pickup.type)
        );

        return {
          id: order.id,
          status: order.get("status"),
          pickups,
          hub: order.get("hub")?.get("name"),
        };
      });

      setOrders(result);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={8}>
          <InventoryInStoreScan
            newItem={(item) => {
              const { product, objectId: id } = item.toJSON();
              const index = scannedItems.findIndex(
                (i) => i.id === product.objectId
              );

              if (index !== -1) {
                scannedItems[index].quantity += 1;
                scannedItems[index].key = id;
                const updatedItem = scannedItems[index];
                scannedItems.splice(index, 1);
                scannedItems.unshift(updatedItem);
                setScannedItems([...scannedItems]);
                return;
              }

              const newItem = {
                name: product.name,
                id: product.objectId,
                quantity: 1,
                key: id,
              };
              scannedItems.unshift(newItem);
              setScannedItems([...scannedItems]);
            }}
          />
          <ScannedInStoreItems items={scannedItems} />
        </Col>
        <Col span={24} lg={16}>
          <div style={{ marginBottom: "10px" }}>
            <Space>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Order Id"
              />
              <Button
                loading={loading}
                type="primary"
                onClick={() => searchOrder(search)}
                icon={<SearchOutlined />}
              >
                Search Order
              </Button>
            </Space>
          </div>
          <Row gutter={[10, 10]}>
            {orders.map((order) => (
              <Order key={order.id} {...order} />
            ))}
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .search {
    margin-bottom: 10px;
  }
  .order {
    padding: 12px;
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    .header {
      background: #000;
      padding: 10px;
      border-radius: 10px;
      color: #fff;
      display: flex;
      align-items: start;
      justify-content: space-between;
    }

    &__scan {
      width: 100px;
      border: none;
    }

    &__id {
      font-weight: 500;
    }

    &__hub {
      font-weight: 500;
      color: #e4e4e4;
      letter-spacing: 1px;
    }

    &__status {
      background: #fff;
      padding: 3px 10px;
      color: #000;
      border-radius: 30px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .scanned__items {
      margin-top: 10px;

      .scanned__items_item {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid lightgray;
      }
    }

    .order__pickups {
      margin-top: 10px;

      .pickup {
        background: #f7f7f7;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid lightgray;

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 10px;
          margin-bottom: 10px;
          background: #fff;
          border-radius: 10px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          border: 1px solid #ff7875;
        }

        &__items {
          background: #fff;
          border-radius: 10px;
          border: 1px solid gray;
        }

        &__item {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:not(:last-child) {
            border-bottom: 1px solid lightgray;
          }

          &__info {
            display: flex;
            align-items: center;
          }

          &__image {
            border-radius: 10px;
            margin-right: 10px;
          }

          &__quantity {
            width: 40px;
            height: 40px;
            background: rgba(0, 0, 0, 0.85);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 50%;
            font-size: 18px;
          }
        }
      }
    }
  }
`;
