import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import "lrm-graphhopper";
import "./test.css";
const createRoutineMachineLayer = (props) => {
  const { routes, color } = props;

  // remove default marker

  const instance = L.Routing.control({
    waypoints: routes.map((route) => {
      return L.latLng(route[0], route[1]);
    }),
    lineOptions: {
      styles: [{ color: color, weight: 3.4, className: "animate" }],
    },
    show: false,
    addWaypoints: false,
    routeWhileDragging: false,
    draggableWaypoints: false,
    waypointMode: "snap",
    router: L.Routing.graphHopper(undefined /* no api key */, {
      serviceUrl: "https://graph.munchies.com.bd/route",
      urlParameters: {
        profile: "car",
        elevation: false,
        instructions: false,
      },
    }),
    fitSelectedRoutes: false,
    showAlternatives: false,
    createMarker: () => null,
  });

  return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
