import { Form, Col, Input, Space, Button, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Box } from "../../../Common/Boxer";
import { isFloat } from "../../../../common/utils";

const { Item, List } = Form;

export default function Addons({ addons = [] }) {
  return (
    <Box title="Addons">
      <Item label="Addons">
        <Item name={["addons", "title"]} style={{ marginBottom: "10px" }}>
          <Input
            style={{ maxWidth: "350px" }}
            size="large"
            placeholder="Title"
          />
        </Item>
        <Item style={{ marginBottom: "10px" }}>
          <Space direction="horizontal">
            <Item
              label="Min"
              name={["addons", "min"]}
              style={{ marginBottom: "0" }}
            >
              <InputNumber style={{ minWidth: "100px" }} size="large" min={0} />
            </Item>
            <Item
              label="Max"
              name={["addons", "max"]}
              style={{ marginBottom: "0" }}
            >
              <InputNumber style={{ minWidth: "100px" }} size="large" min={0} />
            </Item>
          </Space>
        </Item>
        <List name={["addons", "items"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Missing name" }]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input size="large" placeholder="Name" />
                  </Item>
                  <Item
                    {...restField}
                    name={[name, "price"]}
                    style={{ marginBottom: "0px" }}
                    rules={[
                      {
                        required: true,
                        type: "number",
                        validator: (_, value) => {
                          if (value === undefined) {
                            return Promise.reject("Price required!");
                          } else if (isFloat(value)) {
                            return Promise.reject("Price must be integer");
                          } else if (value < 0) {
                            return Promise.reject("Price must be positive");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: 120 }}
                      size="large"
                      placeholder="Price"
                    />
                  </Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Item style={{ marginBottom: "5px" }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Add Addons
                </Button>
              </Item>
            </>
          )}
        </List>
      </Item>
    </Box>
  );
}
